import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { truncateString } from 'common/utils';
import api from 'api';
import { useController } from 'react-hook-form';
import { capitalizeString } from 'utils/functions';
import { useTranslation } from 'react-i18next';

const MultiSelectLanguages = (props) => {
  const [departmentsList, setDepartmentsList] = useState([]);
  const [value, setValues] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (value.length === 0) {
      setValues([]);
    }
  }, [value]);

  function sortByAlphabetical() {
    return function (a, b) {
      return a.translation.localeCompare(b.translation);
    };
  }

  const sortedByUserLanguage = (list) => {
    const translatedList = list.map((item) => ({ ...item, translation: t(item.label) }));
    return translatedList.sort(sortByAlphabetical());
  };

  useEffect(() => {
    if (props.options) {
      setDepartmentsList(sortedByUserLanguage(props.options));
    }
  }, []);

  const filterDepartments = (selectedLabels) => {
    return departmentsList.filter((department) => selectedLabels.includes(department.value));
  };

  useEffect(() => {
    if (props.queryParams && props.queryParams.length == 0) {
      setValues([]);
    } else if (props.queryParams) {
      console.log('props.queryParams 1', props.queryParams);
      console.log('props.queryParams 2', filterDepartments(props.queryParams));
      //console.log('props.queryParams 2', handleTransformListForOptionsSelect(props.queryParams));
      setValues(filterDepartments(props.queryParams));
    }
  }, [props.queryParams]);

  const mergeWithoutDuplicates = (newList) => {
    const list = props.labels.filter((item) => {
      if (item.key === props.keyLabel && !newList.includes((obj) => obj.value === item.value)) {
        return false;
      }
      return true;
    });
    const combined = list.concat(newList);
    const unique = {};

    combined.forEach((item) => {
      if (!unique[item.value]) {
        unique[item.value] = item;
      }
    });

    return Object.values(unique);
  };

  return (
    <Box>
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={departmentsList}
        limitTags={1}
        disableListWrap
        getOptionLabel={(option) => t(option.label) || ''}
        onChange={(event, newValue) => {
          setValues(newValue);
          props.setLabels(
            mergeWithoutDuplicates(
              newValue.map((item) => ({ label: t(item.label), value: item.value, key: props.keyLabel }))
            )
          );
          props.onChange(newValue);
        }}
        sx={{ width: '195px' }}
        renderTags={(value, getTagProps) => {
          const numTags = value.length;
          const limitTags = 1;
          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Tooltip key={index} title={option.label}>
                  <Chip
                    sx={{ height: '20px', fontSize: '10px' }}
                    size="small"
                    {...getTagProps({ index })}
                    label={truncateString(`${t(option.label)}`, 11)}
                  />
                </Tooltip>
              ))}
              {numTags > limitTags && <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>...</Typography>}
            </>
          );
        }}
        renderInput={(params) => <TextField variant="standard" {...params} label={props.label} />}
      />
    </Box>
  );
};

export default MultiSelectLanguages;
