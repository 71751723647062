import { get, del, post, put } from './request';

export default class Scopes {
  static list() {
    return get('/scopes').then((res) => res.data);
  }
  static listWithParams(queryParams) {
    return get('/scopes', queryParams).then((res) => res.data);
  }

  static delete(id) {
    return del(`/scopes/${id}`).then((res) => res.data);
  }

  static create(data) {
    return post('/scopes', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/scopes/${id}`, data).then((res) => res.data);
  }
}
