import React, { useState } from 'react';

// UI Lib dependencies
import { Box, Grid, Chip, Typography, Breadcrumbs, Link } from '@mui/material';

import { useTranslation } from 'react-i18next';

import { useActivities } from 'hooks/usecase/useActivities';

import ActivityButton from './components/ActivityButton/ActivityButton';
import SummaryActivityButton from './components/SummaryActivityButton/SummaryActivityButton';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import styles from './Activities.styles';
import { parseJsonTranslate } from 'utils/parse';
import Button from 'ui/components/Button/Button';
import YooptaEditor from 'ui/components/YooptaEditor/YooptaEditor';
import EditorExercise from './components/EditorExercise/EditorExercise';
import { useNavigate } from 'react-router-dom';

const Activities = ({ programId, editMode = false }) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [exerciseIdSelected, setExerciseIdSelected] = useState('');
  const navigate = useNavigate();

  const { state, actions } = useActivities(programId);

  const { activityIdSelected, activities, exercises, isLoading } = state;
  const { setActivityIdSelected } = actions;

  if (isLoading) return <p>Loading...</p>;

  const categories = [...new Set(activities.map(({ category }) => category))];
  categories.sort();

  const categoriesConfig = [];

  for (const category of categories) {
    categoriesConfig.push({
      title: t(`program_activities.all_activities.sub_title.${category}`),
      activities: activities.filter((activity) => activity.category === category),
    });
  }

  const getActivityById = (id) => {
    for (const category of categoriesConfig) {
      for (const activity of category.activities) {
        if (activity.id === id) {
          return activity;
        }
      }
    }

    return null;
  };

  const getExerciseById = (idSubActivity) => {
    return exercises.find((exercise) => exercise.id === idSubActivity);
  };

  const onActivityClick = (id) => {
    setActivityIdSelected(id);
    setStep(2);
  };

  const onSubActivityClick = (id) => {
    setExerciseIdSelected(id);
    setStep(3);
  };

  const onValidatedChange = (id) => {
    actions.onValidatedChange(id);
  };

  const breadCrumbsConfig = () => {
    const config = [{ id: 'all-activities', title: t('program_activities.all_activities.title') }];

    if (activityIdSelected) {
      const { name } = getActivityById(activityIdSelected);
      config.push({ id: activityIdSelected, title: name });
    }

    if (exerciseIdSelected) {
      const { name } = getExerciseById(exerciseIdSelected);
      config.push({ id: exerciseIdSelected, title: name });
    }

    return config;
  };

  return (
    <>
      {step !== 1 && (
        <ActivitiesBreadcrumbs
          editMode={editMode}
          config={breadCrumbsConfig()}
          onStepChange={(newStep) => {
            setStep(newStep);

            if (newStep <= 2) setExerciseIdSelected('');
          }}
          onCreate={() => {
            navigate(`/activities/${activityIdSelected}/exercises/editor`);
          }}
          onEdit={() => {
            navigate(`/activities/${activityIdSelected}/exercises/${exerciseIdSelected}/editor`);
          }}
        />
      )}
      {step === 1 && <FirstPartActivities categoriesConfig={categoriesConfig} onActivityClick={onActivityClick} />}
      {step === 2 && (
        <SecondPartActivities
          activity={getActivityById(activityIdSelected)}
          exercises={exercises}
          onSubActivityClick={onSubActivityClick}
          onValidatedChange={onValidatedChange}
          editMode={editMode}
        />
      )}
      {step === 3 && <ExerciseDetail exercise={getExerciseById(exerciseIdSelected)} />}
      {step === 4 && <EditorExercise key={`editor-exercise-${exerciseIdSelected}`} exerciseId={exerciseIdSelected} />}
    </>
  );
};

const ActivitiesBreadcrumbs = ({ editMode, config, onStepChange, onCreate, onEdit }) => {
  const { t } = useTranslation();

  const buttonTranslate = config.length === 3 ? t('edit') : t('create');

  return (
    <Box sx={styles.breadcrumbsContainer}>
      <Breadcrumbs aria-label="breadcrumb">
        {config.map(({ id, title }, index) => (
          <Link
            key={id}
            underline="none"
            color={index === config.length - 1 ? 'primary.main' : 'inherit'}
            sx={[styles.breadcrumbsText, index !== config.length - 1 ? styles.breadcrumbsTextCursor : {}]}
            onClick={() => {
              onStepChange(index + 1);
            }}>
            {parseJsonTranslate(title)}
          </Link>
        ))}
      </Breadcrumbs>

      {editMode && config.length <= 3 && (
        <Button
          variant="secondary"
          onClick={() => {
            config.length === 3 ? onEdit() : onCreate();
          }}>
          {buttonTranslate}
        </Button>
      )}
    </Box>
  );
};

const ExerciseDetail = ({ exercise }) => {
  return (
    <>
      <Box sx={styles.exerciseDetailHeader}>
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: '700',
          }}>
          {parseJsonTranslate(exercise.name)}
        </Typography>

        <Chip label={`${exercise.duration}min`} sx={styles.timeChip} />
      </Box>

      {exercise.content && (
        <Typography sx={{ fontSize: '16px', overflowWrap: 'break-word' }}>
          <YooptaEditor readOnly style={{ width: '100%' }} value={parseJsonTranslate(exercise.content)} />
        </Typography>
      )}
    </>
  );
};

const SecondPartActivities = ({ activity, editMode, exercises = [], onValidatedChange, onSubActivityClick }) => {
  const { t } = useTranslation();

  const subTitle = exercises.length
    ? 'program_activities.second-part.sub_title'
    : 'program_activities.second-part.no_activity';

  return (
    <>
      <Box sx={styles.secondPartHeader}>
        <Box sx={styles.secondPartHeaderImg} component="img" src={activity.picture} />
        <Box>
          <Typography
            sx={{
              fontSize: '22px',
              fontWeight: '700',
            }}>
            {parseJsonTranslate(activity.name)}
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              color: 'primary.grey',
            }}>
            {t(subTitle)}
          </Typography>
        </Box>
      </Box>

      {!!exercises?.length && (
        <Grid container spacing={'24px'} sx={{ marginTop: '1px' }}>
          {exercises.map(({ id, subtitle, name, programExercisesValidated, duration }) => (
            <Grid key={id} xs={12} sm={12} md={6} lg={6} xl={4} item>
              <SummaryActivityButton
                editMode={editMode}
                validated={!!programExercisesValidated?.length}
                desc={subtitle}
                title={name}
                duration={duration}
                onValidatedChange={() => onValidatedChange(id)}
                onClick={() => onSubActivityClick(id)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

const FirstPartActivities = ({ categoriesConfig, onActivityClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        sx={{
          display: 'flex',
          fontSize: '20px',
          fontWeight: '700',
        }}>
        <ChatBubbleIcon sx={{ marginRight: '6px' }} />
        {t('program_activities.title')}
      </Typography>
      {categoriesConfig.map(({ title, activities }) => (
        <Box key={title}>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: '700',
              margin: '16px 0',
            }}>
            {title}
          </Typography>

          <Box sx={styles.containerActivities}>
            {activities.map(({ id, name, picture }) => (
              <Box key={id} xs={4} sm={4} md={4} lg={3} item>
                <ActivityButton id={id} img={picture} title={name} onClick={onActivityClick} />
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </>
  );
};

export default Activities;
