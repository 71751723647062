import React, { useState } from 'react';
import { Button, Box, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import useStore from 'store';
import useViewModal from 'hooks/shared/useViewModal';
import useFormPublic from 'hooks/usecase/useFormPublic';
import ButtonUI from 'ui/components/Button/Button';
import { useChooseExpert } from 'hooks/usecase/useChooseExpert';

// Components
import CoachItem from './CoachItem';
import DeleteButton from './DeleteButton';
import CircularProgress from '@mui/material/CircularProgress';
import ChooseExpertContent from 'ui/components/ChooseExpertContent/ChooseExpertContent';

import {
  GO_BACK,
  STEP3_CHOOSE_YOUR_EXPERT,
  STEP3_ORDER_SELECTION,
  STEP3_ORDER_SELECTION_SUBTITLE,
  STEP3_SUBTITLE,
  VALIDATE,
} from 'litterals';

const ChooseExpert = ({ onSubmit, isLoadingSubmit, actions: storeActions, showBackButton = true }) => {
  const { t } = useTranslation();
  const {
    company,
    selectedExperts,
    removeExpert,
    removeOrderedExpert,
    setOrderedSelectedExperts,
    orderedSelectedExperts,
  } = useStore();
  const [queryParams, setQueryParams] = useState({ page: 1, size: 20 });
  const { totalPages, coaches, isLoadingCoaches, isFetched } = useChooseExpert(company.evergreenLink, queryParams);

  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    { field: 'actions', headerName: '', renderCell: CoachItem, flex: 1, minWidth: 180 },
    {
      field: 'remove',
      headerName: '',
      // eslint-disable-next-line no-unused-vars
      renderCell: (params) => (
        <DeleteButton
          onClick={() => {
            removeExpert(params.id);
            removeOrderedExpert(params.id);
          }}
        />
      ),
    },
  ];

  function updateRowPosition(initialIndex, newIndex, rows) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        resolve(rowsClone);
      }, Math.random() * 500 + 100); // simulate network latency
    });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        '&::-webkit-scrollbar': {
          '-webkit-appearance': 'none',
          width: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '25px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
        },
      }}>
      <Box
        sx={{
          padding: '32px 24px 32px 24px',
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}>
        <Typography sx={{ fontSize: '30px', lineHeight: '37px', fontWeight: 700 }}>
          {t(STEP3_CHOOSE_YOUR_EXPERT, { expertLabel: company?.expertLabel || '' })}
        </Typography>
        <Typography sx={{ fontSize: '20px', lineHeight: '37px', fontWeight: 400, color: '#00000099' }}>
          {t(STEP3_SUBTITLE, { expertLabel: company && company.expertLabel ? company.expertLabel.toLowerCase() : '' })}
        </Typography>

        <Box>
          <ChooseExpertContent
            company={company}
            totalPages={totalPages}
            coaches={coaches}
            isLoadingCoaches={isLoadingCoaches}
            isFetched={isFetched}
            queryParams={queryParams}
            noExceedMaxCapacity
            setQueryParams={setQueryParams}
          />
        </Box>

        {showBackButton && (
          <Stack alignItems="center">
            <ButtonUI
              onClick={() => {
                storeActions.handleGoBack();
              }}
              style={{ marginTop: '20px' }}
              variant="primary">
              {t(GO_BACK)}
            </ButtonUI>
          </Stack>
        )}
      </Box>
      {/* Order of selection coachs */}
      {Array.isArray(selectedExperts) && selectedExperts.length > 0 ? (
        <>
          <Box
            sx={{
              marginLeft: '20px',
              backgroundColor: 'white',
              width: '314px',
              padding: '32px 20px',
            }}>
            <Typography sx={{ fontSize: '30px', lineHeight: '37px', fontWeight: 500, color: '#000000' }}>
              {t(STEP3_ORDER_SELECTION).replace(
                '{{expertLabel}}',
                company && company.expertLabel ? company.expertLabel : ''
              )}
            </Typography>
            <Box height={'8px'} />
            <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#00000099' }}>
              {t(STEP3_ORDER_SELECTION_SUBTITLE)}
            </Typography>
            <Box height={'16px'} />
            <Box
              sx={{
                height: '300px',
                overflowX: 'hidden',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  '-webkit-appearance': 'none',
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '25px',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
                },
              }}>
              <DataGridPro
                columnHeaderHeight={0}
                rows={orderedSelectedExperts}
                columns={columns}
                rowReordering
                hideFooterRowCount
                hideFooter
                loading={isLoading}
                onRowOrderChange={async (params) => {
                  setIsLoading(true);
                  const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, [
                    ...orderedSelectedExperts,
                  ]);
                  setOrderedSelectedExperts(newRows);
                  setIsLoading(false);
                }}
              />
            </Box>
            <Box height={'15px'} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                onClick={onSubmit}
                variant="contained"
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'white',
                  width: '111px',
                  height: '42px',
                  textTransform: 'none',
                }}>
                {isLoadingSubmit ? <CircularProgress sx={{ color: 'white' }} size={20} /> : t(VALIDATE)}
              </Button>
            </Box>
          </Box>
        </>
      ) : null}
    </Box>
  );
};

export default ChooseExpert;
