import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from 'common/alertContext';

import api from 'api';

const defaultValues = {
  firstname: '',
  lastname: '',
  email: '',
  idNumber: '',
  managerEmail: '',
  scopeId: '',
};

const useInviteCoachee = (onSuccessFn) => {
  const [disabled, setDisabled] = React.useState(false);
  const { setStateAlert, stateAlert } = useAlert();
  const { handleSubmit, control, setError, reset } = useForm({
    mode: 'all',
    defaultValues,
  });
  const inviteCoachee = useMutation(api.Users.createCoachee, {
    onSuccess: (data) => {
      onSuccessFn(data);
      setDisabled(true);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const inviteCoacheeGuest = useMutation(api.Guests.invite, {
    onSuccess: (data) => {
      onSuccessFn(data);
      setDisabled(true);
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const submitHandler = handleSubmit((data) => {
    data.firstname = data.firstname.replace(/ /g, '');
    data.lastname = data.lastname.replace(/ /g, '');
    inviteCoachee.mutate(data);
    reset();
  });

  const handleInviteCoachee = (data) => {
    if (data.source === 'User') {
      return onSuccessFn(data);
    } else if (data.source === 'Guest') return inviteCoacheeGuest.mutate(data.id);
  };

  const resetHandler = () => {
    reset();
    setDisabled(false);
  };

  return {
    state: {
      nav: {
        disabled,
      },
      form: { control },
    },
    actions: {
      isAddLoading: inviteCoachee.isLoading,
      submitForm: submitHandler,
      handleInviteCoachee: handleInviteCoachee,
      resetForm: resetHandler,
    },
  };
};

export default useInviteCoachee;
