import React from 'react';

import styles from './Select.styles';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import { useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const index = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    ref: props.ref,
  });

  return (
    <FormControl sx={{ width: '100%' }} variant="standard">
      <InputLabel error={!!error} id="demo-simple-select-standard-label">
        {props.label}
      </InputLabel>
      <Select
        inputRef={ref}
        error={!!error}
        helperText={error?.message}
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={onChange}>
        {props.options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {t(option.label)}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default index;
