import React from 'react';
import Box from 'ui/components/system/Box';
import Typography from '@mui/material/Typography';
import Switch from 'ui/components/form/Switch';

import { useTranslation } from 'react-i18next';

const ActiveRemindersForm = ({ control }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ margin: '24px', maxWidth: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="h5" component="h3" sx={{ mb: '24px' }}>
        {t('active_reminders_form.title')}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          fontFamily: 'Karla',
          fontSize: '16px',
          fontStyle: 'normal',
          lineHeight: '150%', // 24px
          letterSpacing: '0.15px',
          color: 'subtitle.main',
          marginTop: '24px',
        }}>
        {t('active_reminders_form.desc')}
      </Typography>
      <Switch
        control={control}
        variant="outlined"
        label={t('active_reminders_form.label')}
        labelPlacement="end"
        name="reminderActivated"
        fullWidth
      />
    </Box>
  );
};

export default ActiveRemindersForm;
