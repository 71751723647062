import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import NavBar from './NavBar/NavBar';
import { Alert, Snackbar } from '@mui/material';
import { useAlert } from 'common/alertContext';

const Layout = () => {
  const { stateAlert, setStateAlert } = useAlert();

  const handleOnClose = () => {
    setStateAlert((previousState) => ({ ...previousState, open: false }));
  };

  return (
    <React.Fragment>
      <Box sx={{ height: '100%', backgroundColor: 'background.secondary', overflowY: 'auto' }}>
        <NavBar isFixed />
        <Box
          sx={{
            minHeight: 'calc(100vh - 65px)',
            marginTop: '65px',
            display: 'flex',
            flexDirection: 'column',
            padding: '0',
          }}>
          <Outlet />
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={stateAlert.open}
        autoHideDuration={stateAlert.duration || 3000}
        onClose={handleOnClose}>
        <Alert severity={stateAlert.type} variant="filled" sx={{ width: '100%' }} onClose={handleOnClose}>
          {stateAlert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default Layout;
