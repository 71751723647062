const styles = {
  containerActivities: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px',
  },

  breadcrumbsText: {
    textTransform: 'uppercase',
    fontWeight: 400,
    fontSize: '15px',
  },

  breadcrumbsTextCursor: {
    cursor: 'pointer',
  },

  secondPartHeader: {
    display: 'flex',
    height: '60px',
    marginTop: '24px',
  },

  secondPartHeaderImg: {
    marginRight: '16px',
  },

  exerciseDetailHeader: {
    marginTop: '24px',
    marginBottom: '6px',
    justifyContent: 'space-between',
    display: 'flex',
  },

  timeChip: {
    color: '#B18225',
    background: '#FEBA361F',
  },

  breadcrumbsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
export default styles;
