import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useListTargetTemplateItemQueryWithParams } from 'hooks/queries';

const useTargetTemplateItems = (companyId, queryParams) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingTargetTemplateItem, setEditingTargetTemplateItem] = useState(null);

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const { data: targetTemplateItems, isLoading } = useListTargetTemplateItemQueryWithParams(queryParams);

  const mutationDelete = useMutation(api.TargetTemplateItems.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-target-template-items-params']);
      setStateAlert({ open: true, message: 'target template itemsuccessfully deleted' });
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const onRemoveTargetTemplateItem = (id) => {
    mutationDelete.mutate(id);
  };

  const mutationAdd = useMutation(api.TargetTemplateItems.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-target-template-items-params']);
      setStateAlert({ open: true, message: 'target template itemsuccessfully created' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const onAddTargetTemplateItem = handleSubmit(async (data) => {
    mutationAdd.mutate({ ...data, companyId: companyId });
  });

  const mutationEdit = useMutation(api.TargetTemplateItems.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-target-template-items-params']);
      setStateAlert({ open: true, message: 'target template itemsuccessfully edited' });
      setIsOpenEditModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  useEffect(() => {
    if (editingTargetTemplateItem) {
      setValue('name', editingTargetTemplateItem.name);
      setIsOpenEditModal(true);
    }
  }, [editingTargetTemplateItem]);

  const onEditTargetTemplateItem = handleSubmit(async (data) => {
    mutationEdit.mutate({ id: editingTargetTemplateItem.id, data: { ...data, companyId: companyId } });
  });

  return {
    isEditLoading: mutationEdit.isLoading,
    isAddLoading: mutationAdd.isLoading,
    isLoading: isLoading,
    targetTemplateItems: targetTemplateItems ? targetTemplateItems.rows : [],
    totalPages: targetTemplateItems && targetTemplateItems.totalPages,
    totalItems: targetTemplateItems && targetTemplateItems.totalItems,
    control,
    watch,
    reset,
    getValues,
    onRemoveTargetTemplateItem,
    onAddTargetTemplateItem,
    isOpenAddModal,
    setIsOpenAddModal,
    setEditingTargetTemplateItem,
    setIsOpenEditModal,
    isOpenEditModal,
    onEditTargetTemplateItem,
  };
};

export default useTargetTemplateItems;
