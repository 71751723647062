/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';

// Style
import { StatusEvergreenChip } from 'ui/components/StatusEvergreenChip/StatusEvergreenChip';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellStatusEvergreen({ status }) {
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <StatusEvergreenChip status={status} />
    </Stack>
  );
}

export default CellStatusEvergreen;
