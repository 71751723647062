import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteButton = (props) => {
  return (
    <IconButton
      onClick={() => {
        props.onClick();
      }}>
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteButton;
