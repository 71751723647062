import React from 'react';
import { Box, Tooltip } from '@mui/material';
import Switch from 'ui/components/form/Switch';
import { detailedCoachActivationLabel } from 'utils/coach';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import UserChip from '../UserChip/UserChip';

import { t } from 'i18next';

const chipIconStyle = {
  fontSize: '0.9rem',
  marginRight: '4px',
};

const CoachActivationSwitch = ({ active, deactivatedFrom, deactivatedUntil, onChange }) => {
  const tooltipActivationSwitch = detailedCoachActivationLabel(active, deactivatedFrom, deactivatedUntil, t);

  const chipIcon = active ? (
    <VisibilityOutlinedIcon sx={chipIconStyle} />
  ) : (
    <VisibilityOffOutlinedIcon sx={chipIconStyle} />
  );
  const chipColor = active ? 'primary' : 'black';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <UserChip color={chipColor} icon={chipIcon} sxLabel={{ fontSize: '13px' }} label={tooltipActivationSwitch} />

      <SettingsOutlinedIcon
        sx={{ cursor: 'pointer', color: '#475467', marginLeft: '12px', marginRight: '4px', fontSize: '18px' }}
        onClick={() => onChange(!active)}
      />
    </Box>
  );
};

export default CoachActivationSwitch;
