import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CANCEL, VALIDATE } from 'litterals';
import CloseIcon from '@mui/icons-material/Close';

const DeleteModal = ({ open, onClose, onValidate, itemToDelete }) => {
  const { t } = useTranslation();

  const fullname = itemToDelete ? itemToDelete.firstname + ' ' + itemToDelete.lastname : '';
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          alignSelf: 'end',
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ p: 0, pb: '16px', pl: '24px' }}>{'USER'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{'Do you want to delete user : ' + fullname + ' ?'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>{t(CANCEL)}</Button>
        <Button onClick={() => onValidate()} variant="contained" sx={{ mr: '5px' }}>
          {t(VALIDATE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
