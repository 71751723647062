/* eslint-disable react/react-in-jsx-scope */
/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import TopicFilterOperators from 'ui/components/DataGrid/Filters/TopicFilter/TopicFilter';
import DateFilterOperators from 'ui/components/DataGrid/Filters/DateFilter/DateFilter';
import StatusFilterOperators from 'ui/components/DataGrid/Filters/StatusFilter/StatusFilter';

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/

let tableSchemaBase = [
  { field: 'coachee', hideable: false, headerName: 'coachee', fontSize: '2px', type: 'user', minWidth: 250, flex: 1 },
  { field: 'theme', headerName: 'theme', type: 'topic', minWidth: 200, filterOperators: TopicFilterOperators, flex: 1 },
  {
    field: 'next_session',
    sortable: false,
    filterable: false,
    headerName: 'next_session',
    type: 'text',
    minWidth: 200,
    filterOperators: DateFilterOperators,
    flex: 1,
  },
  {
    field: 'progress',
    headerName: 'progress',
    type: 'text',
    minWidth: 280,
    sortable: true,
    filter: false,
    filterable: false,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'status',
    type: 'status',
    filterOperators: StatusFilterOperators,
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'actions',
    headerName: 'actions',
    sortable: false,
    minWidth: 250,
    filter: false,
    filterable: false,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    type: 'actionsHome',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    hideable: false,

    resizable: true,
  },
];

export { tableSchemaBase };
