import { get, post, put } from './request';

class ProgramExerciseValidated {
  static getById(id) {
    console.log('getById', id);
    return get(`/exercises/${id}`).then((res) => res.data);
  }

  static create({ data }) {
    return post('/exercises', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/exercises/${id}`, data).then((res) => res.data);
  }
}

export default ProgramExerciseValidated;
