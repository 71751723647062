import { get, del, post, put } from './request';

export default class SeniorityLevels {
  static list(companyId) {
    return get(`/seniority-levels/?companyId=${companyId}`).then((res) => res.data);
  }

  static listWithParams(queryParams) {
    console.log('UPDATE LIST PARAMS:', queryParams);
    return get('/seniority-levels', queryParams).then((res) => res.data);
  }

  static listSeniorityMentor() {
    return get('/seniority-levels').then((res) => res.data);
  }

  static listSeniorityEvergreen(_Link) {
    return get(`/guests/${_Link}/seniority-levels`).then((res) => res.data);
  }

  static delete(id) {
    return del(`/seniority-levels/${id}`).then((res) => res.data);
  }

  static create(data) {
    return post('/seniority-levels', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/seniority-levels/${id}`, data).then((res) => res.data);
  }
}
