/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useMemo, useRef } from 'react';
import JoditEditor from 'jodit-react';
import { useController } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { options } from './Editor.configs';
import i18n from 'i18n';
import styles from './Editor.styles';
import { Roles } from 'utils/roles';

/**
 * Jodit Editor component for React forms.
 *
 * @component
 * @example
 * <Editor control={control} name="editorField" ref={ref} rules={rules} />
 *
 * @param {Object} props - Component properties.
 * @param {Object} props.control - Controller provided by react-hook-form.
 * @param {string} props.name - Name of the field in the form.
 * @param {Object} [props.ref] - Reference to the field.
 * @param {Object} [props.rules] - Validation rules for the field.
 *
 * @returns {JSX.Element} Jodit Editor component.
 */

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

export default function Editor(props) {
  // Destructuring props to get control and name
  const { control, name, disableInsertImage } = props;
  const editor = useRef(null);

  // Using useController to manage the form field
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue: null, ref: props.ref, rules: props.rules });

  /**
   * Removes specific buttons based on the user's role.
   *
   * @param {string[]} buttons - The array of buttons to be filtered.
   * @param {string} role - The role of the user (Roles.ADMIN or Roles.USER).
   * @returns {string[]} - The updated array of buttons after filtering.
   */
  const removeButtonByRole = (buttons, role) => {
    const updatedButtons = buttons.reduce((acc, button) => {
      if (role === Roles.ADMIN) {
        acc.push(button);
      } else {
        if (!(button === 'source' || button === 'fullsize')) {
          acc.push(button);
        }
      }
      return acc;
    }, []);
    return updatedButtons;
  };

  const updatedOptions = {
    ...options,
    buttons: removeButtonByRole(options.buttons, props.role),
    language: i18n.language,
  };

  if (disableInsertImage) {
    updatedOptions.removeButtons.push('image');
  }

  // Memoizing options to avoid recalculation on every render
  const config = useMemo(() => updatedOptions, []);

  // We want to remove all unnecessary attributes to avoid very heavy HTML.
  const cleanupContent = () => {
    const editorContent = editor.current.value;
    const div = document.createElement('div');
    div.innerHTML = editorContent;

    const ignoreProperties = ['href', 'src'];

    div.querySelectorAll('*').forEach((element) => {
      const attributes = Array.from(element.attributes);

      for (const attribute of attributes) {
        if (ignoreProperties.includes(attribute?.name)) continue;

        if (attribute?.name === 'style') {
          const newValue = attribute.value.replaceAll(/--[\w-]+\s*:\s*[^;]+;/g, '');
          element.setAttribute('style', newValue);
          continue;
        }
        element.removeAttribute(attribute?.name);
      }
    });
    return div.innerHTML;
  };

  return (
    <Box sx={styles.container}>
      {props.label && <Typography style={styles.label}>{props.label}</Typography>}
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        tabIndex={1}
        onBlur={() => {
          const cleanup = cleanupContent();

          // Calling the onChange function provided by useController
          onChange(cleanup);
          props.onChange && props.onChange(cleanup);
        }}
      />
    </Box>
  );
}
