import React from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CHECK_FOR_NEW_LINK, CONTINUE, LINK_SUCCESS_SENT } from 'litterals';

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  marginTop: '12px',
  // gap: "24px"
};

const SuccessResetLink = ({ onClick, t }) => {
  return (
    <Box sx={{ ...styleBox, gap: '10px' }} component="div">
      <img
        src={`${__dirname}uploads/images/Logo-Check.png`}
        width={'164px'}
        height={'164px'}
        style={{ margin: 'auto' }}
      />
      <Typography color={'#153060'} fontSize={'45px'} lineHeight={'52px'} align={'center'}>
        {t(LINK_SUCCESS_SENT)}
      </Typography>
      <Typography color={'#828282'} fontSize={'16px'} lineHeight={'24px'} align={'center'} letterSpacing={'0.15px'}>
        {t(CHECK_FOR_NEW_LINK)}
      </Typography>
      <Button sx={{ height: '64px', backgroundColor: 'primary.main' }} variant="contained" onClick={onClick}>
        {t(CONTINUE)}
      </Button>
    </Box>
  );
};

export default SuccessResetLink;
