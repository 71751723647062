import React from 'react';

import { useController } from 'react-hook-form';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

const Dropdown = ({ control, name, label }) => {
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue: '' });

  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={value}
        onChange={onChange}
        label={label}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={'Article'}>Article</MenuItem>
        <MenuItem value={'Video'}>Video</MenuItem>
        <MenuItem value={'Podcast'}>Podcast</MenuItem>
        <MenuItem value={'Document'}>Document</MenuItem>
      </Select>
    </FormControl>
  );
};

export default Dropdown;
