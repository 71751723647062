import React from 'react';
import { CHARACTERS, EXPECT_AT_LEAST, VALUE_MUST_BE_LESS_THAN, VALUE_MUST_BE_SET } from 'litterals';
import { useTranslation } from 'react-i18next';
import BaseTextField from './BaseTextField';

const TextField = (
  {
    name,
    control,
    placeholder,
    minLength,
    maxLength,
    required = false,
    pattern,
    defaultValue = '',
    rules = {},
    disabled = false,
    multiline = false,
    ...rest
  },
  ref
) => {
  const { t } = useTranslation();
  if (minLength !== undefined) {
    rules.minLength = {
      value: minLength,
      message: `${t(EXPECT_AT_LEAST)} ${minLength} ${t(CHARACTERS)}`,
    };
  }

  if (maxLength !== undefined) {
    rules.maxLength = {
      value: maxLength,
      message: `${t(VALUE_MUST_BE_LESS_THAN)} ${maxLength} ${t(CHARACTERS)}`,
    };
  }

  if (required) {
    rules.required = typeof rules.required === 'string' ? rules.required : t(VALUE_MUST_BE_SET);
  }

  return (
    <BaseTextField
      ref={ref}
      name={name}
      placeholder={placeholder}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      disabled={disabled}
      pattern={pattern}
      required={required}
      multiline={multiline}
      {...rest}
    />
  );
};

export default React.forwardRef(TextField);
