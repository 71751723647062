/* eslint-disable react/react-in-jsx-scope */
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import styles from './IncrementalStepsInput.styles';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const IncrementalStepsInput = ({ value, setValue, control, max, name }) => {
  const {
    field: { onChange: handleChange },
  } = useController({ control, name: name, defaultValue: null });

  const handleInputChange = (event) => {
    if (/^\d*$/.test(event.target.value) && Number(event.target.value) >= 0 && Number(event.target.value) <= max) {
      setValue(event.target.value);
      handleChange(parseInt(event.target.value));
    }
  };

  const formattedMinutes =
    parseInt(value, 10).toString().length === 1 ? `0${parseInt(value, 10)}` : parseInt(value, 10);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
          }}
          value={formattedMinutes}
          onChange={handleInputChange}
          name={name}
          min="0"
          max={max}
          step="1"
          InputLabelProps={{
            style: {
              fontSize: 12,
              outline: 'none',
            },
          }}
          InputProps={{
            inputProps: {
              min: 0,
              max: { max },
              step: 1,
            },
            inputMode: 'numeric',
            style: {
              outline: 'none',
              fontSize: '16px',
              fontWeight: 'bold',
              height: '27.63px',
            },
          }}
          type="number"
          size="small"
          sx={styles.numericInput}
        />
      </Box>
    </Box>
  );
};

export default IncrementalStepsInput;
