// Importing locale configurations for different languages in the Day.js library
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import 'dayjs/locale/es';

import i18next from 'i18next';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export const formatSessionDate = (date) => {
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format('DD MMMM YYYY');
  }
};

export const formatSessionDateCondensed = (date) => {
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format('DD/MM/YY');
  }
};

export const formatSessionTime = (date) => {
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format('HH:mm');
  }
};

export const sessionDateAfterDate = (date) => {
  return dayjs(date).isAfter(dayjs(), 'minute');
};

export const formatAbbreviatedDate = (date, isMobile) => {
  const format = isMobile ? 'ddd DD MMM' : 'dddd DD MMM';
  if (date) {
    return dayjs(date)
      .locale(i18next ? i18next.language : 'en')
      .format(format);
  }
};
