const styles = {
  text: {
    fontSize: '16px',
    fontWeight: 800,
    lineHeight: '18.7px',
  },
  chip: {
    height: '24.01px',
    minWidth: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
  },
};

export default styles;
