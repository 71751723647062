const styles = {
  footerContainer: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
  },
  footerButton: {
    width: {
      xs: '100%',
      sm: 'max-content',
    },
  },
};
export default styles;
