import React from 'react';

import styles from './Input.styles';

import TextField from '@mui/material/TextField';

import { useController } from 'react-hook-form';

const Input = ({ ...props }) => {
  const {
    fieldState: { error },
    field: { onChange, value, ref },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    ref: props.ref,
  });
  return (
    <TextField
      inputRef={ref}
      sx={styles.container}
      inputProps={styles.inputProps}
      InputLabelProps={styles.InputLabelProps}
      variant="standard"
      onChange={onChange}
      value={value}
      error={!!error}
      helperText={error?.message}
      {...props}
    />
  );
};

export default Input;
