import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';

export const iconList = {
  PersonIcon,
  AccountBoxIcon,
  CheckCircleIcon,
  SettingsApplicationsIcon,
};
