import React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

const CheckBoxItem = ({ onClick, checked, label, checkedColor = 'primary.main' }) => (
  <ListItem
    onClick={onClick}
    sx={{
      padding: '0',
      pb: '4px',
      '& .MuiCheckbox-root': {
        padding: '0px',
      },
    }}
    classes={{
      'MuiButtonBase-root': {
        padding: '0px',
      },
      'MuiCheckbox-root': { padding: '0px' },
    }}>
    <ListItemIcon sx={{ padding: '0', minWidth: '32px' }}>
      <Checkbox
        checked={checked}
        sx={{
          '&.Mui-checked': {
            color: checkedColor,
          },
        }}
      />
    </ListItemIcon>
    <ListItemText sx={{}}>{label}</ListItemText>
  </ListItem>
);

export default CheckBoxItem;
