import { get, post, put, del } from './request';

class SessionTemplates {
  static list() {
    return get('/session_templates').then((res) => res.data);
  }

  static create(payload) {
    return post('/session_templates', payload).then((res) => res.data);
  }

  static edit({ id, payload }) {
    return put(`/session_templates/${id}`, payload).then((res) => res.data);
  }

  static delete(id) {
    return del('/session_templates/' + id).then((res) => res.data);
  }
}

export default SessionTemplates;
