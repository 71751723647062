import { useState, useEffect } from 'react';
import { useListCoachesWithParams } from 'hooks/queries';

export default function (initialFilters) {
  const query = useListCoachesWithParams(initialFilters.initialFilters);
  const [internalFilter, setInternalFilter] = useState(initialFilters.internal);
  const [coachingTypeFilter, setCoachingTypeFilter] = useState(initialFilters.type);
  const [disabledTab, setDisabledTab] = useState('');

  const state = {
    query,
    nav: {
      internalFilter,
      coachingTypeFilter,
      disabledTab,
    },
  };
  state.query.coaches = query.data;

  const changeInternalValue = (value) => {
    setInternalFilter(value);
    localStorage.setItem();
  };
  const changeTypeValue = (value) => {
    setCoachingTypeFilter(value);
  };
  const disableTab = (value) => setDisabledTab(value);

  return {
    state,
    actions: {
      changeInternalValue,
      disableTab,
      changeTypeValue,
    },
  };
}
