const styles = {
  containerDisconnect: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.2)',
    outline: 'none',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  roleName: {
    minWidth: '120px',
    height: '40px',
    backgroundColor: 'inherit',
    cursor: 'auto',
    fontSize: '14px',
    fontFamily: 'Karla',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.17000000178813934px',
    textAlign: 'left',
  },
  navBarButton: {
    margin: '12px 8px',
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Karla',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    textTransform: 'uppercase',
    backgroundColor: '#fff',
  },
  navBarButtonSelected: {
    fontWeight: '500',
    color: '#fff',
    backgroundColor: 'primary.main',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  },
  logo: {
    maxHeight: '60px',
    maxWidth: '100px',
    width: 'auto',
    height: 'auto',
    objectFit: 'contain',
    mr: '5px',
    cursor: 'pointer',
  },
};
export default styles;
