const styles = {
  container: {
    width: '100%',
    '& .MuiFilledInput-root': { bgcolor: 'white' },
    '& .MuiInputBase-root.MuiFilledInput-root:before': { borderBottom: 'none' },
  },
  inputProps: { style: { fontSize: 15 } },
  InputLabelProps: { style: { fontSize: 15 } },
};
export default styles;
