/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, { useState } from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';

// UI Local components
import Input from 'ui/components/Input';
import TitleInput from 'ui/components/TitleInput';
import EditorField from 'ui/components/EditorField';
import Editor from 'ui/components/Editor';

import DeleteModal from 'ui/pages/home/admin/DeleteModal';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// UI Local Lib dependencies
import DataGridReorder from 'ui/components/DataGridReorder';

// Icons
import AddIcon from '@mui/icons-material/Add';

// Hooks
import useSessionTemplates from 'hooks/usecase/useSessionTemplates';

// Style
import styles from './styles';
import { t } from 'i18next';
import { TITLE } from 'litterals';
import { truncateString } from 'common/utils';

// Configs
import { tableSchemaBase as columns } from './SessionTemplates.config';

const SessionTemplates = ({ companyId, themeId, user }) => {
  const {
    onEditsessionTemplate,
    editingsessionTemplate,
    setEditingsessionTemplate,
    sessionTemplates,
    onAddsessionTemplate,
    reset,
    control,
    watch,
    isOpenAddModal,
    setIsOpenAddModal,
    isLoading,
    onRemovesessionTemplate,
    onDuplicatesessionTemplate,
    onReorderSessionTemplates,
  } = useSessionTemplates(companyId, themeId);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  // Helper
  // eslint-disable-next-line no-unused-vars
  const updateList = (sessionTemplates) =>
    sessionTemplates.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        marketingPicture: currentItem.marketingPicture + `?${Date.now()}`,
      };
      acc.push(modifiedItem);

      return acc;
    }, []);

  return (
    <Stack sx={{ height: '500px', backgroundColor: 'white', paddingTop: '50px' }}>
      <Stack sx={styles.containerButton}>
        <Button
          endIcon={<AddIcon />}
          onClick={() => {
            setIsOpenAddModal(true);
          }}
          sx={styles.addButton}>
          ADD SESSION TEMPLATE
        </Button>
      </Stack>
      {/** DataGrid */}
      <DataGridReorder
        onEdit={(sessionTemplate) => {
          setEditingsessionTemplate(sessionTemplate);
        }}
        onDelete={(item) => {
          setSelectedItem(item);
          setIsOpenDeleteModal(true);
        }}
        onDuplicate={(data) => {
          // eslint-disable-next-line no-unused-vars
          const { id, ...rest } = data;
          onDuplicatesessionTemplate(rest);
        }}
        onRowClicked={(data, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingsessionTemplate(data.row);
          }
        }}
        // eslint-disable-next-line no-unused-vars
        onPaginationChange={(data) => {}}
        onReorder={(data) => {
          onReorderSessionTemplates(data);
        }}
        rows={sessionTemplates ? sessionTemplates : []}
        columns={columns}
        loading={isLoading}
      />
      {/** Create  dialog */}
      <CreateSessionTemplate
        onAddsessionTemplate={onAddsessionTemplate}
        setOpen={setIsOpenAddModal}
        isOpen={isOpenAddModal}
        control={control}
        reset={reset}
        watch={watch}
        user={user}
      />
      {/**  Edit dialog */}
      <EditSessionTemplate
        onEditsessionTemplate={onEditsessionTemplate}
        setOpen={setEditingsessionTemplate}
        isOpen={editingsessionTemplate}
        control={control}
        reset={reset}
        watch={watch}
        user={user}
      />

      <DeleteModal
        title="Delete session template"
        description="Are you sure you want to delete this session template"
        open={isOpenDeleteModal}
        onDelete={() => {
          onRemovesessionTemplate(selectedItem.id);
        }}
        handelModal={() => {
          setIsOpenDeleteModal(false);
        }}
      />
    </Stack>
  );
};

const CreateSessionTemplate = ({ control, isOpen, setOpen, onAddsessionTemplate, reset, watch, user }) => {
  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  return (
    <Dialog
      disableEnforceFocus
      PaperProps={{ sx: styles.containerSessionTemplateDialog }}
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle sx={styles.containerTitle}>
        <Tooltip title={watch('session_title')}>
          <Typography style={styles.title}>
            {'Create Session Template ' + truncateString(`${watch('session_title')}`, 30)}
          </Typography>
        </Tooltip>
        <TitleInput
          disabled={false}
          // eslint-disable-next-line no-unused-vars
          exportTitle={(title) => {
            //handleChangeSession(selectedSession.id, { name: title });
          }}
          control={control}
          actions={() => {}}
          session={null}
          placeholder={t(TITLE)}
          name="session_title"
          sx={styles.titleContent}
          inputProps={{ style: styles.titleContent }}
        />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Stack sx={{ minHeight: '630px', backgroundColor: 'white', paddingTop: '50px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={styles.containerInputsSessionDialog}>
              <Input ref={null} control={control} variant="outlined" label="Subtitle" name="session_subtitle" />
            </Box>
            <Box sx={{ width: '48px' }} />
            <Box sx={styles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={control}
                variant="outlined"
                label="Order"
                name="session_order"
                type="number"
              />
            </Box>
          </Box>
          <Box sx={styles.containerInputsSessionDialog}>
            <Editor
              role={user && user.role ? user.role : 'ADMIN'}
              title="Agenda"
              control={control}
              name="session_agenda"
              label={'Agenda'}
              actions={() => {}}
              sx={{ minWidth: '300px', mb: '5px' }}
            />
          </Box>
          <Box
            sx={{
              border: '1px solid #F9F9F9',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              padding: '0px 100px',
            }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: 'title.main',
                alignSelf: 'center',
              }}>
              {'Session Template Preview'}
            </Typography>
            <Box sx={{ display: 'flex', flex: 1 }}>
              <div dangerouslySetInnerHTML={{ __html: watch('session_agenda') }} />
            </Box>
          </Box>
        </Stack>
        {/** Content of dialog */}
      </DialogContent>
      <DialogActions sx={styles.btnBack}>
        <Button variant="outlined" onClick={handleClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={styles.btnSave}
          onClick={() => {
            onAddsessionTemplate();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const EditSessionTemplate = ({ control, isOpen, setOpen, onEditsessionTemplate, reset, watch, user }) => {
  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
    reset();
  };
  return (
    <Dialog
      disableEnforceFocus
      PaperProps={{ sx: styles.containerSessionTemplateDialog }}
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle sx={styles.containerTitle}>
        <Tooltip title={watch('session_title')}>
          <Typography style={styles.title}>
            {'Edit Session Template ' + truncateString(`${watch('session_title')}`, 30)}
          </Typography>
        </Tooltip>
        <TitleInput
          disabled={false}
          // eslint-disable-next-line no-unused-vars
          exportTitle={(title) => {
            //handleChangeSession(selectedSession.id, { name: title });
          }}
          control={control}
          actions={() => {}}
          session={null}
          placeholder={t(TITLE)}
          name="session_title"
          sx={styles.titleContent}
          inputProps={{ style: styles.titleContent }}
        />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Stack sx={{ minHeight: '630px', backgroundColor: 'white', paddingTop: '50px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={styles.containerInputsSessionDialog}>
              <Input ref={null} control={control} variant="outlined" label="Subtitle" name="session_subtitle" />
            </Box>
            <Box sx={{ width: '48px' }} />
            <Box sx={styles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={control}
                variant="outlined"
                label="Order"
                name="session_order"
                type="number"
              />
            </Box>
          </Box>
          <Box sx={styles.containerInputsSessionDialog}>
            <Editor
              role={user && user.role ? user.role : 'ADMIN'}
              title="Agenda"
              control={control}
              name="session_agenda"
              label={'Agenda'}
              actions={() => {}}
              sx={{ minWidth: '300px', mb: '5px' }}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                color: 'title.main',
                alignSelf: 'center',
              }}>
              {'Session Template Preview'}
            </Typography>
            <Box sx={{ border: '1px solid #F9F9F9', display: 'flex', flex: 1, padding: '0px 100px' }}>
              <div dangerouslySetInnerHTML={{ __html: watch('session_agenda') }} />
            </Box>
          </Box>
        </Stack>
        {/** Content of dialog */}
      </DialogContent>
      <DialogActions sx={styles.btnBack}>
        <Button variant="outlined" onClick={handleClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={styles.btnSave}
          onClick={() => {
            onEditsessionTemplate();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTemplates;
