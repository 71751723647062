/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';

// Style
import { StatusChip } from 'ui/components/StatusChip/StatusChip';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellStatus({ status, expertLabel }) {
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <StatusChip status={status} expertLabel={expertLabel} />
    </Stack>
  );
}

export default CellStatus;
