import React from 'react';
import Paper from '@mui/material/Paper';
import GoBackButton from 'ui/components/shared/GoBackButton';
import { useParams } from 'react-router-dom';
import useEditCoach from 'hooks/usecase/useEditCoach';
import { useMeQuery } from 'hooks/queries';
import EditUser from '../user/EditUser';

export default function EditCoachPage() {
  const { coachId } = useParams();
  const { data: user } = useMeQuery();
  const { actions, state } = useEditCoach(coachId, user?.companyId || '');

  return (
    <Paper
      sx={{
        width: '90%',
        alignSelf: 'center',
        mt: '24px',
        height: '80%',
        overflowY: 'auto',
        pb: '24px',
      }}>
      <GoBackButton sx={{ mt: '12px', ml: '12px', position: 'fixed' }} />

      <EditUser
        userRole={user?.role}
        company={user?.company}
        actions={actions}
        state={state}
        typeUser="coach"
        control={state.form.control}
        userId={coachId}
      />
    </Paper>
  );
}
