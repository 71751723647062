import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import App from './App';
import theme from './theme';
import './i18n';
import 'index.css';
import { AlertProvider } from 'common/alertContext';
// MUI pro license key
import { LicenseInfo } from '@mui/x-license-pro';
import { IntercomProvider } from 'react-use-intercom';

const { REACT_APP_INTERCOM_APP_ID, REACT_APP_INTERCOM_API_BASE, REACT_APP_MUI_PRO_LICENSE_KEY } = process.env;

LicenseInfo.setLicenseKey(REACT_APP_MUI_PRO_LICENSE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
      // staleTime: 1000 * 60 * 15,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <AlertProvider>
            <IntercomProvider apiBase={REACT_APP_INTERCOM_API_BASE} appId={REACT_APP_INTERCOM_APP_ID}>
              <App />
            </IntercomProvider>
          </AlertProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

console.log('Environment : ', process.env.NODE_ENV);
