import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useLogin from 'hooks/usecase/useLogin';
import AuthLayout from '../login/AuthLayout';
import { getTypeSaml } from 'utils/saml';

const SamlRedirect = () => {
  const { type } = useParams();
  const { actions } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (getTypeSaml(type)) {
      actions.onStartSaml(type);
    } else {
      navigate('/');
    }
  }, [type]);

  return <AuthLayout></AuthLayout>;
};

export default SamlRedirect;
