import React, { useRef } from 'react';
import { Box, Tooltip, IconButton } from '@mui/material';
import Switch from 'ui/components/form/Switch';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTranslation } from 'react-i18next';

const CoachExceedMaxCapacitySwitch = ({ control, clientLabel, isCoach = false, onChange }) => {
  const { t } = useTranslation();
  const refSwitch = useRef();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}>
      <Switch
        ref={refSwitch}
        control={control}
        variant="outlined"
        label={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {t(isCoach ? 'coach_exceeding_quota_me' : 'coach_exceeding_quota')}

            <Tooltip title={t('coach_exceeding_quota_tooltip', { clientLabel })}>
              <IconButton edge="end" sx={{ marginLeft: '2px' }}>
                <InfoOutlinedIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
          </Box>
        }
        name="canExceedMaxCapacity"
        labelPlacement="end"
        fullWidth
        onChange={onChange}
      />
    </Box>
  );
};

export default CoachExceedMaxCapacitySwitch;
