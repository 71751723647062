const borderColor = '#EAECF0';

const styles = {
  card: {
    borderRadius: '8px',
    border: `1px solid ${borderColor}`,
    padding: '24px',
    position: 'relative',
  },
  availabilitiesHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  availabilitiesTitle: {
    fontSize: '18px',
    fontWeight: 600,
  },
  availabilitiesSubtitle: {
    fontSize: '16px',
    fontWeight: 300,
    marginBottom: '16px',
    marginTop: '-10px',
  },
  availabilitiesIconEdit: {
    width: '16px',
    marginRight: '3px',
  },
  availabilitiesButtonEdit: {
    height: '32px',
    paddingLeft: '8px',
    paddingRight: '10px',
  },
  availabilitiesContent: {
    overflow: 'scroll',
    maxHeight: '455px',
  },
};

export default styles;
