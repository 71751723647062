const styles = {
  container: {
    minHeight: '630px',
    backgroundColor: 'white',
    paddingLeft: '90px',
  },
  containerAddButton: {
    paddingBottom: '9px',
  },
  addButton: {
    width: '114px',
    height: '50px',
    borderRadius: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'primary.main',
    color: 'white',
    fontWeight: 500,
    fontSize: '15px',
    boxShadow: '0px 3px 5px -1px #00000033',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
  },
};
export default styles;
