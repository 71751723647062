import React, { useState } from 'react';
import TextField from './TextField';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';

import {
  PASSWORD_LENGTH_ERROR,
  PASSWORD_MISSING_UPPERCASE_ERROR,
  PASSWORD_MISSING_SPECIAL_CHARACTER_ERROR,
  PASSWORD_MISSING_NUMBER_ERROR,
  PASSWORD_MISSING_LOWERCASE_ERROR,
} from 'litterals';

const PasswordField = ({
  name,
  label,
  placeholder,
  control,
  styleInput,
  enableFormattingErrors,
  rules = {},
  ...rest
}) => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleShowPassword = () => {
    return setIsShowPassword((prevValue) => !prevValue);
  };

  const rulesConfig = { ...rules };

  if (enableFormattingErrors) {
    const validate = rules.validate || {};

    rulesConfig.validate = {
      checkLength: (password) => password.length >= 8 || t(PASSWORD_LENGTH_ERROR),
      checkUppercase: (password) => !!password.match(/[A-Z]/g) || t(PASSWORD_MISSING_UPPERCASE_ERROR),
      checkLowercase: (password) => !!password.match(/[a-z]/g) || t(PASSWORD_MISSING_LOWERCASE_ERROR),
      checkSpecialChar: (password) =>
        !!password.match(/[@[\]{}\-"'#$€()*+:;!?|&~`+=^<>/\\]/g) || t(PASSWORD_MISSING_SPECIAL_CHARACTER_ERROR),
      checkNumber: (password) => !!password.match(/[0-9]/g) || t(PASSWORD_MISSING_NUMBER_ERROR),
      ...validate,
    };
  }

  return (
    <TextField
      name={name}
      rules={rulesConfig}
      label={label}
      placeholder={placeholder}
      type={isShowPassword ? 'text' : 'password'}
      control={control}
      style={styleInput}
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword}>{isShowPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default PasswordField;
