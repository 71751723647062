const styles = {
  container: {
    height: '156px',
    border: '1px solid',
    borderColor: 'primary.border',
    background: 'white',
    borderRadius: '4px',
    padding: '16px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    position: 'relative',

    ':hover': {
      borderColor: 'primary.main',
      backgroundColor: 'card.hover',
    },
  },

  validated: {
    backgroundColor: 'card.hover',
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  containerTitle: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '-9px',
    marginLeft: '-9px',
  },

  title: {
    fontSize: '20px',
    fontWeight: 400,
  },

  titleValidated: {
    color: 'primary.main',
  },

  desc: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
    maxHeight: '56px',
    textOverflow: 'ellipsis',
    lineHeight: '25px',
    height: 'auto',
    color: 'primary.grey',

    '> *': {
      fontSize: '16px',
      fontWeight: '400 !important',
      margin: 0,
      padding: 0,
    },
  },

  timeChip: {
    color: '#B18225',
    background: '#FEBA361F',
  },

  containerButton: {
    position: 'absolute',
    bottom: '6px',
    right: '16px',
  },

  button: {
    padding: '5px !important',
    minWidth: '43px !important',
    height: '28px !important',
  },
};
export default styles;
