import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import Button from 'ui/components/Button/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AvailabilityManagementModal from '../shared/coaches/AvailabilityManagementModal/AvailabilityManagementModal';
import AvailabilityButton from '../shared/AvailabilityButton/AvailabilityButton';

import { sortByDate } from 'utils/dateUtils';
import styles from './CoachAvailabilities.styles';

const CoachAvailabilities = ({
  availabilities,
  coachId,
  availabilitiesEditable = false,
  redBorderNoAvailabilities = false,
  subtitle,
  onSelectAvailability,
}) => {
  const { t } = useTranslation();
  const [openAvailabilityManagement, setOpenAvailabilityManagement] = useState(false);
  const [availabilityIdSelected, setAvailabilityIdSelected] = useState(null);
  const availabilitiesSorted = JSON.parse(JSON.stringify(availabilities)).sort(sortByDate);

  const isRedBorder = redBorderNoAvailabilities && !availabilitiesSorted.length;
  const redCard = isRedBorder ? { ...styles.card, borderColor: 'red' } : styles.card;

  return (
    <Box sx={redCard} className="availabilities-card">
      <Box sx={styles.availabilitiesHeader}>
        <Typography sx={styles.availabilitiesTitle} component="h2" noWrap>
          {t('coach_availabilities.title')}
        </Typography>

        {availabilitiesEditable && (
          <Button
            variant="secondary"
            size="xs"
            sx={styles.availabilitiesButtonEdit}
            onClick={() => {
              setOpenAvailabilityManagement(true);
              setAvailabilityIdSelected(null);
            }}>
            <EditOutlinedIcon sx={styles.availabilitiesIconEdit} />
            <span style={{ fontSize: '12px' }}>{t('coach_availabilities.edit_button')}</span>
          </Button>
        )}
      </Box>

      {subtitle && !isRedBorder && (
        <Typography sx={styles.availabilitiesSubtitle} component="h3" noWrap>
          {subtitle}
        </Typography>
      )}

      {!!availabilities.length && (
        <Box sx={styles.availabilitiesContent}>
          {availabilitiesSorted.map(({ id, date, duration }) => {
            return (
              <AvailabilityButton
                forceMobileVersion
                isSelected={availabilityIdSelected === id}
                key={id}
                date={date}
                duration={duration}
                onClick={() => {
                  setAvailabilityIdSelected(id);
                  if (availabilitiesEditable) {
                    setOpenAvailabilityManagement(true);
                  }

                  onSelectAvailability && onSelectAvailability({ id, date, duration });
                }}
              />
            );
          })}
        </Box>
      )}

      {availabilities.length === 0 && <Box>{t('no_availability_defined')}</Box>}

      {openAvailabilityManagement && (
        <AvailabilityManagementModal
          coachId={coachId}
          open={openAvailabilityManagement}
          availabilityIdSelected={availabilityIdSelected}
          onClose={() => setOpenAvailabilityManagement(false)}
        />
      )}
    </Box>
  );
};

export default CoachAvailabilities;
