const styles = {
  container: {
    position: 'relative',
  },
  hover: {
    background: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    top: 0,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconAddPhoto: {
    marginRight: '4px',
    cursor: 'pointer',
  },
  iconDeletePhoto: {
    marginRight: '4px',
    cursor: 'pointer',
  },
};
export default styles;
