import React from 'react';
import Box from '@mui/material/Box';
import styles from './OutlookCalendarButton.styles';
import { useTranslation } from 'react-i18next';
import Button from 'ui/components/Button/Button';

const OUTLOOK_CALENDAR_IMAGE = `${process.env.PUBLIC_URL}/uploads/icons/outlook-calendar.svg`;

export default function OutlookCalendarButton({ subject, email, date, minuteDuration, disabled, description }) {
  const { t } = useTranslation();

  const onClick = () => {
    const endDate = new Date(date);
    const endMinutes = parseInt(endDate.getMinutes(), 10) + parseInt(minuteDuration, 10);
    endDate.setMinutes(endMinutes);

    const params = [];

    if (email) params.push(`to=${encodeURIComponent(email)}`);
    if (description) params.push(`body=${encodeURIComponent(description)}`);

    window.open(
      `https://outlook.office.com/calendar/action/compose?rru=addevent&online=1&startdt=${date}&enddt=${endDate.toISOString()}&subject=${encodeURIComponent(
        subject
      )}&${params.join('&')}`
    );
  };

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={styles.calendarButton}
      variant="secondary"
      startIcon={<Box height={'27.08px'} width={'32px'} component="img" src={OUTLOOK_CALENDAR_IMAGE} />}>
      {t('outlook_calendar')}
    </Button>
  );
}
