/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'ui/components/Button/Button';

// Styles
import styles from './RhValidationModal.styles';
import { useTranslation } from 'react-i18next';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const RhValidationModal = ({ isOpen, onClose, onAccept, onRefuse, menteeName, type }) => {
  const { t } = useTranslation();

  const buttonVariant = type === 'refuse' ? 'delete' : 'primary';

  const handleClick = () => {
    if (type === 'refuse') onRefuse();
    else onAccept();
  };

  return (
    <>
      <Dialog disableEnforceFocus PaperProps={{ sx: styles.containerDialog }} open={isOpen} onClose={onClose}>
        <DialogTitle sx={styles.containerTitle}>
          <Typography style={styles.title}>{t(`rh_validation_modal.${type}.title`)}</Typography>
        </DialogTitle>
        <DialogContent sx={styles.content}>
          <Box>
            <Typography>{t(`rh_validation_modal.${type}.desc`, { menteeName })}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={styles.btnBack}>
          <Button variant="secondary" onClick={onClose}>
            {t('go_back')}
          </Button>
          <Button variant={buttonVariant} onClick={handleClick}>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RhValidationModal;
