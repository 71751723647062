const initialState = {
  company: [],
  user: {},
};

export const createHomeSlice = (set) => ({
  ...initialState,
  setCompany: (currentCompany) =>
    // eslint-disable-next-line no-unused-vars
    set((state) => ({
      company: currentCompany,
    })),
  setUser: (currentUser) =>
    // eslint-disable-next-line no-unused-vars
    set((state) => ({
      user: currentUser,
    })),
});
