import React from 'react';
import { useParams } from 'react-router-dom';

import ThemeView from 'ui/components/shared/theme/ThemeView';
import GoBackButton from 'ui/components/shared/GoBackButton';
import { Paper } from '@mui/material';

export default function ThemePage() {
  const { themeId } = useParams();
  return (
    <Paper
      sx={{
        position: 'absolute',
        left: '0',
        right: '0',
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: '84vh',
        marginTop: '20px',
        overflowY: 'auto',
        backgroundColor: 'transparent',
      }}
    >
      <GoBackButton sx={{ mt: '12px', ml: '12px' }} />
      <ThemeView themeId={themeId} />
    </Paper>
  );
}
