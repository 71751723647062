import React from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { START, WELCOME, WELCOME_SCREEN_EVERGREEN_BODY, WELCOME_SCREEN_EVERGREEN_SUBTITLE } from 'litterals';
import { Box } from '@mui/system';
import Layout from './Layout';
import { capitalizeString } from 'utils/functions';

const Welcome = ({ onStart, isLoading, company }) => {
  const { t } = useTranslation();

  return (
    <Layout isLoading={isLoading} logo={company && company.logo ? company.logo : ''}>
      {isLoading ? null : (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} component="form">
          <Typography sx={{ fontSize: '34px', fontWeight: 700 }} color={'primary.main'}>
            {capitalizeString(t(WELCOME))} !
          </Typography>
          <Box sx={{ height: '8px' }} />
          <Typography
            sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '24px', color: 'title.main', textTransform: 'none' }}
            textTransform={'capitalize'}>
            {t(WELCOME_SCREEN_EVERGREEN_SUBTITLE, { sessionLabel: company.sessionLabel })}
          </Typography>
          <Box sx={{ height: '32px' }} />
          <Typography
            sx={{
              padding: '0px 35px',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '24px',
              color: '#000000CC',
              textAlign: 'center',
              textTransform: 'none',
            }}
            textTransform={'capitalize'}>
            {t(WELCOME_SCREEN_EVERGREEN_BODY)
              .replace('{{sessionLabel}}', company && company.sessionLabel ? company.sessionLabel.toLowerCase() : '')
              .replace('{{expertLabel}}', company && company.expertLabel ? company.expertLabel.toLowerCase() : '')}
          </Typography>
          <Box sx={{ height: '32px' }} />
          <Button
            variant="contained"
            onClick={onStart}
            sx={{
              width: '100%',
              backgroundColor: 'primary.main',
              height: {
                xs: '34px',
                sm: '34px',
                md: '34px',
                lg: '64px',
                xl: '64px',
                fontSize: '20px',
                fontWeight: 700,
                textTransform: 'none',
              },
            }}>
            {t(START)}
          </Button>
        </Box>
      )}
    </Layout>
  );
};

export default Welcome;
