import React from 'react';

// UI Lib dependencies
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import styles from './ActivityButton.styles';
import { parseJsonTranslate } from 'utils/parse';

const ActivityButton = ({ title, img, id, onClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.container} onClick={() => onClick(id)}>
      <Box sx={styles.img} component="img" src={img} />
      <Typography sx={styles.title}>{parseJsonTranslate(title)}</Typography>
    </Box>
  );
};

export default ActivityButton;
