const styles = {
  cellText: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '21.04px',
    fontStyle: 'italic',
    color: 'rgba(0, 0, 0, 1)',
    wordWrap: 'break-word',
  },
};

export default styles;
