import { useEffect, useState } from 'react';
import { useListAdminsWithParams } from 'hooks/queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'common/alertContext';
import { t } from 'i18next';
import { hasAdminRole, getLabelByValue } from 'utils/roles';
import { useForm } from 'react-hook-form';
import api from 'api';
import dayjs from 'dayjs';

var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const useAdmins = (companyId, queryParams) => {
  // React query
  const { data, isLoading, isFetched, refetch } = useListAdminsWithParams(companyId, queryParams);
  const { setStateAlert, stateAlert } = useAlert();
  const queryClient = useQueryClient();

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingItem, setEditingItem] = useState(null);

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
    defaultValues: {
      scopes: [],
      role: '',
      firstname: '',
      lastname: '',
      email: '',
      idNumber: '',
    },
  });

  // Get list
  const updateList = (users) =>
    users.reduce((acc, currentItem) => {
      const availableRolesLabels = currentItem.availableRoles.map((role) => getLabelByValue(role));
      const modifiedItem = {
        ...currentItem,
        name: currentItem.firstname + ' ' + currentItem.lastname,
        user: {
          name: currentItem.firstname + ' ' + currentItem.lastname,
          title: currentItem.title,
          picture: currentItem.picture,
        },
        roles: Array.isArray(availableRolesLabels) ? availableRolesLabels.join(', ') : '',
        scopeName: Array.isArray(currentItem.scopes) ? currentItem.scopes.map((item) => item.name).join(', ') : '',
        createdAt: dayjs(currentItem.createdAt).fromNow(),
      };

      acc.push(modifiedItem);

      return acc;
    }, []);

  // Delete
  const mutationDelete = useMutation(api.Users.delete, {
    onSuccess: async (_data, id) => {
      refetch();
      setStateAlert({ open: true, message: t('user_deleted_successfully') });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
    },
  });
  const onRemove = ({ id }) => {
    return mutationDelete.mutate(id);
  };

  // Add
  const mutationAddRH = useMutation(api.Users.createRH, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-admins-params']);
      setStateAlert({ open: true, message: 'user successfully created' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const mutationAddRHManager = useMutation(api.Users.createRHManager, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-admins-params']);
      setStateAlert({ open: true, message: 'user successfully created' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onAdd = handleSubmit(async (data) => {
    if (data.role == 'RH') {
      mutationAddRH.mutate({ ...data, companyId: companyId });
    } else if (data.role == 'RH_MANAGER') {
      mutationAddRHManager.mutate({ ...data, companyId: companyId });
    }
  });

  // Edit
  useEffect(() => {
    if (editingItem) {
      setValue('firstname', editingItem.firstname);
      setValue('lastname', editingItem.lastname);
      setValue('email', editingItem.email);
      setValue('idNumber', editingItem.idNumber ? editingItem.idNumber : '');
      setValue('role', editingItem.role);
      setValue(
        'scopes',
        editingItem.scopes.map((item) => item.id)
      );
      setValue('availableRoles', editingItem.availableRoles);
      setIsOpenEditModal(true);
    }
  }, [editingItem]);
  const mutationEdit = useMutation(api.Users.edit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-admins-params']);
      setStateAlert({ open: true, message: t('user_successfully_edited') });
      setIsOpenEditModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });
  const onEdit = handleSubmit(async (data) => {
    const body = { ...data };
    body.scopes = data.scopes.length > 0 ? data.scopes : [];

    mutationEdit.mutate({ id: editingItem.id, data: { ...body, companyId: queryParams.companyId } });
  });

  const onIsMainCompanyAdmin = (user, activated) => {
    mutationEdit.mutate({ id: user.id, data: { isMainCompanyAdmin: activated } });
  };

  return {
    admins: data ? updateList(data.rows) : [],
    totalPages: data && data.totalPages,
    totalItems: data && data.totalItems,
    isLoading,
    isFetched,
    onRemove,
    onAdd,
    onEdit,
    control,
    watch,
    isOpenAddModal,
    setIsOpenAddModal,
    isOpenEditModal,
    setIsOpenEditModal,
    onIsMainCompanyAdmin,
    setEditingItem,
    reset,
  };
};
