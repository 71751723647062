/* eslint-disable react/react-in-jsx-scope */
/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */
import React from 'react';
import ScopeFilterOperators from 'ui/components/DataGrid/Filters/ScopeFilter/ScopeFilter';
import ActiveFilterOperators from 'ui/components/DataGrid/Filters/ActiveFilter/ActiveFilter';
import TypeFilterOperators from 'ui/components/DataGrid/Filters/TypeFilter/TypeFilter';
import LanguageFilterOperators from 'ui/components/DataGrid/Filters/LanguageFilter/LanguageFilter';
import DateFilterOperators from 'ui/components/DataGrid/Filters/DateFilter/DateFilter';

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/

const tableSchemaBase = [
  {
    field: 'name',
    headerName: 'Name',
    type: 'text',
    hideable: false,
    minWidth: 130,
    resizable: true,
    flex: 1,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'CreatedAt',
    type: 'text',
    minWidth: 130,
    resizable: true,
    flex: 1,
    hideable: false,
    sortable: false,
    filterable: false,
  },
  {
    field: 'actions',
    headerName: 'actions',
    sortable: false,
    minWidth: 120,
    filter: false,
    filterable: false,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    type: 'actionsCompanies',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    hideable: false,
    resizable: true,
  },
];

export { tableSchemaBase };
