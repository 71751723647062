import theme from 'theme';

const styles = {
  container: {
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '4px',
    maxHeight: '430px',
    maxWidth: '368px',
    minWidth: {
      xs: '290px',
      sm: '368px',
    },
    boxSizing: 'border-box',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'white',
      borderColor: 'primary.main',
    },
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
    border: '1px solid',
    borderColor: '#d4d4d4',
  },
  containerSelected: {
    borderColor: 'primary.main',
    border: '2px solid',
    backgroundColor: 'card.selected',
    '&:hover': {
      backgroundColor: 'card.selected',
    },
  },
  picture: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
  title: {
    marginTop: '8px',
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textWrap: 'nowrap',
  },
  subtitle: {
    marginTop: '6px',
    marginBottom: '6px',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '2',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1.5',
    maxHeight: '3em',
    color: 'primary.grey',
    minHeight: '48px',
  },
  containerButtons: {
    display: 'flex',
    gap: '16px',
    alignSelf: 'end',
  },
  adminButton: {
    padding: '5px 10px',
  },
};
export default styles;
