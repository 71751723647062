/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// Style
import styles from './styles';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellText({ text }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Tooltip title={text && text.length > 18 ? text : ''}>
      <Typography sx={styles.cellText}> {text}</Typography>
    </Tooltip>
  );
}

export default CellText;
