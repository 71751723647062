import { get, post, put } from './request';

class Programs {
  static create(body) {
    return post('/programs', body).then((res) => res.data);
  }

  static list(queryParams) {
    return get('/programs', queryParams).then((res) => res.data);
  }

  static listCsv(queryParams) {
    return get('/programs', queryParams).then((res) => res);
  }

  static get(id) {
    return get(`/programs/${id}`).then((res) => res.data);
  }

  static getCoaches(id) {
    return get(`/programs/${id}/coaches`).then((res) => res.data);
  }

  static getCoachees(id) {
    return get(`/programs/${id}/coachees`).then((res) => res.data);
  }

  static chooseCoach({ id, coachId }) {
    return post(`/programs/${id}/coaches/choose`, { coachId }).then((res) => res.data);
  }

  static respond({ id, body }) {
    return post(`/programs/${id}/respond`, body).then((res) => res.data);
  }

  static update({ id, body }) {
    return put(`/programs/${id}`, body).then((res) => res.data);
  }
}

export default Programs;
