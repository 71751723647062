import React, { useRef, useState } from 'react';
import useStore from 'store';
import styles from './SelfRegistrationIndex.styles.js';

// Components
import CardCoach from 'ui/pages/evergreen-link/components/Cards/CardCoach/CardCoach';
import Button from 'ui/components/Button/Button';
import { Typography } from '@mui/material';
import Box from 'ui/components/system/Box';
import NavBar from 'ui/layout/NavBar/NavBar';
import ThemeView from 'ui/components/shared/theme/ThemeView';
import SelfRegistrationModal from './SelfRegistrationModal/SelfRegistrationModal.jsx';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSelfRegistration } from 'hooks/usecase/useSelfRegistration';
import useLogin from 'hooks/usecase/useLogin';
import PoweredBy from 'ui/components/PoweredBy';
import { useNavigate } from 'react-router-dom';
import { showIntercomVisitor } from 'utils/intercom';
import LoginModal from '../login/LoginModal.jsx';

const SelfRegistrationIndex = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openLogin, setOpenLogin] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { state, actions: selfRegistrationActions } = useSelfRegistration();
  const { state: loginState, actions } = useLogin(false);
  const coachesListContainerRef = useRef();

  const { theme, coaches, isConnected, user, selfRegistrationLink } = state;

  const { company } = useStore();
  const { expertLabel } = company;

  showIntercomVisitor();

  const onSelectMentor = () => {
    if (!isConnected) {
      setOpenLogin(true);
    } else {
      setOpenModal(true);
    }
  };

  const onShowExpert = () => {
    if (!isConnected) {
      setOpenLogin(true);
    } else {
      setOpenModal(true);
    }
  };

  const onCloseLogin = () => {
    setOpenLogin(false);
  };

  const onClickPreviousUser = () => {
    coachesListContainerRef.current.scrollBy({
      left: -314,
      behavior: 'smooth',
    });
  };

  const onClickNextUser = () => {
    coachesListContainerRef.current.scrollBy({
      left: 314,
      behavior: 'smooth',
    });
  };

  return (
    <Box sx={{ height: '100%', overflowY: 'scroll' }}>
      <NavBar company={company} isRegistrationFlowWithMyAccount isFixed />

      <Box sx={styles.container}>
        <Box sx={styles.themeContainer}>
          <ThemeView md={12} theme={theme} paddingContent="0 24px 20px 24px" />
        </Box>

        <Box sx={styles.selectingMentorContainer}>
          <Typography component="h3" sx={styles.selectingMentorTitle}>
            {t('self_registration.selecting_mentor.title')}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <Typography component="p" sx={styles.selectingMentorDesc}>
              {t('self_registration.selecting_mentor.desc')}
            </Typography>

            <Box sx={{ textWrap: 'nowrap' }}>
              <Button
                sx={{ marginRight: '10px', ...styles.directionArrow }}
                variant="secondary"
                onClick={onClickPreviousUser}>
                <KeyboardArrowLeftIcon sx={{ fontSize: '1.3rem' }} />
              </Button>
              <Button sx={styles.directionArrow} variant="secondary" onClick={onClickNextUser}>
                <KeyboardArrowRightIcon sx={{ fontSize: '1.3rem' }} />
              </Button>
            </Box>
          </Box>

          <Box sx={styles.coachesListContainer} ref={coachesListContainerRef}>
            <Box sx={styles.coachesList}>
              {coaches.map((coach) => (
                <CardCoach
                  key={coach.id}
                  id={coach.id}
                  title={coach.firstname + ' ' + coach.lastname}
                  description={coach?.descriptionExperience || ''}
                  job={coach?.title || ''}
                  skills={coach.coachingSkills}
                  languagesSpoken={coach.languagesSpoken}
                  timeZone={coach.timeZoneDescription}
                  availabilitiesCount={coach.availabilitiesCount}
                  onShowExpert={onShowExpert}
                  picture={coach.picture ? coach.picture : ''}
                  showViewProfile={false}
                  showSelectButton={false}
                  canExceedMaxCapacity={coach.canExceedMaxCapacity}
                  sessionLabel={company.sessionLabel}
                  onSelect={onShowExpert}
                />
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={styles.poweredByContainer}>
          <PoweredBy />
        </Box>
      </Box>

      <Box sx={styles.footer}>
        <Button variant="primary" sx={styles.selectMentorButton} onClick={onSelectMentor}>
          {t('self_registration.select_mentor', { expertLabel })}
        </Button>
      </Box>

      <SelfRegistrationModal
        company={company}
        open={openModal}
        theme={theme}
        user={user}
        selfRegistrationLink={selfRegistrationLink}
        onClose={() => setOpenModal(false)}
      />

      <LoginModal
        company={company}
        open={openLogin}
        control={loginState.form.control}
        isEmailCompany={loginState.form.isEmailCompany}
        onClickApplicationForm={selfRegistrationActions.onClickApplicationLinkSelfRegistration}
        onClose={onCloseLogin}
        onSubmit={async (data) => {
          await actions.setRedirection();
          actions.submit(data);
        }}
        onSubmitMicrosoft={async () => {
          await actions.setRedirection();
          actions.onSubmitMicrosoft();
        }}
        onSubmitCompany={async () => {
          await actions.setRedirection();
          actions.onSubmitCompany();
        }}
      />
    </Box>
  );
};

export default SelfRegistrationIndex;
