import React, { useState } from 'react';
import { Box, Chip, Stack, Typography, Tooltip } from '@mui/material';
import styles from './AvailabilityButton.styles';
import Button from 'ui/components/Button/Button';
import { formatSessionTime, formatSessionDate, formatAbbreviatedDate } from 'utils/sessions';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

const AvailabilityButton = ({
  date,
  duration,
  isSelected,
  onClick,
  forceMobileVersion = false,
  viewFullDate = false,
}) => {
  const [isHover, setIsHover] = useState(false);
  const { t } = useTranslation();
  const isMobile = forceMobileVersion || useMediaQuery('(max-width:400px)');

  const dateFormatter = viewFullDate ? formatSessionDate : formatAbbreviatedDate;
  const dateFormatted = date == null ? t('undefined') : dateFormatter(date, isMobile);
  const sessionTime = date == null ? '00:00' : formatSessionTime(date);

  const chipLabel = duration == null ? '-' : `${duration} min`;

  return (
    <Tooltip title={date == null ? null : dateFormatted} sx={{ justifyContent: 'space-between' }}>
      <Stack
        sx={[styles.container, isSelected && styles.containerSelected]}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={onClick}>
        <Typography sx={date == null ? styles.dateUndefined : styles.date} component="h4" noWrap>
          {dateFormatted}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip label={chipLabel} sx={styles.durationChip} />
          <Button sx={styles.hours} variant="secondary" size="xs">
            {sessionTime}
          </Button>
        </Box>
      </Stack>
    </Tooltip>
  );
};

export default AvailabilityButton;
