import React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { truncateString } from 'common/utils';

const CoachItem = ({ row }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Avatar alt="Remy Sharp" src={row.picture} />
      <Box sx={{ width: '10px' }} />
      <Box>
        <Tooltip title={row.firstname + ' ' + row.lastname}>
          <Typography sx={{ fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#00000099' }}>
            {truncateString(`${row.firstname + ' ' + row.lastname}`, 14)}
          </Typography>
        </Tooltip>
        <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400, color: '#00000099' }}>
          {truncateString(`${row.title ? row.title : ''}`, 10)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CoachItem;
