import React from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

// UI Local components
import Input from 'ui/components/Input';
import TitleInput from 'ui/components/TitleInput';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Style
import styles from './styles';

const ContentTheme = ({ control }) => {
  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {};
  const handleOpen = () => {};
  return (
    <Box
      sx={{
        border: '5px solid red',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '30px',
        paddingLeft: '70px',
        paddingRight: '70px',
      }}>
      <Box style={{ border: '2px solid red' }}>
        <Box
          sx={{
            color: 'black',
            fontWeight: 600,
            height: '24px',
            width: '80px',
            paddingRight: '15px',
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': { backgroundColor: '#FEBA36' },
          }}
          variant="text">
          <KeyboardArrowLeftIcon />
          RETURN
        </Box>
      </Box>
      <Box
        onClick={() => {}}
        component="img"
        sx={{
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
          width: '100%',
          height: '120px',
          objectFit: 'fill',
          border: '5px solid blue',
        }}
        src={
          'https://images.squarespace-cdn.com/content/61cc23f6b7114869708d5ad2/60fc1fd6-a150-48e2-9bf9-3db6575e61da/logo+pathlinebleu.png?format=1500w&content-type=image%2Fpng'
        }
      />
      <Box
        sx={{
          height: '20px',
        }}
      />
      <Typography style={styles.titleContent}>{'Diversity & Inclusion'}</Typography>
      <Box
        sx={{
          height: '20px',
        }}
      />
      <Typography style={styles.themeContent}>
        {
          'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
        }
      </Typography>
    </Box>
  );
};

export default ContentTheme;
