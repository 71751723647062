import React from 'react';
import { Box } from '@mui/material';

const PATHLINE_LOGO = `${process.env.PUBLIC_URL}/uploads/images/Pathline-logo.svg`;

const PoweredBy = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ marginRight: '4px', display: 'inline-block', fontSize: '14px', fontWeight: 300 }}>Powered By</Box>
      <Box
        component="img"
        sx={{
          maxHeight: '12px',
          width: 'auto',
          height: 'auto',
          objectFit: 'contain',
        }}
        src={PATHLINE_LOGO}
      />
    </Box>
  );
};

export default PoweredBy;
