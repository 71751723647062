/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

/* eslint-disable react/react-in-jsx-scope */
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as NativeDatePicker } from '@mui/x-date-pickers/DatePicker';
import { useController } from 'react-hook-form';

import i18next from 'i18next';

// Importing locale configurations for different languages in the Day.js library
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import 'dayjs/locale/es';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */
const DatePicker = ({ control, name, maxDate, minDate, ...props }) => {
  const {
    field: { onChange: handleChange, value },
  } = useController({ control, name, defaultValue: props.defaultValue });

  /* ********************************  HELPERS ******************************* */

  const changeHandler = (value) => {
    if (value) {
      handleChange(dayjs(value.$d).toISOString());
    } else {
      handleChange(null);
    }
  };
  /* ********************************** CONSTANTS ********************************* */

  const ADAPTER_LOCALE = i18next ? i18next.language : 'en';

  /* ******************************** RENDERING ******************************* */

  return (
    <LocalizationProvider adapterLocale={ADAPTER_LOCALE} dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <NativeDatePicker
          name="date"
          onChange={changeHandler}
          format="MMMM DD, YYYY"
          label={props.label}
          sx={{
            height: '37px',
          }}
          slotProps={{
            textField: {
              size: 'small',
              style: {
                height: '39px',
                padding: '1px',
                width: '70px',
              },
            },
          }}
          value={dayjs(value)}
          maxDate={maxDate}
          minDate={minDate}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DatePicker;
