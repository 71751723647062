import { useIntercom } from 'react-use-intercom';

const userSetToIntercom = (user) => {
  localStorage.setItem('USER_INTERCOM', JSON.stringify(user));
};

const getUserIntercom = () => {
  return JSON.parse(localStorage.getItem('USER_INTERCOM'));
};

const deleteUserIntercom = () => {
  localStorage.removeItem('USER_INTERCOM');
};

const showIntercomVisitor = () => {
  const { boot, getVisitorId } = useIntercom();

  const userIntercom = getUserIntercom();

  const visitorId = getVisitorId();

  if (!userIntercom || !visitorId) {
    userSetToIntercom({ userId: 'VISITOR' });
    boot();
  }
};

const showIntercomWithUser = (user) => {
  const { boot, update, shutdown } = useIntercom();

  if (!user || !user.intercomUserHash) {
    shutdown();
    return deleteUserIntercom();
  }

  const { email, id, intercomUserHash: userHash, userId } = user;
  const userDataIntercom = { email, userId: userId || id, userHash };

  const userIntercom = getUserIntercom();

  if (!userIntercom) {
    boot(userDataIntercom);
    userSetToIntercom(userDataIntercom);
  } else if (userDataIntercom.userId !== userIntercom.userId) {
    update(userDataIntercom);
    userSetToIntercom(userDataIntercom);
  }
};

export { showIntercomWithUser, showIntercomVisitor, deleteUserIntercom };
