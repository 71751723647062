const styles = {
  container: {
    backgroundColor: 'background.paper',
    borderRadius: '4px',
    padding: '32px',
    flexDirection: 'column',
    display: 'flex',
    minHeight: 'max-content',
    maxHeight: '500px',
    maxWidth: '450px',
    width: 'auto',
    height: 'none',
    overflowY: 'none',
    boxShadow: 24,
    textAlign: 'justify',
  },

  containerButtons: {
    paddingTop: '20px',
    textAlign: 'right',
  },

  validButton: {
    marginLeft: '16px',
  },

  title: {
    fontSize: '24px',
    fontWeight: 600,
    paddingBottom: '16px',
  },
};
export default styles;
