/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React, { useState } from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { parseJsonTranslate } from 'utils/parse';

import { truncateString } from 'common/utils';

// Style
import ThemeCardMini from 'ui/components/shared/theme/ThemeCardMini';
import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellTopic({ theme }) {
  const [modalOpened, setModalOpened] = useState(false);
  const onClick = (event) => {
    event.stopPropagation();
    theme?.id ? setModalOpened(true) : setModalOpened(false);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setModalOpened(false);
  };
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <ThemeCardMini onClick={onClick} sx={{ maxWidth: '12vw' }} name={parseJsonTranslate(theme.name)} />
      {theme.id && modalOpened && <ThemeViewModal themeId={theme.id} open={modalOpened} onClose={handleClose} />}
    </Stack>
  );
}

export default CellTopic;
