const styles = {
  dateTimePicker: {
    height: '37px',
    width: '255.79px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  durationInput: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    backgroundColor: 'white',
    width: '150px',
  },
  colonText: {
    fontSize: '26px',
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 1)',
    lineHeight: '24px',
  },
  label: {
    fontSize: '12px',
    fontWeight: 400,
    color: 'rgba(66, 66, 66, 1)',
    paddingBottom: '8px',
  },
};

export default styles;
