import React from 'react';

// UI lib dependencies;
import { Stack, Menu, MenuItem, Tooltip, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';

function EditButton({ clientLabel, expertLabel, onClickTheme, onClickExpert }) {
  /* ******************************** RENDERING ******************************* */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack>
      <Button
        sx={{ background: 'white', '&:hover': { background: '#F5F5FC' } }}
        variant="outlined"
        startIcon={<EditIcon />}
        onClick={handleClick}>
        {t('edit_program_button')}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onClickTheme();
          }}>
          {t('edit_theme_button')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onClickExpert();
          }}>
          {t('edit_expert_label_button', { expertLabel })}
        </MenuItem>
        <Tooltip title={t('tooltip_edit_mentee_disabled', { clientLabel })} placement="left">
          <div>
            <MenuItem disabled>{t('edit_client_label_button', { clientLabel })}</MenuItem>
          </div>
        </Tooltip>
      </Menu>
    </Stack>
  );
}

export default EditButton;
