/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';

// Importing locale configurations for different languages in the Day.js library
import 'dayjs/locale/fr';
import 'dayjs/locale/en';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import i18next from 'i18next';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const DateFilter = (props) => {
  const { item, applyValue } = props;
  /* ********************************  HELPERS ******************************* */

  const changeHandler = (value) => {
    applyValue({ ...item, value: value });
  };
  /* ********************************** CONSTANTS ********************************* */

  const ADAPTER_LOCALE = i18next ? i18next.language : 'en';
  /* ******************************** RENDERING ******************************* */

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        height: 42,
        mt: '4px',
      }}>
      <LocalizationProvider adapterLocale={ADAPTER_LOCALE} dateAdapter={AdapterDayjs}>
        <DatePicker
          renderInput={(params) => <TextField variant="standard" {...params} />}
          format="MMMM DD, YYYY"
          onChange={changeHandler}
          value={dayjs(item.value)}
          sx={{
            height: '37px',
            width: '211px',
          }}
          slotProps={{ textField: { size: 'small' } }}
          {...props}
        />
      </LocalizationProvider>
    </Box>
  );
};

const DateFilterOperators = [
  {
    label: 'Above',
    value: 'above',
    getApplyFilterFn: () => {},
    InputComponent: DateFilter,
  },
];

export default DateFilterOperators;
