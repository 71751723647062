const styles = {
  container: {
    width: '172px',
    height: '172px',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'primary.border',
    background: 'white',
    padding: '16px',
    flexDirection: 'column',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',

    ':hover': {
      borderColor: 'primary.main',
      backgroundColor: 'card.hover',
    },
  },

  img: {
    maxWidth: '100%',
    flexGrow: '1',
    objectFit: 'cover',
    height: '0',
  },

  title: {
    fontSize: '16px',
    fontWeight: '700',
    textAlign: 'center',
  },
};
export default styles;
