import React from 'react';

import Activities from 'ui/pages/program/components/Sessions/Activities/Activities';
import { Box } from '@mui/material';

const ActivitiesConfigPage = () => {
  return (
    <Box sx={{ padding: '0 32px 32px 32px', background: 'white', width: '100%' }}>
      <Activities showExerciseEditor editMode />
    </Box>
  );
};

export default ActivitiesConfigPage;
