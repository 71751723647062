import React from 'react';
import Box from '@mui/material/Box';
import styles from './GoogleCalendarButton.styles';
import { useTranslation } from 'react-i18next';
import Button from 'ui/components/Button/Button';
import { formatDateGoogleAgenda } from 'utils/dateUtils';

const GOOGLE_CALENDAR_IMAGE = `${process.env.PUBLIC_URL}/uploads/icons/google-calendar.svg`;

export default function GoogleCalendarButton({ subject, email, date, minuteDuration, disabled, description }) {
  const { t } = useTranslation();

  const onClick = () => {
    const endDate = new Date(date);
    const endMinutes = parseInt(endDate.getMinutes(), 10) + parseInt(minuteDuration, 10);
    endDate.setMinutes(endMinutes);

    const dates = `${formatDateGoogleAgenda(date)}/${formatDateGoogleAgenda(endDate.toISOString())}`;

    const params = [`dates=${dates}`];

    if (description) params.push(`details=${description}`);
    if (email) params.push(`add=${encodeURIComponent(email)}`);

    window.open(
      `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(subject)}&${params.join('&')}`
    );
  };

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      sx={styles.calendarButton}
      variant="secondary"
      startIcon={<Box height={'26px'} width={'26px'} component="img" src={GOOGLE_CALENDAR_IMAGE} />}>
      {t('google_calendar')}
    </Button>
  );
}
