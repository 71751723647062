import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useCompanyQuery, useMeQuery } from 'hooks/queries';
import { MESSAGE_SUCCESS_CHANGES } from 'litterals';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const defaultValues = {
  expertLabel: '',
  clientLabel: '',
  sessionLabel: '',
};

const useEditLabels = () => {
  const { handleSubmit, control, reset, watch } = useForm({ defaultValues });
  const { setStateAlert } = useAlert();
  const { data: user } = useMeQuery();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data } = useCompanyQuery(user?.company?.id || '');

  useEffect(() => {
    reset({
      clientLabel: data?.clientLabel ?? '',
      expertLabel: data?.expertLabel ?? '',
      sessionLabel: data?.sessionLabel ?? '',
    });
  }, [data]);

  const handleLabelMutation = useMutation(api.Companies.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-me']);
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGES) });
    },
  });

  const onHandleLabel = handleSubmit((data) => {
    return handleLabelMutation.mutate({
      data: { ...data, id: user?.company?.id },
      id: user?.company?.id,
    });
  });

  return {
    form: { control },
    state: { expertLabel: watch('expertLabel'), clientLabel: watch('clientLabel') },
    actions: { onHandleLabel },
  };
};

export default useEditLabels;
