import { del, get, post, put } from './request';

class Reminders {
  static getRemindersSettings() {
    return get(`/reminders/settings`).then((res) => res.data);
  }

  static updateRemindersSettings(settings) {
    return post(`/reminders/settings`, { settings }).then((res) => res.data);
  }
}

export default Reminders;
