import React from 'react';
import MUICard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

const ImageCard = ({ src, onClick, title, sxCard, sxTitle, children, elevation, nbSessions, sxCardActionArea }) => {
  return (
    <MUICard
      sx={{
        width: '220px',
        height: '275px',
        borderRadius: '6px',
        ...sxCard,
      }}
      elevation={elevation}>
      <CardActionArea onClick={onClick} sx={sxCardActionArea ? sxCardActionArea : { height: 'inherit' }}>
        <CardMedia component="img" src={src} />
        <Typography
          component="p"
          align="center"
          sx={{
            padding: '10px 8px 8px 8px',
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            ...sxTitle,
          }}>
          {title}
        </Typography>
      </CardActionArea>
      <CardActions
        disableSpacing
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '0',
        }}>
        {children}
      </CardActions>
    </MUICard>
  );
};

export default ImageCard;
