const styles = {
  container: {
    minWidth: '644px',
    borderRadius: '16px',
  },
  title: {
    color: '#000000DE',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  content: {
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
  rowInput: {
    padding: '8px 0px',
    height: '70px',
    width: '311px',
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '19px 77px',
    borderTop: '1px solid #dfe0e0',
  },
};
export default styles;
