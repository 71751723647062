/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, { useState, useEffect } from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'ui/components/Button/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import { useListEmailsWithName } from 'hooks/queries';
import PreviewEmail from '../../../subscription/create/Modals/';

import {
  PROGRAM_DEACTIVATION_EMAIL_TEMPLATE_NAME,
  PROGRAM_REACTIVATION_EMAIL_TEMPLATE_NAME,
} from '../../../subscription/create/constants/email-templates-constants';

//Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// Styles
import styles from './ChangeStatusRequest.styles';
import { useTranslation } from 'react-i18next';

import {
  variablesReplacementContent,
  variablesReplacementProgramDeactivation,
} from '../../../subscription/create/helpers/email-templates-functions';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const ChangeStatusRequest = ({ isOpen, onClose, onAccept, program, expertLabel, clientLabel, type, sessionLabel }) => {
  const { t } = useTranslation();

  const templateEmail =
    type === 'reactivation' ? PROGRAM_REACTIVATION_EMAIL_TEMPLATE_NAME : PROGRAM_DEACTIVATION_EMAIL_TEMPLATE_NAME;
  const { data: dataEmailTemplate } = useListEmailsWithName(templateEmail);

  const [isEmailTemplateOpen, setIsEmailTemplateOpen] = useState(false);
  const [isArchived, setIsArchived] = useState(false);
  const [sendEmailToCoach, setSendEmailToCoach] = useState(true);
  const [sendEmailToCoachee, setSendEmailToCoachee] = useState(true);
  const [typePreviewMail, setTypePreviewMail] = useState('');

  useEffect(() => {
    setSendEmailToCoach(isInProgressProgram());
    setSendEmailToCoachee(true);
  }, [program]);

  const onShowTemplateClick = (type) => {
    setIsEmailTemplateOpen(true);
    setTypePreviewMail(type);
  };

  const msgDialog = (program) => {
    if (!Object.keys(program).length) return '';

    if (type === 'reactivation') {
      return t('program_reactivation_warning_case1');
    }

    const oneSessionExists = program.sessions.find((session) => session.date);
    if (oneSessionExists) {
      return t('program_deactivation_warning_case1');
    } else {
      return t('program_deactivation_warning_case2');
    }
  };

  const onActionButton = () => {
    onAccept({ programId: program.id, sendEmailToCoach, sendEmailToCoachee, isArchived });
  };

  const clientEmailTemplateEmail = (type) => {
    if (type === 'expert') {
      return program?.coach?.user?.email;
    }

    return program?.coachee?.email;
  };

  const contentTemplateEmail = () => {
    if (!dataEmailTemplate?.content) return '';

    return variablesReplacementContent(
      dataEmailTemplate.content,
      variablesReplacementProgramDeactivation(sessionLabel)
    );
  };

  const subjectTemplateEmail = () => {
    if (!dataEmailTemplate?.subject) return '';

    return variablesReplacementContent(
      dataEmailTemplate.subject,
      variablesReplacementProgramDeactivation(sessionLabel)
    );
  };

  const inProgressTranslation = t('in_progress');

  const isInProgressProgram = () => {
    return program.status === 'in progress' || program.statusBeforeDeactivation === 'in progress';
  };

  const switchTooltip = () => {
    if (!isInProgressProgram()) {
      return t('switch_tooltip_program_email_in_progress', { inProgress: inProgressTranslation });
    }

    return '';
  };

  const informationMail = () => {
    if (sendEmailToCoach && sendEmailToCoachee) {
      return t(`program_${type}_email`, { expertLabel, clientLabel });
    }

    if (sendEmailToCoach || sendEmailToCoachee) {
      const typeLabel = sendEmailToCoach ? expertLabel : clientLabel;
      return t(`program_${type}_email_only_one`, { typeLabel });
    }

    return t(`program_${type}_email_nobody`);
  };

  return (
    <>
      <Dialog disableEnforceFocus PaperProps={{ sx: styles.containerDialog }} open={isOpen} onClose={onClose}>
        <DialogTitle sx={styles.containerTitle}>
          <Typography style={styles.title}>{t(`program_${type}_title`)}</Typography>
        </DialogTitle>
        <DialogContent sx={styles.content}>
          <Box>
            <Typography>{msgDialog(program)}</Typography>
            <Typography style={{ paddingTop: '20px' }}>{informationMail()}</Typography>

            <MailSettings
              buttonLabel={t('program_deactivate_expert_send_email_button', { expertLabel })}
              onSwitch={(e) => setSendEmailToCoach(e.target.checked)}
              onShowTemplateClick={() => onShowTemplateClick('expert')}
              switchValue={sendEmailToCoach}
              disabled={!isInProgressProgram()}
              switchTooltip={switchTooltip()}
              switchLabel={t('program_deactivate_expert_send_email_switch', { expertLabel })}
            />

            <MailSettings
              buttonLabel={t('program_deactivate_client_send_email_button', { clientLabel })}
              onSwitch={(e) => setSendEmailToCoachee(e.target.checked)}
              onShowTemplateClick={() => onShowTemplateClick('client')}
              switchValue={sendEmailToCoachee}
              switchLabel={t('program_deactivate_client_send_email_switch', { clientLabel })}
            />

            {type === 'deactivation' && (
              <FormControlLabel
                sx={{ padding: '12px' }}
                control={<Switch onChange={(e) => setIsArchived(e.target.checked)} checked={isArchived} />}
                label={t('switch_archived_program')}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={styles.btnBack}>
          <Button variant="secondary" onClick={onClose}>
            {t('go_back')}
          </Button>
          {type === 'deactivation' && (
            <Button sx={styles.deactivateButton} variant="delete" onClick={onActionButton}>
              {t('deactivate_program_button')}
            </Button>
          )}
          {type === 'reactivation' && (
            <Button variant="primary" onClick={onActionButton}>
              {t('reactivate_program_button')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <PreviewEmail
        title={typePreviewMail === 'expert' ? expertLabel : clientLabel}
        clientEmail={clientEmailTemplateEmail(typePreviewMail)}
        isOpen={isEmailTemplateOpen}
        setOpen={setIsEmailTemplateOpen}
        templateName={templateEmail}
        content={contentTemplateEmail()}
        fromName={dataEmailTemplate ? dataEmailTemplate.fromName : ''}
        subject={subjectTemplateEmail()}
      />
    </>
  );
};

const MailSettings = ({
  buttonLabel,
  onSwitch,
  onShowTemplateClick,
  switchValue,
  switchLabel,
  switchTooltip,
  disabled,
}) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', minWidth: '250px', flexDirection: 'column', marginBottom: '5px' }}>
        <Tooltip title={switchTooltip}>
          <FormControlLabel
            sx={{ padding: '12px 0px 0px 12px' }}
            control={<Switch disabled={disabled} onChange={onSwitch} checked={switchValue} />}
            label={switchLabel}
          />
        </Tooltip>

        <Button
          variant="tertiary"
          disabled={disabled}
          startIcon={<VisibilityIcon sx={{ height: '16px' }} />}
          onClick={onShowTemplateClick}>
          {buttonLabel}
        </Button>
      </Box>
    </Box>
  );
};

export default ChangeStatusRequest;
