import React from 'react';
import Stack from '@mui/material/Stack';
import DataGrid from 'ui/components/DataGrid';
import { tableSchemaBase as columns } from './Experts.config';
import useViewModal from 'hooks/shared/useViewModal';
import CoachViewModalWithRequest from 'ui/components/shared/coaches/CoachViewModalWithRequest';
import dayjs from 'dayjs';
import useFormSettings from 'hooks/usecase/useFormSettings';
import { useMeQuery } from 'hooks/queries';

const Experts = ({
  activeBorder,
  experts,
  expertIdSelected,
  onClickSelect,
  setQueryParams,
  queryParams,
  isLoading,
  coachee,
  onCoachDetail,
  hiddenAddButton = false,
  showCoachViewInModal = true,
}) => {
  // Hooks
  const { state, actions } = useViewModal();
  const { data: user } = useMeQuery();
  const qParams = { isMentor: true };
  const { formSettingsList } = useFormSettings(user.company.id, qParams);

  function computeCommonValues(tab1, tab2) {
    // Look for common elements between tab1 and tab2
    var commonValues = tab1.filter(function (valeur) {
      return tab2.includes(valeur);
    });

    return commonValues.length;
  }

  const dataGridStyles = activeBorder
    ? { border: '1px solid rgb(224, 224, 224)', padding: '0 8px', borderRadius: '0', cursor: 'pointer' }
    : { cursor: 'pointer' };

  let positions = [
    { index: 1, title: 'Intern' },
    { index: 2, title: 'Individual' },
    { index: 3, title: 'Contributor' },
    { index: 4, title: 'Manager' },
    { index: 5, title: 'Senior Manager' },
    { index: 6, title: 'Director' },
    { index: 7, title: 'VP' },
    { index: 8, title: 'Executive' },
  ];
  function findIndexByTitle(title, positions) {
    // Recherche du titre dans le tableau positions
    for (var i = 0; i < positions.length; i++) {
      if (positions[i].title == title) {
        return parseInt(positions[i].index);
      }
    }

    return false;
  }

  const updateList = (users) =>
    users.reduce((acc, currentItem) => {
      const checkMaxCapacity = currentItem && currentItem.maxCapacity ? currentItem.maxCapacity : false;
      let seniorityGap =
        Number.isInteger(
          findIndexByTitle(currentItem && currentItem.seniorityLevel ? currentItem.seniorityLevel : '', positions)
        ) &&
        Number.isInteger(findIndexByTitle(coachee && coachee.seniorityLevel ? coachee.seniorityLevel : '', positions))
          ? findIndexByTitle(currentItem && currentItem.seniorityLevel ? currentItem.seniorityLevel : '', positions) -
            findIndexByTitle(coachee && coachee.seniorityLevel ? coachee.seniorityLevel : '', positions)
          : '-';

      const max_capacity = checkMaxCapacity
        ? parseInt(checkMaxCapacity) - parseInt(currentItem.programsInProgress)
        : '-';

      const modifiedItem = {
        ...currentItem,
        name: currentItem.firstname + ' ' + currentItem.lastname,
        user: {
          name: currentItem.firstname + ' ' + currentItem.lastname,
          title: currentItem.title,
          picture: currentItem.picture,
        },
        scopeName: currentItem.userScope != null ? currentItem.userScope.name : '',
        registrationDate: dayjs(currentItem.registrationDate).fromNow(),
        languagesSpoken: currentItem.languagesSpoken ? currentItem.languagesSpoken.join(', ') : '',
        hasSubscriptions: currentItem.hasSubscriptions ? 'YES' : 'NO',
        internalCoach: currentItem.internalCoach ? 'INTERNAL' : 'EXTERNAL',
        //TODO : get equation to calcul availableSpots
        max_capacity,
        expertLabel: user.company.expertLabel,
        sessionLabel: user.company.sessionLabel,
        addButtonDisabled: max_capacity <= 0 && currentItem.canExceedMaxCapacity === false,
        //TODO : coach.languagesSpoken - coachee.seniorityLevel
        languages_in_common: computeCommonValues(
          Array.isArray(currentItem.languagesSpoken) ? currentItem.languagesSpoken : [],
          coachee && coachee.languages && typeof coachee.languages === 'string'
            ? coachee.languages.split(', ')
            : coachee.languages || []
        ),
        //TODO : coach.seniorityLevel - coachee.seniorityLevel
        seniority_gap: seniorityGap,
        targets_in_common: computeCommonValues(
          Array.isArray(currentItem.coachingSkills) && currentItem.coachingSkills ? currentItem.coachingSkills : [],
          coachee && Array.isArray(coachee.objectives) && coachee ? coachee.objectives : []
        ),
      };
      acc.push(modifiedItem);
      return acc;
    }, []);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userScope: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'userScope' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  function checkAllNulls(array, attribute) {
    return array.every(function (object) {
      return object[attribute] === null;
    });
  }
  function checkAllEmpty(array, attribute) {
    return array.every(function (object) {
      return object[attribute] === '-';
    });
  }

  const filterColumnFromSettings = (list) => {
    const EXCLUDE_LIST = [];
    formSettingsList?.formFields?.map((item) => {
      switch (item.fieldName) {
        case 'spokenLanguages':
          if (!item.isActivated) EXCLUDE_LIST.push('languages_in_common');
          break;
        case 'objectives_expertises':
          if (!item.isActivated) EXCLUDE_LIST.push('targets_in_common');
          break;
        case 'genderIdentity':
          if (!item.isActivated) EXCLUDE_LIST.push('gender');
          break;
      }
    });
    return list.filter((item) => !EXCLUDE_LIST.includes(item.field));
  };

  const getColumns = () => {
    let filteredColumns = JSON.parse(JSON.stringify(columns));

    if (hiddenAddButton) {
      const actionColumn = filteredColumns.find((column) => column.field === 'actions');
      actionColumn.hiddenAddButton = true;
      actionColumn.minWidth = 140;
    }

    if (
      experts &&
      experts.rows &&
      Array.isArray(updateList(experts.rows)) &&
      checkAllNulls(updateList(experts.rows), 'gender')
    ) {
      filteredColumns = columns.filter((col) => col.field !== 'gender');
    }
    if (
      experts &&
      experts.rows &&
      Array.isArray(updateList(experts.rows)) &&
      checkAllEmpty(updateList(experts.rows), 'seniority_gap')
    ) {
      filteredColumns = filteredColumns.filter((col) => col.field !== 'seniority_gap');
    }

    return filterColumnFromSettings(filteredColumns);
  };

  return (
    <Stack sx={{ padding: '0px 20px', height: '520px' }}>
      <DataGrid
        sx={dataGridStyles}
        getRowClassName={(params) => {
          if (expertIdSelected === params.row.id) return 'Mui-selected';
        }}
        onChangeSize={() => {}}
        onChangePage={() => {}}
        progressPending={false}
        onRowClicked={(coach, event) => {
          if (coach?.row?.addButtonDisabled) return;
          if (!event.defaultMuiPrevented) {
            onClickSelect(coach.row);
          }
        }}
        onAdd={(expert) => {
          onClickSelect(expert);
        }}
        sizePages={[
          { id: 1, value: 5 },
          { id: 2, value: 10 },
          { id: 3, value: 15 },
        ]}
        onShow={(expert) => {
          actions.openModal(expert.id);
          onCoachDetail && onCoachDetail(expert.id);
        }}
        totalPages={experts && experts.totalPages}
        totalItems={experts && experts.totalItems}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        rows={experts !== undefined && updateList(experts.rows)}
        columns={getColumns()}
        customHeight="550px"
        loading={isLoading}
      />
      {showCoachViewInModal && (
        <CoachViewModalWithRequest
          userRole={user.role}
          coachId={state.viewId}
          open={state.open}
          onClose={actions.closeModal}
        />
      )}
    </Stack>
  );
};

export default Experts;
