import { get } from './request';

class Activities {
  static list(queryParams) {
    return get('/activities', queryParams).then((res) => res.data);
  }

  static exercisesList(queryParams) {
    const { activityId } = queryParams;

    const queryParamsCloned = JSON.parse(JSON.stringify(queryParams));
    delete queryParamsCloned.activityId;
    return get(`/activities/${activityId}/exercises`, queryParamsCloned).then((res) => res.data);
  }
}

export default Activities;
