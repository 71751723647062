import React, { useRef, useState, useEffect } from 'react';
import ViewModal from 'ui/components/shared/ViewModal';
import { Checkbox, Box, Typography, FormGroup, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DatePicker from 'ui/components/form/DatePicker';
import MuiTextField from '@mui/material/TextField';
import Button from 'ui/components/Button/Button';
import dayjs from 'dayjs';

const ActiveSettingsCoachModal = ({ open, control, watch, coach, setValue, onClose, onValidate }) => {
  const { t } = useTranslation();
  const [choice, setChoice] = useState('deactivated-during');
  const isActivatedWithPlannedDeactivation = (coach.active && coach.deactivatedFrom) || !coach.active;

  useEffect(() => {
    setChoice('deactivated-during');
  }, [coach]);

  const isDisableValidateButton = () => {
    if (choice === 'deactivated-during') {
      return !watch('deactivatedFrom');
    }

    return false;
  };

  const title = isActivatedWithPlannedDeactivation
    ? t('active-settings-coach-modal.title-choice')
    : t('active-settings-coach-modal.title');

  const onChangeChoice = (newChoice) => {
    setChoice(newChoice);
  };

  const onClick = () => {
    if (choice === 'ignore-deactivation') {
      setValue('active', true);
      setValue('deactivatedFrom', null);
      setValue('deactivatedUntil', null);
    } else if (choice === 'deactivate-indefinite') {
      setValue('active', false);
      setValue('deactivatedFrom', null);
      setValue('deactivatedUntil', null);
    } else if (choice === 'activated') {
      setValue('active', true);
      setValue('deactivatedFrom', null);
      setValue('deactivatedUntil', null);
    }

    onValidate();
  };

  const radioButtonsActivated = () => {
    const results = ['deactivated-during'];

    if (!coach.active) results.push('activated');
    if (!coach.active && coach.deactivatedUntil) results.push('deactivate-indefinite');
    if (coach.active) results.push('deactivate-indefinite');
    if (coach.active && coach.deactivatedFrom) results.push('ignore-deactivation');

    return results;
  };

  return (
    <ViewModal
      open={open}
      onClose={onClose}
      customStyleParentContent={{ height: '100%' }}
      customStyleBox={{
        borderRadius: '12px',
        maxWidth: '540px',
        height: 'auto',
        top: '35%',
      }}>
      <Box sx={{ flexDirection: 'column', display: 'flex', height: '100%', justifyContent: 'space-between' }}>
        <Box>
          <Typography sx={{ fontSize: '24px', fontWeight: 600 }} component="h2" noWrap>
            {title}
          </Typography>
          <Typography sx={{ fontSize: '16px', color: 'primary.grey', marginBottom: '16px' }} component="h2">
            {t('active-settings-coach-modal.desc')}
          </Typography>
        </Box>

        <DefaultContent
          coach={coach}
          control={control}
          value={choice}
          watch={watch}
          radioButtonsActivated={radioButtonsActivated()}
          onChange={onChangeChoice}
        />

        <Box sx={{ marginTop: '16px', textAlign: 'right' }}>
          <Button variant="secondary" onClick={onClose}>
            {t('go_back')}
          </Button>
          <Button sx={{ marginLeft: '12px' }} variant="primary" disabled={isDisableValidateButton()} onClick={onClick}>
            {t('confirmation')}
          </Button>
        </Box>
      </Box>
    </ViewModal>
  );
};

const DefaultContent = ({ coach, control, value, radioButtonsActivated, watch, onChange }) => {
  const { t } = useTranslation();

  const minDateForDeactivatedUntil = watch('deactivatedFrom')
    ? dayjs.utc(new Date(watch('deactivatedFrom')))
    : dayjs.utc(new Date());

  const ignoreDeactivationLabel = () => {
    if (!watch('active')) {
      return t('active-settings-coach-modal.radio.ignore-deactivation');
    }

    return watch('deactivatedUntil')
      ? t('active-settings-coach-modal.radio.ignore-deactivation-with-date', {
          dateFrom: dayjs(watch('deactivatedFrom')).format('DD/MM/YYYY'),
          dateUntil: dayjs(watch('deactivatedUntil')).format('DD/MM/YYYY'),
        })
      : t('active-settings-coach-modal.radio.ignore-deactivation-without-date-until', {
          dateFrom: dayjs(watch('deactivatedFrom')).format('DD/MM/YYYY'),
        });
  };

  const activatedLabel =
    !coach.active && !coach.deactivatedUntil
      ? 'active-settings-coach-modal.radio.activated'
      : 'active-settings-coach-modal.radio.reactivate-profile';

  return (
    <Box>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={(e) => onChange(e.target.value)}>
        {radioButtonsActivated.includes('deactivated-during') && (
          <>
            <FormControlLabel
              value="deactivated-during"
              control={<Radio />}
              label={t('active-settings-coach-modal.radio.deactivated-during')}
            />
            {value === 'deactivated-during' && (
              <Box
                sx={{
                  display: 'flex',
                  marginLeft: '28px',
                  marginBottom: '12px',
                  gap: '16px',
                  '.MuiTextField-root': { minWidth: '160px !important' },
                }}>
                <DatePicker
                  onlyDate
                  minDate={dayjs.utc(new Date())}
                  format="DD/MM/YYYY"
                  control={control}
                  name="deactivatedFrom"
                  label={t('active-settings-coach-modal.from')}
                  slotProps={{
                    actionBar: {
                      actions: ['accept'],
                    },
                  }}
                  renderInput={(props) => (
                    <MuiTextField {...props} variant="outlined" error={false} label={''} sx={{ minWidth: '160px' }} />
                  )}
                />

                <DatePicker
                  onlyDate
                  minDate={minDateForDeactivatedUntil}
                  format="DD/MM/YYYY"
                  control={control}
                  name="deactivatedUntil"
                  label={t('active-settings-coach-modal.until')}
                  slotProps={{
                    actionBar: {
                      actions: ['accept'],
                    },
                  }}
                  renderInput={(props) => <MuiTextField {...props} variant="outlined" error={false} label={''} />}
                />
              </Box>
            )}
          </>
        )}
        {radioButtonsActivated.includes('ignore-deactivation') && (
          <FormControlLabel value="ignore-deactivation" control={<Radio />} label={ignoreDeactivationLabel()} />
        )}
        {radioButtonsActivated.includes('deactivate-indefinite') && (
          <FormControlLabel
            value="deactivate-indefinite"
            control={<Radio />}
            label={t('active-settings-coach-modal.radio.deactivate-indefinite')}
          />
        )}
        {radioButtonsActivated.includes('activated') && (
          <FormControlLabel value="activated" control={<Radio />} label={t(activatedLabel)} />
        )}
      </RadioGroup>
    </Box>
  );
};

export default ActiveSettingsCoachModal;
