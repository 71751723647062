import React from 'react';
import MUIBox from '@mui/material/Box';

// https://github.com/mui/material-ui/issues/29524

const Box = ({ children, variant, sx, ...rest }, ref) => {
  const variants = {
    shadow: {
      boxShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
      backgroundColor: 'background.paper',
      borderRadius: '24px',
    },
    flex: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const style = { ...(variants[variant] || {}), ...sx };

  return (
    <MUIBox sx={style} {...rest} ref={ref}>
      {children}
    </MUIBox>
  );
};

export default React.forwardRef(Box);
