import React from 'react';
import Typography from '@mui/material/Typography';

import Box from 'ui/components/system/Box';
import Grid from '@mui/material/Grid';
import { parseJsonTranslate } from 'utils/parse';
import YooptaEditor from 'ui/components/YooptaEditor/YooptaEditor';

const styleText = {
  fontWeight: 500,
  fontSize: '32px',
  color: 'black',
  textAlign: 'center',
  paddingTop: '30px',
  paddingBottom: '30px',
};

const ThemeViewHeader = ({ backgroundPicture }) => {
  return (
    <>
      <Box sx={{ alignItems: 'left', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{ height: { lg: '230px', xl: '230px' }, objectFit: 'cover' }}
          component="img"
          src={backgroundPicture}
        />
      </Box>
    </>
  );
};

const ThemeViewContent = ({ description, name, md, paddingContent }) => {
  const descriptionJson = parseJsonTranslate(description);

  return (
    <Box sx={{ alignItems: 'left', width: '95%', display: 'flex', flexDirection: 'column' }}>
      <Grid container sx={{ justifyContent: 'center', padding: paddingContent }}>
        <Grid item xs={12} md={md}>
          <Typography component="h1" sx={styleText}>
            {parseJsonTranslate(name)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={md} sx={{ boxShadow: 'none' }}>
          {description && (
            <Typography sx={{ fontSize: '16px', overflowWrap: 'break-word' }}>
              <YooptaEditor readOnly style={{ width: '100%' }} value={descriptionJson} />
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const ThemeView = ({ theme, md = 8, paddingContent = '0 12px 20px 12px' }) => {
  return (
    <>
      <Box
        sx={{
          alignItems: 'center',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <ThemeViewHeader {...theme} />
        <ThemeViewContent md={md} paddingContent={paddingContent} {...theme} />
      </Box>
    </>
  );
};

export default ThemeView;
