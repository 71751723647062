/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';

// Style
import styles from './styles';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellAvatar({ logo }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <img
        data-tag="allowRowEvents"
        alt="Remy Sharp"
        src={
          logo
            ? logo
            : 'https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg'
        }
        style={styles.picture}
      />
    </Stack>
  );
}

export default CellAvatar;
