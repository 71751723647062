/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// React
import React from 'react';
import clipboardCopy from 'clipboard-copy';

// MUI
import IconButton from '@mui/material/IconButton';

// Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Style
import { t } from 'i18next';

import { useAlert } from 'common/alertContext';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const CopyButton = ({ data, message = t('EMAIL_COPIED'), sx, ...props }) => {
  /* ********************************** HOOKS ********************************* */
  const { setStateAlert } = useAlert();
  /* ******************************** RENDERING ******************************* */

  return (
    <IconButton
      sx={[{ padding: '10px' }, sx]}
      size="small"
      onClick={() => {
        clipboardCopy(data)
          .then(() => {})
          .catch((err) => {
            console.error('Copy failed:', err);
          });

        setStateAlert({ open: true, message });
      }}
      {...props}>
      <ContentCopyIcon fontSize="small" />
    </IconButton>
  );
};

export default CopyButton;
