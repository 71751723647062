import React from 'react';
import MuiSwitch from '@mui/material/Switch';
import { useController } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Switch({ name, control, label, color, sx, labelPlacement = 'start', onChange }) {
  const {
    field: { onChange: onChangeControl, value },
  } = useController({ control, name });
  return (
    <FormControlLabel
      sx={sx}
      control={
        <MuiSwitch
          checked={value}
          onChange={(e) => {
            onChangeControl(e);
            onChange && onChange(e);
          }}
          color={color}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
    />
  );
}
