import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import styles from './SelfRegistrationLinkModal.styles';

import ViewModal from 'ui/components/shared/ViewModal';
import SelfRegistration from 'ui/pages/settings/components/self-registration/SelfRegistration';

const SelfRegistrationLinkModal = ({ applicationLink, companyId, open, selfRegistrationLink, onClose }) => {
  const { t } = useTranslation();

  return (
    <ViewModal customStyleBox={{ height: 'max-content', width: '500px' }} open={open} onClose={onClose}>
      <Typography sx={styles.title}>{t('self_registration_link_modal.title')}</Typography>
      <Typography sx={styles.desc}>{t('self_registration_link_modal.desc')}</Typography>
      <SelfRegistration
        activeClipboardCopy
        selfRegistrationLinkCode={selfRegistrationLink}
        applicationLinkCode={applicationLink}
        companyId={companyId}
      />
    </ViewModal>
  );
};

export default SelfRegistrationLinkModal;
