/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useRef } from 'react';

// UI Lib dependencies
import { Button, Dialog, DialogActions, DialogContent, Box, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// UI Local Lib dependencies
import Input from 'ui/components/Input';
import MultiSelectScope from 'ui/components/MultiSelectScope/MultiSelectScope';
import MultiSelectRole from 'ui/components/MultiSelectRole/MultiSelectRole';
import { Tooltip } from '@mui/material';
import Select from 'ui/components/Select';
import { truncateString } from 'common/utils';

// Validation
import { useTranslation } from 'react-i18next';

// Styles
import styles from './EditModal.styles';
import { ROLE, SCOPE } from 'litterals';

// Constants
import { ROLES_OPTIONS_ADMIN } from '../../../../utils/roles';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const EditModal = ({ isOpen, onClose, control, title, onEdit, validation, watch, companyId }) => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  // Refs
  const refName = useRef();
  const refLanguages = useRef();

  return (
    <Dialog PaperProps={{ sx: styles.container }} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Tooltip title={title + ' ' + watch('firstname') + ' ' + watch('lastname')}>
          <Typography style={styles.title}>
            {truncateString(`${title + ' ' + watch('firstname') + ' ' + watch('lastname')}`, 30)}
          </Typography>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
        <Box sx={styles.rowInput}>
          <Input
            ref={refName}
            control={control}
            variant="outlined"
            label="Firstname"
            name="firstname"
            rules={validation.firstname}
            fullWidth
          />
        </Box>
        <Box sx={styles.rowInput}>
          <Input
            ref={refName}
            control={control}
            variant="outlined"
            label="Lastname"
            name="lastname"
            rules={validation.lastname}
            fullWidth
          />
        </Box>
        <Box sx={styles.rowInput}>
          <Input
            ref={refName}
            control={control}
            variant="outlined"
            label="Email"
            name="email"
            rules={validation.email}
            fullWidth
          />
        </Box>
        <Box sx={styles.rowInput}>
          <Input
            ref={refName}
            control={control}
            variant="outlined"
            label="idNumber"
            name="idNumber"
            rules={validation.idNumber}
            fullWidth
          />
        </Box>
        <Box sx={styles.rowInput}>
          <MultiSelectScope name="scopes" label={t(SCOPE)} control={control} companyId={companyId} />
        </Box>
        <Box sx={styles.rowInput}>
          <Select
            options={ROLES_OPTIONS_ADMIN}
            label={t(ROLE)}
            name="role"
            ref={null}
            control={control}
            rules={validation.language}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={styles.containerButtons}>
        <Button variant="outlined" onClick={onClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'primary.dark' }}
          onClick={() => {
            onEdit();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditModal;
