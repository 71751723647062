const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '400px',
  },
  title: {
    fontSize: '30px',
    fontFamily: 'Karla',
    fontWeight: 800,
    lineHeight: '35.07px',
  },
  horizontalCardsContainer: {
    paddingBottom: '10px',
    overflowY: 'scroll',
    textWrap: 'nowrap',
  },
  resourceCard: {
    display: 'inline-block',
    margin: '20px 32px 10px 0',
    minWidth: '240px',
    width: '240px',

    ':last-child': {
      marginRight: '2px',
    },
  },
};
export default styles;
