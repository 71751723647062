const validation = {
  name: { required: 'This field is required' },
  linkTerms: { required: 'This field is required' },
  documentationExpert: {},
  documentationCollaborator: {},
  feedbackLin: {},
  expertLabel: {
    required: 'This field is required',
  },
  clientLabel: {
    required: 'This field is required',
  },
  sessionLabel: {
    required: 'This field is required',
  },
  deactivatedPrograms_delay_before_deletion: {
    required: 'This field is required',
  },
  feedbackLinkMentor: {},
};

export default validation;
