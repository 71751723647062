/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { truncateString } from 'common/utils';

// Style
import styles from './styles';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellChip({ text }) {
  /* ********************************** HOOKS ********************************* */
  function isRed(text) {
    if (typeof text === 'string') {
      var lowercaseText = String(text).toLowerCase();
      return lowercaseText === 'NO' || lowercaseText === 'no';
    } else if (typeof text === 'number') {
      return text <= 0;
    } else {
      return false;
    }
  }

  function formatText(text) {
    if (text && typeof text === 'string') {
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    } else {
      return text;
    }
  }
  /* ******************************** RENDERING ******************************* */
  return (
    <Box
      sx={[
        styles.chip,
        {
          backgroundColor: isRed(text) ? '#EDC9C4' : '#BFE0CE',
        },
      ]}>
      <Tooltip title={text}>
        <Typography sx={styles.text}> {truncateString(`${formatText(text)}`, 5)}</Typography>
      </Tooltip>
    </Box>
  );
}

export default CellChip;
