const styles = {
  container: {
    width: '100%',
  },
  tab: {
    boxShadow: 'none',
    color: 'black',
    fontFamily: 'Karla',
    fontWeight: 800,
    fontSize: '16px',
    lineHeight: '18.7px',
    textAlign: 'center',
    textTransform: 'none',
    height: '37px',
    width: '114px',
    '&:hover': {
      backgroundColor: '#FEBA36',
      boxShadow: 'none',
    },
  },
  unselectedTab: {
    backgroundColor: 'white',
  },
  iconReturn: {
    height: '12px',
    width: '12px',
    paddingBottom: '1px',
  },
  fontHeader: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'karla',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '29.64px',
    letterSpacing: '0.25px',
    fontFeatureSettings: " 'clig' off, 'liga' off",
  },
};
export default styles;
