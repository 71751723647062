/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import { Stack, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';

// Style
import style from './styles';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsProgramRh({ rowData, onDeactivate, onReactivate, user }) {
  /* ******************************** RENDERING ******************************* */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cannotBeDeactivatedOrReactivated = user.id !== rowData.rhId && user.role !== 'RH_MANAGER';

  return (
    <Stack>
      <IconButton onClick={handleClick} size="medium">
        <SettingsIcon fontSize="inherit" />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {rowData.status !== 'deactivated' && rowData.status !== 'archived' && (
          <Tooltip
            title={cannotBeDeactivatedOrReactivated ? t('program_deactivation_impossible') : ''}
            placement="left">
            <div>
              <MenuItem
                sx={style.redItem}
                disabled={cannotBeDeactivatedOrReactivated}
                onClick={() => {
                  setAnchorEl(null);
                  onDeactivate(rowData);
                }}>
                {t('deactivate')}
              </MenuItem>
            </div>
          </Tooltip>
        )}
        {(rowData.status === 'deactivated' || rowData.status === 'archived') && (
          <Tooltip title={cannotBeDeactivatedOrReactivated ? t('program_reactivated_impossible') : ''} placement="left">
            <div>
              <MenuItem
                disabled={cannotBeDeactivatedOrReactivated}
                onClick={() => {
                  setAnchorEl(null);
                  onReactivate(rowData);
                }}>
                {t('reactivate')}
              </MenuItem>
            </div>
          </Tooltip>
        )}
      </Menu>
    </Stack>
  );
}

export default CellActionsProgramRh;
