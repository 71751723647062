import React, { useRef, useState } from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

// UI Local components
import Input from 'ui/components/Input';
import IncrementalStepsInput from 'ui/components/form/IncrementalStepsInput';
import Editor from 'ui/components/Editor';

import ImageCard from 'ui/components/ImageCard';
import UploadField from 'ui/components/form/UploadField';
import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';

// Icons
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Styles
import stylesComponent from './GeneralInfos.styles';
import { randomFileId } from 'utils/uploader';

const GeneralInfos = ({ theme, control, watch, submitEditMarketingPicture, submitEditBackgroundPicture, user }) => {
  // States
  const [open, setOpen] = useState(false);
  const [nbrSessions, setNbrSessions] = useState(watch('nbSessions'));
  const [selectedTabHtmlEditor, setSelectedTabHtmlEditor] = useState('tab-2');
  const [optionsTab, setOptionsTab] = useState([{ id: 'tab-2', text: 'HTML Editor', selected: true }]);

  // Refs
  const uploadMarketingPictureRef = useRef();
  const uploadBackgroundPictureRef = useRef();
  const descriptionRef = useRef();

  // Helpers
  const handleUploadMarketingPicture = () => {
    uploadMarketingPictureRef?.current?.click();
  };
  const handleUploadBackgroundPicture = () => {
    uploadBackgroundPictureRef?.current?.click();
  };
  function isObject(variable) {
    return typeof variable === 'object' && variable !== null;
  }

  return (
    <Box sx={stylesComponent.container}>
      {/* Container left */}
      <Box sx={stylesComponent.containerLeft}>
        <Box sx={stylesComponent.containerNbrSessions}>
          <Typography style={stylesComponent.nbrSesstionText}>{'1. Define a Number of sessions'}</Typography>
          <Box sx={{ width: '22px' }} />
          <IncrementalStepsInput
            max={99}
            value={nbrSessions}
            setValue={setNbrSessions}
            control={control}
            name="nbSessions"
          />
        </Box>
        <Box sx={stylesComponent.containerUploadPicture}>
          <Box sx={stylesComponent.subContainerUploadPicture}>
            <Typography style={stylesComponent.labelUploadPicture}>{'2. Upload a Marketing Picture'}</Typography>
            <IconButton onClick={handleUploadMarketingPicture}>
              <UploadFileIcon />
            </IconButton>
          </Box>

          <Box style={{ display: 'none' }}>
            <UploadField
              label="marketingPicture"
              name="marketingPicture"
              folder={'theme-pictures/'}
              fileId={randomFileId()}
              control={control}
              ref={uploadMarketingPictureRef}
              setDisabledButton={() => {}}
              accept={'picture'}
              submitForm={submitEditMarketingPicture}
              onUploadSuccess={() => {}}
            />
          </Box>
          {isObject(watch('marketingPicture')) ? (
            <Box
              sx={{
                height: '121.24px',
                width: '76.04px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CircularProgress size="1rem" color="secondary" />{' '}
            </Box>
          ) : (
            <Box
              height={'121.24px'}
              width={'165.24px'}
              component="img"
              name="marketingPicture"
              src={`${watch('marketingPicture')}?${Date.now()}`}
              sx={{
                objectFit: 'contain',
                borderTopLeftRadius: '25px',
                borderTopRightRadius: '25px',
              }}
              //  onLoad={() => setLoadedLogo(false)}
              //  onLoadStart={() => setLoadedLogo(true)}
              loading="lazy"
            />
          )}
        </Box>
        <Box sx={stylesComponent.containerUploadBackgroundPicture}>
          <Box sx={stylesComponent.subContainerUploadBackgroundPicture}>
            <Typography style={stylesComponent.labelUploadPicture}>{'3. Upload a Background Picture'}</Typography>
            <IconButton onClick={handleUploadBackgroundPicture}>
              <UploadFileIcon />
            </IconButton>
          </Box>
          <Box style={{ display: 'none' }}>
            <UploadField
              label="backgroundPicture"
              name="backgroundPicture"
              folder={'theme-pictures/'}
              fileId={randomFileId()}
              control={control}
              ref={uploadBackgroundPictureRef}
              setDisabledButton={() => {}}
              accept={'picture'}
              submitForm={submitEditBackgroundPicture}
              onUploadSuccess={() => {}}
            />
          </Box>

          {isObject(watch('backgroundPicture')) ? (
            <Box sx={stylesComponent.containerLoader}>
              <CircularProgress size="1rem" color="secondary" />{' '}
            </Box>
          ) : (
            <Box
              height={'127px'}
              width={'333px'}
              component="img"
              name="backgroundPicture"
              src={`${watch('backgroundPicture')}?${Date.now()}`}
              sx={stylesComponent.backgroundPicture}
              loading="lazy"
            />
          )}
        </Box>
        {false && (
          <Box sx={stylesComponent.containerDescription}>
            <Typography style={stylesComponent.labelThemeDescription}>{'4. Put a theme description'}</Typography>
            <Editor
              role={user && user.role ? user.role : 'ADMIN'}
              title="Content"
              control={control}
              name="description"
              label={'description'}
              actions={() => {}}
            />
            <Editor
              role={user && user.role ? user.role : 'ADMIN'}
              title="Content1"
              control={control}
              name="content1"
              label={'content1'}
              actions={() => {}}
            />
            <Editor
              role={user && user.role ? user.role : 'ADMIN'}
              title="Content2"
              control={control}
              name="content2"
              label={'content2'}
              actions={() => {}}
            />
            <Editor
              role={user && user.role ? user.role : 'ADMIN'}
              title="Content3"
              control={control}
              name="content3"
              label={'content3'}
              actions={() => {}}
            />
          </Box>
        )}
        <Box sx={stylesComponent.containerGroupLabel}>
          <Typography style={stylesComponent.labelDescription}>{'5. Choose a group label'}</Typography>
          <Input ref={null} control={control} variant="outlined" label="Group Label" name="groupLabel" />
        </Box>
      </Box>
      {/* Container right */}
      <Box sx={stylesComponent.containerRight}>
        <Box sx={stylesComponent.subContainerRight}>
          <Typography style={stylesComponent.labelThemePreview}>{'Theme Preview'}</Typography>
          <ImageCard
            title={watch('name')}
            sxTitle={{ minHeight: '20px' }}
            sxCard={{ height: '270px', width: '220px' }}
            sxCardActionArea={{}}
            key={`theme-card-${'theme.id'}`}
            src={`${watch('marketingPicture')}?${Date.now()}`}
            onClick={() => {
              setOpen(true);
            }}>
            <IconButton onClick={() => ''} sx={{ padding: '0px' }}>
              <VisibilityIcon />
            </IconButton>
          </ImageCard>
          <ThemeViewModal themeId={theme && theme.id} open={open} onClose={() => setOpen(false)} />
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralInfos;
