const styles = {
  cellText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.5,
    wordWrap: 'break-word',
    fontFamily: 'karla',
  },
};

export default styles;
