import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'ui/components/system/Box';
import TextField from 'ui/components/form/TextField';
import useEditLabels from 'hooks/usecase/useEditLabels';
import Button from 'ui/components/Button/Button';
import { CLIENT, COACH, COACHING, LABEL, MENTORING, SAVE_CHANGES } from 'litterals';

const Labels = () => {
  const { t } = useTranslation();
  const {
    form: { control },
    actions: { onHandleLabel },
  } = useEditLabels();

  const styleInput = { maxWidth: '500px', shrink: 'true', textTransform: 'capitalize' };

  return (
    <Box component={'form'} display={'flex'} flexDirection={'column'} gap={'20px'} sx={{ marginTop: '32px' }}>
      <TextField
        name="expertLabel"
        label={`${t(COACH)} ${t(LABEL)} (Mentor, Coach, etc)`}
        placeholder=""
        type="text"
        control={control}
        style={styleInput}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        name="clientLabel"
        label={`${t(CLIENT)} ${t(LABEL)} (Mentee, Coachee, etc)`}
        placeholder=""
        type="text"
        control={control}
        style={styleInput}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        name="sessionLabel"
        label={`${t(COACHING)}/${t(MENTORING)} ${t(LABEL)} (Mentoring, Coaching, Tutoring, etc)`}
        placeholder=""
        type="text"
        control={control}
        style={styleInput}
        InputLabelProps={{ shrink: true }}
      />
      <Button type="submit" onClick={onHandleLabel}>
        {t(SAVE_CHANGES)}
      </Button>
    </Box>
  );
};

export default Labels;
