import { get, put, post, del } from './request';

class Program_Documents {
  static get(id) {
    return get(`/program_documents/${id}`).then((res) => res.data);
  }

  static getByProgramId(id) {
    return get(`/program_documents/program/${id}`).then((res) => res.data);
  }

  static edit({ id, payload }) {
    return put(`/program_documents/${id}`, payload).then((res) => res.data);
  }

  static create(payload) {
    return post('/program_documents', payload).then((res) => res.data);
  }

  static delete(id) {
    return del('/program_documents/' + id).then((res) => res.data);
  }
}

export default Program_Documents;
