import React from 'react';
import ThemeView from 'ui/components/shared/theme/ThemeView';
import ThemeViewWithRequest from './ThemeViewWithRequest';
import ViewModal from '../ViewModal';

const ThemeViewModal = ({ themeId, open, onClose, theme }) => {
  return (
    <ViewModal
      open={open}
      customStyleBox={{ padding: 0, borderRadius: '16px', maxWidth: '1100px' }}
      positionClose="sticky"
      onClose={onClose}>
      {!!theme && <ThemeView theme={theme} />}
      {!theme && <ThemeViewWithRequest themeId={themeId} />}
    </ViewModal>
  );
};

export default ThemeViewModal;
