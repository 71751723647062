import React from 'react';
import { Box, Tooltip } from '@mui/material';
import styles from './UserChip.styles';

const UserChip = ({ tooltip, icon, color, label, noWrap = false, sx = {}, sxLabel = {}, noBorder = false }) => {
  const styleNoWrap = noWrap ? { textWrap: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' } : {};
  const styleBorder = noBorder ? { border: 'none' } : {};

  return (
    <Tooltip title={tooltip ? label : ''}>
      <Box sx={{ ...styles.chip, ...styles[`${color}Chip`], ...styleNoWrap, ...styleBorder, ...sx }}>
        {icon}
        <span style={{ ...sxLabel, ...styleNoWrap }}>{label}</span>
      </Box>
    </Tooltip>
  );
};

export default UserChip;
