import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { truncateString } from 'common/utils';
import api from 'api';
import { useController } from 'react-hook-form';

const MultiSelectScope = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scopesList, setScopesList] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const [values, setValues] = useState([]);

  const {
    // eslint-disable-next-line no-unused-vars
    field: { onChange, value, ref },
    fieldState: { error },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    ref: props.ref,
  });

  const mutateGetScopesList = useMutation(api.Scopes.list, {
    onSuccess: (data) => {
      if (!props.companyId) {
        setScopesList(data);
      } else {
        setScopesList(data.filter((item) => item.companyId == props.companyId));
      }

      setIsFetched(true);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (!isFetched) {
      mutateGetScopesList.mutate();
    }
  }, [isFetched]);

  React.useEffect(() => {
    if (value && value.length === 0) {
      setValues([]);
    } else if (value && scopesList) {
      const clonedValues = [...value];
      // eslint-disable-next-line no-unused-vars
      const objectArray = clonedValues.map((chaine, index) => ({
        name: chaine,
        value: chaine,
        id: chaine,
      }));
      setValues([...scopesList.filter((op) => objectArray.find((sv) => op.id === sv.id))]);
    }
  }, [isFetched]);

  return (
    <Box>
      {isLoading ? (
        <Skeleton sx={{ width: '211px' }} />
      ) : (
        <Autocomplete
          multiple
          disableCloseOnSelect
          value={values}
          options={scopesList}
          limitTags={1}
          disableListWrap
          getOptionLabel={(option) => option.name || ''}
          onChange={(event, newValue) => {
            const idsList = newValue.map((item) => item.id);
            if (idsList.length == 0) {
              onChange('');
              setValues(newValue);
            } else {
              onChange(idsList);
              setValues(newValue);
            }
          }}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = 1;
            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Tooltip key={index} title={option.name}>
                    <Chip
                      sx={{ height: '20px', fontSize: '10px' }}
                      size="small"
                      {...getTagProps({ index })}
                      label={truncateString(`${option.name}`, 11)}
                    />
                  </Tooltip>
                ))}
                {numTags > limitTags && <Typography sx={{ fontSize: '10px', fontWeight: 'bold' }}>...</Typography>}
              </>
            );
          }}
          renderInput={(params) => (
            <TextField variant="standard" error={!!error} helperText={error?.message} {...params} label={props.label} />
          )}
        />
      )}
    </Box>
  );
};

export default MultiSelectScope;
