/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';
import Button from 'ui/components/Button/Button';

// Style
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsUser({ onAccept, onRefuse }) {
  /* ******************************** RENDERING ******************************* */

  const handleRefuseClick = (event) => {
    event.stopPropagation();
    onRefuse();
  };

  const handleAcceptClick = (event) => {
    event.stopPropagation();
    onAccept();
  };

  return (
    <Stack sx={{ gap: '12px', flexDirection: 'row' }}>
      <Button variant="tertiary" sx={{ color: 'primary.grey' }}>
        {t('show_more')}
      </Button>
      <Button variant="secondary" onClick={handleRefuseClick}>
        {t('decline')}
      </Button>
      <Button variant="primary" onClick={handleAcceptClick}>
        {t('validate')}
      </Button>
    </Stack>
  );
}

export default CellActionsUser;
