import { useController } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Draft from 'draft-js';
import { Typography } from '@mui/material';

export const EditorField = (props) => {
  const { control, name, title } = props;
  const [editorState, setEditorState] = useState();
  const [disableRerender, setDisableRerender] = useState(false); // setting default value
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue: null });

  const onEditorStateChange = (editorContent) => {
    const html = draftToHtml(convertToRaw(editorContent.getCurrentContent()));

    value !== html && props.onChange && props.onChange(html);

    setDisableRerender(true);
    setEditorState(editorContent);
    onChange(html);
  };

  useEffect(() => {
    if (!disableRerender) {
      if (value == null) {
        setEditorState(Draft.EditorState.createEmpty());
      } else {
        const html = value;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
      }
    }
  }, [value]);

  // const toolbarButtons = {
  //   options: ['inline', 'list', 'textAlign', 'history'],
  //   inline: {
  //     inDropdown: false,
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined,
  //     options: ['bold', 'italic', 'underline', 'strikethrough'],
  //     bold: { className: undefined },
  //     italic: { className: undefined },
  //     underline: { className: undefined },
  //     strikethrough: { className: undefined },
  //     monospace: { className: undefined },
  //     superscript: { className: undefined },
  //     subscript: { className: undefined },
  //   },
  //   list: {
  //     inDropdown: false,
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined,
  //     options: ['unordered', 'ordered', 'indent', 'outdent'],
  //     unordered: { className: undefined },
  //     ordered: { className: undefined },
  //     indent: { className: undefined },
  //     outdent: { className: undefined },
  //   },
  //   textAlign: {
  //     inDropdown: false,
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined,
  //     options: ['left', 'center', 'right', 'justify'],
  //     left: { className: undefined },
  //     center: { className: undefined },
  //     right: { className: undefined },
  //     justify: { className: undefined },
  //   },
  //   history: {
  //     inDropdown: false,
  //     className: undefined,
  //     component: undefined,
  //     dropdownClassName: undefined,
  //     options: ['undo', 'redo'],
  //     undo: { className: undefined },
  //     redo: { className: undefined },
  //   },
  // };

  return (
    <>
      <Typography component="p">{title}</Typography>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="demo-wrapper"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        wrapperStyle={{ border: '1px solid rgba(0, 0, 0, 0.30', borderRadius: '4px', mb: '15px' }}
        editorStyle={{ borderRadius: '4px', padding: '6px 12px 6px 12px' }}
        // toolbar={toolbarButtons}
      />
    </>
  );
};
