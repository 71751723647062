import { useState } from 'react';

const useViewModal = () => {
  const [open, setOpen] = useState(false);
  const [viewId, setViewId] = useState('');

  const openModal = (id) => {
    setViewId(id);
    setOpen(true);
  };

  const closeModal = () => setOpen(false);

  return {
    state: { open, viewId },
    actions: { openModal, closeModal },
  };
};

export default useViewModal;
