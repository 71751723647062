/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Box from '@mui/material/Box';
import { OpenProgramCell, ChooseCoachButtonCell, RequestAnswerButtonCell } from 'ui/pages/home/ProgramsTable';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsHome({ rowData, role, asCoachee, onAnswerRequest }) {
  /* ******************************** RENDERING ******************************* */
  const chooseCoach = rowData.status === 'pending';
  return (
    <Box>
      {rowData && rowData.type === null && rowData.status === 'pending' && chooseCoach && (
        <ChooseCoachButtonCell programId={rowData.id} />
      )}
      {rowData && rowData.type === 'EVERGREEN' && rowData.status === 'pending' ? (
        <RequestAnswerButtonCell
          onClick={(e) => {
            e.stopPropagation();
            onAnswerRequest(rowData);
          }}
        />
      ) : null}
      {rowData && (rowData.status === 'in progress' || rowData.status === 'done') && role !== 'RH' && !chooseCoach && (
        <OpenProgramCell programId={rowData.id} />
      )}
    </Box>
  );
}

export default CellActionsHome;
