const styles = {
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    borderRadius: '8px',
    border: '1px solid rgba(224, 224, 224, 0.4)',
    width: {
      xs: '280px',
      sm: '280px',
      md: '280px',
      lg: '280px',
      xl: '290px',
    },
    height: '310px',
    boxShadow: ' 0px 0px 0px 1px #E0E0E0',
    cursor: 'pointer',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
  picture: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    border: '1px solid #0000000A',
  },
};
export default styles;
