/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// Style
import styles from './styles';

import { getLabelByValue } from 'utils/languages';
import { useTranslation } from 'react-i18next';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellLanguages({ languages }) {
  /* ********************************** HOOKS ********************************* */
  const { t } = useTranslation();
  /* ******************************** HELPERS ******************************* */
  function replaceTerms(inputString) {
    const words = inputString.split(' ');

    const replacedWords = words.map((word) => {
      if (word === ',') {
        return ',';
      }
      return t(getLabelByValue(word));
    });

    const result = replacedWords.join(' ');

    return result;
  }

  function replaceTerms2(chaine) {
    return chaine
      .split(',')
      .map(function (key) {
        return t(getLabelByValue(key)) || key;
      })
      .join(',');
  }

  function transformChaine(input) {
    input = input.replace(/ /g, '');
    const elements = input.split(',');
    let output = '';

    for (let i = 0; i < elements.length; i++) {
      output += t(getLabelByValue(elements[i]));
      if (i < elements.length - 1) {
        output += ', ';
      }
    }

    return output;
  }
  /* ******************************** RENDERING ******************************* */

  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <Typography sx={styles.cellText}>{transformChaine(languages)}</Typography>
    </Stack>
  );
}

export default CellLanguages;
