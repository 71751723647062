import React from 'react';
import AuthLayout from '../login/AuthLayout';
import Loader from '../../components/shared/Loader.jsx';
import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import litterals from 'litterals';
import api from 'api';
import { t } from 'i18next';
import { getTypeAuth } from 'utils/auth';

const MicrosoftAuth = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { type } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [, setCompany] = useState({});
  const [error, setError] = useState('');

  const submitMicrosoftAuthCallBack = useMutation(api.Auth.microsoftAuthCallback, {
    onSuccess: async (data) => {
      localStorage.removeItem('MICROSOFT_TOKEN_AUTH');
      localStorage.setItem('REACT_TOKEN_AUTH', data.token);
      localStorage.setItem('REACT_REFRESH_TOKEN_AUTH', data.refreshToken);

      await queryClient.invalidateQueries();
      const redirectAuth = localStorage.getItem('REDIRECT_AUTH');
      if (redirectAuth) {
        setCompany(data.user.company);
        localStorage.removeItem('REDIRECT_AUTH');
        window.location = redirectAuth;
      } else {
        if (data.user.acceptCompanyTerms) return navigate('/home');
        setCompany(data.user.company);
      }
    },
    onError: (err) => {
      localStorage.removeItem('MICROSOFT_TOKEN_AUTH');

      if (!error && err?.response?.data?.error) {
        if (err?.response?.data?.code === 500) {
          setError(t(litterals['MICROSOFT_AUTH_UNKNOWN_ERROR']));
        } else {
          const { code } = err.response.data;
          if (code) {
            setError(t(litterals[`MICROSOFT_AUTH_SERVER_${code}_ERROR`]));
          } else {
            setError(err.response.data.error);
          }
        }
      }
    },
  });

  useEffect(() => {
    if (searchParams.has('code')) {
      const code = searchParams.get('code');
      searchParams.delete('code');
      setSearchParams(searchParams);

      const ssoType = getTypeAuth(type);

      submitMicrosoftAuthCallBack.mutate({ code, type: ssoType });
    } else if (!error) {
      setError(initError());
    }
  }, []);

  const initError = () => {
    if (searchParams.has('error')) {
      const codeError = searchParams.get('error');
      const existingTranslation = litterals[`MICROSOFT_AUTH_${codeError.toUpperCase()}_ERROR`];

      if (existingTranslation) {
        return t(existingTranslation);
      } else if (searchParams.has('error_description')) {
        return searchParams.get('error_description');
      }
    } else if (!searchParams.has('code')) {
      return t(litterals['MICROSOFT_AUTH_NO_CODE_ERROR']);
    }

    return;
  };

  const displayError = (error) => {
    if (!error) return;

    return (
      <Box sx={{ margin: 'auto' }}>
        <Typography
          gutterBottom
          component="h4"
          color="red"
          fontSize={'16px'}
          marginLeft={'12px'}
          lineHeight={'24px'}
          fontWeight={400}
          letterSpacing={'0.15px'}>
          {error}
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <Button onClick={() => navigate('/login')} variant="contained">
            {t(litterals.MICROSOFT_AUTH_BUTTON)}
          </Button>
        </Box>
      </Box>
    );
  };

  const displayLoader = () => {
    if (error) return;

    return (
      <Box sx={{ margin: 'auto' }}>
        <Loader show />
      </Box>
    );
  };

  return (
    <AuthLayout>
      {displayError(error)}
      {displayLoader(error)}
    </AuthLayout>
  );
};

export default MicrosoftAuth;
