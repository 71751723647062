import React from 'react';
import UserView from 'ui/components/shared/UserView/UserView';
import Box from 'ui/components/system/Box';
import { useParams } from 'react-router-dom';
import { useUserDetail, useMeQuery, useGetCompany } from 'hooks/queries';

import GoBackButton from 'ui/components/shared/GoBackButton';

const CoacheePage = () => {
  const { coacheeId } = useParams();
  console.log('coacheeId', coacheeId);
  const { data: user, isLoading: isLoadingUser } = useMeQuery();
  const { data: coachee, isLoading } = useUserDetail(coacheeId);
  const { data: company } = useGetCompany(coachee?.companyId, {
    enabled: !isLoadingUser && !isLoading && !user.company,
  });

  console.log('coachee', coachee);

  return (
    <Box
      variant=""
      sx={{
        width: '100%',
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: '2%',
        height: '84vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}>
      <GoBackButton sx={{ mt: '12px', ml: '12px' }} />
      <Box sx={{ padding: '2rem', margin: '2rem', background: '#fff' }}>
        <UserView
          isCurrentUser
          editButtonDisplay
          userRole={user.role}
          user={coachee}
          isLoading={isLoading}
          company={company}
          typeView="mentee"
          availabilitiesDisabled={true}
        />
      </Box>
    </Box>
  );
};

export default CoacheePage;
