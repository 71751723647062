// api/departments.js
import { get, del, post, put } from './request';

export default class Departments {
  static list(companyId) {
    return get(`/departments/?companyId=${companyId}`).then((res) => res.data);
  }

  static listWithParams(queryParams) {
    return get('/departments', queryParams).then((res) => res.data);
  }

  static listDepartmentEvergreen(_Link) {
    return get(`/guests/${_Link}/departments`).then((res) => res.data);
  }
  static delete(id) {
    return del(`/departments/${id}`).then((res) => res.data);
  }

  static create(data) {
    return post('/departments', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/departments/${id}`, data).then((res) => res.data);
  }
}
