import React from 'react';
import { useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import useProgramOnboarding from 'hooks/usecase/useProgramOnboarding';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stack from '@mui/material/Stack';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { ThemeDiscovery, ChooseCoach, ValidateCoach } from './OnboardingSteps';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import IconButton from 'ui/components/system/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { CONFIRMATION, CONTINUE } from 'litterals';
import { useMeQuery } from 'hooks/queries';

const ProgramOnboarding = () => {
  const { programId } = useParams();
  const { state, actions } = useProgramOnboarding(programId);
  const { data: user } = useMeQuery({
    enabled: !!localStorage.getItem('REACT_TOKEN_AUTH'),
  });

  return (
    <Paper sx={{ m: '24px', p: '12px', pb: '24px' }} elevation={1}>
      <Container sx={{ height: '76vh' }}>
        <Stack
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          component={Paper}
          sx={{ height: '100%' }}
          elevation={0}>
          <OnboardingStepper activeStep={state.nav.activeStep} />
          <Switch value={state.nav.activeStep}>
            <Case value={0}>
              <ThemeDiscovery theme={state.query.program.data?.theme} />
            </Case>
            <Case value={1}>
              <ChooseCoach
                company={user.company}
                coaches={state.query.subscription.data?.coaches}
                onSelect={actions.selectCoach}
                value={state.form.coach}
              />
            </Case>
            <Case value={2}>
              <ValidateCoach coach={state.form.coach} />
            </Case>
          </Switch>
          <Box
            sx={{
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}>
            <NavButtons
              activeStep={state.nav.activeStep}
              clickContinue={actions.clickContinue}
              clickPrev={actions.clickPrev}
              clickSubmit={actions.validate}
              disabledContinue={state.nav.disabledNext}
            />
          </Box>
        </Stack>
      </Container>
    </Paper>
  );
};

const PrevButton = ({ activeStep, clickPrev, children }) => (
  <>
    {!!activeStep && (
      <IconButton onClick={clickPrev}>
        <ArrowCircleLeftOutlinedIcon />
      </IconButton>
    )}
    {children}
    {!!activeStep && <Box sx={{ width: '40px' }} />}
  </>
);

const ValidateButton = ({ clickSubmit }) => {
  const { t } = useTranslation();
  return (
    <Fab color="ternary" variant="extended" onClick={clickSubmit} sx={{ width: '12vw' }}>
      {t(CONFIRMATION)}
      <CheckIcon sx={{ pl: '6px', pb: '4px' }} />
    </Fab>
  );
};

const ContinueButton = ({ clickContinue, disabled }) => {
  const { t } = useTranslation();
  return (
    <Fab color="ternary" variant="extended" onClick={clickContinue} sx={{ width: '12vw' }} disabled={disabled}>
      {t(CONTINUE)}
      <ArrowCircleRightOutlinedIcon sx={{ ml: '6px' }} />
    </Fab>
  );
};

const NavButtons = ({ activeStep, clickContinue, clickPrev, clickSubmit, disabledContinue }) => {
  return (
    <PrevButton activeStep={activeStep} clickPrev={clickPrev}>
      {activeStep === 2 ? (
        <ValidateButton clickSubmit={clickSubmit} />
      ) : (
        <ContinueButton clickContinue={clickContinue} disabled={disabledContinue} />
      )}
    </PrevButton>
  );
};

const OnboardingStepper = ({ activeStep }) => (
  <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: '24px', pb: '8px' }} color="ternary">
    <Step>
      <StepLabel />
    </Step>
    <Step>
      <StepLabel />
    </Step>
    <Step>
      <StepLabel />
    </Step>
  </Stepper>
);

export default ProgramOnboarding;
