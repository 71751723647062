/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/

import ScopeFilterOperators from 'ui/components/DataGrid/Filters/ScopeFilter/ScopeFilter';
import ActiveFilterOperators from 'ui/components/DataGrid/Filters/ActiveFilter/ActiveFilter';
import TypeFilterOperators from 'ui/components/DataGrid/Filters/TypeFilter/TypeFilter';
import LanguageFilterOperators from 'ui/components/DataGrid/Filters/LanguageFilter/LanguageFilter';
import GenderFilter from 'ui/components/DataGrid/Filters/GenderFilter/GenderFilter';

const tableSchemaBase = [
  {
    field: 'user',
    headerName: 'name',
    type: 'user',
    minWidth: 200,
    resizable: true,
    flex: 1,
  },
  {
    field: 'internalCoach',
    headerName: 'type',
    type: 'internalCoach',
    filterOperators: TypeFilterOperators,
    minWidth: 120,
    resizable: true,
    flex: 1,
  },
  {
    field: 'gender',
    headerName: 'gender',
    type: 'gender',
    minWidth: 100,
    flex: 1,
    filterOperators: GenderFilter,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'max_capacity',
    headerName: 'max_capacity',
    type: 'chip',
    minWidth: 120,
    resizable: true,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'languages_in_common',
    headerName: 'languages_in_common',
    type: 'chip',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'seniority_gap',
    headerName: 'seniority_gap',
    type: 'chip',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'targets_in_common',
    headerName: 'targets_in_common',
    type: 'chip',
    minWidth: 100,
    flex: 1,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'actions',
    type: 'actionsButtons',
    minWidth: 250,
    resizable: true,
    headerName: 'actions',
    sortable: false,
    filter: false,
    filterable: false,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
];

const sampleDataBase = [...Array(10).keys()].map((i) => ({
  id: i + 1,
  userScope: null,
  user: {
    name: 'rami',
    title: 'Coaching professionnel - Experienced Management Consultant & Executive Coach',
    picture:
      'https://pathline-dev-bucket.s3.fr-par.scw.cloud/profile-pictures/a19c78e0-6695-11ed-b755-138e5076a13d.png',
  },
  registrationDate: '12 month ago',
  type: 'true',
  email: 'laurent_coach_dev@yopmail.com',
}));

export { tableSchemaBase, sampleDataBase };
