/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, { useState, useRef } from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';

// UI Local components
import Input from 'ui/components/Input';
import TitleInput from 'ui/components/TitleInput';
import Editor from 'ui/components/Editor';
import { t } from 'i18next';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Icons

// Styles
import styles from './EditEmailTemplate.styles';

// Configs
import { TITLE } from 'litterals';
import { truncateString } from 'common/utils';

// Templates
import { emailTemplate } from 'utils/templateEmailUtils';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const EditEmailTemplate = ({
  optionsTab,
  setOptionsTab,
  control,
  isOpen,
  setOpen,
  onEdit,
  reset,
  watch,
  user,
  setSelectedTab,
}) => {
  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
    /*
    setSelectedTab('tab-1');
    setOptionsTab([
      { id: 'tab-1', text: 'Rich Text', selected: true },
      { id: 'tab-2', text: 'HTML Editor', selected: false },
    ]);
    */
    reset();
  };

  return (
    <Dialog
      disableEnforceFocus
      PaperProps={{ sx: styles.containerSessionTemplateDialog }}
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle sx={styles.containerTitle}>
        <Tooltip title={watch('name')}>
          <Typography style={styles.title}>
            {'Edit Email Template ' + truncateString(`${watch('name')}`, 30)}
          </Typography>
        </Tooltip>
        <TitleInput
          disabled={false}
          // eslint-disable-next-line no-unused-vars
          exportTitle={(title) => {
            //handleChangeSession(selectedSession.id, { name: title });
          }}
          control={control}
          actions={() => {}}
          session={null}
          placeholder={t(TITLE)}
          name="name"
          sx={styles.titleContent}
          inputProps={{ style: styles.titleContent }}
        />
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Stack sx={{ minHeight: '630px', backgroundColor: 'white', paddingTop: '50px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={styles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={control}
                variant="outlined"
                label="Email subject"
                name="subject"
              />
            </Box>
            <Box sx={{ width: '48px' }} />
            <Box sx={styles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={control}
                variant="outlined"
                label="FromName"
                name="fromName"
              />
            </Box>
          </Box>
          <Box sx={styles.containerInputsSessionDialog}>
            <Editor
              role={user && user.role ? user.role : 'ADMIN'}
              title="Content"
              control={control}
              name="content"
              label={'Content'}
              actions={() => {}}
            />
          </Box>

          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              color: 'title.main',
              alignSelf: 'center',
            }}>
            {'Email Template Preview'}
          </Typography>
          <Box
            sx={{
              border: '1px solid #F9F9F9',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              padding: '0px 200px',
              backgroundColor: '#f5f5f5',
            }}>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
              }}>
              <div dangerouslySetInnerHTML={{ __html: emailTemplate.replace('$customMessage$', watch('content')) }} />
            </Box>
          </Box>
        </Stack>
        {/** Content of dialog */}
      </DialogContent>
      <DialogActions sx={styles.btnBack}>
        <Button variant="outlined" onClick={handleClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={styles.btnSave}
          onClick={() => {
            onEdit();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditEmailTemplate;
