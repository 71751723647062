import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from 'ui/components/Button/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import { BACK_TO_HOME, PROGRAM_CREATED_SUCCESSFULLY, PROGRAM_CREATED_TEXT } from 'litterals';

const ConfirmationSuccess1 = () => {
  // Hooks
  const navigate = useNavigate();
  const { t } = useTranslation();

  const SUCCESS_IMAGE = `${process.env.PUBLIC_URL}/uploads/icons/success-create-program.svg`;

  return (
    <Stack sx={{ padding: '0px 20px', height: '520px' }}>
      <Box sx={styles.stepSuccess.rowTitle}>
        <Box sx={{ paddingRight: '10px' }} component="img" src={SUCCESS_IMAGE} />
        <Typography sx={styles.stepSuccess.title}>{t(PROGRAM_CREATED_SUCCESSFULLY)}</Typography>
      </Box>
      <Box sx={styles.stepSuccess.rowContent}>
        <Typography sx={styles.stepSuccess.value}>{t(PROGRAM_CREATED_TEXT)}</Typography>
      </Box>
      <Box sx={{ height: '30px' }} />
      <Box sx={styles.stepSuccess.rowContent}>
        <Button
          onClick={() => {
            navigate('/');
          }}
          variant="secondary">
          {t(BACK_TO_HOME)}
        </Button>
      </Box>
    </Stack>
  );
};

export default ConfirmationSuccess1;
