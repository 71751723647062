import parse from 'html-react-parser';
import i18n from 'i18n';

import { languagesAccepted } from './languages';

const parseJsonTranslate = (content, activeParse = false) => {
  if (typeof content === 'object') {
    const langue = i18n?.language?.split('-')?.[0]?.toLowerCase() || 'en';

    if (content?.[langue] == null && content != null) {
      for (const lng of Object.keys(content)) {
        if (languagesAccepted.includes(lng)) {
          return activeParse ? parse(content[lng]) : content[lng];
        }
      }

      return content;
    } else if (content == null) {
      return content;
    }

    const contentWithLangue = content[langue];
    return activeParse ? parse(contentWithLangue) : contentWithLangue;
  }

  return activeParse ? parse(content) : content;
};

const getContentTranslate = (content, langue) => {
  if (typeof content === 'object') {
    return content?.[langue] || '';
  }

  return '';
};

export { parseJsonTranslate, getContentTranslate };
