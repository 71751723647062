import React from 'react';

// MUI
import { Box, Card, CardMedia, Chip, Tooltip, Typography } from '@mui/material';

import MenuDropdown from './MenuDropdown';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { useTranslation } from 'react-i18next';

// Style
import styles from './ResourceCard.styles';

const ResourceCard = ({
  resource,
  id,
  onUpdate,
  onDelete,
  origin,
  name,
  type,
  language,
  picture,
  filepath,
  withoutActions,
}) => {
  const { t } = useTranslation();

  const PLACEHOLDER_RESOURCE = `${process.env.PUBLIC_URL}/uploads/images/placeholder-resource.svg`;

  // Delete resource
  const handleDeleteResource = () => {
    onDelete(id);
  };

  // Open file
  const handleOpenResource = () => {
    window.open(filepath, '_blank');
  };

  const originChip = () => {
    const style = origin === 'program' ? styles.chipProgram : styles.chipCompany;
    const type = origin === 'program' ? t('resource_card_origin_program') : t('resource_card_origin_company');

    return <Chip sx={[styles.chip, style]} label={type} variant="outlined" />;
  };

  return (
    <Card sx={styles.container} onClick={handleOpenResource}>
      <Box sx={styles.containerHeader}>
        {/* {picture ? (
          <CardMedia component="img" image={picture} sx={styles.picture} height="148" />
        ) : (
          <Box sx={styles.picturePlaceholder}>
            <CropOriginalIcon sx={styles.iconPlaceholder} />
          </Box>
        )} */}
        {
          <CardMedia
            component="img"
            image={picture ? picture : PLACEHOLDER_RESOURCE}
            sx={styles.picture}
            height="148"
          />
        }
        {!withoutActions && (
          <Box sx={styles.dropdownActions}>
            <MenuDropdown resource={resource} onDelete={handleDeleteResource} onUpdate={onUpdate} color={'white'} />
          </Box>
        )}
      </Box>
      <Box sx={styles.content}>
        <Box sx={styles.chips}>
          {type && <Chip sx={[styles.chip, styles.typeChip]} label={type} variant="outlined" />}
          {originChip()}
        </Box>
        <Tooltip title={name && name.length > 20 && name}>
          <Typography sx={styles.name}>{name}</Typography>
        </Tooltip>
        {language && (
          <Box sx={styles.containerLanguage}>
            <Box sx={styles.languageBadge}>{language}</Box>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default ResourceCard;
