import React, { useState, useRef, useEffect } from 'react';

import TabLangues from 'ui/components/TabLangues/TabLangues';
import TitleInput from 'ui/components/TitleInput';
import Box from 'ui/components/system/Box';
import Switch from 'ui/components/form/Switch';
import useEditExercises from 'hooks/usecase/useEditExercises';
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { getContentTranslate } from 'utils/parse';
import YooptaEditor from 'ui/components/YooptaEditor/YooptaEditor';
import { useMeQuery } from 'hooks/queries';
import { roleList } from 'utils/constUtils';
import { formatDateWithSecondes } from 'utils/dateUtils';
import TextFieldPathline from 'ui/components/form/TextField';

import styles from './EditorExercise.styles';

const EditorExercise = ({ exerciseId, activityId }) => {
  const { t } = useTranslation();
  const [languageSelected, setLanguageSelect] = useState('');
  const languageSelectedRef = useRef(languageSelected);
  const [languagesConfigured, setLanguagesConfigured] = useState([]);
  const [subtitle, setSubtitle] = useState('');
  const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);
  const [description, setDescription] = useState('');
  const descriptionRef = useRef(description);
  const refSwitch = useRef();
  const refDuration = useRef();
  const exerciseIdRef = useRef(exerciseId);
  const isDescriptionChangedRef = useRef(isDescriptionChanged);
  const { data: user } = useMeQuery();

  const { state: exerciseState, actions: exerciseActions } = useEditExercises(exerciseId);

  const exercise = exerciseState.query.data;

  const { control, setValue } = useForm({ mode: 'all', defaultValues: { name: '' } });

  useEffect(() => {
    if (exerciseIdRef && exercise?.id && exerciseIdRef.current !== exercise.id) {
      window.location.reload();
    }

    if (exercise && typeof exercise.content === 'object' && exercise.content !== null) {
      const languages = Object.keys(exercise.content);
      setLanguagesConfigured(languages);

      if (languages.length && !languageSelected) setLanguage(languages[0]);
    } else {
      setLanguagesConfigured([]);
      setLanguage('', false);
    }
  }, [exercise]);

  useEffect(() => {
    if (!exerciseId && activityId) {
      exerciseActions.setValue('activityId', activityId);
      exerciseActions.setValue('name', ' ');
      exerciseActions.setValue('duration', 0);
      exerciseActions.setValue('content', ' ');
      exerciseActions.setValue('subtitle', ' ');
    }
  }, [exerciseId, activityId]);

  const onAddLangueClick = (lng) => {
    setLanguagesConfigured([...languagesConfigured, lng]);
    exerciseActions.addLanguage(lng);
    setLanguage(lng, false);
  };

  const onLangueClick = (lng) => {
    setLanguage(lng);
  };

  const onDeleteLangue = () => {
    setLanguagesConfigured([...languagesConfigured.filter((line) => line !== languageSelected)]);

    exerciseActions.deleteLanguage(languageSelected);

    if (languagesConfigured.length) {
      setLanguage(languagesConfigured[0], false);
    } else {
      setLanguage(null, false);
    }
  };

  const setLanguage = (language, sendDescription = true) => {
    if (languageSelected && sendDescription) {
      exerciseActions.setValueLanguage(languageSelected, 'content', description);
    }

    setLanguageSelect(language);

    const currentTitle = language ? getContentTranslate(exercise?.name, language) : '';
    const currentSubtitle = language ? getContentTranslate(exercise?.subtitle, language) : '';
    const currentDescription = language ? getContentTranslate(exercise?.content, language) : '';

    setValue('name', currentTitle, { shouldDirty: true, shouldValidate: true });
    setSubtitle(currentSubtitle);
    setDescription(currentDescription);
  };

  const onChangeContent = (value) => {
    setDescription(value);
    setIsDescriptionChanged(true);
  };

  useEffect(() => {
    languageSelectedRef.current = languageSelected;
  }, [languageSelected]);

  useEffect(() => {
    isDescriptionChangedRef.current = isDescriptionChanged;
  }, [isDescriptionChanged]);

  useEffect(() => {
    descriptionRef.current = description;
  }, [description]);

  const onSaveTitle = (title) => {
    exerciseActions.setValueLanguage(languageSelected, 'name', title);
  };

  const onSaveSubtitle = (event) => {
    exerciseActions.setValueLanguage(languageSelected, 'subtitle', event.target.value);
  };

  const onChangeSubtitle = (event) => {
    setSubtitle(event.target.value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isDescriptionChangedRef.current) {
        exerciseActions.setValueLanguage(languageSelectedRef.current, 'content', descriptionRef.current);
        setIsDescriptionChanged(false);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Box sx={styles.titleContainer}>
        <TitleInput
          disabled={false}
          control={exerciseState.form.control}
          actions={() => {}}
          rules={{ required: 'This field is required' }}
          session={null}
          name="templateName"
          sx={styles.titleContent}
          inputProps={{ style: { ...styles.titleContent, width: '100%' } }}
          exportTitle={() => {
            if (!exercise) {
              exerciseActions.createForm();
            } else {
              exerciseActions.submitForm();
            }
          }}
        />

        <Switch
          ref={refSwitch}
          control={exerciseState.form.control}
          variant="outlined"
          label={exerciseActions.watch('active') ? t('deactivate') : t('reactivate')}
          name="active"
          labelPlacement="end"
          fullWidth
          onChange={() => {
            if (exercise) {
              exerciseActions.submitForm();
            }
          }}
        />
      </Box>

      {exerciseId && (
        <Box sx={{ marginBottom: '32px' }}>
          <TabLangues
            languageSelected={languageSelected}
            languagesEnabled={languagesConfigured}
            onAddLangueClick={onAddLangueClick}
            onDeleteLangue={onDeleteLangue}
            onLangueClick={onLangueClick}
          />
        </Box>
      )}

      {languageSelected && (
        <Box sx={{ position: 'relative' }}>
          {exercise?.updatedAt && (
            <Box sx={styles.updatedAt}>{t('last_save', { date: formatDateWithSecondes(exercise.updatedAt) })}</Box>
          )}
          <Box sx={{ marginBottom: '24px' }}>
            <TitleInput
              name="name"
              disabledTooltip
              control={control}
              sxInputProps={styles.titleInput}
              exportTitle={onSaveTitle}
            />
          </Box>

          <Box sx={{ marginBottom: '24px' }}>
            <TextField
              sx={styles.subTitleInput}
              label={t('edit_theme.subtitle_label')}
              rows={1}
              maxRows={1}
              value={subtitle}
              multiline
              control={control}
              name="subtitle"
              variant="outlined"
              onChange={onChangeSubtitle}
              onBlur={onSaveSubtitle}
            />
          </Box>

          <Box sx={{ marginBottom: '32px' }}>
            <TextFieldPathline
              inputRef={refDuration}
              sx={styles.subTitleInput}
              label={t('duration')}
              rows={1}
              maxRows={1}
              control={exerciseState.form.control}
              name="duration"
              variant="outlined"
              type="number"
              onBlur={() => {
                exerciseActions.submitForm();
              }}
            />
          </Box>

          <Box sx={styles.editorContainer}>
            {languageSelected && (
              <YooptaEditor
                key={`${languageSelected}_${exerciseId}`}
                disablePlugins={user.role === roleList.ADMIN ? [] : ['accordion']}
                folderUpload={`activities/exercises/${exerciseId}`}
                style={{ width: '100%' }}
                value={description}
                onChange={onChangeContent}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default EditorExercise;
