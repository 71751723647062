import React from 'react';
import { InputLabel, Select as MuiSelect, MenuItem, FormControl } from '@mui/material';
import { useController } from 'react-hook-form';

export default function Select({
  label,
  name,
  control,
  options,
  defaultValue,
  onUpdate,
  style = {},
  required,
  variant = 'outlined',
  disabled,
  shrink = undefined,
  notched = undefined,
}) {
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue });

  const handleChange = (e) => {
    onChange(e);
    if (onUpdate) {
      onUpdate(e);
    }
  };

  return (
    <FormControl fullWidth variant={variant}>
      <InputLabel id={label} shrink={shrink}>
        {label}
      </InputLabel>
      <MuiSelect
        onChange={handleChange}
        labelId={label}
        value={value}
        label={label}
        sx={{ ...style }}
        required={required}
        disabled={disabled}
        notched={notched}
        defaultValue={defaultValue}>
        {options &&
          options.map((option) => (
            <MenuItem value={option.value} key={`select-${option.value}`}>
              {option.label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  );
}
