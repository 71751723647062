import React, { useEffect, useState } from 'react';
import LoginForm from './LoginForm';
import useLogin from 'hooks/usecase/useLogin';
import ResetPasswordForm from './ResetPasswordForm';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import AuthLayout from './AuthLayout';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
  const { t } = useTranslation();
  const { state, actions } = useLogin();
  const { hash } = useLocation();
  const [errorMessage, setErrorMessage] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (hash === '#disconnection') {
      setErrorMessage(t('token_expired_message'));
      navigate('#');
    }

    if (hash === '#reset') {
      actions.hideLoggin();
      navigate('#');
    }
  }, [hash]);

  return (
    <AuthLayout>
      <Switch value={state.nav.displayLogin}>
        <Case value={true}>
          <LoginForm
            control={state.form.control}
            errorMessage={errorMessage}
            isEmailCompany={state.form.isEmailCompany}
            onSubmit={actions.submit}
            onSubmitMicrosoft={() => actions.onSubmitMicrosoft()}
            onSubmitCompany={() => actions.onSubmitCompany()}
            onClickReset={actions.hideLoggin}
          />
        </Case>
        <Case value={false}>
          <ResetPasswordForm onClickLogin={actions.showLoggin} />
        </Case>
      </Switch>
    </AuthLayout>
  );
};

export default LoginPage;
