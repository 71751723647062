import { del, get, post, put } from './request';

class FormSettings {
  static getFormSettings(id, queryParams) {
    return get(`/form-settings/${id}`, queryParams).then((res) => res.data);
  }

  static getFormSettingsPublic(_Link, queryParams) {
    return get(`/guests/${_Link}/form-settings/`, queryParams).then((res) => res.data);
  }

  static updateFilds({ id, data }) {
    return put(`/form-settings/form-field/${id}`, data).then((res) => res.data);
  }
}

export default FormSettings;
