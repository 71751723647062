import React, { useState } from 'react';

import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';

import ResourceCard from './ResourceCard';

import styles from './ListResources.styles';

import DeleteModal from 'ui/pages/program/DeleteModal';

import { useDeleteProgramDocument } from 'hooks/usecase/useCreateProgramDocument';
import { t } from 'i18next';
import { getLabelByValue } from 'utils/languages';

const ListResources = ({ items, onUpdate }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedResource, setSelectedResource] = useState(null);

  const { actions } = useDeleteProgramDocument();

  const resourceName = (resource) => {
    if (!resource) return '';

    return resource.filename ? resource.filename : resource.name;
  };

  return (
    <Box sx={styles.container}>
      <Box style={styles.horizontalCardsContainer}>
        {items
          ? items.map((resource) => (
              <Box key={resource.id} sx={styles.resourceCard}>
                <ResourceCard
                  withoutActions={resource.origin === 'company'}
                  id={resource.id}
                  name={resourceName(resource)}
                  origin={resource.origin}
                  type={resource.type}
                  language={t(getLabelByValue(resource.language))}
                  picture={resource.picture}
                  filepath={resource.filepath ? resource.filepath : resource.path}
                  onDelete={() => {
                    setSelectedResource(resource);
                    setShowDeleteModal(true);
                  }}
                  onUpdate={onUpdate}
                  resource={resource}
                />
              </Box>
            ))
          : null}
      </Box>

      <DeleteModal
        title={t('delete_resource_modal_title')}
        description={t('delete_resource_modal_desc', { resourceName: resourceName(selectedResource) })}
        question={t('delete_resource_modal_question')}
        acceptText={t('delete_resource_modal_accept_button')}
        isDelLoading={actions.isDelLoading}
        open={showDeleteModal}
        onDelete={() => {
          actions.remove(selectedResource.id);
        }}
        handelModal={() => {
          setShowDeleteModal(false);
        }}
      />
    </Box>
  );
};

export default ListResources;
