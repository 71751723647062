const randomFileId = () => {
  const randomNumber = Math.floor(100000 + Math.random() * 900000);
  return `${Date.now()}-${randomNumber}`;
};

const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export { randomFileId, fileToBase64 };
