import { get, post, put, del } from './request';

class Objectives {
  static list() {
    return get('/objectives').then((res) => res.data);
  }

  static create(payload) {
    return post('/objectives', payload).then((res) => res.data);
  }

  static edit({ id, payload }) {
    return put(`/objectives/${id}`, payload).then((res) => res.data);
  }

  static delete(id) {
    return del('/objectives/' + id).then((res) => res.data);
  }
}

export default Objectives;
