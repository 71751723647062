import React from 'react';
import Box from 'ui/components/system/Box';
import Typography from '@mui/material/Typography';
import TextField from 'ui/components/form/TextField';
import PasswordField from 'ui/components/form/PasswordField';
import Button from 'ui/components/Button/Button';

import { useTranslation } from 'react-i18next';
import {
  CHANGE_YOUR_PASSWORD,
  CONFIRM_YOUR_PASSWORD,
  CURRENT_PASSWORD,
  NEW_PASSWORD,
  CANNOT_CHANGE_PASSWORD_SSO,
  PASSWORD_REQUIRED,
  PASSWORD_NOT_MATCH_ERROR,
} from 'litterals';

const ChangePasswordForm = ({ control, isSsoConnection, onSubmit, newPasswordValue }) => {
  const styleInput = { margin: '12px 0' };
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        alignItems: 'center',
        margin: '24px',
        maxWidth: '100%',
        justifyContent: 'center',
      }}>
      <Typography variant="h5" component="h3" sx={{ mb: '24px' }}>
        {t(CHANGE_YOUR_PASSWORD)}
      </Typography>
      {!isSsoConnection && (
        <Box component="form" onSubmit={onSubmit} variant="flexColumn">
          <TextField
            type="password"
            name="oldPassword"
            label={t(CURRENT_PASSWORD)}
            placeholder={t(CURRENT_PASSWORD)}
            required
            control={control}
            style={styleInput}
          />
          <PasswordField
            enableFormattingErrors
            required
            name="newPassword"
            label={t(NEW_PASSWORD)}
            placeholder={t(NEW_PASSWORD)}
            control={control}
            style={styleInput}
          />
          <PasswordField
            required
            name="newPasswordConfirmation"
            label={t(CONFIRM_YOUR_PASSWORD)}
            placeholder={t(CONFIRM_YOUR_PASSWORD)}
            control={control}
            style={styleInput}
            rules={{
              required: t(PASSWORD_REQUIRED),
              validate: {
                checkNotMatch: (value) => value === newPasswordValue || t(PASSWORD_NOT_MATCH_ERROR),
              },
            }}
          />
          <Button type="submit" sx={{ mt: '12px', width: '100%' }}>
            {t(CHANGE_YOUR_PASSWORD)}
          </Button>
        </Box>
      )}
      {isSsoConnection && (
        <Box variant="flexColumn">
          <Typography
            gutterBottom
            component="p"
            fontSize={'16px'}
            marginLeft={'12px'}
            lineHeight={'24px'}
            fontWeight={400}
            letterSpacing={'0.15px'}>
            {t(CANNOT_CHANGE_PASSWORD_SSO)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ChangePasswordForm;
