import React, { createContext, useState } from 'react';

const AlertContext = createContext(null);

export const AlertProvider = ({ children }) => {
  const [stateAlert, setStateAlert] = useState({ open: false, message: '', type: 'success' });

  return <AlertContext.Provider value={{ stateAlert, setStateAlert }}>{children}</AlertContext.Provider>;
};

export const useAlert = () => React.useContext(AlertContext);
