import React, { useState, useImperativeHandle } from 'react';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useAdmins } from 'hooks/usecase/useAdmins';
import DeleteModal from './components/DeleteModal';
import DataGrid from 'ui/components/DataGrid';
import dayjs from 'dayjs';
import AddModal from './components/AddModal';
import EditModal from './components/EditModal';

// Configs
import { tableSchemaBase as columns } from './Admins.config';

// eslint-disable-next-line react/display-name
const Admins = React.forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const [queryParams, setQueryParams] = useState({ page: 1, size: 25 });
  const [coachToDelete, setCoachToDelete] = useState(null);

  // Hooks
  const {
    admins,
    isLoading,
    isOpenAddModal,
    setIsOpenAddModal,
    totalPages,
    totalItems,
    onRemove,
    control,
    isOpenEditModal,
    setIsOpenEditModal,
    onAdd,
    onEdit,
    onIsMainCompanyAdmin,
    setEditingItem,
    reset,
    watch,
  } = useAdmins(props.companyId, queryParams);

  // Filter & Sort
  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, scopes: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      companyId: queryParams.companyId,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'scopes' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        handleAddAdmin() {
          setIsOpenAddModal(true);
        },
      };
    },
    []
  );

  return (
    <Stack sx={{ height: '630px', backgroundColor: 'white', padding: '10px 38px' }}>
      {/** DataGrid */}
      <DataGrid
        columns={columns}
        rows={admins}
        role={props.role}
        onDelete={(item) => {
          setIsOpen(true);
          setCoachToDelete(item);
        }}
        totalItems={totalItems}
        onShow={(coach) => {}}
        onRowClicked={(coach, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingItem(coach.row);
          }
        }}
        onChangeIsMainCompanyAdmin={({ user, activated }) => {
          onIsMainCompanyAdmin(user, activated);
        }}
        onEdit={(user) => {
          setEditingItem(user);
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        totalPages={totalPages ? totalPages : 0}
        loading={isLoading}
      />
      {/** Modal to add */}
      <AddModal
        title={'Create a new Admin '}
        control={control}
        isOpen={isOpenAddModal}
        onClose={() => {
          reset();
          setIsOpenAddModal(false);
        }}
        onAdd={onAdd}
        watch={watch}
      />
      {/** Modal to edit */}
      <EditModal
        title={'Edit admin  '}
        control={control}
        isOpen={isOpenEditModal}
        onClose={() => {
          reset();
          setIsOpenEditModal(false);
        }}
        onEdit={onEdit}
        watch={watch}
      />

      {/** Modal to delete */}
      <DeleteModal
        itemToDelete={coachToDelete}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onValidate={() => {
          onRemove({ id: coachToDelete.id });
          setIsOpen(false);
        }}
      />
    </Stack>
  );
});
export default Admins;
