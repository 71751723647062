const styles = {
  container: {
    width: '100%',
  },
  iconReturn: {
    height: '12px',
    width: '12px',
    paddingBottom: '1px',
  },
  paragraph: {
    pl: '12px',
    marginBottom: '50px',
  },
  description: {
    fontSize: '15px',
    fontFamily: 'Karla',
    fontWeight: 400,
    marginBottom: '20px',
  },
};
export default styles;
