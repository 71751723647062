const initialState = {
  title: 'Company title',
  date: new Date().toISOString(),
  companies: [],
  selectedCompany: {},
};

export const createCompanySlice = (set) => ({
  ...initialState,
  addCompanyItem: () =>
    set((state) => ({
      companies: [
        ...state.companies,
        {
          _id: '',
          title: '',
        },
      ],
    })),
});
