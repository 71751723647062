import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useExerciseQuery } from 'hooks/queries';
import { useAlert } from 'common/alertContext';
import { addLanguagePropertiesInForm, deleteLanguagePropertiesInForm, setValueLanguageInForm } from 'utils/translate';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const defaultValues = {
  name: '',
  activityId: '',
  content: '',
  duration: 0,
  subtitle: '',
  templateName: '',
  active: false,
};

const useEditExercises = (exerciseId) => {
  const { setStateAlert } = useAlert();
  const { data, isLoading } = useExerciseQuery(exerciseId);
  const [showAlert, setShowAlert] = useState(true);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    watch,
    reset,
    resetField,
    setValue,
    formState: { isDirty },
  } = useForm({ mode: 'all', defaultValues });

  const translatedProperties = ['name', 'subtitle', 'content'];

  useEffect(() => {
    reset({
      name: data?.name || undefined,
      content: data?.content || undefined,
      duration: data?.duration == null ? undefined : data?.duration,
      subtitle: data?.subtitle || undefined,
      templateName: data?.templateName || undefined,
      active: data?.active || undefined,
      activityId: data?.activityId || undefined,
    });
  }, [data, reset]);

  const mutation = useMutation(api.Exercises.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-exercise', exerciseId]);
      queryClient.invalidateQueries(['get-exercises']);
      if (showAlert) {
        setStateAlert({ open: true, message: t('message_success_changes') });
      }
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const mutationCreate = useMutation(api.Exercises.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-exercises']);
      if (showAlert) {
        setStateAlert({ open: true, message: t('message_success_exercise_creation') });
      }

      navigate(`/activities/${data.activityId}/exercises/${data.id}/editor`);
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const submitForm = handleSubmit(async (data) => {
    setShowAlert(true);

    setTimeout(() => {
      mutation.mutate({ id: exerciseId, data });
    }, 100);
  });

  const submitFormWithoutAlert = handleSubmit(async (data) => {
    setShowAlert(false);
    setTimeout(() => {
      mutation.mutate({ id: exerciseId, data });
    }, 100);
  });

  const deleteLanguage = (language) => {
    deleteLanguagePropertiesInForm(language, translatedProperties, watch, setValue);
    submitForm();
  };

  const addLanguage = (language) => {
    if (!language) return;
    addLanguagePropertiesInForm(language, translatedProperties, watch, setValue);
    submitForm();
  };

  const setValueLanguage = (language, property, content) => {
    setValueLanguageInForm(language, property, content, watch, setValue);
    submitFormWithoutAlert();
  };

  const createForm = handleSubmit(async (data) => {
    mutationCreate.mutate({ data });
  });

  return {
    state: {
      form: { control, resetField, isDirty },
      query: { data, isLoading },
    },
    actions: { setValue, watch, submitForm, createForm, deleteLanguage, addLanguage, setValueLanguage },
  };
};

export default useEditExercises;
