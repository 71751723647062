/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: 'green', // Set a solid color for testing
}));

function CellLinearProgress({ progress }) {
  const [numerator, denominator] = progress.split('/');
  let percentage = 0;
  if (numerator > 0) {
    percentage = (parseFloat(numerator) / parseFloat(denominator)) * 100;
  }
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */

  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents" sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', padding: '5px' }}>
        <LinearProgress variant="determinate" value={percentage} />
      </Box>
      {progress}
    </Stack>
  );
}

export default CellLinearProgress;
