import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { useMutation } from '@tanstack/react-query';
import Skeleton from '@mui/material/Skeleton';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import api from 'api';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

const ScopeFilter = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [scopesList, setScopesList] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const { item, applyValue } = props;

  const handleFilterChange = (newValue) => {
    if (newValue) {
      applyValue({ ...item, value: newValue, scopeId: newValue });
    } else {
      applyValue({ ...item, value: null, scopeId: null });
    }
  };

  const mutateGetScopesList = useMutation(api.Scopes.list, {
    onSuccess: (data) => {
      setScopesList(data);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (!isFetched) {
      mutateGetScopesList.mutate();
      setIsFetched(true);
    }
  }, [isFetched]);

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        pt: '2px',
        width: '240px',
      }}>
      {isLoading ? (
        <Skeleton sx={{ width: '100%' }} />
      ) : (
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="combo-box-demo"
          options={scopesList}
          getOptionLabel={(option) => option.name || ''}
          onChange={(event, newValue) => {
            const idsList = newValue.map((item) => {
              return item.id;
            });
            handleFilterChange(idsList);
          }}
          value={item && item.value && scopesList.filter((scope) => item.value.includes(scope.id))}
          sx={{ width: 350 }}
          renderInput={(params) => <TextField variant="standard" {...params} label="Scope" />}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            const limitTags = 1;
            return (
              <>
                {value.slice(0, limitTags).map((option, index) => (
                  <Chip
                    sx={{ height: '20px' }}
                    size="small"
                    {...getTagProps({ index })}
                    key={index}
                    label={option.name}
                  />
                ))}
                {numTags > limitTags && <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>...</Typography>}
              </>
            );
          }}
        />
      )}
    </Box>
  );
};

const ScopeFilterOperators = [
  {
    label: 'Above',
    value: 'above',
    getApplyFilterFn: (filterItem) => {},
    InputComponent: ScopeFilter,
  },
];

export default ScopeFilterOperators;
