/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';
import { Box, Tooltip, Typography } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import UpdateIcon from '@mui/icons-material/Update';
import { t } from 'i18next';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellEvergreenClosingDate({ config }) {
  const isEstimate = config.estimate;
  const color = isEstimate ? 'primary.grey' : 'black';
  const tooltip = isEstimate ? t('evergreen_closing_date_estimate') : '';

  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <Tooltip title={tooltip}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {isEstimate && <UpdateIcon sx={{ color, marginRight: '4px' }} />}
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 500,
              fontStyle: 'italic',
              color,
            }}>
            {config.date}
          </Typography>
        </Box>
      </Tooltip>
    </Stack>
  );
}

export default CellEvergreenClosingDate;
