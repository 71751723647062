import React from 'react';
import { Box, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import { truncateString } from 'common/utils';
import Avatar from '@mui/material/Avatar';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import styles from './CardCoach.styles';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Tooltip } from '@mui/material';
import Button from 'ui/components/Button/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UserChip from 'ui/components/shared/UserChip/UserChip';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const CardCoach = ({
  id,
  title,
  job,
  description,
  isSelected,
  onSelect,
  picture,
  timeZone,
  onShowExpert,
  availabilitiesCount,
  maxCapacity,
  programsInProgress,
  canExceedMaxCapacity,
  expertLabel,
  sessionLabel,
  languagesSpoken = [],
  skills = [],
  showSelectButton = true,
  showViewProfile = true,
}) => {
  const { t } = useTranslation();

  const decodeHtmlEntities = (str) => {
    const txt = document.createElement('textarea');
    txt.innerHTML = str;
    return txt.value;
  };

  const handleRemoveHtmlTag = (html) => {
    // Remove HTML tags
    const withoutTags = html.replace(/(<([^>]+)>)/gi, ' ');

    // Decode HTML entities
    return decodeHtmlEntities(withoutTags);
  };

  const tooltipLanguages =
    !!languagesSpoken && languagesSpoken?.length > 3 ? (
      <span style={{ whiteSpace: 'pre-line' }}>
        {languagesSpoken
          .slice(2)
          .map((x) => t(`lang_${x.toLowerCase()}`))
          .join('\n')}
      </span>
    ) : (
      ''
    );

  const tooltipExeedMaxCapacity =
    canExceedMaxCapacity === false ? t('coach_not_selectable', { expertLabel, sessionLabel }) : '';

  return (
    <Box
      onClick={() => {
        onSelect && canExceedMaxCapacity !== false && onSelect(id);
      }}
      sx={[
        styles.container,
        canExceedMaxCapacity === false && { cursor: 'default', opacity: 0.7 },
        isSelected && { backgroundColor: 'card.selected', border: '1px solid', borderColor: 'primary.main' },
      ]}>
      <Tooltip title={tooltipExeedMaxCapacity}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            justifyContent: 'space-between',
            height: '278px',
            position: 'relative',
          }}>
          {timeZone && (
            <Box sx={{ position: 'absolute', top: '15px', right: '15px' }}>
              <Tooltip title={timeZone}>
                <TravelExploreIcon sx={{ color: 'primary.main' }} />
              </Tooltip>
            </Box>
          )}
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar alt={title} src={picture ? picture : null} sx={{ height: '80px', width: '80px' }} />
              <Box sx={{ display: 'flex', flexDirection: 'column', paddingLeft: '16px' }}>
                <Tooltip title={title}>
                  <Typography
                    sx={{
                      fontSize: '18px',
                      lineHeight: '26px',
                      fontWeight: 500,
                      color: '#000000DE',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '140px',
                    }}>
                    {title}
                  </Typography>
                </Tooltip>
                <Tooltip title={job}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '18.2px',
                      fontWeight: 500,
                      color: 'primary.main',
                      height: '18.2px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '144px',
                    }}>
                    {job}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={{ height: '8px' }} />
            <Box sx={{ display: 'flex', gap: '4px' }}>
              {availabilitiesCount != null && (
                <Chip
                  sx={{ marginBottom: '8px' }}
                  size="small"
                  label={t('availabilities_count', { count: availabilitiesCount })}
                  color={availabilitiesCount ? 'info' : 'default'}
                  variant="outlined"
                />
              )}
              {maxCapacity != null && (
                <UserChip
                  tooltip
                  noWrap
                  color="black"
                  icon={
                    <PeopleAltOutlinedIcon
                      sx={{
                        fontSize: '0.9rem',
                        marginRight: '4px',
                      }}
                    />
                  }
                  label={t('mentoring_available', { count: maxCapacity - programsInProgress, sessionLabel })}
                />
              )}
            </Box>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: 400,
                color: '#00000099',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '-webkit-line-clamp': '2',
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
              }}>
              {handleRemoveHtmlTag(description)}
            </Typography>
            <Box sx={{ height: '8px' }} />
            <Grid container sx={{ flexGrow: 1 }} spacing={1}>
              {skills.slice(0, 2).map((chip) => (
                <Grid key={'id-' + Math.random()} item>
                  <Tooltip title={chip && chip.length > 17 ? chip : ''} placement="bottom">
                    <Chip size="small" label={truncateString(`${t(chip)}`, 17)} color="default" />
                  </Tooltip>
                </Grid>
              ))}
              {!!languagesSpoken &&
                languagesSpoken.slice(0, languagesSpoken.length === 3 ? 3 : 2).map((language) => (
                  <Grid key={'id-' + language} item>
                    <Tooltip title={t(`lang_${language.toLowerCase()}`)} placement="bottom">
                      <Chip size="small" label={language} color="default" />
                    </Tooltip>
                  </Grid>
                ))}
              {!!languagesSpoken && languagesSpoken?.length > 3 && (
                <Grid key={'id-' + Math.random()} item>
                  <Tooltip title={tooltipLanguages} placement="bottom">
                    <Chip
                      size="small"
                      label={languagesSpoken[2]}
                      color="default"
                      deleteIcon={<AddCircleOutlineIcon style={{ fill: 'grey' }} />}
                      onDelete={() => {}}
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
            {onSelect && showSelectButton && (
              <Button
                disabled={!canExceedMaxCapacity}
                sx={{ backgroundColor: isSelected ? 'white' : 'primary.main', width: '75px' }}
                variant={isSelected ? 'secondary' : 'primary'}
                onClick={(event) => {
                  event.stopPropagation();
                  onSelect && canExceedMaxCapacity && onSelect(id);
                }}>
                {isSelected ? 'selected' : 'Select'}
              </Button>
            )}
            {(!onSelect || !showSelectButton) && <div></div>}
            {showViewProfile && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  onShowExpert(id);
                }}
                variant="secondary">
                {t('view_profile')}
              </Button>
            )}
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default CardCoach;
