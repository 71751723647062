import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CANCEL, VALIDATE } from 'litterals';
import CloseIcon from '@mui/icons-material/Close';

const DeleteModal = ({ open, handelModal, onDelete, title, description }) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    return onDelete();
  };

  return (
    <Dialog open={open} onClose={handelModal}>
      <IconButton
        aria-label="close"
        onClick={handelModal}
        sx={{
          alignSelf: 'end',
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ p: 0, pb: '16px', pl: '24px' }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handelModal}>{t(CANCEL)}</Button>
        <Button
          onClick={() => {
            handleDelete();
            handelModal();
          }}
          variant="contained"
          sx={{ mr: '5px' }}>
          {t(VALIDATE)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
