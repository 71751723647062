import React, { useState } from 'react';

import { DataGridPro, GridPagination } from '@mui/x-data-grid-pro';
import MuiPagination from '@mui/material/Pagination';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/system/Stack';
import { Typography, Box } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

// Custom cells
import CellActionsExperts from './Cells/CellActionsExperts';
import CellActionsCoachee from './Cells/CellActionsCoachee';
import CellActionsButtons from './Cells/CellActionsButtons';
import CellActionsCompanies from './Cells/CellActionsCompanies';
import CellActionsHome from './Cells/CellActionsHome';
import CellActionsBackoffice from './Cells/CellActionsBackoffice';

import CellText from './Cells/CellText';
import CellSmallText from './Cells/CellSmallText';
import CellUser from './Cells/CellUser';
import CellTopic from './Cells/CellTopic';
import CellStatus from './Cells/CellStatus';
import CellCoach from './Cells/CellCoach';
import CellLanguages from './Cells/CellLanguages';
import CellAvatar from './Cells/CellAvatar';
import CellLinearProgress from '../DataGrid/Cells/CellLinearProgress';
function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const [row] = rows.splice(initialIndex, 1);
      rows.splice(newIndex, 0, row);
      rows.forEach((row, index) => {
        row.session_order = index + 1;
      });
      resolve(rows.slice());
    }, Math.random() * 500 + 100);
  });
}

const imageNoData = `${process.env.PUBLIC_URL}/uploads/images/file.png`;

// Sub components
// eslint-disable-next-line react/prop-types
function Pagination({ className, pageCount, currentPage, onChangePage }) {
  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={currentPage}
      onChange={(event, newPage) => {
        onChangePage(newPage);
      }}
    />
  );
}

function CustomPagination(props) {
  return (
    <GridPagination
      ActionsComponent={(paginationProps) => (
        <Pagination
          pageCount={props.pageCount}
          currentPage={props.currentPage}
          onChangePage={props.onChangePage}
          {...paginationProps}
        />
      )}
      {...props}
    />
  );
}

// Entities & constants
const ColumnType = {
  Text: 'text',
  SmallText: 'smallText',
  ActionsExperts: 'actionsExperts',
  ActionsCoachee: 'actionsCoachee',
  ActionsCompanies: 'actionsCompanies',
  ActionsBackoffice: 'actionsBackoffice',
  ActionsButtons: 'actionsButtons',
  ActionsHome: 'actionsHome',
  User: 'user',
  Topic: 'topic',
  Status: 'status',
  Coach: 'coach',
  Avatar: 'avatar',
  Languages: 'languages',
  LinearProgress: 'linearProgress',
};

function DataGridReorder({
  rows,
  columns,
  onDelete,
  onEdit,
  onDuplicate,
  onShow,
  onFilter,
  onSort,
  onAdd,
  onPaginationChange,
  formPath,
  onRowClicked,
  role,
  totalPages,
  loading,
  totalItems,
  asCoachee,
  onReorder,
  ...restProps
}) {
  const { t } = useTranslation();

  // Pagination
  const SIZES = [10, 25, 50, 100];

  //HEADER_STYLE_COLUMN
  const HEADER_STYLE_COLUMN = {
    overflow: 'visible !important',
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 1)',
    fontFamily: 'Karla',
    fontWeight: 700,
  };

  // State
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSize, setCurrentSize] = useState(10);

  // Get list with filter params

  const onFilterChange = (filter) => {
    onFilter(filter, currentPage, currentSize);
  };

  // Get list with pagination params
  const onPaginationChange1 = (page) => {
    onPaginationChange(page, currentSize);
    setCurrentPage(page);
  };

  // Get list with sort params
  const onSortChange = (model) => {
    if (model.length !== 0) {
      onSort(
        {
          orderBy: model[0].field,
          orderDirection: model[0].sort.toUpperCase(),
        },
        currentPage,
        currentSize
      );
    } else {
      onSort({ orderBy: '', orderDirection: '' }, currentPage, currentSize);
    }
  };

  // Translate i18n of header
  const translatedColumns = columns.map((column) => ({
    ...column,
    headerName: column.translateHeaderName === false ? column.headerName : t(column.headerName),
  }));

  // Custom column
  function getAddColumnCta() {
    return (
      <Stack>
        <Typography style={{ fontSize: '18px' }} fontWeight="fontWeightBold" variant="h6">
          {t('actions')}
        </Typography>
      </Stack>
    );
  }

  /**
   * Provides a rendering function to provide to custom table cells
   * @param {Function} Component Functional component of kind `CellComp`
   * @param {String} valueProp Name of the value prop to be passed to the
   * component (depending on component signature)
   * @returns
   */
  function getCellRenderer(Component, valueProp) {
    return function render({ row, colDef }) {
      let value;
      if (row[colDef.field] && colDef.type) {
        switch (colDef.type) {
          case 'text':
            value = row[colDef.field];
            break;
          case 'bigText':
            value = row[colDef.field];
            break;
          case 'chip':
            value = row[colDef.field];
            break;
          case 'user':
            value = row[colDef.field];
            break;
          case 'date':
            value = row[colDef.field];
            break;
          default:
            // Row value is a serialized object
            // @TODO: Better handled with try catch to check if it's
            // an actual object, otherwise fallback to text
            value = row[colDef.field];
            break;
        }
      }
      // Has no content or not a specific or supported type
      else {
        value = row[colDef.field];
      }
      const cellProps = { [valueProp]: value };
      return <Component {...cellProps} />;
    };
  }

  const adaptedColumns = translatedColumns.map((c) => {
    // Get information of each column
    let adaptedCol = { ...c };
    switch (c.type) {
      case undefined:
        if (!c.type || c.type === 'text') {
          adaptedCol.selector = function render(row) {
            return row[c.field];
          };
        }
        break;
      case null:
        adaptedCol.selector = undefined;
        break;
      case ColumnType.ActionsExperts: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsExperts
            enableDelete={
              !row.hasSubscriptions &&
              role === 'RH_MANAGER' &&
              (!row.availableRoles || (row.availableRoles.length === 1 && row.availableRoles[0] == 'COACH'))
            }
            onAdd={onAdd}
            onShow={onShow}
            onDelete={onDelete}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionsCompanies: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsCompanies onShow={onShow} onDelete={onDelete} onEdit={onEdit} pathname={formPath} rowData={row} />
        );
        break;
      }
      case ColumnType.ActionsBackoffice: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsBackoffice
            onShow={onShow}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.ActionsCoachee: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsCoachee
            enableDelete={row.source !== 'User'}
            onDelete={onDelete}
            pathname={formPath}
            rowData={row}
          />
        );
        break;
      }
      case ColumnType.Avatar: {
        adaptedCol.renderCell = getCellRenderer(CellAvatar, 'logo');
        break;
      }
      case ColumnType.ActionsHome: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsHome
            role={role}
            enableDelete={row.source !== 'User'}
            onDelete={onDelete}
            pathname={formPath}
            rowData={row}
            asCoachee={asCoachee}
          />
        );
        break;
      }
      case ColumnType.ActionsButtons: {
        adaptedCol.name = getAddColumnCta();
        adaptedCol.renderCell = ({ row }) => (
          <CellActionsButtons onAdd={onAdd} onShow={onShow} onDelete={onDelete} pathname={formPath} rowData={row} />
        );
        break;
      }
      case ColumnType.User: {
        adaptedCol.renderCell = getCellRenderer(CellUser, 'user');
        break;
      }
      case ColumnType.Topic: {
        adaptedCol.renderCell = getCellRenderer(CellTopic, 'theme');
        break;
      }
      case ColumnType.Languages: {
        adaptedCol.renderCell = getCellRenderer(CellLanguages, 'languages');
        break;
      }
      case ColumnType.Status: {
        adaptedCol.renderCell = getCellRenderer(CellStatus, 'status');
        break;
      }
      case ColumnType.LinearProgress: {
        adaptedCol.renderCell = getCellRenderer(CellLinearProgress, 'progress');
        break;
      }
      case ColumnType.Coach: {
        adaptedCol.renderCell = getCellRenderer(CellCoach, 'coach');
        break;
      }
      case ColumnType.Text: {
        adaptedCol.renderCell = getCellRenderer(CellText, 'text');
        break;
      }
      case ColumnType.SmallText: {
        adaptedCol.renderCell = getCellRenderer(CellSmallText, 'text');
        break;
      }
      default:
        adaptedCol.cell = getCellRenderer(CellText, 'text');
        break;
    }

    return adaptedCol;
  });

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const handleRowOrderChange = async (params) => {
    const cloneRows = [...rows];
    updateRowPosition(params.oldIndex, params.targetIndex, cloneRows)
      .then((updatedRows) => {
        onReorder(updatedRows);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <DataGridPro
      columns={adaptedColumns}
      rows={rows}
      rowReordering
      onRowOrderChange={handleRowOrderChange}
      pagination
      rowCount={totalItems ? totalItems : null}
      slots={{
        pagination: (paginationProps) => (
          <CustomPagination
            {...paginationProps}
            pageCount={totalPages}
            currentPage={currentPage}
            onChangePage={(page) => {
              setPaginationModel({ pageSize: currentSize, page: page - 1 });
              setCurrentPage(page);
              onPaginationChange1(page);
            }}
          />
        ),
        loadingOverlay: LinearProgress,
      }}
      loading={loading}
      filterMode="server"
      sortingMode="server"
      paginationMode="server"
      slotProps={{
        columnsPanel: {
          disableHideAllButton: true,
          disableShowAllButton: true,
        },
        filterPanel: {
          sx: { width: '470px' },
          filterFormProps: {
            operatorInputProps: {
              disabled: true,
              sx: { display: 'none' },
            },
            columnInputProps: {
              sx: { paddingRight: '20px' },
            },
          },
        },
      }}
      disableColumnPinning
      hideFooterSelectedRowCount
      onRowClick={onRowClicked}
      onFilterModelChange={onFilterChange}
      onPaginationModelChange={({ pageSize }) => {
        if (pageSize !== paginationModel.pageSize) {
          onPaginationChange(1, pageSize);
          setPaginationModel({ pageSize: pageSize, page: 0 });
          setCurrentSize(pageSize);
          setCurrentPage(1);
        } else {
          onPaginationChange(currentPage, pageSize);
          setCurrentSize(pageSize);
        }
      }}
      paginationModel={paginationModel}
      onSortModelChange={onSortChange}
      initialState={{
        pagination: { paginationModel: paginationModel },
        noRowsOverlay: (
          <Stack sx={{ height: '45px', padding: 25 }} height={30} alignItems="center" justifyContent="center">
            Local filter returns no result
          </Stack>
        ),
      }}
      pageSizeOptions={SIZES}
      sx={{
        overflowX: 'none',
        boxShadow: 0,
        border: 0,
        borderColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        '.MuiDataGrid-columnHeaderTitle': HEADER_STYLE_COLUMN,
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-sortIcon': {
          opacity: 1,
          color: 'secondary.main',
        },
        '& .MuiDataGrid-menuIconButton': {
          opacity: 1,
        },
      }}
      localeText={{
        noRowsLabel: (
          <Stack alignItems={'center'}>
            {imageNoData ? <Box onClick={() => {}} component="img" src={imageNoData} /> : null}
            <Typography>{'No data'}</Typography>
          </Stack>
        ),
      }}
      {...restProps}
    />
  );
}

export default DataGridReorder;
