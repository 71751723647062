import React, { useEffect, useState } from 'react';
import Box from 'ui/components/system/Box';
import ImageCard from 'ui/components/shared/ImageCard';
import IconButton from 'ui/components/system/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';
import useViewModal from 'hooks/shared/useViewModal';
import { Typography } from '@mui/material';
import { SESSIONS } from 'litterals';
import { groupByAttribute } from 'common/utils';
import TabNavigation from '../../program/components/TabNavigation';
import Stack from '@mui/material/Stack';
import CardProgram from 'ui/pages/evergreen-link/components/Cards/CardProgram/CardProgram';

const ChooseTheme = ({ themes, onClickSelect, t }) => {
  const { state, actions } = useViewModal();
  const [groups, setGroups] = useState({});
  const [selectedGroup, setSelectedGroup] = useState(null);

  useEffect(() => {
    if (themes) {
      const groupedThemes = groupByAttribute(themes, 'groupLabel');
      setGroups(groupedThemes);

      if (themes && themes.length && !selectedGroup) {
        const firstGroupKey = Object.keys(groupedThemes)[0];
        setSelectedGroup(firstGroupKey);
      }
    }
  }, [themes]);

  // Créer des options pour TabNavigation
  const tabOptions = Object.keys(groups).map((groupLabel) => ({
    id: groupLabel,
    // text: `${nbSession} ${t(SESSIONS)}`,
    text: groupLabel,
    selected: groupLabel === selectedGroup,
  }));

  const handleTabChange = (selectedTabId) => {
    setSelectedGroup(selectedTabId);
  };

  return (
    <>
      <Box
        sx={{
          ml: '20px',
          mr: '20px',
        }}>
        {Object.keys(groups).length > 1 && (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ ml: '10%', mb: '28px' }}>
            <TabNavigation options={tabOptions} onChange={handleTabChange} />
          </Stack>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'left',
            overflowY: 'auto',
            gap: '32px',
            ml: '10%',
          }}>
          {groups &&
            groups[selectedGroup]?.map((theme) => (
              <>
                <CardProgram
                  title={theme.name}
                  subtitle={theme.subtitle}
                  nbSessions={theme?.nbSessions || 0}
                  onSelect={() => onClickSelect(theme)}
                  onShowMoreInfo={() => actions.openModal(theme.id)}
                  picture={theme.marketingPicture}
                  showSelectButton
                />
              </>
            ))}
        </Box>
        {false && <Typography sx={{ ml: '10%', marginTop: '20px' }}>*{t('minimum_number_of_sessions')}</Typography>}
      </Box>
      <ThemeViewModal themeId={state.viewId} open={state.open} onClose={actions.closeModal} />
    </>
  );
};

export default ChooseTheme;
