import React, { useEffect } from 'react';
import HomeRH from './HomeRH';
import HomeCOACH from './HomeCOACH';
import HomeCOACHEE from './HomeCOACHEE';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import { useMeQuery } from 'hooks/queries';
import HomeAdmin from './admin/HomeAdmin';
import { roleList } from 'utils/constUtils';

const Home = ({ showExerciseEditor }) => {
  const { data: user } = useMeQuery();

  return (
    <Switch value={user.role}>
      <Case validate={(value) => [roleList.RH, roleList.RH_MANAGER].includes(value)}>
        <HomeRH user={user} />
      </Case>
      <Case value={roleList.COACH}>
        <HomeCOACH user={user} />
      </Case>
      <Case value={roleList.COACHEE}>
        <HomeCOACHEE user={user} />
      </Case>
      <Case value={roleList.ADMIN}>
        <HomeAdmin showExerciseEditor={showExerciseEditor} user={user} />
      </Case>
    </Switch>
  );
};

export default Home;
