import React from 'react';
import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';
import Button from 'ui/components/Button/Button';

const DeleteModal = ({ acceptText, title, description, question, open, handelModal, onDelete, editionNotSaved }) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    return onDelete();
  };

  return (
    <ViewModal
      customStyleBox={{
        maxWidth: '450px',
        height: 'max-content',
      }}
      open={open}
      onClose={handelModal}>
      <Box>
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              paddingBottom: '16px',
            }}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ width: '100%', margin: 'auto', lineHeight: '25px', fontWeight: 300 }}>
          <Typography variant="p">{description}</Typography>
          {question && (
            <Typography variant="p" sx={{ display: 'block' }}>
              {question}
            </Typography>
          )}
        </Box>

        {editionNotSaved ? (
          <Box
            sx={{
              paddingTop: '20px',
              textAlign: 'right',
            }}>
            <Button
              variant="delete"
              onClick={() => {
                handleDelete();
                handelModal();
              }}>
              {acceptText}
            </Button>
            <Button onClick={handelModal} variant="primary" sx={{ marginLeft: '16px' }}>
              {t('back_to_edit')}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              paddingTop: '20px',
              textAlign: 'right',
            }}>
            <Button onClick={handelModal} variant="secondary">
              {t('cancel')}
            </Button>
            <Button
              sx={{ marginLeft: '16px' }}
              variant="deletePrimary"
              onClick={() => {
                handleDelete();
                handelModal();
              }}>
              {acceptText}
            </Button>
          </Box>
        )}
      </Box>
    </ViewModal>
  );
};

export default DeleteModal;
