import dayjs from 'dayjs';

const detailedCoachActivationLabel = (active, deactivatedFrom, deactivatedUntil, t) => {
  const dateFrom = new Date(deactivatedFrom);
  const currentDate = new Date();

  if (deactivatedFrom && dateFrom.getTime() < currentDate.getTime()) {
    if (!deactivatedUntil) {
      return t('active_status_coach.disabled_profile');
    }

    if (!active) {
      return t('active_status_coach.disabled_profile_until', { date: dayjs(deactivatedUntil).format('DD/MM/YYYY') });
    }
  }

  if (deactivatedFrom) {
    return t('active_status_coach.activated_profile_until', { date: dayjs(deactivatedFrom).format('DD/MM/YYYY') });
  }

  if (!active) {
    return t('active_status_coach.disabled_profile');
  }

  return t('active_status_coach.activated_profile');
};

export { detailedCoachActivationLabel };
