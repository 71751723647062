import { get, post, put, del } from './request';

class Actions {
  static list() {
    return get('/session_actions').then((res) => res.data);
  }

  static get(id) {
    return get(`/session_actions/${id}`).then((res) => res.data);
  }

  static listBySession(id) {
    return get(`/session_actions/session/${id}`).then((res) => res.data);
  }

  static create(payload) {
    return post('/session_actions', payload).then((res) => res.data);
  }

  static edit({ id, payload }) {
    return put(`/session_actions/${id}`, payload).then((res) => res.data);
  }

  static delete(id) {
    console.log('delete', id);
    return del(`/session_actions/${id}`).then((res) => res.data);
  }
}

export default Actions;
