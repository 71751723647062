import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import ProgramsTable from './ProgramsTable';
import { Alert, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PROGRAMS } from 'litterals';
import useViewSubscription from 'hooks/usecase/useViewSubscription';
import { roleList } from 'utils/constUtils';

const HomeCOACH = ({ user }) => {
  const [queryParams, setQueryParams] = useState({
    page: 1,
    size: 25,
    status: ['done', 'draft', 'pending', 'in progress'],
  });
  const { actions, query } = useViewSubscription({ initialFilterState: queryParams });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const showAvailabilitiesAlert = !user.availabilitiesCount && user?.company?.selfRegistrationActivated;

  const navigateToCoachAvailabilities = (e) => {
    e.stopPropagation();
    navigate(`/coach/${user.id}#showAvailabilities`);
  };

  return (
    <Paper elevation={3} sx={{ m: '24px', p: '12px', pb: '24px', position: 'relative' }}>
      <Typography component="h1" variant="h4" fontWeight="bold" sx={{ pl: '12px' }}>
        {t(PROGRAMS)}
      </Typography>
      {showAvailabilitiesAlert && (
        <Alert severity="info" sx={{ margin: '12px 0', cursor: 'pointer' }} onClick={navigateToCoachAvailabilities}>
          {t('availability_undefined_alert')}{' '}
          <Link sx={{ cursor: 'pointer' }} onClick={navigateToCoachAvailabilities}>
            {t('link').toLowerCase()}
          </Link>
          .
        </Alert>
      )}
      {(!showAvailabilitiesAlert || !!query?.data?.rows.length) && (
        <ProgramsTable
          handleCoachingRequest={actions.handleCoachingRequest}
          user={user}
          extraColumns={['Coachee', 'Next-Session']}
          programs={query.data ? query.data.rows : []}
          isLoading={query.isLoading}
          sx={{ height: '70vh' }}
          setQueryParams={setQueryParams}
          queryParams={queryParams}
          role={roleList.COACH}
          updateFilter={actions.updateFilterSubscription}
          totalPage={query.data ? query.data.totalPages : 0}
          totalItems={query.data && query.data.totalItems}
        />
      )}
    </Paper>
  );
};

export default HomeCOACH;
