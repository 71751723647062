import React, { useRef, useEffect, useState } from 'react';
import { Typography, Box, Stack, Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
// Components
import Input from '../components/Input';
import PhoneInput2 from '../components/PhoneInput2';
import Button from 'ui/components/Button/Button';
import Select from '../components/Select';
import Skeleton from '@mui/material/Skeleton';
import MultiSelect from 'ui/components/shared/MultiSelect';
import MultiSelectNative from './components/MultiSelectNative/MultiSelectNative';
import UploadField from 'ui/components/form/UploadField';
import Grid from '@mui/material/Grid';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
// Hooks
import styles from './ApplicationForm.styles';
import createValidation from '../validators/application.validator';
import useFormPublic from 'hooks/usecase/useFormPublic';
// Style
import { useTranslation } from 'react-i18next';
import LanguageSelect from 'ui/components/LanguageSelect/LanguageSelect';

import { randomFileId } from 'utils/uploader';

// Validation
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import {
  ADD_PHOTO,
  APPFORM_TOOLTIP_DEPARTMENT,
  APPFORM_TOOLTIP_EXPECTATIONS,
  APPFORM_TOOLTIP_IDEMPLOYEE,
  APPFORM_TOOLTIP_LANGUAGES,
  APPFORM_TOOLTIP_LOCALIZATION,
  APPFORM_TOOLTIP_SECTOR,
  APPLICATION_FORM_FIELD_AFFILIATION,
  APPLICATION_FORM_FIELD_CURRENT_LOCATION,
  APPLICATION_FORM_FIELD_DEPARTMENT,
  APPLICATION_FORM_FIELD_GENDER_IDENTITY,
  APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE,
  APPLICATION_FORM_FIELD_SENIORITY_LEVEL,
  APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES,
  APPLICATION_FORM_MANAGER_EMAIL_LABEL,
  APPLICATION_FORM_TITLE_MATCHING_INFO,
  APPLICATION_FORM_TITLE_PROGRAM_GOALS,
  APPLICATION_FORM_TITLE_SHARE_YOUR_PORTRAIT_PHOTO,
  APPLY_TO_PROGRAM,
  EMAIL,
  FIELD_EMPLOYEEID,
  FIELD_LINKEDINURL,
  FIRSTNAME,
  GO_BACK,
  IMAGE_UPLOADED_SUCCESS,
  LASTNAME,
  PHONE_NUMBER,
  PLACEHOLDER_MANAGER_EMAIL,
  PROFILE_INFO,
} from 'litterals';

import { capitalizeString } from 'utils/functions';
import InputWithTooltip from '../components/InputWithTooltip';
const mapFormSetting = (formSettingsList) => {
  let result = [];
  if (formSettingsList) {
    result = Object.fromEntries(
      formSettingsList?.formFields.map(({ fieldName, isActivated }) => [fieldName, isActivated])
    );
  }
  return result;
};
const ApplicationForm = ({ actions, onSubmit, company, control, setStateAlert }) => {
  const ref = useRef();
  const refFirstname = useRef();
  const refLastname = useRef();
  const refEmail = useRef();
  const refEmailLeader = useRef();

  const refLinkedinUrl = useRef();
  const refIdNumber = useRef();

  const refLocation = useRef();
  const refScope = useRef();
  const refSeniorityLevel = useRef();
  const refTimeZone = useRef();
  const refGender = useRef();
  const refLanguages = useRef();
  const refDepartment = useRef();

  const refCoachingSkills = useRef();

  const refExpectations = useRef();

  const inputRef = useRef();

  const { t } = useTranslation();

  const handleTransformListForOptionsSelect = (list) =>
    list.map((item) => ({
      label: item.name,
      value: item.id,
    }));

  const handleTransformListForOptionsSelectTargets = (list) =>
    list.map((item) => ({
      label: item.name,
      value: item.name,
    }));

  /* ********************************** CONSTANTS ********************************* */
  const [queryParams, setQueryParams] = useState({ isMentor: false });
  const { formSettingsList, departments, seniorityLevels, timeZones } = useFormPublic(
    company.evergreenLink,
    queryParams
  );
  // const { seniorityLevels, isLoading } = useEvergreenSeniority(_Link);
  let SENIORITY_LEVELS_OPTIONS = [];
  if (seniorityLevels) {
    SENIORITY_LEVELS_OPTIONS = seniorityLevels?.map((level) => {
      return { label: capitalizeString(t(level.name)), value: level.id };
    });
  }

  let DEPARTMENTS_OPTIONS = [];
  if (departments) {
    DEPARTMENTS_OPTIONS = departments?.map((department) => {
      return { label: capitalizeString(t(department.name)), value: department.id };
    });
  }

  let TIME_ZONES_OPTIONS = [];
  if (timeZones?.length) {
    TIME_ZONES_OPTIONS = timeZones.map((timeZone) => {
      return { label: `${timeZone.label} (${timeZone.value})`, value: timeZone.value };
    });
  }

  const GENDER_OPTIONS = [
    { label: t('F'), value: 'F' },
    { label: t('M'), value: 'M' },
    { label: t('-'), value: '-' },
  ];

  const onClick = () => {
    inputRef?.current?.click();
  };

  let result = mapFormSetting(formSettingsList);

  if (formSettingsList) {
    result = Object.fromEntries(
      formSettingsList?.formFields.map(({ fieldName, isActivated }) => [fieldName, isActivated])
    );
  }

  const [validation, setValidation] = useState(createValidation(result));

  useEffect(() => {
    setValidation(createValidation(result));
  }, [formSettingsList]);

  const matching_info_Condition =
    validation?.location?.required ||
    validation?.scopeId?.required ||
    validation?.seniorityLevel?.required ||
    validation?.gender?.required ||
    validation?.department?.required ||
    validation?.timeZone?.required ||
    validation?.languages?.required;

  const sessionLabel = company?.sessionLabel;
  const expertLabel = company?.expertLabel;

  const phoneRegex = /^\+[1-9]\d{1,2}\s?\d{3,7}$/;

  const validatePhone = (input) => {
    const cleanedInput = input.replace(/[^\d+]/g, '');
    return cleanedInput === '' || phoneRegex.test(cleanedInput);
  };

  return (
    <Stack sx={{ maxHeight: 200, minHeight: '100vh', backgroundColor: 'background.secondary' }}>
      <Stack ref={ref} sx={styles.container} alignItems={'center'}>
        {/*********** profil_info **************/}
        <Stack sx={styles.containerInput}>
          <Typography variant="h7">{capitalizeString(t(PROFILE_INFO))} </Typography>
          <Box sx={styles.spacerLabelTop} />

          <Stack>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
              <Input
                label={capitalizeString(t(FIRSTNAME)) + '*'}
                name="firstname"
                ref={refFirstname}
                control={control}
                rules={validation?.firstname}
                style={{ width: '46%' }}
              />
              <Input
                label={capitalizeString(t(LASTNAME)) + '*'}
                name="lastname"
                ref={refLastname}
                control={control}
                rules={validation?.lastname}
                style={{ width: '46%' }}
              />
              <Input
                ref={refEmail}
                label={t(EMAIL) + '*'}
                name="email"
                control={control}
                rules={validation?.email}
                style={{ width: '46%' }}
              />
              {validation?.phoneNumber?.required && (
                <PhoneInput2
                  name="phoneNumber"
                  label={capitalizeString(t(PHONE_NUMBER))}
                  control={control}
                  style={{ width: '46%' }}
                  rules={{
                    required: false,
                    validate: (value) => {
                      if (!value) {
                        return true;
                      } else if (validatePhone(value)) {
                        return t('validate_phone_number');
                      }
                    },
                  }}
                />
              )}
              {validation?.linkedinUrl?.required && (
                <Input
                  ref={refLinkedinUrl}
                  label={capitalizeString(t(FIELD_LINKEDINURL))}
                  name="linkedinUrl"
                  control={control}
                  style={{ width: '46%' }}
                />
              )}
              {validation?.idNumber?.required && (
                <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_IDEMPLOYEE)}>
                  <Input
                    label={capitalizeString(t(FIELD_EMPLOYEEID) + '*')}
                    name="idNumber"
                    ref={refIdNumber}
                    control={control}
                    rules={validation?.idNumber}
                    style={{ width: '100%' }}
                  />
                </InputWithTooltip>
              )}
              <Stack sx={{ width: '46%' }}>
                <LanguageSelect
                  label={capitalizeString(t('application_form_field_platform_content_language')) + '*'}
                  name="platformContentLanguage"
                  control={control}
                  rules={validation?.platformContentLanguage}
                />
              </Stack>
            </Box>
          </Stack>
          <Stack sx={{ m: 1 }} />
        </Stack>
        {/*********** Picture ***********/}
        {validation?.picture?.required && (
          <Stack sx={styles.containerInput}>
            <Box style={{ display: 'none' }}>
              <UploadField
                label={capitalizeString('picture')}
                name="picture"
                folder={'users/'}
                fileId={randomFileId()}
                control={control}
                ref={inputRef}
                setDisabledButton={() => {}}
                accept={'picture'}
                wq
                submitForm={actions.onUploadPicture}
                onUploadSuccess={actions.updateImage}
              />
            </Box>
            <Typography variant="h7">
              {capitalizeString(t(APPLICATION_FORM_TITLE_SHARE_YOUR_PORTRAIT_PHOTO))}
            </Typography>
            <Stack sx={{ m: 1 }} />
            <Grid alignItems={'center'} container spacing={1}>
              <Grid item>
                <Box
                  variant="flex"
                  role="button"
                  onClick={onClick}
                  sx={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    borderRadius: '4px',
                    ':hover': { background: '#1407BF14' },
                  }}>
                  <CropOriginalIcon />
                  <Box sx={{ width: '7px' }} />
                  <Typography>{capitalizeString(t(ADD_PHOTO))}</Typography>
                </Box>
              </Grid>
              {actions.watch('picture').length > 0 && (
                <Grid item>
                  <Box sx={{ display: 'flex' }} size="small" onClick={() => {}}>
                    <FileDownloadDoneIcon fontSize="small" />
                    <Box sx={{ width: '10px' }} />
                    <Typography sx={{}} variant="body2">
                      {capitalizeString(t(IMAGE_UPLOADED_SUCCESS))}
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Stack sx={{ m: 1 }} />
          </Stack>
        )}
        {/*********** matching_info  ***********/}
        {matching_info_Condition && (
          <Stack sx={styles.containerInput}>
            <Typography variant="h7">{capitalizeString(t(APPLICATION_FORM_TITLE_MATCHING_INFO))} </Typography>
            <Box sx={styles.spacerLabelTop} />
            <Stack>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'space-between' }}>
                {validation?.location?.required && (
                  <InputWithTooltip
                    tooltipText={t(APPFORM_TOOLTIP_LOCALIZATION).replace('{{sessionLabel}}', sessionLabel)}>
                    <Input
                      label={capitalizeString(t(APPLICATION_FORM_FIELD_CURRENT_LOCATION)) + '*'}
                      name="location"
                      ref={refLocation}
                      control={control}
                      rules={validation?.location}
                      style={{ width: '100%' }}
                    />
                  </InputWithTooltip>
                )}
                {company && company.scopes.length == 0 ? (
                  <Skeleton width={'100%'} />
                ) : (
                  <Stack style={{ width: '46%' }}>
                    {validation?.scopeId?.required && (
                      <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_SECTOR)} width="100%">
                        <Stack sx={{ width: '100%' }}>
                          <Select
                            options={handleTransformListForOptionsSelect(company.scopes)}
                            label={capitalizeString(t(APPLICATION_FORM_FIELD_AFFILIATION)) + '*'}
                            name="scopeId"
                            ref={refScope}
                            control={control}
                            rules={validation?.scopeId}
                          />
                        </Stack>
                      </InputWithTooltip>
                    )}
                  </Stack>
                )}
                {validation?.seniorityLevel?.required && (
                  <Stack style={{ width: '46%' }}>
                    <Select
                      options={SENIORITY_LEVELS_OPTIONS}
                      label={capitalizeString(t(APPLICATION_FORM_FIELD_SENIORITY_LEVEL)) + '*'}
                      name="seniorityLevelId"
                      ref={refSeniorityLevel}
                      control={control}
                      rules={validation?.seniorityLevel}
                    />
                  </Stack>
                )}
                {validation?.gender?.required && (
                  <Stack sx={{ width: '46%' }}>
                    <Select
                      options={GENDER_OPTIONS}
                      label={capitalizeString(t(APPLICATION_FORM_FIELD_GENDER_IDENTITY)) + '*'}
                      name="gender"
                      ref={refGender}
                      control={control}
                      rules={validation?.gender}
                    />
                  </Stack>
                )}
                {validation?.languages?.required && (
                  <InputWithTooltip
                    tooltipText={t(APPFORM_TOOLTIP_LANGUAGES).replace('{{sessionLabel}}', sessionLabel)}
                    top="60%">
                    <MultiSelect
                      label={capitalizeString(t(APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES)) + '*'}
                      ref={refLanguages}
                      name="languages"
                      control={control}
                      rules={validation?.languages}
                    />
                  </InputWithTooltip>
                )}
                {validation?.department?.required && (
                  <InputWithTooltip tooltipText={t(APPFORM_TOOLTIP_DEPARTMENT)}>
                    <Stack sx={{ width: '100%' }}>
                      <Select
                        options={DEPARTMENTS_OPTIONS}
                        label={capitalizeString(t(APPLICATION_FORM_FIELD_DEPARTMENT)) + '*'}
                        name="departmentId"
                        ref={refDepartment}
                        control={control}
                        rules={validation?.department}
                      />
                    </Stack>
                  </InputWithTooltip>
                )}
                {validation?.timeZone?.required && (
                  <Stack style={{ width: '46%' }}>
                    <Select
                      options={TIME_ZONES_OPTIONS}
                      label={capitalizeString(t('application_form_field_time_zone')) + '*'}
                      name="timeZone"
                      ref={refTimeZone}
                      control={control}
                      rules={validation?.timeZone}
                    />
                  </Stack>
                )}
              </Box>
            </Stack>
            <Stack sx={{ m: 1 }} />
          </Stack>
        )}

        {/*********** Expertise ***********/}
        {validation?.targetList?.required && (
          <Stack sx={styles.containerTargets}>
            <Typography variant="h7">{t(APPLICATION_FORM_TITLE_PROGRAM_GOALS)}</Typography>
            <Box sx={styles.spacerLabelTop} />
            {company && company.target_template_items.length > 0 ? (
              <MultiSelectNative
                options={handleTransformListForOptionsSelectTargets(
                  company && company.target_template_items ? company.target_template_items : []
                )}
                ref={refCoachingSkills}
                name="targetList"
                label={t('application_form_field_select_objectives') + '*'}
                control={control}
                rules={validation.targetList}
              />
            ) : (
              <Skeleton width={'100%'} />
            )}

            <Stack sx={{ m: 1 }} />
          </Stack>
        )}
        {/*********** Expectations ***********/}
        {validation?.expectations1?.required && (
          <Stack sx={styles.containerInput}>
            <InputWithTooltip
              width="100%"
              top="60%"
              tooltipText={t(APPFORM_TOOLTIP_EXPECTATIONS).replace('{{expertLabel}}', expertLabel)}>
              <Box sx={styles.spacerLabelTop} />
              <Typography variant="h7">{t('application_form_title_expectations_initiative')}</Typography>
            </InputWithTooltip>
            <Box sx={styles.spacerLabelTop} />
            <Box sx={styles.spacerLabelTop} />
            <Box sx={styles.spacerLabelTop} />
            <Input
              rows={3}
              maxRows={4}
              multiline
              variant="outlined"
              ref={refExpectations}
              label={t(APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE) + '*'}
              name="expectations"
              control={control}
              rules={validation.expectations1}
            />
            <Stack sx={{ m: 1 }} />
          </Stack>
        )}
        {/*********** Email leader ***********/}
        {validation?.leaderEmail?.required && (
          <Stack sx={styles.containerInput}>
            <Typography variant="h7">{t(APPLICATION_FORM_MANAGER_EMAIL_LABEL)}</Typography>
            <Box sx={styles.spacerLabelTop} />
            <Box sx={styles.spacerLabelTop} />
            <Box sx={styles.spacerLabelTop} />
            <Input ref={refEmailLeader} label={t(PLACEHOLDER_MANAGER_EMAIL)} name="leaderEmail" control={control} />
            <Stack sx={{ m: 1 }} />
          </Stack>
        )}
        <Stack sx={{ m: 1 }} />
        <Stack direction="horizontal" alignItems="flex-end">
          <Button
            onClick={() => {
              actions.handleGoBack();
            }}
            style={{ marginRight: '15px' }}
            variant="primary">
            {t(GO_BACK)}
          </Button>
          <Button
            onClick={() => {
              onSubmit();
            }}
            type="primary">
            {t(APPLY_TO_PROGRAM)}
          </Button>
        </Stack>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={false}
        autoHideDuration={3000}
        onClose={() => setStateAlert((previousState) => ({ ...previousState, open: false }))}>
        <Alert
          severity={'warning'}
          variant="filled"
          sx={{ width: '100%' }}
          onClose={() => setStateAlert((previousState) => ({ ...previousState, open: false }))}>
          {'stateAlert.message'}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default ApplicationForm;
