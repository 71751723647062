const defaultStyleColumn = {
  padding: '18px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  paddingTop: 0,
  paddingBottom: 0,
};

const styles = {
  description: {
    paddingTop: '6px',
  },
  content: {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    border: '2px solid #eaecf0',
    marginTop: '28px',
    color: '#475467',
  },
  header: {
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    background: '#F9FAFB',
    display: 'flex',
  },
  column1: {
    flex: '1.5',
    padding: '18px',
    alignContent: 'center',
  },
  column2: {
    flex: '0.4',
    alignContent: 'center',
    ...defaultStyleColumn,
  },
  column3: {
    flex: '1',
    ...defaultStyleColumn,
  },
  column4: {
    flex: '1',
    ...defaultStyleColumn,
  },
  section: {
    display: 'flex',
    padding: '16px 0',
    borderTop: '2px solid #eaecf0',

    '&:nth-child(2)': {
      borderTop: 'none',
    },
  },
  delaySelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  delaySelector: {
    flex: 'auto',

    '.MuiOutlinedInput-input': {
      padding: '10px',
    },
  },
  showEmailTemplate: {
    marginLeft: '6px',
  },
};

export default styles;
