import { useEffect, useState } from 'react';
import { useListCoachesWithParams } from 'hooks/queries';
import { useMutation } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const useExperts = (queryParams) => {
  // React query
  const { data, isLoading, isFetched, refetch } = useListCoachesWithParams(queryParams);
  const { setStateAlert, stateAlert } = useAlert();

  useEffect(() => {
    if (data) {
      console.log('RESPONSE API ::: ', data);
    }
  }, [data]);

  const updateList = (users) =>
    users.reduce((acc, currentItem) => {
      const modifiedItem = {
        ...currentItem,
        name: currentItem.firstname + ' ' + currentItem.lastname,
        user: {
          name: currentItem.firstname + ' ' + currentItem.lastname,
          title: currentItem.title,
          picture: currentItem.picture,
        },
        scopeName:
          currentItem.scopeName != null
            ? currentItem.scopeName
            : currentItem.userScope
            ? currentItem.userScope.name
            : '',
        internalCoach: currentItem.internalCoach ? 'INTERNAL' : 'EXTERNAL',
        registrationDate: dayjs(currentItem.registrationDate).fromNow(),
      };

      acc.push(modifiedItem);
      return acc;
    }, []);

  const mutationDelete = useMutation(api.Coaches.delete, {
    onSuccess: async (_data, id) => {
      refetch();
      setStateAlert({ open: true, message: t('user_deleted_successfully') });
    },
    onError: (error) => {
      setStateAlert({ open: true, message: `${error.response.data.error}` });
    },
  });

  const handleDeleteCoach = ({ id }) => {
    return mutationDelete.mutate(id);
  };

  return {
    experts: data ? updateList(data.rows) : [],
    totalPages: data && data.totalPages,
    totalItems: data && data.totalItems,
    isLoading,
    isFetched,
    handleDeleteCoach,
    refetch,
  };
};
