const styles = {
  containerDialog: {
    width: '450px',
    backgroundColor: 'white',
    padding: '32px',
  },
  btnBack: {
    display: 'flex',
    padding: '20px 0 0 0',
  },
  title: {
    color: '#000000DE',
    fontSize: '24px',
    padding: '0',
    fontWeight: 700,
    lineHeight: '30px',
  },
  containerTitle: {
    display: 'flex',
    padding: '0 0 20px 0',
  },
  content: {
    fontWeight: 400,
    fontSize: '16px',
    padding: 0,
    textAlign: 'justify',
  },
};
export default styles;
