import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from 'ui/components/Button/Button';
import styles from './ReopenProgramModal.styles';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';

const ReopenProgramModal = ({ open, programName, onClose, onReopenProgram }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ViewModal open={open} onClose={onClose} customStyleBox={styles.container}>
        <Box>
          <Box>
            <Typography variant="h1" sx={styles.title}>
              {t('reopen_program_modal_title')}
            </Typography>
          </Box>
          <Box sx={{ width: '100%', margin: 'auto', lineHeight: '25px', fontWeight: 300 }}>
            <Typography variant="p">{t('reopen_program_modal_desc', { programName })}</Typography>
            <Typography variant="p" sx={{ display: 'block' }}>
              {t('reopen_program_modal_question')}
            </Typography>
          </Box>
          <Box sx={styles.containerButtons}>
            <Button onClick={onClose} variant="secondary">
              {t('cancel')}
            </Button>
            <Button
              sx={styles.validButton}
              variant="primary"
              onClick={() => {
                onReopenProgram();
                onClose();
              }}>
              {t('reopen_program_modal_accept_button')}
            </Button>
          </Box>
        </Box>
      </ViewModal>
    </div>
  );
};

export default ReopenProgramModal;
