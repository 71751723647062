import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EuroIcon from '@mui/icons-material/Euro';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from 'ui/components/shared/Card';
import ImageCard from 'ui/components/shared/ImageCard';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import IconButton from 'ui/components/system/IconButton';
import {
  ADD_TO_PROGRAM,
  SHOW_CALENDAR,
  SHOW_MORE,
  COPY_EMAIL,
  ACTIVATED,
  DESACTIVATED,
  SHOW_PHONE,
  EMAIL_COPIED,
} from 'litterals';
import { useAlert } from 'common/alertContext';
import { Tooltip } from '@mui/material';

export const AddButton = (onClick) => {
  const { t } = useTranslation();
  return <IconButton onClick={onClick} icon={<AddIcon />} tooltip={t(ADD_TO_PROGRAM)} aria-label="add-coach-button" />;
};

export const MoreButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <IconButton onClick={onClick} icon={<VisibilityIcon />} tooltip={t(SHOW_MORE)} aria-label="see-more-coach-button" />
  );
};

const EmailButton = ({ email }) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();

  const onClick = async () => {
    await navigator.clipboard.writeText(email);
    setStateAlert({ open: true, message: t(EMAIL_COPIED), type: 'info' });
  };
  return <IconButton onClick={onClick} icon={<EmailIcon />} tooltip={t(COPY_EMAIL)} aria-label="copy-mail-button" />;
};

export const CalendarButton = ({ calendarLink }) => {
  const { t } = useTranslation();
  const onClick = () => window.open(calendarLink, '_blank');
  return (
    <IconButton
      onClick={onClick}
      icon={<CalendarMonthIcon />}
      key="show-coach-calendar-button"
      tooltip={t(SHOW_CALENDAR)}
    />
  );
};

export const PhoneButton = ({ phoneNumber }) => {
  const { t } = useTranslation();
  return (
    <IconButton icon={<PhoneIcon />} key="show-coach-phone-button" tooltip={t(SHOW_PHONE) + ' : ' + t(phoneNumber)} />
  );
};

export const ActivateButton = ({ active }) => {
  const { t } = useTranslation();
  return (
    <IconButton
      icon={active ? <DoneIcon sx={{ color: 'success.main' }} /> : <CloseIcon sx={{ color: 'error.main' }} />}
      key="show-coach-active-button"
      tooltip={active ? t(ACTIVATED) : t(DESACTIVATED)}
    />
  );
};

const CoachCardContent = ({ price, city, isInternal, languages, logo, hasPrice }) => {
  const styleBox = {
    color: 'grey',
    display: 'flex',
    flexDirection: 'raw',
    justifyContent: 'left',
    alignItems: 'center',
    mt: '2%',
  };
  const styleLogo = {
    width: '35px',
    mr: 'auto',
    ml: 'auto',
    display: 'flex',
    flexDirection: 'raw',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const styleText = {
    paddingLeft: '8px',
    color: 'text.secondary',
    overflow: 'hidden',
    maxHeight: '24px',
    textOverflow: 'ellipsis',
  };
  const styleIcon = { fontSize: '14px', color: 'grey' };
  const iconColor = isInternal ? 'primary' : 'secondary';
  const localPrice = parseInt(price).toLocaleString('fr-FR');
  const languagesList = languages && languages.join(', ');

  return (
    <Box sx={{ height: '65px' }}>
      <Box component="img" sx={styleLogo} src={logo} />
      <Tooltip title={city} placement="right">
        <Box sx={styleBox}>
          <LocationOnIcon sx={styleIcon} color={iconColor} />
          <Typography sx={styleText}>{city}</Typography>
        </Box>
      </Tooltip>
      <Tooltip title={languagesList} placement="right">
        <Box sx={styleBox}>
          <LanguageIcon sx={styleIcon} color={iconColor} />
          {languagesList && (
            <Typography sx={styleText} key={languagesList}>
              {languagesList}
            </Typography>
          )}
        </Box>
      </Tooltip>
      {/* {hasPrice && (
        <Box sx={styleBox}>
          <EuroIcon sx={styleIcon} color={iconColor} />
          <Typography sx={styleText}>{`${localPrice}/h`}</Typography>
        </Box>
      )} */}
    </Box>
  );
};

export const BaseCoachCard = ({
  onClick,
  disabled,
  buttons,
  expertLabel,
  sessionLabel,
  sx = {},
  fieldsNotShown,
  ...coach
}) => {
  const { t } = useTranslation();

  const picture = coach?.picture ? coach.picture : `${__dirname}uploads/icons/default_user.svg`;
  const sxDisabled = disabled ? { cursor: 'default', opacity: 0.5, pointerEvents: 'none' } : {};
  const tooltipExeedMaxCapacity = disabled ? t('coach_not_selectable', { expertLabel, sessionLabel }) : '';

  return (
    <Tooltip title={tooltipExeedMaxCapacity}>
      <Box>
        <Card
          onClick={onClick}
          img={picture}
          title={`${coach.firstname} ${coach.lastname}`}
          sxTitle={{ color: coach.internalCoach ? 'primary.main' : 'secondary.main', fontSize: '13px' }}
          content={
            <CoachCardContent
              price={coach.price}
              city={coach.city}
              isInternal={coach.internalCoach}
              languages={coach.languagesSpoken}
              logo={coach.logo}
              hasPrice={!fieldsNotShown.includes('price')}
            />
          }
          buttons={buttons}
          sx={{ ...sx, ...sxDisabled }}
        />
      </Box>
    </Tooltip>
  );
};

const CoachCard = ({
  id,
  email,
  picture,
  firstname,
  lastname,
  company,
  city,
  price,
  internalCoach,
  calendarLink,
  phoneNumber,
  active,
  languagesSpoken,
  logo,
  onClickCard,
  onClickMoreOverride,
  fieldsNotShown,
  canExceedMaxCapacity,
  programsInProgress,
  maxCapacity,
  sx = {},
}) => {
  const navigate = useNavigate();
  const onClickMore = () => (onClickMoreOverride ? onClickMoreOverride(id) : navigate(`/coach/${id}`));

  const remainingCapacity = maxCapacity - programsInProgress;
  const isDisabled = canExceedMaxCapacity === false && remainingCapacity <= 0;

  const onClick = () => {
    !isDisabled && onClickCard();
  };

  return (
    <BaseCoachCard
      onClick={onClick}
      buttons={[
        <MoreButton key="coach-see-more-button" onClick={onClickMore} />,
        <EmailButton key="coach-email-button" email={email} />,
        <CalendarButton calendarLink={calendarLink} key="goto-calendarlink-button" />,
        <PhoneButton phoneNumber={phoneNumber} key="goto-phone-button" />,
        <ActivateButton active={active} key="goto-close-button" />,
      ]}
      id={id}
      picture={picture}
      firstname={firstname}
      lastname={lastname}
      city={city}
      price={price}
      internalCoach={internalCoach}
      languagesSpoken={languagesSpoken}
      logo={logo}
      sx={sx}
      fieldsNotShown={fieldsNotShown}
      disabled={isDisabled}
      expertLabel={company.expertLabel}
      sessionLabel={company.sessionLabel}
    />
  );
};

export const InviteCoachCard = ({ onClick, title }) => {
  const { t } = useTranslation();
  return (
    <ImageCard
      src={`${__dirname}uploads/icons/add_user.svg`}
      onClick={onClick}
      title={t(title)}
      sxCard={{ margin: '24px', opacity: '1' }}
      sxTitle={{ opacity: '0.8' }}
      elevation={5}
    />
  );
};

export default CoachCard;
