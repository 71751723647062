import React from 'react';
import TextField from './TextField';

const EmailField = ({ name, placeholder, control, ...rest }) => {
  const rules = {
    pattern: {
      value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      message: 'Invalid email address',
    },
  };
  return (
    <TextField name={name} placeholder={placeholder} control={control} rules={rules} {...rest} />
  );
};

export default EmailField;
