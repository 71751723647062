import { primaryMain } from 'theme';

const hoverContainer = {
  borderColor: primaryMain,
  backgroundColor: `${primaryMain}16`,
};

const styles = {
  container: {
    alignItems: 'center',
    border: '2px dashed',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    alignContent: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    borderColor: '#ccc',
  },

  hoverContainer: hoverContainer,
  dragContainer: hoverContainer,

  disabledContainer: {
    filter: 'grayscale(1)',
    cursor: 'default',
  },

  uploadIcon: {
    padding: '10px',
    color: 'primary.main',
    backgroundColor: `${primaryMain}10`,
    borderRadius: '50%',
    fontSize: '28px',
    marginBottom: '6px',
  },

  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  uploadText: {
    color: 'primary.main',
    marginRight: '4px',
    textDecoration: 'underline',
    fontSize: '14px',
  },

  warningText: {
    color: 'primary.grey',
    fontSize: '13px',
    marginTop: '8px',
  },
};

export default styles;
