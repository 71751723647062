const styles = {
  avatar: {
    border: '2px solid white',
    fontWeight: 600,
    letterSpacing: '-2px',
  },
  avatarPicture: {
    backgroundColor: 'background.paper',
  },
};
export default styles;
