const styles = {
  cellText: {
    margin: '0px',
    fontWeight: '400',
    display: 'flex',
    paddingTop: '2px',
  },
  picture: {
    width: 36,
    height: 36,
    borderRadius: '25px',
    objectFit: 'cover',
  },
  title: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '14px',
    color: '#202020',
  },
  description: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '14px',
    color: '#B2AEAE',
  },
};

export default styles;
