import React, { useState } from 'react';

import styles from './PhoneInput2.styles';

import { useController } from 'react-hook-form';

import { BaseTextFieldProps, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CountryIso2, defaultCountries, FlagEmoji, parseCountry, usePhoneInput } from 'react-international-phone';

const index = ({ ...props }) => {
  const {
    fieldState: { error },
    field: { onChange, value },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
  });

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'fr',
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone);
    },
  });

  return (
    <TextField
      variant="standard"
      color="primary"
      placeholder="Phone number"
      value={phone}
      onChange={handlePhoneValueChange}
      error={!!error}
      helperText={error ? error.message : ''}
      type="tel"
      inputRef={inputRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
            <Select
              MenuProps={{
                style: {
                  height: '300px',
                  width: '360px',
                  top: '10px',
                  left: '-34px',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
              sx={{
                width: 'max-content',
                // Remove default outline (display only on focus)
                fieldset: {
                  display: 'none',
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: 'block',
                  },
                },
                // Update default spacing
                '.MuiSelect-select': {
                  padding: '8px',
                  paddingRight: '24px !important',
                },
                svg: {
                  right: 0,
                },
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => <FlagEmoji iso2={value} style={{ display: 'flex' }} />}>
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagEmoji iso2={country.iso2} style={{ marginRight: '8px', width: '25px' }} />
                    <Typography marginRight="8px">{country.name}</Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default index;
