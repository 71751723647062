/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useRef } from 'react';

// UI Lib dependencies
import { Box } from '@mui/material';
import Input from 'ui/components/Input';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const EditGuest = ({ control, validation, sxBox }) => {
  // Refs
  const refName = useRef();

  return (
    <>
      <Box sx={sxBox}>
        <Input
          ref={refName}
          control={control}
          variant="outlined"
          label="Firstname"
          name="firstname"
          rules={validation.firstname}
          fullWidth
        />
      </Box>
      <Box sx={sxBox}>
        <Input
          ref={refName}
          control={control}
          variant="outlined"
          label="Lastname"
          name="lastname"
          rules={validation.lastname}
          fullWidth
        />
      </Box>
      <Box sx={sxBox}>
        <Input
          ref={refName}
          control={control}
          variant="outlined"
          label="Email"
          name="email"
          rules={validation.email}
          fullWidth
        />
      </Box>
    </>
  );
};

export default EditGuest;
