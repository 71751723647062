import { put } from './request';
import Subscriptions from './subscriptions';
import dayjs from 'dayjs';

class SubscriptionCoachee {
  // TODO: wait until proper backend route
  static async getMySubscriptionCoachees() {
    const data = await Subscriptions.getMe();
    const subIds = data.map((item) => item.id);
    const queries = subIds.map((id) => Subscriptions.getById(id));
    return Promise.all(queries);
  }

  static updateMyProgramStatus({ id, status = 'done' }) {
    return put(`/programs/${id}`, {
      status,
      date_done: dayjs().format('YYYY-MM-DD'),
    });
  }
}

export default SubscriptionCoachee;
