import { useReducer } from 'react';

const useNavStepperReducer = (initialState = {}) =>
  useReducer(
    (state, action) => {
      switch (action.type) {
        case 'NEXT_STEP':
          return { ...state, activeStep: state.activeStep + 1 };
        case 'PREV_STEP':
          // eslint-disable-next-line no-case-declarations
          const prevStep = state.activeStep - 1;
          return { ...state, activeStep: prevStep, disabledPrev: !prevStep };
        case 'RESET_STEP':
          return { activeStep: 0, disabledPrev: true, disabledNext: true };
        case 'ACTIVE_NEXT':
          return { ...state, disabledNext: false };
        case 'ACTIVE_PREV':
          return { ...state, disabledPrev: false };
        case 'DISABLE_NEXT':
          return { ...state, disabledNext: true };
        case 'DISABLE_PREV':
          return { ...state, disabledPrev: true };
        default:
          return { ...state };
      }
    },
    {
      activeStep: 0,
      disabledPrev: true,
      disabledNext: true,
      ...initialState,
    }
  );

export default useNavStepperReducer;
