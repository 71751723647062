/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Box from '@mui/material/Box';
import { OpenProgramCell, ChooseCoachButtonCell } from 'ui/pages/home/ProgramsTable';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsHome({ rowData, role, asCoachee }) {
  /* ******************************** RENDERING ******************************* */
  const chooseCoach = rowData.status === 'pending' && asCoachee;
  return (
    <Box>
      {chooseCoach && <ChooseCoachButtonCell programId={rowData.id} />}
      {role !== 'RH' && !chooseCoach && <OpenProgramCell programId={rowData.id} />}
    </Box>
  );
}

export default CellActionsHome;
