import React, { useState } from 'react';
import ViewModal from 'ui/components/shared/ViewModal';
import styles from './EditThemePicturesModal.styles';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from 'ui/components/Button/Button';
import CardProgram from 'ui/pages/evergreen-link/components/Cards/CardProgram/CardProgram';
import UploaderContainer from 'ui/components/UploaderContainer/UploaderContainer';
import { fileToBase64 } from 'utils/uploader';

const EditThemePicturesModal = ({ isLoading, open, theme, onClose, onChangeFiles }) => {
  const { t } = useTranslation();
  const [newThumbnailSrc, setNewThumbnailSrc] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState('');
  const [coverFile, setCoverFile] = useState('');

  const onChangeCover = (file) => {
    setCoverFile(file);
  };

  const onChangeThumbnail = async (file) => {
    setThumbnailFile(file);

    const src = await fileToBase64(file);
    setNewThumbnailSrc(src);
  };

  return (
    <ViewModal
      open={open}
      customStyleBox={{ padding: 0, borderRadius: '16px', maxWidth: '950px', height: 'max-content' }}
      positionClose="sticky"
      onClose={onClose}>
      <Box sx={styles.container}>
        <Typography sx={styles.title} component="h1">
          {t('edit_theme_pictures.title')}
        </Typography>

        <Typography sx={styles.subtitle} component="p">
          {t('edit_theme_pictures.subtitle')}
        </Typography>

        <Box>
          <Typography sx={styles.categoryTitle} component="h1">
            {t('edit_theme_pictures.cover_image.title')}
          </Typography>

          <Typography sx={styles.categorySubtitle} component="p">
            {t('edit_theme_pictures.cover_image.subtitle')}
          </Typography>

          <Box sx={styles.coverContainer}>
            <UploaderContainer sx={styles.uploaderCover} disabled={isLoading} onChangeFile={onChangeCover} />
          </Box>
        </Box>

        <Box>
          <Typography sx={styles.categoryTitle} component="h1">
            {t('edit_theme_pictures.thumbnail_image.title')}
          </Typography>

          <Typography sx={styles.categorySubtitle} component="p">
            {t('edit_theme_pictures.thumbnail_image.subtitle')}
          </Typography>

          <Box sx={styles.thumbnailContainer}>
            <UploaderContainer sx={styles.uploaderThumbnail} disabled={isLoading} onChangeFile={onChangeThumbnail} />

            <Box sx={styles.preview}>
              <CardProgram
                key={theme.id}
                active={theme.active}
                adminButtons
                picture={newThumbnailSrc || theme.marketingPicture}
                title={theme.name}
                subtitle={theme.subtitle}
                nbSessions={theme.nbSessions}
                onSelect={() => {}}
                onEdit={() => {}}
                onShowMoreInfo={() => {}}
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            paddingTop: '20px',
            textAlign: 'right',
            paddingBottom: '30px',
          }}>
          <Button onClick={onClose} variant="secondary">
            {t('cancel')}
          </Button>
          <Button
            disabled={(!thumbnailFile && !coverFile) || isLoading}
            loading={isLoading}
            sx={{ marginLeft: '16px' }}
            variant="primary"
            onClick={() => {
              onChangeFiles(thumbnailFile, coverFile);
            }}>
            {t('edit_theme_pictures.button')}
          </Button>
        </Box>
      </Box>
    </ViewModal>
  );
};

export default EditThemePicturesModal;
