import React from 'react';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseRounded from '@mui/icons-material/CloseRounded';

const ViewModal = ({
  open,
  onClose,
  children,
  backgroundColor,
  height,
  customStyleBox,
  customStyleParentContent = {},
  positionClose = 'absolute',
  contentRef,
}) => {
  const styleBox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: height || '80%',
    p: 4,
    overflowY: 'auto',
    backgroundColor: backgroundColor,
    ...customStyleBox,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Paper sx={styleBox} ref={contentRef} elevation={4} onClick={(e) => e.stopPropagation()}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            position: positionClose,
            top: positionClose === 'sticky' ? '2px' : '6px',
            right: positionClose === 'sticky' ? '0px' : '6px',
            marginRight: positionClose === 'sticky' ? '2px' : '0px',
            zIndex: 1,
          }}>
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Box
          sx={
            positionClose === 'sticky'
              ? { marginTop: '-40px', ...customStyleParentContent }
              : { ...customStyleParentContent }
          }>
          {children}
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewModal;
