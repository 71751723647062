import React from 'react';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { ACCEPT_TNC_LINK, ACCEPT_TNC_TEXT, TNC_BUTTON } from 'litterals';

const TnCButton = ({ acceptTerms, setAcceptTerms, link }) => {
  const { t } = useTranslation();

  return (
    <Switch value={acceptTerms}>
      <Case value={false}>
        <Button
          onClick={() => setAcceptTerms(true)}
          sx={{
            width: 'fit-content',
            alignSelf: 'center',
          }}
          variant="outlined"
          startIcon={<CheckBoxOutlineBlankIcon />}>
          {t(ACCEPT_TNC_TEXT)}&nbsp;
          <Link target="_blank" rel="noreferrer" href={link} onClick={(e) => e.stopPropagation()}>
            {t(ACCEPT_TNC_LINK)}
          </Link>
        </Button>
      </Case>
      <Case value={true}>
        <Button
          onClick={() => setAcceptTerms(false)}
          sx={{
            width: 'fit-content',
            alignSelf: 'center',
          }}
          variant="outlined"
          startIcon={<CheckBoxIcon />}>
          {t(TNC_BUTTON)}
        </Button>
      </Case>
    </Switch>
  );
};

export default TnCButton;
