import { red } from '@mui/material/colors';

const styles = {
  container: {
    backgroundColor: 'background.paper',
    borderRadius: '4px',
    padding: '32px',
    width: '480px',
    boxShadow: 24,
    height: 'none',
  },

  containerButtons: {
    paddingTop: '30px',
    textAlign: 'right',
  },

  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
  },

  deactivateProgramButton: {
    color: red[700],
    borderColor: red[700],
    marginLeft: '16px',
    '&:hover': {
      borderColor: red[900],
      color: red[900],
      backgroundColor: 'white',
    },
  },

  title: {
    fontSize: '24px',
    fontWeight: 600,
    paddingBottom: '24px',
  },
};
export default styles;
