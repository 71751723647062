import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useThemeQuery } from 'hooks/queries';
import { MESSAGE_SUCCESS_CHANGES, MESSAGE_SUCCESS_CHANGE_PROFILE } from 'litterals';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { addLanguagePropertiesInForm, deleteLanguagePropertiesInForm, setValueLanguageInForm } from 'utils/translate';
import { randomFileId } from 'utils/uploader';

const defaultValues = {
  name: '',
  description: '',
  content1: '',
  content2: '',
  content3: '',
  marketingPicture: '',
  backgroundPicture: '',
  active: false,
  documentLinked: '',
};

export const useCreateTheme = () => {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: defaultValues,
  });
  const [showDialog, setShowDialog] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation(api.Theme.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-list-themes']);
      setShowDialog(false);
      reset();
    },
  });

  const submitForm = handleSubmit((data) => {
    const payload = {
      name: data.name,
    };
    mutation.mutate(payload);
    setStateAlert({ open: true, message: `${t(MESSAGE_SUCCESS_CHANGES)}` });
  });

  const close = () => {
    setShowDialog(false);
    reset();
  };

  const clickAdd = () => setShowDialog(true);

  return {
    state: { form: { control }, nav: { showDialog } },
    actions: { clickAdd, close, submitForm },
  };
};

export default function useEditTheme(themeId) {
  const queryClient = useQueryClient();
  const { data, isLoading } = useThemeQuery(themeId);
  const [showAlert, setShowAlert] = useState(true);

  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    reset,
    resetField,
    setValue,
    formState: { isDirty },
  } = useForm({ mode: 'all', defaultValues });
  const { setStateAlert } = useAlert();

  const translatedProperties = ['name', 'subtitle', 'description'];

  useEffect(() => {
    reset({
      name: data?.name || undefined,
      subtitle: data?.subtitle || undefined,
      description: data?.description || undefined,
      content1: data?.content1 || undefined,
      content2: data?.content2 || undefined,
      content3: data?.content3 || undefined,
      marketingPicture: data?.marketingPicture || undefined,
      backgroundPicture: data?.backgroundPicture || undefined,
      active: data?.active || undefined,
      documentLinked: data?.documentLinked || undefined,
    });
  }, [data, reset]);

  const onNavigate = () => {
    navigate(`/themes/${themeId}`);
  };

  const mutation = useMutation(api.Theme.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-theme']);
      queryClient.invalidateQueries(['get-list-themes']);
      if (showAlert) {
        setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGES) });
      }
    },
    onError: (error) => {
      console.log('error', error.response.data.error);
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });

  const submitForm = handleSubmit(async (data) => {
    setShowAlert(true);

    setTimeout(() => {
      mutation.mutate({ id: themeId, data });
    }, 100);
  });

  const submitFormWithoutAlert = handleSubmit(async (data) => {
    setShowAlert(false);
    setTimeout(() => {
      mutation.mutate({ id: themeId, data });
    }, 100);
  });

  const deleteLanguage = (language) => {
    deleteLanguagePropertiesInForm(language, translatedProperties, watch, setValue);
    submitForm();
  };

  const addLanguage = (language) => {
    if (!language) return;
    addLanguagePropertiesInForm(language, translatedProperties, watch, setValue);
    submitForm();
  };

  const setValueLanguage = (language, property, content) => {
    setValueLanguageInForm(language, property, content, watch, setValue);
    submitFormWithoutAlert();
  };

  const updateFile = async (
    file,
    folderUpload = `companies/${data?.company?.id}/themes/${data?.id}/theme-pictures/`
  ) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('folder', folderUpload);
    formData.append('fileId', randomFileId());

    const result = await api.Users.upload(formData);

    return result?.url?.Location;
  };

  const updatePictures = async (thumbnailFile, coverFile) => {
    if (thumbnailFile) {
      const srcThumbnail = await updateFile(thumbnailFile);
      await setValue('marketingPicture', srcThumbnail);
    }

    if (coverFile) {
      const srcCover = await updateFile(coverFile);
      await setValue('backgroundPicture', srcCover);
    }

    await submitForm();
  };

  return {
    state: {
      form: { control, resetField, isDirty },
      query: { data, isLoading },
    },
    actions: { watch, submitForm, onNavigate, deleteLanguage, addLanguage, setValueLanguage, updatePictures },
  };
}
