import React, { useState, useRef } from 'react';

// UI Lib dependencies
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import dayjs from 'dayjs';

// UI Local Lib dependencies
import DataGrid from 'ui/components/DataGrid';
import Input from 'ui/components/Input';
import Switch from 'ui/components/Switch';
import DeleteModal from '../../../DeleteModal';

// Hooks

import useCompany from 'hooks/usecase/useCompany';
//import useTargetTemplateItems from 'hooks/usecase/useTargetTemplateItems';
import useTargetTemplateItems from 'hooks/usecase/useTargetTemplateItems';

// Configs
import { tableSchemaBase as columns } from './TargetTemplateItems.config';

// Validation
import validation from './validators/company.validator';

// Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const TargetTemplateItems = () => {
  // States
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  let { companyId } = useParams();
  const [queryParams, setQueryParams] = useState({ page: 1, size: 25, companyId: companyId });
  const { t } = useTranslation();
  // Hook
  const {
    control,
    isLoading,
    targetTemplateItems,
    reset,
    onRemoveTargetTemplateItem,
    onAddTargetTemplateItem,
    isOpenAddModal,
    setIsOpenAddModal,
    setEditingTargetTemplateItem,
    setIsOpenEditModal,
    isOpenEditModal,
    onEditTargetTemplateItem,
    totalPages,
    totalItems,
  } = useTargetTemplateItems(companyId, queryParams);

  // Refs
  const refName = useRef();

  // Helper
  const updateList = (companies) =>
    companies.reduce((acc, currentItem) => {
      if (currentItem.companyId === companyId) {
        const modifiedItem = {
          ...currentItem,
          createdAt: dayjs(currentItem.createdAt).format('DD-MM-YYYY'),
        };
        acc.push(modifiedItem);
      }

      return acc;
    }, []);

  const handleOnFilter = (filters, currentPage, currentSize) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userTargetTemplateItem: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }

    setQueryParams({
      ...{ orderBy: queryParams.orderBy, orderDirection: queryParams.orderDirection },
      page: currentPage,
      size: currentSize,
      ...queryParamsFilter,
    });
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    let queryParamsSort = {};
    switch (sort.orderBy) {
      case 'scopeName':
        queryParamsSort = { ...sort, orderBy: 'userTargetTemplateItem' };
        break;
      case 'user':
        queryParamsSort = { ...sort, orderBy: 'firstname' };
        break;
      case 'registrationDate':
        queryParamsSort = { ...sort, orderBy: 'createdAt' };
        break;
      case '':
        queryParamsSort = { orderBy: '' };
        break;
      default:
        queryParamsSort = { ...sort };
        break;
    }
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...queryParamsSort });
  };

  const handleOpenModal = () => {
    setIsOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenAddModal(false);
    reset();
  };

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
    reset({ name: '' });
  };

  return (
    <Stack sx={{ height: '500px', backgroundColor: 'white', paddingTop: '35px' }}>
      <Typography
        sx={{
          color: 'title.main',
          fontSize: '20px',
          fontWeight: 800,
          lineHeight: '32px',
          paddingBottom: '20px',
        }}>
        {'Target Template Item List'}
      </Typography>
      <Stack sx={{ paddingBottom: '9px' }}>
        <Button
          endIcon={<AddIcon />}
          onClick={handleOpenModal}
          sx={{
            width: '114px',
            height: '50px',
            borderRadius: '64px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'primary.main',
            color: 'white',
            fontWeight: 500,
            fontSize: '15px',
            boxShadow: '0px 3px 5px -1px #00000033',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'primary.dark',
            },
          }}>
          {t('add')}
        </Button>
      </Stack>

      {/** DataGrid */}
      <DataGrid
        columns={columns}
        rows={targetTemplateItems ? updateList(targetTemplateItems) : []}
        onDelete={(item) => {
          setSelectedItem(item);
          setIsOpenDeleteModal(true);
        }}
        onShow={() => {}}
        onEdit={(scope) => {
          setEditingTargetTemplateItem(scope);
        }}
        onRowClicked={(scope, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingTargetTemplateItem(scope.row);
          }
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        onPaginationChange={(page, nbrPages) => {
          setQueryParams({ ...queryParams, page: page, size: nbrPages });
        }}
        loading={isLoading}
        totalItems={totalItems}
        totalPages={totalPages ? totalPages : 0}
      />
      {/* Add modal */}
      <Dialog open={isOpenAddModal} onClose={handleCloseModal}>
        <DialogTitle>
          <Typography
            style={{
              color: '#000000DE',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
              marginTop: '15px',
            }}>
            {t('create_new_target_template')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '25px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
            },
          }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ padding: '8px 24px', height: '70px', minWidth: '300px' }}>
            <Input
              ref={refName}
              control={control}
              variant="outlined"
              label="Name"
              name="name"
              rules={validation.name}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '19px 77px',
            borderTop: '1px solid #dfe0e0',
          }}>
          <Button variant="outlined" onClick={handleCloseModal} size="large">
            {'BACK'}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'primary.dark' }}
            onClick={() => {
              onAddTargetTemplateItem();
            }}
            size="large">
            {'SAVE'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit modal */}
      <Dialog open={isOpenEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>
          <Typography
            style={{
              color: '#000000DE',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
              marginTop: '15px',
            }}>
            {t('edit_new_target_template')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '25px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
            },
          }}>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenEditModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ padding: '8px 24px', height: '70px', minWidth: '300px' }}>
            <Input
              ref={refName}
              control={control}
              variant="outlined"
              label="Name"
              name="name"
              rules={validation.name}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '19px 77px',
            borderTop: '1px solid #dfe0e0',
          }}>
          <Button variant="outlined" onClick={handleCloseEditModal} size="large">
            {'BACK'}
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: 'primary.dark' }}
            onClick={() => {
              onEditTargetTemplateItem();
            }}
            size="large">
            {'SAVE'}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteModal
        title="Delete target template item"
        description="Are you sure you want to delete this target template item"
        open={isOpenDeleteModal}
        onDelete={() => {
          onRemoveTargetTemplateItem(selectedItem.id);
        }}
        handelModal={() => {
          setIsOpenDeleteModal(false);
        }}
      />
    </Stack>
  );
};

export default TargetTemplateItems;
