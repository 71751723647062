import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Button from 'ui/components/Button/Button';
import EditIcon from '@mui/icons-material/Edit';
import GoBackButton from 'ui/components/shared/GoBackButton';
import ViewModal from '../ViewModal';
import UserView from '../UserView/UserView';
import EditUser from 'ui/pages/user/EditUser';
import { t } from 'i18next';

const MenteeDetailModal = ({
  actions,
  user = {},
  open,
  company = {},
  onClose,
  isLoading,
  onClickPreviousMentee,
  onClickNextMentee,
  userRole,
  directionalArrowsActivated = false,
  editable = false,
  editMode = false,
  control,
  validation,
  onSave,
  onRefuseGuestSelfRegistration,
  onValidateGuestSelfRegistration,
  onUploadSuccess,
}) => {
  const [contentType, setContentType] = useState('mentee');
  const styleBox = editable ? { paddingBottom: 0 } : {};

  if (isLoading) return <></>;

  useEffect(() => {
    if (editMode) {
      setContentType('edit');
    }
  }, [editMode]);

  return (
    <ViewModal
      open={open}
      onClose={onClose}
      customStyleParentContent={{ height: '100%' }}
      customStyleBox={{
        width: '90%',
        borderRadius: '16px',
        position: 'relative',
        height: '70%',
        ...styleBox,
      }}>
      {contentType === 'mentee' && (
        <MenteeDetail
          user={user}
          isLoading={isLoading}
          company={company}
          editable={editable}
          directionalArrowsActivated={directionalArrowsActivated}
          userRole={userRole}
          validGuestButton={user.typeGuest === 'RH_Validation'}
          onClickPreviousMentee={onClickPreviousMentee}
          onClickNextMentee={onClickNextMentee}
          onClickEditProfile={() => setContentType('edit')}
          onRefuseGuestSelfRegistration={onRefuseGuestSelfRegistration}
          onValidateGuestSelfRegistration={onValidateGuestSelfRegistration}
        />
      )}

      {contentType === 'edit' && (
        <MenteeEditContent
          actions={actions}
          userRole={userRole}
          user={user}
          control={control}
          company={company}
          validation={validation}
          onUploadSuccess={onUploadSuccess}
          onClickShowProfile={() => setContentType('mentee')}
          onSave={onSave}
        />
      )}
    </ViewModal>
  );
};

const MenteeDetail = ({
  user,
  isLoading,
  company,
  directionalArrowsActivated,
  validGuestButton,
  editable,
  userRole,
  onClickPreviousMentee,
  onClickNextMentee,
  onClickEditProfile,
  onRefuseGuestSelfRegistration,
  onValidateGuestSelfRegistration,
}) => {
  return (
    <Box
      sx={{
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <UserView
        userRole={userRole}
        availabilitiesEditable
        user={user}
        isLoading={isLoading}
        company={company}
        typeView="mentee"
        directionalArrowsActivated={directionalArrowsActivated}
        availabilitiesDisabled={true}
        onClickPreviousUser={onClickPreviousMentee}
        onClickNextUser={onClickNextMentee}
      />

      {editable && (
        <MenteeViewModalFooter
          showProfileEditButton={true}
          validGuestButton={validGuestButton}
          onRefuseGuestSelfRegistration={onRefuseGuestSelfRegistration}
          onValidateGuestSelfRegistration={onValidateGuestSelfRegistration}
          onClickEditProfile={onClickEditProfile}
        />
      )}
    </Box>
  );
};

const MenteeEditContent = ({
  actions,
  control,
  user,
  company,
  userRole,
  validation,
  onClickShowProfile,
  onSave,
  onUploadSuccess,
}) => {
  return (
    <>
      <Box>
        <GoBackButton onClick={onClickShowProfile} />

        <EditUser
          company={company}
          actions={actions}
          control={control}
          userRole={userRole}
          userId={user.id}
          typeUser="mentee"
          showBackToProfile={false}
          showSaveButton={false}
          showActiveProfile={false}
          onUploadSuccess={onUploadSuccess}
        />
      </Box>

      <MenteeViewModalFooter showProfileSaveButton onSave={onSave} />
    </>
  );
};

const MenteeViewModalFooter = ({
  validGuestButton,
  onClickEditProfile,
  onSave,
  onRefuseGuestSelfRegistration,
  onValidateGuestSelfRegistration,
  showProfileEditButton = false,
  showProfileSaveButton = false,
}) => {
  return (
    <Box
      sx={{
        padding: '16px 32px',
        background: '#FFFFFFE6',
        position: 'sticky',
        bottom: '0',
        marginTop: '16px',
        borderBottomLeftRadius: '16px',
        borderBottomRightRadius: '16px',
        marginLeft: '-32px',
        marginRight: '-32px',
        justifyContent: 'end',
        display: 'flex',
        zIndex: '10000',
      }}>
      {showProfileEditButton && !validGuestButton && (
        <Button variant="primary" onClick={onClickEditProfile}>
          <EditIcon sx={{ width: '20px', height: '20px', marginRight: '6px' }} />
          {t('mentee_view_edit_profile')}
        </Button>
      )}

      {showProfileSaveButton && <Button onClick={onSave}>{t('save_profile')}</Button>}

      {validGuestButton && (
        <Button sx={{ marginRight: '16px' }} onClick={onRefuseGuestSelfRegistration} variant="delete">
          {t('decline')}
        </Button>
      )}
      {validGuestButton && <Button onClick={onValidateGuestSelfRegistration}>{t('validate')}</Button>}
    </Box>
  );
};

export default MenteeDetailModal;
