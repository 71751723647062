import React, { useState, Suspense, useEffect } from 'react';

// UI Lib dependencies
import { Box, Chip, Checkbox, IconButton, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import styles from './SummaryActivityButton.styles';
import Button from 'ui/components/Button/Button';
import { parseJsonTranslate } from 'utils/parse';

const SummaryActivityButton = ({ validated, desc, title, duration, editMode = false, onValidatedChange, onClick }) => {
  const { t } = useTranslation();

  const containerStyle = validated ? { ...styles.container, ...styles.validated } : styles.container;
  const titleStyle = validated ? { ...styles.title, ...styles.titleValidated } : styles.title;

  return (
    <Box sx={containerStyle} onClick={onClick}>
      <Box sx={styles.header}>
        <Box sx={styles.containerTitle}>
          {!editMode && (
            <Checkbox
              checked={validated}
              onClick={(e) => {
                e.stopPropagation();
                onValidatedChange(e);
              }}
            />
          )}

          <Typography sx={titleStyle}>{parseJsonTranslate(title)}</Typography>
        </Box>

        <Chip label={`${duration}min`} sx={styles.timeChip} />
      </Box>

      <Box sx={styles.desc}>{parseJsonTranslate(desc)}</Box>

      <Box sx={styles.containerButton}>
        <Button variant="tertiary" sx={styles.button}>
          {t('view_details')}
        </Button>
      </Box>
    </Box>
  );
};

export default SummaryActivityButton;
