const styles = {
  text_explanation_reporting: {
    marginBottom: '25px',
    color: 'primary.dark',
    fontWeight: '600',
  },
  reset_button: {
    fontSize: '13px',
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  resetButtonBashboard: {
    height: '35px',
    color: 'black',
    border: '1px solid black',
    marginTop: '6px',
    textTransform: 'capitalize',
    '&:hover': {
      border: '1px solid black',
    },
  },
};

export default styles;
