// hooks/usecase/useDepartments.js
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useListDepartmentsQueryWithParams } from 'hooks/queries'; // Assuming you have a query hook for listing departments

const useDepartments = (companyId, queryParams) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingDepartment, setEditingDepartment] = useState(null);

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const { data: departmentList, isLoading } = useListDepartmentsQueryWithParams(queryParams);
  console.log({ departmentList });
  const mutationDelete = useMutation(api.Departments.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-departments']);
      setStateAlert({ open: true, message: t('delete_department') });
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const onRemoveDepartment = (id) => {
    mutationDelete.mutate(id);
  };

  const mutationAdd = useMutation(api.Departments.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-departments']);
      setStateAlert({ open: true, message: t('create_department') });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  const onAddDepartment = handleSubmit(async (data) => {
    let quary;
    if (!companyId) {
      quary = { ...data };
    } else {
      quary = { ...data, companyId: companyId };
    }
    mutationAdd.mutate(quary);
  });

  const mutationEdit = useMutation(api.Departments.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['list-departments']);
      setStateAlert({ open: true, message: t('edit_department') });
      setIsOpenEditModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}` }),
  });

  useEffect(() => {
    if (editingDepartment) {
      setValue('name', editingDepartment.name);
      setIsOpenEditModal(true);
    }
  }, [editingDepartment]);

  const onEditDepartment = handleSubmit(async (data) => {
    let quary;
    if (!companyId) {
      quary = { ...data };
    } else {
      quary = { ...data, companyId: companyId };
    }
    mutationEdit.mutate({ id: editingDepartment.id, data: quary });
  });

  return {
    isEditLoading: mutationEdit.isLoading,
    isAddLoading: mutationAdd.isLoading,
    isLoading: isLoading,
    departments: departmentList,
    totalItems: departmentList && departmentList.totalItems,
    totalPages: departmentList && departmentList.totalPages,
    control,
    watch,
    reset,
    getValues,
    onRemoveDepartment,
    onAddDepartment,
    isOpenAddModal,
    setIsOpenAddModal,
    onEditDepartment,
    setIsOpenEditModal,
    isOpenEditModal,
    setEditingDepartment,
  };
};

export default useDepartments;
