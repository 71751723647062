import React, { useMemo, useState } from 'react';

import { withReact } from 'slate-react';
import BaseYooptaEditor, { createYooptaEditor } from '@yoopta/editor';
import { Bold, Italic, CodeMark, Underline, Strike, Highlight } from '@yoopta/marks';
import Toolbar, { DefaultToolbarRender } from '@yoopta/toolbar';
import Paragraph from '@yoopta/paragraph';
import Blockquote from '@yoopta/blockquote';
import Accordion from '@yoopta/accordion';
import Divider from '@yoopta/divider';
import Table from '@yoopta/table';
import Callout from '@yoopta/callout';
import Image from '@yoopta/image';
import Embed from '@yoopta/embed';
import Link from '@yoopta/link';
import { NumberedList, BulletedList, TodoList } from '@yoopta/lists';
import { HeadingOne, HeadingThree, HeadingTwo } from '@yoopta/headings';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import api from 'api';
import { randomFileId } from 'utils/uploader';

import ActionMenuList, { DefaultActionMenuRender } from '@yoopta/action-menu-list';
import LinkTool, { DefaultLinkToolRender } from '@yoopta/link-tool';

const TOOLS = {
  ActionMenu: {
    render: DefaultActionMenuRender,
    tool: ActionMenuList,
  },
  Toolbar: {
    render: DefaultToolbarRender,
    tool: Toolbar,
  },
  LinkTool: {
    render: DefaultLinkToolRender,
    tool: LinkTool,
  },
};

const MARKS = [Bold, Italic, CodeMark, Underline, Strike, Highlight];

export default function YooptaEditor({ style, value, readOnly, folderUpload, disablePlugins = [], onChange }) {
  const editor = useMemo(() => withReact(createYooptaEditor()), []);
  const { t } = useTranslation();

  const plugins = [
    Paragraph,
    Blockquote,
    Accordion,
    Divider,
    Table,
    HeadingOne,
    HeadingTwo,
    HeadingThree,
    Callout,
    NumberedList,
    BulletedList,
    TodoList,
    Image,
    Embed,
    Link,
  ];

  const pluginsExtend = [];

  for (const plugin of plugins) {
    if (disablePlugins?.includes(plugin?.plugin?.type?.toLowerCase())) {
      continue;
    }

    const pluginTranslation = t(`editor.plugins.${plugin.plugin.type}`, { returnObjects: true });

    let pluginExtended = plugin;

    if (plugin.plugin.type.toLowerCase() === 'image') {
      pluginExtended = pluginExtended.extend({
        options: {
          onUpload: async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('folder', folderUpload);
            formData.append('fileId', randomFileId());

            const result = await api.Users.upload(formData);

            return {
              src: result?.url?.Location,
            };
          },
        },
      });
    }

    if (typeof pluginTranslation === 'object') {
      pluginExtended = pluginExtended.extend({
        options: {
          display: {
            ...plugin.plugin.options.display,
            ...pluginTranslation.display,
          },
        },
      });
    }

    pluginsExtend.push(pluginExtended);
  }

  /* if (isShowMode) {
    setTimeout(() => {
      setHtml(parse(editor.getHTML(value)));
    }, 250)
  } */

  return (
    <>
      <BaseYooptaEditor
        placeholder={t('editor.placeholder')}
        key={`${i18n.language}-${readOnly ? 'read-only' : 'edit'}`}
        readOnly={readOnly}
        value={value}
        style={{ ...style, position: 'relative' }}
        editor={editor}
        plugins={pluginsExtend}
        tools={TOOLS}
        marks={MARKS}
        onChange={onChange}
      />
    </>
  );
}
