const styles = {
  dateTimePicker: {
    background: 'white',
    '& .MuiOutlinedInput-input': {
      fontSize: '16px',
    },
  },
};

export default styles;
