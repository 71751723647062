/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';
import i18next from 'i18next';
const dayjs = require('dayjs');

// UI lib dependencies;
import { Stack, Tooltip, Typography } from '@mui/material';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellUpcomingSessions({ upcomingSessions = [] }) {
  /* ********************************** HOOKS ********************************* */

  const tooltipTitle = upcomingSessions.length
    ? upcomingSessions
        .map(({ date }) => {
          return dayjs(date)
            .locale(i18next ? i18next.language : 'en')
            .format('DD MMMM YYYY - HH:mm');
        })
        .join('\n')
    : '';

  const tooltipContent = tooltipTitle ? <span style={{ whiteSpace: 'pre-line' }}>{tooltipTitle}</span> : '';

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <Tooltip title={tooltipContent}>
        <Typography sx={{ fontSize: '18px', fontWeight: 500 }}>{upcomingSessions.length}</Typography>
      </Tooltip>
    </Stack>
  );
}

export default CellUpcomingSessions;
