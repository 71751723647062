import { useGetProgramsQuery } from 'hooks/queries';
import { useAddSession, useNextSession } from './useSessions';
import { useMeQuery } from 'hooks/queries';
import { useAlert } from 'common/alertContext';
import { SUCCESS_COMPLETE_PROGRAM } from 'litterals';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { roleList } from 'utils/constUtils';

const useProgram = (programId) => {
  const queryClient = useQueryClient();
  const query = useGetProgramsQuery(programId);
  const { data: user } = useMeQuery();
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);

  const {
    state: {
      form: { control, state: formState },
      nav: { formIsOpen },
    },
    actions: { addSession, openHandler, closeHandler },
  } = useAddSession(query.data?.sessions, programId);

  const mutate = useMutation(api.SubCoachee.updateMyProgramStatus, {
    onSuccess: () => {
      setStateAlert({
        message: t(SUCCESS_COMPLETE_PROGRAM),
        open: true,
      });

      queryClient.invalidateQueries(['get-programs', programId]);
    },
  });

  const mutateReopen = useMutation(api.SubCoachee.updateMyProgramStatus, {
    onSuccess: () => {
      setStateAlert({
        message: t('reopen_program_alert'),
        open: true,
      });

      queryClient.invalidateQueries(['get-programs', programId]);
    },
  });

  const mutateProgramTheme = useMutation(api.Programs.update, {
    onSuccess: () => {
      setStateAlert({
        message: t('alert_change_theme_program'),
        open: true,
      });

      queryClient.invalidateQueries(['get-programs', programId]);
    },
  });

  const mutateProgramMentor = useMutation(api.Programs.update, {
    onSuccess: () => {
      setStateAlert({
        message: t('alert_change_mentor_program', { expertLabel: user?.company?.expertLabel }),
        open: true,
      });

      queryClient.invalidateQueries(['get-programs', programId]);
    },
  });

  const mutateProgramStatus = useMutation(api.Programs.update, {
    onSuccess: (data) => {
      const type = data.status === 'deactivated' ? 'deactivation' : 'reactivation';
      setStateAlert({
        message: t(`program_${type}_success`),
        open: true,
      });

      queryClient.invalidateQueries(['get-programs', programId]);
    },
  });

  const onDeactivateProgram = () => {
    const status = 'deactivated';
    mutateProgramStatus.mutate({ id: programId, body: { status } });
  };

  const onCompleteProgram = () => {
    mutate.mutate({ id: programId });
    closeConfirmationDialog();
  };

  const onReopenProgram = () => {
    mutateReopen.mutate({ id: programId, status: 'in progress' });
  };

  const closeConfirmationDialog = () => {
    setShowDialog(false);
  };

  const clickCompleteButton = () => {
    setShowDialog(true);
  };

  const updateThemeProgram = (themeId) => {
    mutateProgramTheme.mutate({ id: programId, body: { themeId } });
  };

  const updateMentorProgram = (coachId) => {
    mutateProgramMentor.mutate({ id: programId, body: { coachId } });
  };

  return {
    state: {
      query: { program: query, user: user },
      nav: {
        nextSession: useNextSession(query.data),
        //We allow the coachee to edit the program when it is a MENTORING program only
        canEdit:
          query.data?.theme.type === 'COACHING'
            ? user.role === roleList.COACH
            : user.role === roleList.COACH || user.role === roleList.COACHEE,
        userRole: user.role,
        showComment: user.role !== roleList.COACHEE,
        showSessionDetails: user.role !== roleList.RH_MANAGER && user.role !== roleList.RH,
        sessionFormIsOpen: formIsOpen,
        showDialog,
        user,
      },
      form: { control: control, state: formState },
    },
    actions: {
      addSession,
      openSessionForm: openHandler,
      closeSessionForm: closeHandler,
      onCompleteProgram,
      onDeactivateProgram,
      onReopenProgram,
      clickCompleteButton,
      closeConfirmationDialog,
      updateThemeProgram,
      updateMentorProgram,
    },
  };
};

export default useProgram;
