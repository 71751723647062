import React from 'react';
import { useThemeQuery } from 'hooks/queries';
import ThemeView from './ThemeView';

const ThemeViewWithRequest = ({ themeId, ...props }) => {
  const { data: theme, isLoading } = useThemeQuery(themeId);

  if (isLoading || theme.id !== themeId) return <p>Loading...</p>;

  return <ThemeView theme={theme} {...props} />;
};

export default ThemeViewWithRequest;
