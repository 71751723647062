import React from 'react';
import Box from 'ui/components/system/Box';
import Button from 'ui/components/Button/Button';
import Typography from '@mui/material/Typography';
import TextField from 'ui/components/form/TextField';
import { useTranslation } from 'react-i18next';
import { GENERAL_INFORMATIONS, FIRSTNAME, LASTNAME, PHONE_NUMBER, SAVE_CHANGES, EMAIL } from 'litterals';

const ProfileForm = ({ control, onSubmit }) => {
  const styleInput = { margin: '12px 0' };
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        alignItems: 'center',
        margin: '24px',
        justifyContent: 'center',
      }}>
      <Typography variant="h5" component="h3" sx={{ mb: '24px' }}>
        {t(GENERAL_INFORMATIONS)}
      </Typography>
      <Box component="form" onSubmit={onSubmit} variant="flexColumn">
        <TextField
          name="firstname"
          label={t(FIRSTNAME)}
          placeholder={t(FIRSTNAME)}
          required
          control={control}
          style={styleInput}
        />
        <TextField name="lastname" label={t(LASTNAME)} placeholder={t(LASTNAME)} control={control} style={styleInput} />
        <TextField name="email" label={t(EMAIL)} placeholder={t(EMAIL)} control={control} style={styleInput} />
        <TextField
          name="phoneNumber"
          label={t(PHONE_NUMBER)}
          placeholder={t(PHONE_NUMBER)}
          control={control}
          style={styleInput}
        />
        <Button type="submit" sx={{ mt: '12px', width: '100%' }}>
          {t(SAVE_CHANGES)}
        </Button>
      </Box>
    </Box>
  );
};

export default ProfileForm;
