import { get, put } from './request';

class Format {
  static list() {
    return get('/formats').then((res) => res.data);
  }

  static get(id) {
    return get(`/formats/${id}`).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/formats/${id}`, data).then((res) => res.data);
  }

  static listThemes(formatId) {
    return get(`/formats/${formatId}/themes`).then((res) => res.data);
  }
}

export default Format;
