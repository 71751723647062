import React from 'react';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useResetPasswordSendEmail } from 'hooks/usecase/useLogin';
import EmailField from 'ui/components/form/EmailField';
import { BACK_TO_LOG_IN, LINK_EXPIRED, RE_ENTER_EMAIL, RE_SEND_LINK, WELCOME } from 'litterals';
import SuccessResetLink from './SuccessResetLink';

const styleLabel = {
  color: '#153060',
  paddingInline: '12px',
  fontSize: '14px',
  lineHeight: '20px',
  letter: '0.25px',
  marginTop: '18px',
};

const styleInput = {
  margin: '12px',
};

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
};

const FailedResetLink = ({ state, actions, handleNavigate, t }) => {
  return (
    <Box component="form" sx={styleBox}>
      <Typography
        gutterBottom
        component="h4"
        fontSize={'45px'}
        lineHeight={'52px'}
        color={'#153060'}
        m={'12px'}
        textTransform={'uppercase'}>
        {t(WELCOME)} !
      </Typography>
      <Typography
        gutterBottom
        component="h4"
        color="red"
        fontSize={'16px'}
        // margin={'auto'}
        marginLeft={'12px'}
        lineHeight={'24px'}
        fontWeight={400}
        letterSpacing={'0.15px'}>
        {t(LINK_EXPIRED)}
      </Typography>
      <Typography sx={styleLabel}>{t(RE_ENTER_EMAIL)}</Typography>
      <EmailField
        required
        name="email"
        label=""
        placeholder="Enter email"
        control={state.form.control}
        style={styleInput}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        type="submit"
        // color="primary"
        variant="contained"
        onClick={actions.onResetPasswordSendEmail}
        sx={{ m: '12px', backgroundColor: 'primary.main', height: '64px' }}>
        {t(RE_SEND_LINK)}
      </Button>
      <Button
        type="button"
        // color="primary"
        variant="outlined"
        sx={{
          m: '12px',
          height: '64px',
          color: 'primary.main',
          borderColor: 'primary.main',
          textTransform: 'uppercase',
        }}
        onClick={handleNavigate}>
        <ArrowBackIosIcon fontSize="16px" />
        {t(BACK_TO_LOG_IN)}
      </Button>
    </Box>
  );
};

const SendResetLinkForm = ({ t }) => {
  const { state, actions } = useResetPasswordSendEmail();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/login');
  };

  return !state.nav.resetSuccess ? (
    <FailedResetLink state={state} actions={actions} handleNavigate={handleNavigate} t={t} />
  ) : (
    <SuccessResetLink onClick={handleNavigate} t={t} />
  );
};

export default SendResetLinkForm;
