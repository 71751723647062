import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from './EditMentorImpossibleModal.styles';
import { useTranslation } from 'react-i18next';
import ViewModal from 'ui/components/shared/ViewModal';

const EditMentorImpossibleModal = ({ open, clientLabel, expertLabel, onClose, onDeactivateProgram }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ViewModal open={open} onClose={onClose} customStyleBox={styles.container}>
        <Box>
          <Box>
            <Typography variant="h1" sx={styles.title}>
              {t('edit_mentor_impossible_modal_title', { expertLabel })}
            </Typography>
          </Box>
          <Box sx={{ width: '100%', margin: 'auto' }}>
            <Typography variant="p">{t('edit_mentor_impossible_modal_desc', { clientLabel, expertLabel })}</Typography>
            <Typography variant="p" sx={{ display: 'block' }}>
              {t('edit_mentor_impossible_modal_question')}
            </Typography>
          </Box>
          <Box sx={styles.containerButtons}>
            <Button onClick={onClose} variant="outlined">
              {t('edit_mentor_impossible_modal_close_button')}
            </Button>
            <Button
              onClick={() => {
                onDeactivateProgram();
                onClose();
              }}
              sx={styles.deactivateProgramButton}
              variant="outlined">
              {t('edit_mentor_impossible_modal_accept_button')}
            </Button>
          </Box>
        </Box>
      </ViewModal>
    </div>
  );
};

export default EditMentorImpossibleModal;
