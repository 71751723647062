import React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Chip from '@mui/material/Chip';
import { ROLES_OPTIONS, getLabelByValue } from 'utils/roles';
import { useTranslation } from 'react-i18next';

const RoleFilter = (props) => {
  const { item, applyValue } = props;
  const { t } = useTranslation();

  const handleFilterChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  const handleChange = (languages) => {
    handleFilterChange(languages);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        pt: '2px',
        width: '200px',
      }}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        sx={{ width: '100%' }}
        id="tags-standard"
        options={ROLES_OPTIONS.map((lang) => lang.value)}
        getOptionLabel={(option) => t(getLabelByValue(option))}
        onChange={(event, newValue) => {
          handleChange(newValue);
        }}
        value={item.value ? item.value : []}
        renderTags={(value, getTagProps) => {
          const numTags = value.length;
          const limitTags = 1;
          return (
            <>
              {value.slice(0, limitTags).map((option, index) => (
                <Chip
                  sx={{ height: '20px' }}
                  size="small"
                  {...getTagProps({ index })}
                  key={index}
                  label={t(getLabelByValue(option))}
                />
              ))}
              {numTags > limitTags && <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>...</Typography>}
            </>
          );
        }}
        renderInput={(params) => <TextField {...params} variant="standard" label="Roles" />}
      />
    </Box>
  );
};

const RoleFilterOperators = [
  {
    label: 'Above',
    value: 'above',
    // eslint-disable-next-line no-unused-vars
    getApplyFilterFn: (filterItem) => {},
    InputComponent: RoleFilter,
  },
];

export default RoleFilterOperators;
