/* eslint-disable react/react-in-jsx-scope */
/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/

const tableSchemaBase = [
  {
    field: 'marketingPicture',
    headerName: 'Marketing Picture',
    type: 'avatar',
    hideable: false,
    minWidth: 130,
    resizable: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    type: 'text',
    hideable: false,
    minWidth: 130,
    resizable: true,
    flex: 1,
    sortable: false,
  },
  {
    field: 'active',
    headerName: 'Active',
    type: 'text',
    hideable: false,
    minWidth: 130,
    resizable: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'nbSessions',
    headerName: 'Number of sessions',
    type: 'text',
    hideable: false,
    minWidth: 130,
    resizable: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: 'groupLabel',
    headerName: 'Group of labels',
    type: 'text',
    hideable: false,
    minWidth: 130,
    resizable: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },

  {
    field: 'actions',
    headerName: 'actions',
    sortable: false,
    minWidth: 120,
    filter: false,
    filterable: false,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    type: 'actionsBackofficeWithOldEdit',
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    hideable: false,
    resizable: true,
  },
];

export { tableSchemaBase };
