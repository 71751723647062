import { Tooltip } from '@mui/material';
import React from 'react';
import InfoImg from './tooltip.png';

const InputWithTooltip = ({ tooltipText, width, top, children }) => {
  return (
    <div style={{ position: 'relative', width: width || '46%' }}>
      {tooltipText && (
        <div style={{ position: 'absolute', left: '-17px', top: top || '65%', transform: 'translateY(-50%)' }}>
          <Tooltip title={tooltipText}>
            <img src={InfoImg} width={11} height={11} />
          </Tooltip>
        </div>
      )}
      {children}
    </div>
  );
};

export default InputWithTooltip;
