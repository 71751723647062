import { del, post } from './request';

class ProgramExerciseValidated {
  static delete(id) {
    return del(`/program_exercise_validated/${id}`).then((res) => res.data);
  }

  static create(body) {
    return post('/program_exercise_validated', body).then((res) => res.data);
  }
}

export default ProgramExerciseValidated;
