const styles = {
  container: {
    display: 'flex',
    width: '100%',
    minHeight: '68px',
    border: '1px solid',
    borderRadius: '8px',
    borderColor: 'primary.border',
    margin: '16px 0',
    paddingLeft: {
      xs: '10px',
      sm: '12px',
      md: '12px',
      xl: '16px',
    },
    paddingRight: {
      xs: '10px',
      sm: '12px',
      xl: '16px',
    },
    flexFlow: 'row',
    alignItems: 'center',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
    '&:hover': {
      borderColor: 'primary.main',
    },
    cursor: 'pointer',

    ':first-child': {
      marginTop: 0,
    },

    ':last-child': {
      marginBottom: 0,
    },
  },
  containerSelected: {
    backgroundColor: 'card.selected',
    border: '2px solid',
    borderColor: 'primary.main',
  },
  durationChip: {
    height: '22px',

    '.MuiChip-label': {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
  hours: {
    minWidth: '40px !important',
    height: '36px',
    fontSize: {
      xs: '13px',
      sm: '14px',
    },
    marginLeft: {
      xs: '8px',
      sm: '10px',
      xl: '14px',
    },
    color: '#495465',
    borderColor: 'primary.border',
    paddingLeft: {
      xs: '8px',
      xl: '12px',
    },
    paddingRight: {
      xs: '8px',
      xl: '12px',
    },
  },
  date: {
    fontSize: {
      xs: '13px',
      xl: '14px',
    },
  },
  dateUndefined: {
    fontSize: {
      xs: '13px',
      xl: '14px',
    },
    color: 'primary.grey',
    textTransform: 'uppercase',
  },
};

export default styles;
