import React, { useState } from 'react';

import { Typography, Dialog, DialogActions, DialogContent, Box } from '@mui/material';

import styles from './ResourceModal.styles';
import Button from 'ui/components/Button/Button';
import { LANGUAGES_OPTIONS } from 'utils/languages';

// Radio
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import UploadField from 'ui/components/form/UploadField';
import Dropdown from './Dropdown/Dropdown';

import TextArea from './TextArea/TextArea';
import { t } from 'i18next';

import validation from './validators/application.validator';

import Select from 'ui/pages/application/components/Select';

import { getValueByLabel } from 'utils/languages';
import LoadingButton from 'ui/components/LoadingButton';
import { randomFileId } from 'utils/uploader';

const handleTransformScopesList = (list) =>
  list.map((item) => ({
    label: item,
    value: getValueByLabel(item),
  }));

const ResourceModal = ({ companyId, actions, state, programId, open, onClose, onSubmit }) => {
  const [isLink, setIsLink] = useState(true);
  const [disabledButton, setDisabledButton] = useState(true);

  return (
    <Dialog maxWidth="700px" open={open} onClose={actions.close}>
      <DialogContent sx={{ width: '600px' }}>
        <Box sx={styles.container}>
          <Typography sx={styles.title}>{t('add_ressource')}</Typography>
          <Box sx={{ mt: 3 }} />
          <TextArea label={t('title')} control={state.form.control} name="filename" actions={actions} />
          <Box sx={{ mt: 2 }} />
          <FormControl>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
              <FormControlLabel
                value="Link"
                checked={isLink}
                onChange={() => setIsLink(true)}
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <InsertLinkIcon />
                    <Typography sx={styles.link}>{t('link')}</Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="upload"
                checked={!isLink}
                onChange={() => setIsLink(false)}
                control={<Radio />}
                label={
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FileUploadOutlinedIcon />
                    <Typography sx={styles.upload}>{t('upload')}</Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ mt: 2 }} />
          {isLink ? (
            <TextArea rows={1} label={t('link')} control={state.form.control} name="filepath" actions={actions} />
          ) : (
            <div>
              <UploadField
                label={t('upload')}
                control={state.form.control}
                fileId={randomFileId()}
                name="file"
                actions={actions}
                setDisabledButton={setDisabledButton}
                folder={`companies/${companyId}/programs/${programId}/`}
              />
            </div>
          )}
          <Box sx={{ mt: 2 }} />
          <Dropdown label={t('type')} control={state.form.control} name="type" actions={actions} />
          <Box sx={{ mt: 2 }} />
          <>
            <Select
              variant="outlined"
              options={handleTransformScopesList(LANGUAGES_OPTIONS.map((item) => item.label))}
              label={t('please_select_your_language')}
              name="language"
              ref={null}
              control={state.form.control}
              rules={validation.language}
            />
          </>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="secondary" onClick={onClose}>
          {t('cancel')}
        </Button>
        <LoadingButton
          isloading={actions.isAddLoading || actions.isEditLoading}
          style={styles.saveButton}
          onClick={onSubmit}>
          {t('save_changes')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ResourceModal;
