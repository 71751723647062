import React, { forwardRef } from 'react';
import { useController, useFormState } from 'react-hook-form';
import { useEffect } from 'react';
import { InputBase } from '@mui/material';
import { useAlert } from 'common/alertContext';
import { IMAGE_UPLOADED_SUCCESS, UPLOAD_DONE } from 'litterals';
import { useTranslation } from 'react-i18next';

const UploadField = forwardRef(
  ({ label, control, name, setDisabledButton, folder, accept, fileId, submitForm, onUploadSuccess }, ref) => {
    const {
      field: { onChange, value },
    } = useController({ control, name, defaultValue: '' });
    const { t } = useTranslation();

    const { isDirty, isSubmitted } = useFormState({
      control,
    });

    const { setStateAlert } = useAlert();

    useEffect(() => {
      if (isDirty) {
        setDisabledButton(false);
      }
      if (isDirty && isSubmitted) {
        document.getElementById('input-file-upload').value = null;
      }
    }, [isDirty, isSubmitted]);

    const changeHandler = (e) => {
      if (e.target.files) {
        const formData = new FormData();
        formData.append('file', e.target.files[0]);
        formData.append('folder', folder);
        formData.append('fileId', fileId);

        if (accept === 'picture') {
          setStateAlert({ open: true, message: t(IMAGE_UPLOADED_SUCCESS), type: 'success' });
          onChange(formData); // met à jour le champ
          onUploadSuccess();
          submitForm(); // soumet le formulaire
        } else {
          onChange(formData);
        }
      }
    };

    return (
      <InputBase
        id="input-file-upload"
        type="file"
        name={name}
        accept={
          accept === 'picture' ? '.jpg, .png, .jpeg, .svg' : '.jpg, .png, .jpeg, .svg, .xlsx, .xls, .doc, .docx, .pdf'
        }
        label={label}
        control={control}
        value={undefined}
        onChange={changeHandler}
        inputRef={ref}
      />
    );
  },
  []
);

UploadField.displayName = 'UploadField';

export default UploadField;
