import React from 'react';

import Select from 'ui/pages/application/components/Select';
import { srcLanguage, languagesAccepted } from 'utils/languages';
import { useTranslation } from 'react-i18next';

const LanguageButton = ({ name, control, rules, label }) => {
  const { t } = useTranslation();

  const LANGUAGES_OPTIONS = languagesAccepted.map((language) => {
    return { label: t(`lang_${language}`), value: language, src: srcLanguage(language) };
  });

  return <Select options={LANGUAGES_OPTIONS} label={label} name={name} control={control} rules={rules} />;
};

export default LanguageButton;
