import { get, post, BASE_URL } from './request';

class Auth {
  static login(credentials) {
    return post('/auth/login', credentials).then((res) => res.data);
  }

  static logout() {
    return;
  }

  static resetPasswordSendEmail(email) {
    return post('/auth/reset-password', { email }).then((res) => res.data);
  }

  static verifyAccount({ id }) {
    return get(`/auth/getAccount/${id}`).then((res) => res);
  }

  static microsoftAuth(type = 'default') {
    window.location.href = `${BASE_URL}/api/auth/microsoft/${type}`;
  }

  static startSaml(type = 'default') {
    window.location.href = `${BASE_URL}/api/auth/saml/${type}`;
  }

  static microsoftAuthCallback({ code, type = 'default' }) {
    const params = { code };
    const token = localStorage.getItem('MICROSOFT_TOKEN_AUTH');

    if (token) {
      params.token = token;
    }

    return get(`/auth/microsoft/callback/${type}`, params).then((res) => res.data);
  }

  static refreshToken(refreshToken) {
    return post('/auth/refresh-token', refreshToken).then((res) => res.data);
  }

  static samlAuthTokenCallback({ token, type }) {
    const params = { token };
    const resetPasswordToken = localStorage.getItem('SAML_TOKEN_AUTH');

    if (resetPasswordToken) {
      params.resetPasswordToken = resetPasswordToken;
    }

    return post(`/auth/saml/${type}/callback-token`, params).then((res) => res.data);
  }

  static authCompany(email) {
    return post('/auth/email-company-detection', { email }).then((res) => res.data);
  }
}

export default Auth;
