const styles = {
  container: {
    width: '100%',
    '& .MuiFilledInput-root': { bgcolor: 'white' },
    '& .MuiInputBase-root.MuiFilledInput-root:before': { borderBottom: 'none' },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },

  inputProps: { style: { fontSize: '40px', color: '#000000', fontWeight: 700 } },
  InputLabelProps: { style: { fontSize: 40 } },
};
export default styles;
