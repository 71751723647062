import React from 'react';
import { Avatar, Card, CardContent, Typography } from '@mui/material';
import Box from 'ui/components/system/Box';

const StaticCard = ({ label, staticNumber, ratio, src, Icon }) => {
  return (
    <Card
      sx={{
        width: '175px',
        height: '173px',
        boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 25%)',
        padding: '12px',
        boxSizing: 'border-box',
        borderRadius: '14px',
      }}>
      <CardContent
        sx={{
          color: 'black',
          padding: '0px !important',
          paddingTop: '12px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}>
        <Box
          variant="flex"
          sx={{
            width: '40px',
            height: '40px',
            backgroundColor: 'primary.main',
            borderRadius: '8px',
          }}>
          <Avatar
            src={src ? `${__dirname}uploads/icons/${src}.png` : undefined}
            sx={{
              width: '26px',
              height: '26px',
              color: 'white',
              margin: 'auto',
              bgcolor: 'transparent',
              borderRadius: '0px',
            }}>
            {!src && Icon ? <Icon /> : undefined}
          </Avatar>
        </Box>
        {/* <Img style={{ width: '40px', height: '40px', color: color }} alt={imgName} /> */}
        <Typography color="#808191" fontWeight={'500'} fontSize={'14px'} lineHeight={'16.37px'}>
          {label}
        </Typography>
        <Box variant="flex" sx={{ justifyContent: 'space-between' }}>
          <Typography fontWeight={'500'} fontSize={'36px'}>
            {staticNumber}
          </Typography>
          <Typography textAlign={'end'}>{typeof ratio === 'number' ? `${ratio} %` : ''}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default StaticCard;
