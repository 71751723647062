import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import useNavStepperReducer from 'hooks/shared/useNavStepper';
import { useGetProgramsQuery, useGetSubscriptionQuery, useGetCoachesProgramQuery } from 'hooks/queries';
import api from 'api';

export default function useProgramOnboarding(programId) {
  const programsQuery = useGetProgramsQuery(programId);
  const subscriptionQuery = useGetCoachesProgramQuery(programId, {
    enabled: !!programsQuery.data,
  });
  const [navState, navDispatch] = useNavStepperReducer({
    disabledNext: false,
    disabledPrev: false,
  });
  const [coach, setCoach] = useState({});
  const navigate = useNavigate();

  const selectCoach = (coach) => {
    setCoach(coach);
  };

  const mutation = useMutation(api.Subscriptions.chooseCoach, {
    onSuccess: () => navigate('/home'),
  });

  const mutationChooseCoach = useMutation(api.Programs.chooseCoach, {
    onSuccess: () => navigate('/home'),
  });

  const state = {
    query: { program: programsQuery, subscription: subscriptionQuery },
    nav: navState,
    form: { coach },
  };

  useEffect(() => {
    if (navState.activeStep == 1 && !Object.keys(coach).length) return navDispatch({ type: 'DISABLE_NEXT' });

    return navDispatch({ type: 'ACTIVE_NEXT' });
  }, [navState.activeStep, coach]);

  const clickContinue = () => navDispatch({ type: 'NEXT_STEP' });
  const clickPrev = () => navDispatch({ type: 'PREV_STEP' });
  const validate = () => mutationChooseCoach.mutate({ id: programId, coachId: coach.id });

  const actions = { clickContinue, clickPrev, selectCoach, validate };
  return { state, actions };
}
