/* eslint-disable react/react-in-jsx-scope */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useController } from 'react-hook-form';
import styles from './DurationInput.styles';
const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const DurationInput = ({ minutes, hours, setHours, setMinutes, control, handleDurationChange, disabled }) => {
  const {
    field: { onChange: handleChange },
  } = useController({ control, name: 'duration', defaultValue: null });
  const { t } = useTranslation();

  const handleHoursChange = (event) => {
    const value = event.target.value;
    if (['1', '2', '3', '', '0'].includes(value)) {
      setHours(event.target.value);
      handleChange(parseInt(event.target.value) * 60 + parseInt(minutes));
      handleDurationChange && handleDurationChange(parseInt(event.target.value) * 60 + parseInt(minutes));
    }
  };

  const handleMinutesChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value) && Number(value) >= 0 && Number(value) <= 59) {
      setMinutes(event.target.value);
      handleChange(parseInt(hours) * 60 + parseInt(event.target.value));
      handleDurationChange && handleDurationChange(parseInt(hours) * 60 + parseInt(event.target.value));
    }
  };

  const formattedHours = parseInt(hours, 10).toString().length === 1 ? `0${parseInt(hours, 10)}` : parseInt(hours, 10);
  const formattedMinutes =
    parseInt(minutes, 10).toString().length === 1 ? `0${parseInt(minutes, 10)}` : parseInt(minutes, 10);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          label={t('duration_hour')}
          disabled={disabled}
          value={formattedHours}
          onChange={handleHoursChange}
          min="0"
          max="3"
          step="1"
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1);
          }}
          InputProps={{
            inputProps: {
              min: 1,
              max: 3,
              step: 1,
              maxLength: 1,
            },
            inputMode: 'numeric',
            style: {
              outline: 'none',
              fontSize: '16px',
            },
          }}
          type="number"
          sx={styles.durationInput}
        />
        <Box sx={{ width: '8px' }} />
        <Typography sx={styles.colonText}>:</Typography>
        <Box sx={{ width: '8px' }} />
        <TextField
          label={t('duration_minute')}
          disabled={disabled}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 2);
          }}
          value={formattedMinutes}
          onChange={handleMinutesChange}
          min="0"
          max="59"
          step="1"
          InputProps={{
            inputProps: {
              min: 0,
              max: 59,
              step: 1,
            },
            inputMode: 'numeric',
            style: {
              outline: 'none',
              fontSize: '16px',
            },
          }}
          type="number"
          sx={styles.durationInput}
        />
      </Box>
    </Box>
  );
};

export default DurationInput;
