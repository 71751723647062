import { create } from 'zustand';

// Slices
import { createCompanySlice } from 'ui/pages/home/admin/companies/slices/createCompanySlice';
import { createScopeSlice } from 'ui/pages/home/admin/companies/components/scopes/slices/createScopeSlice';
import { createThemeSlice } from 'ui/pages/home/admin/companies/components/themes/slices/createThemeSlice';
import { createStandardThemeSlice } from 'ui/pages/home/admin/standardThemes/slices/createStandardThemeSlice';
import { createEvergreenSlice } from 'ui/pages/evergreen-link/slices/createEvergreenSlice';
import { createHomeSlice } from 'ui/pages/home/slices/createHomeSlice';

export const useStore = create((set, get) => ({
  ...createHomeSlice(set, get),
  ...createCompanySlice(set, get),
  ...createScopeSlice(set, get),
  ...createThemeSlice(set, get),
  ...createStandardThemeSlice(set, get),
  ...createEvergreenSlice(set, get),
}));

export default useStore;
