import theme from 'theme';

const styles = {
  container: {
    borderRadius: '16px',
    minWidth: '1100px',
    height: '1107px',
  },
  containerTitle: {
    height: '18px',
  },
  title: {
    color: '#000000DE',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '30px',
  },
  titleContent: {
    color: theme.palette.primary.dark,
    fontSize: '40px',
    fontWeight: 800,
    lineHeight: '46.76px',
  },
  content: {
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
  btnBack: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '19px 77px',
    borderTop: '1px solid #dfe0e0',
  },
  btnSave: {
    backgroundColor: 'primary.dark',
  },
  labelSwitch: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '18.7px',
  },
  labelSwitchSuccess: {
    color: theme.palette.primary.success,
  },
  labelSwitchError: {
    color: theme.palette.primary.danger,
  },
  labelUploadPicture: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
    paddingBottom: '20px',
  },
  labelThemePreview: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
    marginBottom: '30px',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
  containerHeader: {
    padding: '8px 0px',
    minWidth: '300px',
    display: 'flex',
    alignItems: 'center',
  },
  containerTabNavigation: {
    display: 'flex',
    paddingTop: '53px',
    paddingLeft: '20px',
  },
  nbrSesstionText: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
  },
  containerNbrSessions: {
    paddingBottom: '21px',
    display: 'flex',
    alignItems: 'center',
  },
};
export default styles;
