import { del, get, post, put } from './request';

class Coaches {
  static list() {
    return get('/coaches').then((res) => res.data);
  }

  static listWithParams(queryParams) {
    return get('/coaches', queryParams).then((res) => res.data);
  }

  static getById(id) {
    return get(`/coaches/${id}`).then((res) => res.data);
  }

  static create(data) {
    return post('/coaches', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/coaches/${id}`, data).then((res) => res.data);
  }

  static getCoachCompany(id) {
    return get(`/companies/${id}`).then((res) => res.data);
  }

  static delete(id) {
    return del(`/coaches/${id}`).then((res) => res.data);
  }

  static invite(id) {
    return post(`/coaches/${id}/invite`).then((res) => res.data);
  }
}

export default Coaches;
