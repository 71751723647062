/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React, { useState } from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';

// Style
import CoachCardMini from 'ui/components/shared/coaches/CoachCardMini';
import CoachViewModalWithRequest from 'ui/components/shared/coaches/CoachViewModalWithRequest';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellCoach({ coach }) {
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents">
      <CoachCell coach={coach} />
    </Stack>
  );
}

export const CoachCell = ({ coach }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const onClick = (event) => {
    event.stopPropagation();
    coach?.id ? setModalOpened(true) : setModalOpened(false);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setModalOpened(false);
  };
  return (
    <>
      <CoachCardMini
        firstname={coach && coach.user ? coach.user.firstname : ''}
        lastname={coach && coach.user ? coach.user.lastname : ''}
        {...coach}
        onClick={onClick}
        sx={{ maxWidth: '12vw' }}
      />
      {coach && modalOpened && (
        <CoachViewModalWithRequest coachId={coach.id} open={modalOpened} onClose={handleClose} />
      )}
    </>
  );
};

export default CellCoach;
