const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  tabAndAddButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },

  tab: {
    marginTop: '-8px',
    padding: '0',
    width: 'max-content',
  },
};
export default styles;
