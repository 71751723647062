import React, { useState } from 'react';

import { Menu, MenuItem } from '@mui/material';
import Button from '../Button/Button';

const ButtonWithMenu = ({ id, children, items, onClickItem, sxItem, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClick = (id) => {
    onClickItem(id);
    handleClose();
  };

  const menuId = `${id}-menu`;

  return (
    <>
      <Button
        {...props}
        id={id}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        {children}
      </Button>
      <Menu
        id={menuId}
        sx={sxItem}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {items.map(({ id, name }) => {
          return (
            <MenuItem key={id} onClick={() => onClick(id)}>
              {name}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default ButtonWithMenu;
