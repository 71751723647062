import { get, post, put, del } from './request';

class Emails {
  static list() {
    return get('/email_templates').then((res) => res.data);
  }

  static listWithParams(queryParams) {
    return get(`/email_templates`, queryParams).then((res) => res.data);
  }

  static get(id) {
    return get(`/email_templates/${id}`).then((res) => res.data);
  }

  static getWithName(name) {
    return get(`/email_templates/name/${name}`).then((res) => res.data);
  }

  static create(data) {
    return post('/email_templates', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/email_templates/${id}`, data).then((res) => res.data);
  }

  static delete(id) {
    return del(`/email_templates/${id}`).then((res) => res.data);
  }
}

export default Emails;
