import theme from 'theme';

const styles = {
  container: {
    display: 'flex',
    paddingTop: '31px',
  },
  containerLeft: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: '20px',
  },
  containerRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  subContainerRight: {
    paddingTop: '100px',
  },
  containerUploadPicture: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingTop: '30px',
  },
  subContainerUploadPicture: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingBottom: '20px',
  },
  containerUploadBackgroundPicture: {
    paddingTop: '90px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  subContainerUploadBackgroundPicture: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    paddingBottom: '20px',
  },
  containerGroupLabel: {
    paddingTop: '70px',
  },
  labelThemeDescription: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
    paddingBottom: '20px',
  },
  containerNbrSessions: {
    paddingBottom: '21px',
    display: 'flex',
    alignItems: 'center',
  },
  nbrSesstionText: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
  },
  labelUploadPicture: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
  },
  containerLoader: {
    height: '127px',
    width: '76.04px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundPicture: {
    objectFit: 'contain',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
  },
  containerDescription: {
    paddingTop: '76px',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  labelDescription: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
    paddingBottom: '20px',
  },
  labelThemePreview: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '18.7px',
    color: theme.palette.primary.dark,
    marginBottom: '30px',
    textAlign: 'center',
  },
};
export default styles;
