import { get, post, put, del } from './request';

class CompanyRessources {
  static list() {
    return get('/company_ressources').then((res) => res.data);
  }

  static get(id) {
    return get(`/company_ressources/${id}`).then((res) => res.data);
  }

  static create(data) {
    return post('/company_ressources', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/company_ressources/${id}`, data).then((res) => res.data);
  }

  static delete(id) {
    return del(`/company_ressources/${id}`).then((res) => res.data);
  }
}

export default CompanyRessources;
