const styles = {
  title: {
    fontWeight: '600',
    fontSize: '20px',
  },

  description: {
    paddingTop: '6px',
    fontWeight: '400',
    fontSize: '16px',
  },

  content: {
    marginTop: '18px',
    display: 'flex',
    gap: '32px',
    flexWrap: 'wrap',
  },
};

export default styles;
