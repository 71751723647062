import React from 'react';
import { useCoachQuery, useMeQuery } from 'hooks/queries';
import CoachViewModal from 'ui/components/shared/coaches/CoachViewModal';

const CoachViewModalWithRequest = ({ coachId, ...props }) => {
  const { data: user } = useMeQuery();
  const { data: coach, isLoading } = useCoachQuery(coachId);

  return <CoachViewModal userRole={user.role} coach={coach} company={user.company} isLoading={isLoading} {...props} />;
};

export default CoachViewModalWithRequest;
