const styles = {
  calendarButton: {
    background: 'white',
    width: '100%',
    borderColor: '#D0D5DD',
    color: 'black',
    fontSize: '14px',
  },
};
export default styles;
