import { get, del, post, put } from './request';

export default class TargetTemplateItems {
  static list() {
    return get('/target_template_items').then((res) => res.data);
  }

  static listWithParams(queryParams) {
    return get('/target_template_items', queryParams).then((res) => res.data);
  }

  static delete(id) {
    return del(`/target_template_items/${id}`).then((res) => res.data);
  }

  static create(data) {
    return post('/target_template_items', data).then((res) => res.data);
  }

  static edit({ id, data }) {
    return put(`/target_template_items/${id}`, data).then((res) => res.data);
  }
}
