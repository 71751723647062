/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// React
import React from 'react';

// MUI
import MUICard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const ImageCard = ({ src, onClick, title, sxCard, sxTitle, children, elevation, sxCardActionArea }) => {
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */

  return (
    <MUICard
      sx={{
        width: '220px',
        height: '240px',
        borderRadius: '6px',
        ...sxCard,
      }}
      elevation={elevation}>
      <CardActionArea onClick={onClick} sx={sxCardActionArea ? sxCardActionArea : { height: 'inherit' }}>
        <CardMedia sx={{ height: '200px', objectFit: 'cover' }} component="img" src={src} />
        <Typography
          component="p"
          align="center"
          sx={{
            padding: '10px 8px 8px 8px',
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
            ...sxTitle,
          }}>
          {title}
        </Typography>
      </CardActionArea>
      <CardActions
        disableSpacing
        onClick={onClick}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '0',
        }}>
        {children}
      </CardActions>
    </MUICard>
  );
};

export default ImageCard;
