import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';

export default function GoBackButton({ text = 'go_back', ...props }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Button
      variant="text"
      startIcon={<ArrowBackIosIcon />}
      sx={{ width: 'max-content', padding: '6px 12px' }}
      onClick={onBack}
      {...props}>
      {t(text)}
    </Button>
  );
}
