import theme from 'theme';

const styles = {
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  titleContent: {
    color: theme.palette.primary.dark,
    fontSize: '30px',
    fontWeight: 800,
    lineHeight: '46.76px',
    width: '60%',
  },
  titleInput: {
    fontSize: '32px',
    fontWeight: 500,
    color: '#475467',
  },
  subTitleInput: {
    marginTop: '16px',
    width: '100%',
    '.MuiFormLabel-root': { zIndex: 0 },
  },
  editorContainer: {
    padding: '10px 24px 0 24px',
    border: '1px solid',
    borderColor: 'primary.border',
    borderRadius: '4px',
  },
  updatedAt: {
    color: 'primary.grey',
    position: 'absolute',
    top: '-12px',
    right: '12px',
    fontSize: '12px',
  },
};
export default styles;
