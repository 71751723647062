import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import Box from '../system/Box';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const CoacheeCardMini = ({ id, firstname, lastname, onClickDelete, sx = {}, isProgramCreation }) => {
  const name = `${firstname} ${lastname}`;
  const initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.toUpperCase();
  const bgcolor = stringToColor(name);
  return (
    <>
      {isProgramCreation ? (
        <Chip
          label={name}
          onDelete={onClickDelete ? () => onClickDelete(id) : undefined}
          color="info"
          sx={{ height: '36px', '& .MuiChip-avatar': { color: 'white' }, ...sx }}
          variant="outlined"
          avatar={<Avatar sx={{ bgcolor, color: 'white' }}>{initials}</Avatar>}
        />
      ) : (
        <Box>
          <Avatar
            sx={{
              maxWidth: '30px',
              maxHeight: '30px',
              fontSize: '14px',
              bgcolor,
              color: 'white',
              display: 'inline-block',
              lineHeight: '2',
              textAlign: 'center',
              mr: '3px',
              overflow: 'unset',
            }}>
            {initials}
          </Avatar>
          <Typography sx={{ color: 'primary.dark', display: 'inline-block', verticalAlign: 'middle' }}>
            {firstname} {lastname}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default CoacheeCardMini;
