/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */
import LanguageFilterOperators from 'ui/components/DataGrid/Filters/LanguageFilter/LanguageFilter';
import ScopeFilterOperators from 'ui/components/DataGrid/Filters/ScopeFilter/ScopeFilter';
import AlreadyPairedFilterOperators from 'ui/components/DataGrid/Filters/AlreadyPairedFilter/AlreadyPairedFilter';
import GenderFilter from 'ui/components/DataGrid/Filters/GenderFilter/GenderFilter';

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/
const tableSchemaBase = [
  { field: 'user', flex: 1, headerName: 'name', fontSize: '2px', type: 'user', minWidth: 250 },
  {
    field: 'scopeName',
    headerName: 'scope',
    type: 'smallText',
    width: 200,
    filterOperators: ScopeFilterOperators,
  },

  {
    field: 'gender',
    headerName: 'gender',
    type: 'gender',
    minWidth: 100,
    flex: 1,
    filterOperators: GenderFilter,
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'source',
    headerName: 'already_paired',
    filterOperators: AlreadyPairedFilterOperators,
    minWidth: 100,
    flex: 1,
    type: 'chip',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'languages',
    headerName: 'languages',
    type: 'languages',
    minWidth: 350,
    flex: 1,
    filterOperators: LanguageFilterOperators,
  },
];

const sampleDataBase = [...Array(10).keys()].map((i) => ({
  id: i + 1,
  userScope: null,
  user: {
    name: 'rami',
    title: 'Coaching professionnel - Experienced Management Consultant & Executive Coach',
    picture:
      'https://pathline-dev-bucket.s3.fr-par.scw.cloud/profile-pictures/a19c78e0-6695-11ed-b755-138e5076a13d.png',
  },
  registrationDate: '12 month ago',
  type: 'true',
  email: 'laurent_coach_dev@yopmail.com',
}));

export { tableSchemaBase, sampleDataBase };
