const validation = {
  firstname: { required: 'This field is required' },
  lastname: { required: 'This field is required' },
  email: {
    required: 'This field is required',
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Entered value does not match email format',
    },
  },
  scopes: { required: 'This field is required' },
  role: { required: 'This field is required' },
};

export default validation;
