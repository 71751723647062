export const LANGUAGES_OPTIONS = [
  { label: 'lang_af', value: 'AF' },
  { label: 'lang_sq', value: 'SQ' },
  { label: 'lang_am', value: 'AM' },
  { label: 'lang_ar', value: 'AR' },
  { label: 'lang_hy', value: 'HY' },
  { label: 'lang_as', value: 'AS' },
  { label: 'lang_az', value: 'AZ' },
  { label: 'lang_eu', value: 'EU' },
  { label: 'lang_be', value: 'BE' },
  { label: 'lang_bn', value: 'BN' },
  { label: 'lang_bs', value: 'BS' },
  { label: 'lang_bg', value: 'BG' },
  { label: 'lang_my', value: 'MY' },
  { label: 'lang_ca', value: 'CA' },
  { label: 'lang_ny', value: 'NY' },
  { label: 'lang_hr', value: 'HR' },
  { label: 'lang_cs', value: 'CS' },
  { label: 'lang_da', value: 'DA' },
  { label: 'lang_nl', value: 'NL' },
  { label: 'lang_en', value: 'EN' },
  { label: 'lang_et', value: 'ET' },
  { label: 'lang_tl', value: 'TL' },
  { label: 'lang_fi', value: 'FI' },
  { label: 'lang_fr', value: 'FR' },
  { label: 'lang_ff', value: 'FF' },
  { label: 'lang_gl', value: 'GL' },
  { label: 'lang_ka', value: 'KA' },
  { label: 'lang_de', value: 'DE' },
  { label: 'lang_el', value: 'EL' },
  { label: 'lang_gu', value: 'GU' },
  { label: 'lang_ht', value: 'HT' },
  { label: 'lang_ha', value: 'HA' },
  { label: 'lang_he', value: 'HE' },
  { label: 'lang_hi', value: 'HI' },
  { label: 'lang_hu', value: 'HU' },
  { label: 'lang_is', value: 'IS' },
  { label: 'lang_ig', value: 'IG' },
  { label: 'lang_id', value: 'ID' },
  { label: 'lang_it', value: 'IT' },
  { label: 'lang_ja', value: 'JA' },
  { label: 'lang_jv', value: 'JV' },
  { label: 'lang_kn', value: 'KN' },
  { label: 'lang_kk', value: 'KK' },
  { label: 'lang_km', value: 'KM' },
  { label: 'lang_rw', value: 'RW' },
  { label: 'lang_rn', value: 'RN' },
  { label: 'lang_kok', value: 'KOK' },
  { label: 'lang_ko', value: 'KO' },
  { label: 'lang_ku', value: 'KU' },
  { label: 'lang_ky', value: 'KY' },
  { label: 'lang_lo', value: 'LO' },
  { label: 'lang_lv', value: 'LV' },
  { label: 'lang_lt', value: 'LT' },
  { label: 'lang_lg', value: 'LG' },
  { label: 'lang_lb', value: 'LB' },
  { label: 'lang_mk', value: 'MK' },
  { label: 'lang_mai', value: 'MAI' },
  { label: 'lang_ms', value: 'MS' },
  { label: 'lang_ml', value: 'ML' },
  { label: 'lang_mt', value: 'MT' },
  { label: 'lang_zh', value: 'ZH' },
  { label: 'lang_mr', value: 'MR' },
  { label: 'lang_mn', value: 'MN' },
  { label: 'lang_ne', value: 'NE' },
  { label: 'lang_no', value: 'NO' },
  { label: 'lang_or', value: 'OR' },
  { label: 'lang_ps', value: 'PS' },
  { label: 'lang_fa', value: 'FA' },
  { label: 'lang_pl', value: 'PL' },
  { label: 'lang_pt', value: 'PT' },
  { label: 'lang_pa', value: 'PA' },
  { label: 'lang_qu', value: 'QU' },
  { label: 'lang_ro', value: 'RO' },
  { label: 'lang_ru', value: 'RU' },
  { label: 'lang_gd', value: 'GD' },
  { label: 'lang_sr', value: 'SR' },
  { label: 'lang_sn', value: 'SN' },
  { label: 'lang_sd', value: 'SD' },
  { label: 'lang_si', value: 'SI' },
  { label: 'lang_sk', value: 'SK' },
  { label: 'lang_sl', value: 'SL' },
  { label: 'lang_so', value: 'SO' },
  { label: 'lang_es', value: 'ES' },
  { label: 'lang_su', value: 'SU' },
  { label: 'lang_sw', value: 'SW' },
  { label: 'lang_sv', value: 'SV' },
  { label: 'lang_tg', value: 'TG' },
  { label: 'lang_uk', value: 'UK' },
];

export const languagesAccepted = ['fr', 'en', 'it', 'es', 'pt'];

export function srcLanguage(language) {
  return `${__dirname}uploads/icons/${language}.png`;
}

export function getLabelByValue(value) {
  const language = LANGUAGES_OPTIONS.find((option) => option.value === value);
  return language ? language.label : value;
}

export function getValueByLabel(label) {
  const language = LANGUAGES_OPTIONS.find((option) => option.label === label.toLowerCase());
  return language ? language.value : label;
}

export function getStringFromList(languages) {
  return languages.join(', ');
}
