const validation = {
  name: { required: 'This field is required' },
  order: {
    required: 'This field is required',
    pattern: {
      value: /^(?!0|-)[0-9]+$/,
      message: 'Entered value must be a positive order',
    },
  },
};

export default validation;
