const styles = {
  container: {
    borderRadius: '8px',
    padding: '5px 10px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '20px',
    fontFamily: 'Karla',
    fontWeight: 700,
    lineHeight: '23.38px',
    color: 'title.main',
  },
  link: {
    fontFamily: 'Karla',
    fontSize: '14px',
    lineHeight: '16.94px',
    fontWeight: 400,
    color: 'primary.main',
  },
  upload: {
    fontFamily: 'Karla',
    fontSize: '14px',
    lineHeight: '16.94px',
    fontWeight: 400,
    color: 'primary.main',
  },
  closeButton: {
    minWidth: '105px',
    color: 'primary.main',
    borderColor: 'primary.main',
  },
  saveButton: {
    minWidth: '105px',
    backgroundColor: 'primary.main',
  },
};
export default styles;
