const style = {
  title: {
    color: 'title.main',
    mb: '12px',
    mt: '25px',
    ml: '2%',
    textAlign: 'start',
    fontSize: '40px',
    fontWeight: 800,
    lineHeight: '46.76px',
  },
  step4: {
    container: {
      paddingLeft: '80px',
      paddingTop: '41px',
      height: '520px',
      overflowY: 'scroll',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '25px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
      },
    },
    title: {
      fontSize: '15px',
      fontWeight: 700,
      lineHeight: '17.54px',
    },
    text: {
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17.54px',
      paddingTop: '2px',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      margin: '7px 0px',
    },
    rowWithCopyBtn: {
      display: 'flex',
      alignItems: 'center',
      margin: '3px 0px',
    },
    rowCoacheeWithCopyBtn: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      backgroundColor: 'title.main',
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '18.7px',
      height: '33px',
      textTransform: 'none',
      minWidth: '231px',
    },
  },
  stepSuccess: {
    title: {
      fontSize: '40px',
      fontWeight: 800,
      lineHeight: '17.54px',
      color: 'title.main',
    },
    text: {
      fontSize: '15px',
      fontWeight: 400,
      lineHeight: '17.54px',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 0px',
      margin: '0px',
    },
    rowTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '50px 0px',
      margin: '0px',
    },
    rowContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px 0px',
      margin: '0px',
    },
    button: {
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '18.7px',
      height: '42px',
      minWidth: '192px',
    },
  },
};

export default style;
