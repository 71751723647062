import React from 'react';
import { Box, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { truncateString } from 'common/utils';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from 'ui/components/Button/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UserChip from 'ui/components/shared/UserChip/UserChip';
import { parseJsonTranslate } from 'utils/parse';

import styles from './CardProgram.styles';
import { MINIMUM, SHOW_MORE, SESSIONS } from 'litterals';

const imageNoData = `https://craftsnippets.com/articles_images/placeholder/placeholder.jpg`;

const CardProgram = ({
  active,
  title,
  subtitle,
  nbSessions,
  onSelect,
  onShowMoreInfo,
  picture,
  showSelectButton,
  selected = false,
  adminButtons = false,
  onEdit,
}) => {
  const { t } = useTranslation();

  /* ********************************** CONSTANTS ********************************* */

  const containerStyles = !selected ? styles.container : { ...styles.container, ...styles.containerSelected };

  const textContainerStyle = { display: 'flex', flexDirection: 'column', padding: '16px' };

  if (active === false) {
    textContainerStyle.backgroundColor = 'background.disabled';
  }

  const titleParsed = parseJsonTranslate(title);
  const subtitleParsed = parseJsonTranslate(subtitle);

  return (
    <Box
      onClick={() => {
        onSelect();
      }}
      sx={containerStyles}>
      <Box component="img" src={picture ? picture : imageNoData} sx={styles.picture} />

      <Box sx={textContainerStyle}>
        <Box sx={{ minHeight: '60px', marginBottom: '16px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Chip
              label={`${nbSessions} ${t(SESSIONS)} ${t(MINIMUM)}`}
              sx={{ color: '#B18225', backgroundColor: '#FEBA361F', fontSize: '13px', fontWeight: 400 }}
            />
            {active != null && (
              <UserChip
                sx={{ padding: '2px 8px' }}
                sxLabel={{ textTransform: 'capitalize' }}
                color={active ? 'green' : 'black'}
                label={active ? t('activated') : t('desactivated')}
              />
            )}
          </Box>

          <Tooltip title={titleParsed}>
            <Typography sx={styles.title}>{titleParsed}</Typography>
          </Tooltip>

          <Box sx={styles.subtitle}>{subtitleParsed}</Box>
        </Box>
        {!adminButtons && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {showSelectButton && (
              <Button
                onClick={(event) => {
                  event.stopPropagation();
                  onSelect();
                }}
                sx={{ width: '75px' }}
                variant="primary">
                {t('select')}
              </Button>
            )}
            <Button
              onClick={(event) => {
                event.stopPropagation();
                onShowMoreInfo();
              }}
              sx={showSelectButton ? {} : { margin: 'auto' }}
              variant="tertiary">
              {t(SHOW_MORE)}
            </Button>
          </Box>
        )}
        {adminButtons && (
          <Box sx={styles.containerButtons}>
            <Button
              size="xlg"
              greyWithHover
              sx={styles.adminButton}
              onClick={(event) => {
                event.stopPropagation();
                onShowMoreInfo();
              }}
              variant="secondary">
              <VisibilityOutlinedIcon sx={{ marginRight: '6px', fontSize: '20px' }} />
              {t('overview')}
            </Button>

            <Button
              size="xlg"
              sx={styles.adminButton}
              onClick={(event) => {
                event.stopPropagation();
                onEdit();
              }}
              variant="secondary">
              <EditOutlinedIcon sx={{ marginRight: '6px', fontSize: '20px' }} />
              {t('edit')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardProgram;
