import React from 'react';
import { Avatar, Box, Chip, Typography } from '@mui/material';
import Button from 'ui/components/Button/Button';
import { useTranslation } from 'react-i18next';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatSessionTime, formatAbbreviatedDate } from 'utils/sessions';
import useMediaQuery from '@mui/material/useMediaQuery';

const SummarySelfRegistration = ({ availability, coach, onModifyMyChoice }) => {
  const chipLabel = availability.duration == null ? '-' : `${availability.duration} min`;
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:400px)');

  return (
    <Box
      sx={{
        borderRadius: '8px',
        backgroundColor: '#f4f4f4',
        padding: '16px',
        border: '1px solid',
        borderColor: 'primary.border',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: 'center',
        gap: '8px',
      }}>
      <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={coach.picture}
          sx={{ flexDirection: 'row', display: 'flex', marginRight: '8px', border: '2px solid white' }}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: {
                xs: '13px',
                sm: '14px',
              },
              fontWeight: 600,
            }}>
            {`${coach.firstname} ${coach?.lastname?.toUpperCase()}`}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                xs: '13px',
                sm: '14px',
              },
              fontWeight: 500,
              color: 'primary.main',
            }}>
            {coach.title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: { xs: '8px', sm: '12px', md: '16px' },
            }}>
            <CalendarMonthIcon sx={{ color: 'primary.grey', marginRight: '4px' }} />
            <Typography
              sx={{
                fontSize: {
                  xs: '13px',
                  sm: '14px',
                },
                fontWeight: 400,
                textWrap: 'nowrap',
              }}>
              {formatAbbreviatedDate(availability.date, isMobile)}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginRight: { xs: '8px', sm: '12px', md: '16px' },
            }}>
            <AccessTimeIcon sx={{ color: 'primary.grey', marginRight: '4px' }} />
            <Typography
              sx={{
                fontSize: {
                  xs: '13px',
                  sm: '14px',
                },
                fontWeight: 400,
              }}>
              {formatSessionTime(availability.date)}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Chip
              sx={{
                height: '24px',
                borderColor: 'primary.grey',
                '.MuiChip-label': { paddingLeft: { xs: '8px', sm: '12px' }, paddingRight: { xs: '8px', sm: '12px' } },
              }}
              label={chipLabel}
              variant="outlined"
            />
          </Box>
        </Box>

        {onModifyMyChoice && (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '8px' }}>
            <Button sx={{ marginRight: '-10px' }} variant="tertiary" onClick={onModifyMyChoice}>
              {t('change_my_selection')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SummarySelfRegistration;
