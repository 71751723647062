import React from 'react';
import { Avatar, Card, Typography } from '@mui/material';
import Box from 'ui/components/system/Box';
import { iconList as IconList } from 'utils/icons';

const DetailCard = ({ src, color, nbr, scope, fullName }) => {
  return (
    <Box variant="flex" sx={{ justifyContent: 'space-between' }}>
      <Box variant="flex" sx={{ gap: '10px' }}>
        {src ? (
          <img
            src={src}
            alt={fullName}
            style={{
              width: '38px',
              height: '38px',
              borderRadius: '19px',
              border: `1px solid ${color}`,
              objectFit: 'cover',
            }}
          />
        ) : (
          <Avatar
            style={{
              width: '38px',
              height: '38px',
              borderRadius: '19px',
              border: `1px solid ${color}`,
            }}>
            <IconList.PersonIcon />
          </Avatar>
        )}
        <Box>
          <Typography fontWeight={500} fontSize={'18px'} lineHeight={'21px'}>
            {fullName}
          </Typography>

          <Typography color={color} fontSize={'14px'} fontWeight={400} lineHeight={'16px'}>
            {scope}
          </Typography>
        </Box>
      </Box>
      <Typography fontWeight={400} fontSize={'20px'} lineHeight={'23px'}>
        {nbr} sessions
      </Typography>
    </Box>
  );
};

const GroupStaticCard = ({ title, list, color }) => {
  return (
    <Card
      sx={{
        width: '382px',
        height: '268px',
        boxShadow: '0px 4px 4px 0px rgb(0 0 0 / 50%)',
        padding: '12px',
        boxSizing: 'border-box',
        borderRadius: '14px',
        // marginTop: '12px',
      }}>
      <Typography fontSize={'18px'} fontWeight={'800'} lineHeight={'21px'} color={'#11142D'}>
        {title}
      </Typography>
      <Box variant="flexColumn" sx={{ height: 'calc(100% - 36px)', marginTop: '12px', gap: '30px', overflowY: 'auto' }}>
        {list?.map((item, idx) => (
          <DetailCard {...item} key={idx} color={!!color ? color : idx === 0 ? '#1407C0' : '#FFA900'} />
        ))}
      </Box>
    </Card>
  );
};

export default GroupStaticCard;
