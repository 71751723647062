import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmailField from 'ui/components/form/EmailField';
import { useResetPasswordSendEmail } from 'hooks/usecase/useLogin';
import { Button } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from 'react-i18next';
import { BACK_TO_LOG_IN, EMAIL, EMAIL_NEW_PASSWORD, FORGET_PASSWORD } from 'litterals';
import SuccessResetLink from '../password/SuccessResetLink';

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  marginTop: '12px',
  // gap: "24px"
};

const styleInput = {
  margin: '12px',
};

const ResetPasswordForm = ({ onClickLogin }) => {
  const { state, actions } = useResetPasswordSendEmail();
  const { t } = useTranslation();
  const handleNavigate = () => {
    onClickLogin();
    actions.onResetSuccessUI();
  };

  return !state.nav.resetSuccess ? (
    <Box component="form" sx={styleBox}>
      <Typography
        component="h3"
        sx={{
          color: 'title.main',
          fontSize: '30px',
          lineHeight: '52px',
          paddingInline: '12px',
          marginBlock: '12px',
          fontWeight: '700',
        }}>
        {t(FORGET_PASSWORD)}
      </Typography>
      <Typography
        component="h4"
        sx={{
          color: 'primary.grey',
          fontSize: '16px',
          lineHeight: '24px',
          letter: '0.15px',
          paddingInline: '12px',
          marginBlock: '12px',
        }}>
        {t(EMAIL_NEW_PASSWORD)}
      </Typography>
      <Typography
        sx={{
          marginInline: '12px',
          marginTop: '12px',
          color: 'primary.main',
          lineHeight: '12px',
          fontSize: '14px',
        }}>
        {t(EMAIL)}
      </Typography>
      <EmailField
        required
        name="email"
        label=""
        placeholder="Enter Email"
        control={state.form.control}
        style={styleInput}
        disabled={state.nav.resetSuccess}
      />
      <Button
        type="submit"
        variant="contained"
        onClick={actions.onResetPasswordSendEmail}
        sx={{ m: '12px', height: '64px', backgroundColor: 'primary.main', fontWeight: 400 }}>
        {t('get_reset_link')}
      </Button>
      <Button
        type="submit"
        color="primary"
        variant="outlined"
        onClick={onClickLogin}
        sx={{ m: '12px', color: 'primary.main', borderColor: 'primary.main', height: '64px' }}>
        <ArrowBackIosIcon fontSize="16px" />
        {t(BACK_TO_LOG_IN)}
      </Button>
    </Box>
  ) : (
    <SuccessResetLink t={t} onClick={handleNavigate} />
  );
};

export default ResetPasswordForm;
