import React from 'react';

export const Case = ({ value, validate, switchValue, children }) => {
  if (value !== undefined && value === switchValue) return <>{children}</>;
  if (validate !== undefined && validate(switchValue)) return <>{children}</>;
  return null;
};

export const Switch = ({ value, children }) => {
  const cases = children.map((child, index) =>
    React.cloneElement(child, {
      ...child.props,
      switchValue: value,
      key: (Date.now() * index).toString(),
    })
  );
  return <>{cases}</>;
};
