/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, { useState, useRef } from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from 'ui/components/Switch';
import FormLabel from '@mui/material/FormLabel';
import Tooltip from '@mui/material/Tooltip';

// UI Local components
import TabNavigation from 'ui/components/TabNavigation';
import TitleInput from 'ui/components/TitleInput';
import GeneralInfos from './components/GeneralInfos/GeneralInfos';
import ContentTheme from './components/ContentTheme';
import SessionTemplates from './components/SessionTemplates';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './CreateTheme.styles';

// Configs
import { TITLE } from 'litterals';
import { useTranslation } from 'react-i18next';
import { truncateString } from 'common/utils';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const CreateTheme = ({
  control,
  onAddTheme,
  reset,
  watch,
  submitEditMarketingPicture,
  submitEditBackgroundPicture,
  selectedTab,
  setSelectedTab,
  companyId,
  tabOptions,
  setTabOptions,
  handleClose,
  themeAdded,
  user,
  ...props
}) => {
  /* ********************************** STATES ********************************* */
  // eslint-disable-next-line no-unused-vars
  const [showThemeContent, setShowThemeContent] = useState(false);

  /* ********************************** REFS ********************************* */
  const refName = useRef();
  const refSwitch = useRef();

  /* ********************************** HOOKS ********************************* */
  const { t } = useTranslation();

  /* ********************************** CONSTANTS ********************************* */
  const IS_ACTIVE = watch('active');
  /* ********************************  HELPERS ******************************* */

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return (
          <GeneralInfos
            submitEditBackgroundPicture={submitEditBackgroundPicture}
            submitEditMarketingPicture={submitEditMarketingPicture}
            control={control}
            watch={watch}
            reset={reset}
            setSelectedTab={setSelectedTab}
            selectedTab={selectedTab}
          />
        );
      case 'tab-2':
        return (
          <SessionTemplates user={user} themeId={themeAdded.id} companyId={companyId} watch={watch} control={control} />
        );
      default:
        return null;
    }
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <Dialog disableEnforceFocus PaperProps={{ sx: styles.container }} open={props.isOpen} onClose={handleClose}>
      <DialogTitle sx={styles.containerTitle}>
        <Tooltip title={watch('name')}>
          <Typography style={styles.title}>{'Create a new Theme ' + truncateString(`${watch('name')}`, 21)}</Typography>
        </Tooltip>
      </DialogTitle>

      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Box sx={styles.containerHeader}>
          <TitleInput
            placeholder={t(TITLE)}
            disabled={false}
            exportTitle={() => {}}
            control={control}
            actions={() => {}}
            rules={{ required: 'This field is required' }}
            session={null}
            ref={refName}
            name="name"
            sx={styles.titleContent}
            inputProps={{ style: styles.titleContent }}
          />
          <Switch ref={refSwitch} control={control} variant="outlined" label="active" name="active" fullWidth />
          <FormLabel
            component="legend"
            sx={[styles.labelSwitch, IS_ACTIVE ? styles.labelSwitchSuccess : styles.labelSwitchError]}>
            {IS_ACTIVE ? 'Active' : 'Inactive'}
          </FormLabel>
        </Box>
        <>
          {showThemeContent ? (
            <ContentTheme control={control} />
          ) : (
            <>
              <Box sx={styles.containerTabNavigation}>
                <TabNavigation
                  options={tabOptions}
                  onChange={(tab) => {
                    setSelectedTab(tab);
                    const newOptions = tabOptions.map((item) => {
                      if (item.id === tab) {
                        item.selected = true;
                      } else {
                        item.selected = false;
                      }
                      return item;
                    });
                    setTabOptions(newOptions);
                  }}
                />
              </Box>
              {renderComponent()}
            </>
          )}
        </>
      </DialogContent>
      <DialogActions sx={styles.btnBack}>
        <Button variant="outlined" onClick={handleClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={styles.btnSave}
          onClick={() => {
            onAddTheme();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTheme;
