const COACHEE_PREVIEW_EMAIL_TEMPLATE_NAME = 'COACHEE_INVITATION';
const COACH_PREVIEW_EMAIL_TEMPLATE_NAME = 'EXPERT_CHOSEN_FOR_PROGRAM';
const LEADER_PREVIEW_EMAIL_TEMPLATE_NAME = 'LEADER_NEW_PROGRAM_LAUNCHED';
const PROGRAM_DEACTIVATION_EMAIL_TEMPLATE_NAME = 'PROGRAM_DEACTIVATION';
const PROGRAM_REACTIVATION_EMAIL_TEMPLATE_NAME = 'PROGRAM_REACTIVATION';

export {
  COACHEE_PREVIEW_EMAIL_TEMPLATE_NAME,
  COACH_PREVIEW_EMAIL_TEMPLATE_NAME,
  LEADER_PREVIEW_EMAIL_TEMPLATE_NAME,
  PROGRAM_DEACTIVATION_EMAIL_TEMPLATE_NAME,
  PROGRAM_REACTIVATION_EMAIL_TEMPLATE_NAME,
};
