import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useLogin from 'hooks/usecase/useLogin';
import AuthLayout from '../login/AuthLayout';

const MicrosoftRedirect = () => {
  const { type } = useParams();
  const { actions } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (type.match(/^([a-z]|\d)+$/) !== null) {
      actions.onSubmitMicrosoft(type);
    } else {
      navigate('/');
    }
  }, [type]);

  return <AuthLayout></AuthLayout>;
};

export default MicrosoftRedirect;
