import React, { useEffect, useMemo, useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import styles from './AvatarEdit.styles';

export default function AvatarEdit({
  src,
  height = '120px',
  width = '120px',
  onClickUploader,
  onDeleteAvatar,
  firstname,
  lastname,
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div style={styles.container} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Avatar
        src={src}
        sx={{ width, height, textTransform: 'capitalize', fontSize: `${width.replace('px', '') / 2}px` }}
        alt={`${firstname} ${lastname}`}>
        {!src ? `${firstname?.[0]}${lastname?.[0]}` : ''}
      </Avatar>
      {isHovered && (
        <div style={{ ...styles.hover, height, width }}>
          <CropOriginalIcon sx={styles.iconAddPhoto} onClick={onClickUploader} />
          {src && <DeleteOutlineOutlinedIcon sx={styles.iconDeletePhoto} onClick={onDeleteAvatar} />}
        </div>
      )}
    </div>
  );
}
