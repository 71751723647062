import React from 'react';

import { Box, Stack } from '@mui/material';
import EditorExercise from './components/EditorExercise/EditorExercise';
import { useParams } from 'react-router-dom';

const EditorExercisePage = () => {
  const { activityId, exerciseId } = useParams();

  return (
    <Stack sx={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '54px', paddingRight: '54px' }}>
      <Box sx={{ backgroundColor: 'white', marginRight: '55px' }}>
        <Box sx={{ padding: '32px', background: 'white', width: '100%' }}>
          <EditorExercise exerciseId={exerciseId} activityId={activityId} />
        </Box>
      </Box>
    </Stack>
  );
};

export default EditorExercisePage;
