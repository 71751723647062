/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Stack from '@mui/material/Stack';
import { Switch } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useAlert } from 'common/alertContext';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellActionsMainCompanyAdmin({ isMainCompanyAdmin, onChange }) {
  /* ********************************** HOOKS ********************************* */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  /* ********************************** FUNCTIONS ********************************* */

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack onClick={(e) => e.stopPropagation()}>
      <Switch
        checked={isMainCompanyAdmin}
        onChange={(e) => {
          onChange({ activated: e.target.checked });
        }}
      />
    </Stack>
  );
}

export default CellActionsMainCompanyAdmin;
