/**
 * Enumeration of predefined roles.
 * @enum {string}
 */
const Roles = {
  RH: 'RH',
  RH_MANAGER: 'RH_MANAGER',
  COACH: 'COACH',
  COACHEE: 'COACHEE',
  ADMIN: 'ADMIN',
};

/**
 * Checks if the given role list contains any administrative roles.
 *
 * @param {string[]} roleList - The list of roles to check.
 * @returns {boolean} True if the role list contains RH or RH_MANAGER, otherwise false.
 */
function hasAdminRole(roleList) {
  /**
   * Checks if a role in the list is RH or RH_MANAGER.
   *
   * @param {string} role - The role to check.
   * @returns {boolean} True if the role is RH or RH_MANAGER, otherwise false.
   */
  const isAdminRole = (role) => role === Roles.RH || role === Roles.RH_MANAGER;

  const result = roleList.some(isAdminRole);
  return result;
}

export const ROLES_OPTIONS = [
  { label: 'SUPER ADMIN', value: 'RH_MANAGER' },
  { label: 'ADMIN', value: 'RH' },
  { label: 'COACH', value: 'COACH' },
  { label: 'COACHEE', value: 'COACHEE' },
];

export const ROLES_OPTIONS_ADMIN = [
  { label: 'SUPER ADMIN', value: 'RH_MANAGER' },
  { label: 'ADMIN', value: 'RH' },
];

export function getLabelByValue(value) {
  const language = ROLES_OPTIONS.find((option) => option.value === value);
  return language ? language.label : value;
}

export function getValueByLabel(label) {
  const language = ROLES_OPTIONS.find((option) => option.label === label.toLowerCase());
  return language ? language.value : label;
}

export function getStringFromList(languages) {
  return languages.join(', ');
}

export { Roles, hasAdminRole };
