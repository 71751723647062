const styles = {
  container: {
    padding: '0 24px 50px 24px',
  },
  themeContainer: {
    borderRadius: '8px',
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '135px',
    border: '1px solid',
    marginBottom: '48px',
    borderColor: 'primary.border',
  },
  footer: {
    height: '72px',
    display: 'flex',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ffffffe6',
  },
  selectingMentorTitle: {
    fontWeight: 500,
    fontSize: { md: '24px', sx: '20px' },
    marginBottom: '16px',
  },
  selectingMentorDesc: {
    fontSize: { md: '16px', sx: '14px' },
    color: 'primary.grey',
    marginBottom: '16px',
  },
  selectingMentorContainer: {
    padding: { md: '10px 40px', sx: '10px 10px' },
    marginBottom: '20px',
  },
  coachesList: {
    display: 'flex',
    gap: '24px',
    width: 'max-content',
  },
  coachesListContainer: {
    overflowX: 'scroll',
    paddingBottom: '14px',
  },
  poweredByContainer: {
    textAlign: 'center',
    marginBottom: '70px',
  },
  directionArrow: {
    minWidth: '32px !important',
    height: '32px !important',
    padding: '0  !important',
    color: '#475467',
    borderColor: 'primary.border',
  },
  selectMentorButton: {
    minWidth: {
      sm: '400px !important',
      xs: '95% !important',
    },
    maxWidth: '400px',
  },
};
export default styles;
