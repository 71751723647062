import React from 'react';
import ImageCard from 'ui/components/shared/ImageCard';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from 'ui/components/system/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';
import CoachCard from 'ui/components/shared/coaches/CoachCard';
import CoachViewModalWithRequest from 'ui/components/shared/coaches/CoachViewModalWithRequest';
import useViewModal from 'hooks/shared/useViewModal';
import { useTranslation } from 'react-i18next';
import { parseJsonTranslate } from 'utils/parse';

import {
  COACHEE_ONBOARDING_STEP1_TEXT,
  COACHEE_ONBOARDING_STEP2_SUBTEXT,
  COACHEE_ONBOARDING_STEP2_TEXT,
  COACHEE_ONBOARDING_STEP3_SUBTEXT,
  COACHEE_ONBOARDING_STEP3_TEXT1,
  COACHEE_ONBOARDING_STEP3_TEXT2,
} from 'litterals';

export const ThemeDiscovery = ({ theme }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  if (!theme) return;
  return (
    <Container
      sx={{
        alignItems: 'center',
        justifyContent: 'start',
        display: 'flex',
        flexDirection: 'column',
        height: '65%',
      }}>
      <Typography variant="h6" component="h1" sx={{ mb: '36px' }}>
        {t(COACHEE_ONBOARDING_STEP1_TEXT)}
      </Typography>
      <ImageCard
        title={parseJsonTranslate(theme.name)}
        sxCard={{ height: 'initial', margin: '12px' }}
        src={theme.marketingPicture}
        onClick={() => setOpen(true)}>
        <IconButton onClick={() => setOpen(true)}>
          <VisibilityIcon />
        </IconButton>
      </ImageCard>
      <ThemeViewModal themeId={theme.id} open={open} onClose={() => setOpen(false)} />
    </Container>
  );
};

export const ChooseCoach = ({ coaches, company, onSelect, value }) => {
  const { t } = useTranslation();
  const { state, actions } = useViewModal();
  return (
    <Container
      sx={{
        alignItems: 'center',
        justifyContent: 'start',
        display: 'flex',
        flexDirection: 'column',
        height: '65%',
      }}>
      <Box sx={{ mb: '36px' }}>
        <Typography variant="h6" component="h1">
          {t(COACHEE_ONBOARDING_STEP2_TEXT)}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" fontStyle="italic">
          {t(COACHEE_ONBOARDING_STEP2_SUBTEXT)}
        </Typography>
      </Box>
      <Stack direction="row" justifyContent="center" spacing={3}>
        {coaches?.map((coach) => (
          <CoachCard
            {...coach}
            {...coach.user}
            company={company}
            key={`choose-coache-card-${coach.id}`}
            sx={value.id === coach.id ? { border: 'solid 2px', borderColor: '#fec75e', boxShadow: 'none' } : {}}
            onClickCard={() => onSelect(coach)}
            onClickMoreOverride={() => actions.openModal(coach.id)}
            fieldsNotShown={['price']}
          />
        ))}
      </Stack>
      <CoachViewModalWithRequest coachId={state.viewId} open={state.open} onClose={actions.closeModal} />
    </Container>
  );
};

export const ValidateCoach = ({ coach }) => {
  const { t } = useTranslation();
  const { state, actions } = useViewModal();
  return (
    <Container
      sx={{
        alignItems: 'center',
        justifyContent: 'start',
        display: 'flex',
        flexDirection: 'column',
        height: '65%',
      }}>
      <Box sx={{ mb: '36px' }}>
        <Typography variant="h6" component="h1">
          {`${t(COACHEE_ONBOARDING_STEP3_TEXT1)} ${coach && coach.user && coach.user.firstname} ${t(
            COACHEE_ONBOARDING_STEP3_TEXT2
          )}`}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary" fontStyle="italic">
          {t(COACHEE_ONBOARDING_STEP3_SUBTEXT)}
        </Typography>
      </Box>
      <CoachCard
        {...coach}
        {...coach.user}
        key={`choose-coache-card-${coach.id}`}
        sx={{ border: 'solid 2px', borderColor: '#fec75e', boxShadow: 'none' }}
        onClickMoreOverride={() => actions.openModal(coach.id)}
        fieldsNotShown={['price']}
      />
      <CoachViewModalWithRequest coachId={state.viewId} open={state.open} onClose={actions.closeModal} />
    </Container>
  );
};
