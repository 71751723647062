import { get, put, post, del } from './request';

class Session_Notes {
  static get(id) {
    return get(`/session_notes/${id}`).then((res) => res.data);
  }

  static getBySessionId(id) {
    return get(`/session_notes/session/${id}`).then((res) => res.data);
  }

  static edit({ id, payload }) {
    return put(`/session_notes/${id}`, payload).then((res) => res.data);
  }

  static create(payload) {
    return post('/session_notes', payload).then((res) => res.data);
  }

  static delete(id) {
    return del('/session_notes/' + id).then((res) => res.data);
  }
}

export default Session_Notes;
