import React from 'react';
import Box from 'ui/components/system/Box';
import { Stack, Typography } from '@mui/material';
import Button from 'ui/components/Button/Button';
import { useTranslation } from 'react-i18next';

const NoMobileAccessPage = () => {
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'scroll',
      }}>
      <Box
        alt="logo-pathline"
        component="img"
        sx={{
          margin: '20px auto 0 auto',
          weight: {
            xs: '140px',
            sm: '160px',
            md: '200px',
          },
        }}
        src={`${__dirname}uploads/images/Pathline-logo-subtitle.svg`}
      />

      <Box
        alt="mobile-access"
        component="img"
        sx={{
          margin: '24px auto 32px auto',
        }}
        src={`${__dirname}uploads/images/mobile-access.svg`}
      />

      <Box>
        <Typography
          sx={{
            color: 'black',
            fontSize: '24px',
            maxWidth: '220px',
            margin: 'auto',
            lineHeight: '175%',
            fontWeight: 800,
          }}>
          {t('no-mobile-access-page.title')}
        </Typography>
        <Box
          alt="oups"
          component="img"
          sx={{
            margin: '0 auto',
          }}
          src={`${__dirname}uploads/images/oups.svg`}
        />
      </Box>

      <Typography
        sx={{
          color: 'primary.grey',
          fontSize: '18px',
          maxWidth: '320px',
          margin: 'auto',
          padding: '24px 12px',
          lineHeight: '175%',
        }}>
        {t('no-mobile-access-page.desc')}
      </Typography>

      <Button
        href="https://www.pathline.fr/"
        sx={{
          margin: 'auto',
          padding: '26px 40px',
          borderRadius: '40px',
          fontWeight: 700,
          fontSize: '16px',
          marginBottom: '12px',
        }}>
        {t('no-mobile-access-page.button')}
      </Button>
    </Stack>
  );
};

export default NoMobileAccessPage;
