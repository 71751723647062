import React from 'react';
import MUIIconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const IconButton = ({ onClick, icon, tooltip, sx = {}, children, ...rest }) => {
  if (tooltip === undefined)
    return (
      <MUIIconButton sx={sx} onClick={onClick} {...rest}>
        {icon || children}
      </MUIIconButton>
    );
  return (
    <Tooltip title={tooltip} sx={{ ...sx }}>
      <MUIIconButton onClick={onClick} {...rest}>
        {icon || children}
      </MUIIconButton>
    </Tooltip>
  );
};

export default IconButton;
