import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { MESSAGE_SUCCESS_CHANGE_PASSWORD, MESSAGE_SUCCESS_SAVE_PASSWORD, INVALID_CREDENTIALS_MESSAGE } from 'litterals';
import { useTranslation } from 'react-i18next';
import { useMeQuery } from 'hooks/queries';
import { onErrorPassword } from 'utils/password';

export function useResetPasswordSendEmail() {
  const { handleSubmit, control, setError, reset } = useForm({
    mode: 'all',
    defaultValues: { email: '' },
  });

  const [resetSuccess, setResetSuccess] = useState(false);

  const mutation = useMutation(api.Auth.resetPasswordSendEmail, {
    onSuccess: () => {
      setResetSuccess(true);
      reset();
    },
    onError: (error) => setError('email', { type: 'custom', message: error.response.data.error }),
  });

  const onResetSuccessUI = () => {
    setResetSuccess(false);
  };

  const onResetPasswordSendEmail = handleSubmit(({ email }) => mutation.mutate(email));

  const onSubmitResetPasswordSendEmail = (email) => {
    mutation.mutate(email);
  };

  return {
    state: { form: { control: control }, nav: { resetSuccess } },
    actions: { onSubmitResetPasswordSendEmail, onResetPasswordSendEmail, onResetSuccessUI },
  };
}

export default function (activeRedirection = true) {
  const [displayLogin, setDisplayLogin] = useState(true);
  const [isEmailCompany, setIsEmailCompany] = useState(null);
  const [company, setCompany] = useState({});
  const defaultValues = { email: '', password: '' };
  const { handleSubmit, control, setError, getValues, watch } = useForm({ mode: 'all', defaultValues });
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data: user } = useMeQuery({
    enabled: !!localStorage.getItem('REACT_TOKEN_AUTH') && !!Object.keys(company).length,
  });

  if (activeRedirection) {
    localStorage.removeItem('REDIRECT_AUTH');
  }

  useEffect(() => {
    if (user?.company) {
      setCompany(user.company);
    }
  }, [user]);

  const navigate = useNavigate();

  const mutation = useMutation(api.Auth.login, {
    onSuccess: async (data) => {
      await localStorage.setItem('REACT_TOKEN_AUTH', data.token);
      await localStorage.setItem('REACT_REFRESH_TOKEN_AUTH', data.refreshToken);
      // Avoid any caching data issues
      await queryClient.invalidateQueries();
      await queryClient.setQueryData(['get-me'], data.user);

      const redirectAuth = localStorage.getItem('REDIRECT_AUTH');
      if (redirectAuth) {
        setCompany(data.user.company);
        localStorage.removeItem('REDIRECT_AUTH');
        window.location = redirectAuth;
      } else {
        if (data.user.acceptCompanyTerms) return navigate('/home');
        setCompany(data.user.company);
      }
    },
    onError: () => {
      setError('email', { type: 'custom' });
      setError('password', { type: 'custom', message: t(INVALID_CREDENTIALS_MESSAGE) });
    },
  });

  // https://tanstack.com/query/v4/docs/guides/optimistic-updates
  const updateMeMutation = useMutation(api.Users.updateMe, {
    onSuccess: async () => {
      return navigate('/home');
    },
    onMutate: async () => {
      await queryClient.cancelQueries(['get-me']);
      const previousMe = queryClient.getQueryData(['get-me']);
      queryClient.setQueryData(['get-me'], (prev) => ({ ...prev, acceptCompanyTerms: true }));
      return { previousMe };
    },
    onError: (_err, _newMe, context) => queryClient.setQueryData(['get-me'], context.previousMe),
    onSettled: () => queryClient.invalidateQueries(['get-me']),
  });

  const { setStateAlert } = useAlert();

  const createPasswordMutation = useMutation(api.Users.createPassword, {
    onSuccess: async (data, { token }) => {
      localStorage.setItem('REACT_TOKEN_AUTH', token);
      localStorage.setItem('REACT_REFRESH_TOKEN_AUTH', data.refreshToken);
      await queryClient.setQueryData(['get-me'], data.user);
      navigate('/');
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_SAVE_PASSWORD), type: 'success' });
    },
  });
  const updatePasswordMutation = useMutation(api.Users.createPassword, {
    onSuccess: async (data, { token }) => {
      localStorage.setItem('REACT_TOKEN_AUTH', token);
      localStorage.setItem('REACT_REFRESH_TOKEN_AUTH', data.refreshToken);
      await queryClient.setQueryData(['get-me'], data.user);
      navigate('/');
      setStateAlert({ open: true, message: t(MESSAGE_SUCCESS_CHANGE_PASSWORD), type: 'success' });
    },
  });

  const submit = handleSubmit((data) => mutation.mutate(data));

  const onSubmitMicrosoftWithToken = async (token, type = 'default') => {
    const newToken = await api.Users.tokenMicrosoftAuth(token);
    localStorage.setItem('MICROSOFT_TOKEN_AUTH', newToken);
    await api.Auth.microsoftAuth(type);
  };

  const onSubmitCompany = async (event) => {
    const email = getValues('email');

    if (!email) {
      return;
    }

    api.Auth.authCompany(email)
      .then(({ company, type }) => {
        if (company && type) {
          if (type === 'microsoft') return api.Auth.microsoftAuth(company);
          if (type === 'saml') return api.Auth.startSaml(company);
        }
      })
      .catch(() => {
        setIsEmailCompany(false);
      });
  };

  const onSubmitSamlWithToken = async (token, type) => {
    const newToken = await api.Users.tokenSamlAuth(token);
    localStorage.setItem('SAML_TOKEN_AUTH', newToken);
    await api.Auth.startSaml(type);
  };

  const onSubmitMicrosoft = async (type = 'default') => {
    await api.Auth.microsoftAuth(type);
  };

  const onStartSaml = async (type = 'default') => {
    await api.Auth.startSaml(type);
  };

  const setRedirection = async (url) => {
    await localStorage.setItem('REDIRECT_AUTH', url || window.location.href);
  };

  const acceptTerms = () => updateMeMutation.mutate({ acceptCompanyTerms: true });
  const showLoggin = () => setDisplayLogin(true);
  const hideLoggin = () => setDisplayLogin(false);
  const onLogout = async () => {
    await api.Users.logout();
    queryClient.clear();
    localStorage.removeItem('REACT_TOKEN_AUTH');
    localStorage.removeItem('REACT_REFRESH_TOKEN_AUTH');
    navigate('/login');
  };
  const onCreatePassword = ({ newPassword, token, setError }) => {
    return createPasswordMutation.mutate(
      { newPassword, token },
      {
        onError: (error) => {
          onErrorPassword(error, 'password', setError, t);
        },
      }
    );
  };

  const onUpdatePassword = ({ newPassword, token, setError }) => {
    return updatePasswordMutation.mutate(
      { newPassword, token },
      {
        onError: (error) => {
          onErrorPassword(error, 'password', setError, t);
        },
      }
    );
  };

  return {
    state: {
      form: { control, isEmailCompany },
      nav: {
        displayLogin,
        company,
      },
    },
    actions: {
      watch,
      setRedirection,
      submit,
      acceptTerms,
      showLoggin,
      hideLoggin,
      onLogout,
      onCreatePassword,
      onUpdatePassword,
      onSubmitCompany,
      onSubmitMicrosoft,
      onSubmitMicrosoftWithToken,
      onSubmitSamlWithToken,
      onStartSaml,
    },
  };
}
