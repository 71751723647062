import { get, put, post, del } from './request';

class CoachAvailability {
  static get(coachId, isFull = false) {
    return get(`/coaches/${coachId}/availabilities`, { isFull }).then((res) => res.data);
  }

  static edit({ coachId, availabilityId, payload }) {
    return put(`/coaches/${coachId}/availability/${availabilityId}`, payload).then((res) => res.data);
  }

  static create({ coachId, payload }) {
    return post(`/coaches/${coachId}/availability`, payload).then((res) => res.data);
  }

  static delete({ coachId, availabilityId }) {
    return del(`/coaches/${coachId}/availability/${availabilityId}`).then((res) => res.data);
  }
}

export default CoachAvailability;
