import React, { useState, useRef } from 'react';

// UI Lib dependencies
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import TabNavigation from 'ui/pages/program/components/TabNavigation';

import { useParams } from 'react-router-dom';

import Experts from './components/Experts';
import Coachees from './components/Coachees';
import Admins from './components/Admins';

const Users = ({ user }) => {
  // States
  const [selectedTab, setSelectedTab] = useState('tab-1');

  let { companyId } = useParams();
  // Hook

  const getOptionsTab = () => {
    return [
      { id: 'tab-1', text: 'EXPERTS', selected: true },
      { id: 'tab-2', text: 'COACHEES', selected: false },
      { id: 'tab-3', text: 'ADMIN', selected: false },
    ];
  };

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return <Experts companyId={companyId} user={user} />;
      case 'tab-2':
        return <Coachees companyId={companyId} />;
      case 'tab-3':
        return <Admins companyId={companyId} />;
      default:
        return null;
    }
  };

  return (
    <Stack sx={{ minHeight: '630px', backgroundColor: 'white' }}>
      <Box sx={{ paddingLeft: '90px' }}>
        <TabNavigation
          options={getOptionsTab()}
          onChange={(tab) => {
            setSelectedTab(tab);
          }}
        />
        {renderComponent()}
      </Box>
    </Stack>
  );
};

export default Users;
