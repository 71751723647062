import React from 'react';
import { Box } from '@mui/material';
import { LOGIN_MICROSOFT } from 'litterals';
import Button from 'ui/components/Button/Button';
import { useTranslation } from 'react-i18next';

export default function Switch({ onSubmitMicrosoft, label }) {
  const { t } = useTranslation();

  const microsoftLogo = `${process.env.PUBLIC_URL}/uploads/icons/microsoft-logo.svg`;

  return (
    <Button
      variant="secondary"
      onClick={onSubmitMicrosoft}
      sx={{
        m: '12px',
        color: '#5E5E5E',
        width: 'fill-available',
        borderColor: '#D0D5DD',
      }}>
      <Box
        sx={{
          mr: '12px',
        }}
        component="img"
        src={microsoftLogo}
      />
      {label || t(LOGIN_MICROSOFT)}
    </Button>
  );
}
