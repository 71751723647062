/* -------------------------------------------------------------------------- */
/*                                Configuration                                 */
/* -------------------------------------------------------------------------- */

/**
Table schema configuration for the module
@type {TableSchemaBase}
*/
import ScopeFilterOperators from 'ui/components/DataGrid/Filters/ScopeFilter/ScopeFilter';
import DateFilterOperators from 'ui/components/DataGrid/Filters/DateFilter/DateFilter';

const tableSchemaBase = [
  { field: 'user', flex: 1, resizable: true, headerName: 'name', fontSize: '2px', type: 'user', minWidth: 250 },
  {
    field: 'scopeName',
    headerName: 'scope',
    type: 'text',
    minWidth: 250,
    filterOperators: ScopeFilterOperators,
    resizable: true,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'registration_date',
    filterOperators: DateFilterOperators,
    type: 'text',
    minWidth: 180,
    resizable: true,
    flex: 1,
  },
  {
    field: 'actions',
    headerName: 'actions',
    sortable: false,
    minWidth: 120,
    filter: false,
    filterable: false,
    valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    type: 'actionsAdmins',
    headerAlign: 'center',
    align: 'center',
    flex: 1,

    resizable: true,
  },
];

const sampleDataBase = [...Array(10).keys()].map((i) => ({
  id: i + 1,
  userScope: null,
  user: {
    name: 'rami',
    title: 'Coaching professionnel - Experienced Management Consultant & Executive Coach',
    picture:
      'https://pathline-dev-bucket.s3.fr-par.scw.cloud/profile-pictures/a19c78e0-6695-11ed-b755-138e5076a13d.png',
  },
  registrationDate: '12 month ago',
  type: 'true',
  email: 'laurent_coach_dev@yopmail.com',
}));

export { tableSchemaBase, sampleDataBase };
