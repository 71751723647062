import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { useListFormSettings } from 'hooks/queries';

const useFormSettings = (id, queryParams) => {
  const queryClient = useQueryClient();
  const { data: formSettingsList, isLoading } = useListFormSettings({ id, queryParams });
  const { handleSubmit, reset } = useForm();

  const { setStateAlert } = useAlert();

  const mutationEdit = useMutation(api.FormSettings.updateFilds, {
    onSuccess: (data) => {
      // Invalidate the query to refetch the updated data
      queryClient.invalidateQueries('formSettingsList');
    },
    onError: (error) =>
      setStateAlert({
        open: true,
        message: `${error.response?.data?.error || t('common.genericError')}`,
        type: 'error',
      }),
  });

  const onEdit = async ({ id, isActivated }) => {
    await mutationEdit.mutate({ id, data: { isActivated: isActivated } });
  };

  return {
    isLoading,
    formSettingsList,
    onEdit,
  };
};

export default useFormSettings;
