import React from 'react';

import { Box, Typography } from '@mui/material';

import styles from './ContainerEmpty.styles';

const ContainerEmpty = ({ message, children }) => {
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.message}>{message}</Typography>
      {children}
    </Box>
  );
};

export default ContainerEmpty;
