import React from 'react';

import styles from './Switch.styles';

import { Switch as NativeSwitch } from '@mui/material';

import { useController } from 'react-hook-form';

const Switch = ({ ...props }) => {
  const {
    fieldState: { error },
    field: { onChange, value, ref },
  } = useController({
    name: props.name,
    control: props.control,
    rules: props.rules,
    ref: props.ref,
  });
  return <NativeSwitch inputRef={ref} onChange={onChange} checked={value} {...props} />;
};

export default Switch;
