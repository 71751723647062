import React from 'react';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { NOT_SELECTED } from 'litterals';

export const ThemeCardMini = ({ id, name, onClickDelete, onClick, sx = {} }) => {
  const { t } = useTranslation();
  return (
    <Chip
      label={name ? `${name}` : t(NOT_SELECTED)}
      onDelete={onClickDelete ? () => onClickDelete(id) : undefined}
      color={'info'}
      sx={{ height: '36px', width: '100%' }}
      variant="outlined"
      onClick={onClick}
    />
  );
};

export default ThemeCardMini;
