/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
import React, { useRef } from 'react';

// UI Lib dependencies
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  IconButton,
  Typography,
  CircularProgress,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// UI Local Lib dependencies
import Input from 'ui/components/Input';
import { Tooltip } from '@mui/material';
import { truncateString } from 'common/utils';
import EditGuest from '../EditGuest';

// Validation
import { useTranslation } from 'react-i18next';

// Styles
import styles from './EditGuestModal.styles';
import { ROLE, SCOPE } from 'litterals';

//import SelectScope from 'ui/components/MultiSelectScope/SelectScope';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const EditGuestModal = ({ isOpen, onClose, control, title, onEdit, validation, watch, companyId, isEditLoading }) => {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();

  // Refs
  const refName = useRef();
  const refLanguages = useRef();

  return (
    <Dialog PaperProps={{ sx: styles.container }} open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Tooltip title={title + ' ' + watch('firstname') + ' ' + watch('lastname')}>
          <Typography style={styles.title}>
            {truncateString(`${title + ' ' + watch('firstname') + ' ' + watch('lastname')}`, 30)}
          </Typography>
        </Tooltip>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={onClose} sx={styles.closeIcon}>
          <CloseIcon />
        </IconButton>

        <EditGuest control={control} validation={validation} sxBox={styles.rowInput} />
        {/* <Box sx={styles.rowInput}>
          <Input
            ref={refName}
            control={control}
            variant="outlined"
            label="idNumber"
            name="idNumber"
            rules={validation.idNumber}
            fullWidth
          />
        </Box> */}
        {/* <Box sx={styles.rowInput}>
          <SelectScope companyId={companyId} name="scopeName" label={t(SCOPE)} control={control} />
        </Box> */}
      </DialogContent>
      <DialogActions sx={styles.containerButtons}>
        <Button variant="outlined" onClick={onClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          disabled={isEditLoading}
          startIcon={isEditLoading ? <CircularProgress size={20} /> : null}
          sx={{ backgroundColor: 'primary.dark' }}
          onClick={() => {
            onEdit();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGuestModal;
