//Roles names
export const ROLE_RH_MANAGER = 'role_rhmanager';
export const ROLE_RH = 'role_rh';
export const ROLE_COACH = 'role_coach';
export const ROLE_COACHEE = 'role_coachee';

//Alert and error messages
export const MESSAGE_SUCCESS_CHANGES = 'message_success_changes';
export const MESSAGE_SUCCESS_CHANGE_PROFILE = 'message_success_change_profile';
export const MESSAGE_SUCCESS_INVITE_COACH = 'message_success_invite_coach';
export const MESSAGE_SUCCESS_CHANGE_PASSWORD = 'message_success_change_password';
export const MESSAGE_SUCCESS_SAVE_PASSWORD = 'message_success_save_password';
export const VALUE_MUST_BE_SET = 'value_must_be_set';
export const VALUE_MUST_BE_LESS_THAN = 'value_must_be_less_than';
export const CHARACTERS = 'characters';
export const EXPECT_AT_LEAST = 'expect_at_least';
export const UPLOAD_DONE = 'upload_done';
export const PROGRAM_NOT_COMPLETED = 'program_not_completed';
export const COMPLETION_CONFIRMATION = 'completion_confirmation';

//General texts and Fields texts
export const PLACEHOLDER_CHIPS = 'placeholder_chips';

//My Experts
export const ADD_TO_PROGRAM = 'add_to_program';
export const COPY_EMAIL = 'copy_email';
export const SHOW_CALENDAR = 'show_calendar';
export const SHOW_PHONE = 'show_phone';
export const SHOW_MORE = 'show_more';
export const INVITE_COACH = 'invite_coach';
export const GO_BACK = 'go_back';
export const MY_COACHES = 'my_coaches';
export const COACHES = 'coaches';
export const INTERNAL = 'internal';
export const EXTERNAL = 'external';
export const PARTNERS = 'partners';
export const ACTIVATED = 'activated';
export const DESACTIVATED = 'desactivated';
export const EMAIL_COPIED = 'email_copied';

//Home table
export const NEW_PROGRAM = 'new_program';
export const PROGRAMS = 'programs';
export const TOPIC = 'topic';
export const SCOPES = 'scopes';
export const NEXT_SESSION = 'next_session';
export const STATUS = 'status';
export const PROGRESS = 'progress';
export const ALL_SCOPES = 'all_scopes';
export const NOT_SELECTED = 'not_selected';
export const IN_PROGRESS = 'in_progress';
export const PENDING = 'pending';
export const DONE = 'done';
export const EXPERT = 'expert';
export const ONGOING_COACHING = 'ongoing_coaching';
export const COACHING_THIS_YEAR = 'coaching_this_year';
export const ANNUAL_COST = 'annual_cost';
export const COMMENT_EXPLANATION = 'comment_explanation';
export const SCOPE_COACHEE = 'scope_coachee';
export const SCOPE_COACH = 'scope_coach';
export const NO_PROGRAMS_TO_DISPLAY = 'no_programs_to_display';

//PROGRAM VIEW
export const OBJECTIVES = 'objectives';
export const OBJECTIVE = 'objective';
export const DATE = 'date';
export const STEP = 'step';
export const COMMENTS = 'comments';
export const COACHEE = 'coachee';
export const CANCEL = 'cancel';
export const CREATE = 'create';
export const NEW_SESSION = 'new_session';
export const SUBTEXT_OBJECTIVES = 'subtext_objectives';
export const VIEW_DETAILS = 'view_details';
export const EDIT = 'edit';
export const DELETE = 'delete';
export const COMPLETE_PROGRAM = 'complete_program';
export const SUCCESS_COMPLETE_PROGRAM = 'success_complete_program';
export const DESCRIPTION = 'description';
export const COMPANY_RESOURCES = 'company_resources';
export const PROGRAM_RESOURCES = 'program_resources';
export const TO_SCHEDULE = 'to_schedule';
export const DELETE_OBJECTIVE = 'delete_objective';
export const DELETE_OBJECTIVE_TEXT = 'delete_objective_text';
export const EMAIL_TEMPLATE_NOT_FOUND = 'email_template_not_found';

//SESSION VIEW
export const SESSION = 'session';
export const NOTES = 'notes';
export const NOTE = 'note';
export const DOCUMENTS = 'documents';
export const DOCUMENT = 'document';
export const RESSOURCES = 'ressources';
export const ACTIONS = 'actions';
export const ACTION = 'action';
export const ADD_ACTION = 'add_action';
export const CONFIRM_DELETE_ACTION = 'confirm_delete_action';
export const ADD_NOTE = 'add_note';
export const ADD_RESSOURCE = 'add_ressource';
export const MARK_AS_DONE = 'mark_as_done';
export const MESSAGE_SUCCESS_NOTE = 'message_success_note';
export const SCHEDULED_AT = 'scheduled_at';
export const CONFIRM_DELETE_SESSION = 'confirm_delete_session';
export const SESSION_VALIDATION_DATE_FUTURE = 'session_validation_date_future';
export const DATEPICKER_DISABLED_MESSAGE = 'datepicker_disabled_message';

//THEME VIEW
export const PROGRAM_DESCRIPTION = 'program_description';
export const WHAT_YOU_WILL_LEARN = 'what_you_will_learn';
export const WHAT_IS_IT = 'what_is_it';
export const REQUIREMENTS = 'requirements';
export const THEME = 'theme';

//PROGRAM CREATION - SETTINGS & TITLES
export const MINIMUM_NUMBER_OF_SESSIONS = 'minimum_number_of_sessions';
export const SCOPE = 'scope';
export const SETTINGS = 'settings';
export const COLLABORATORS = 'collaborators';
export const SUMMARY = 'summary';
export const DESCRIPTION_SETTINGS = 'description_settings';
export const DESCRIPTION_TOPIC = 'description_topic';
export const ADD_YOUR = 'add_your';
export const FORMAT = 'format';
export const TYPE = 'type';
export const COUNTRY = 'country';
export const PREV = 'prev';
export const NEXT = 'next';

//PROGRAM CREATION - INVITE COLLABORATORS
export const EMAIL = 'email';
export const PERSONALIZED_MESSAGE = 'personalized_message';
export const INVITE = 'invite';
export const INVITE_ANOTHER = 'invite_another';
export const INVITE_COACHEE = 'invite_coachee';
export const INVITE_ANOTHER_COACHEE = 'invite_another_coachee';
export const SESSIONS = 'sessions';
export const LEADER_INSTRUCTIONS = 'leader_instructions';
export const ID_NUMBER = 'id_number';
export const ID_NUMBER_INSTRUCTIONS = 'id_number_instructions';
export const ADD_PHOTO = 'add_photo';
export const UPLOAD_PROFILE_PICTURE = 'upload_profile_picture';
export const PROGRAMS_IN_PROGRESS = 'programs_in_progress';
export const VIEW = 'view';
export const ADD = 'add';
export const EMAIL_SUBJECT = 'email_subject';
export const PROGRAM_CREATION_STEP4_TEXT1 = 'program_creation_step4_text1';
export const EMAIL_TO = 'email_to';
export const PROGRAM_CREATION_STEP4_TEXT_SWITCH = 'program_creation_step4_text_switch';
export const PROGRAM_CREATED_TEXT = 'program_created_text';
export const PROGRAM_CREATED_SUCCESSFULLY = 'program_created_successfully';
export const BACK_TO_HOME = 'back_to_home';
export const PROGRAM_CREATION_STEP4_TEXT2 = 'program_creation_step4_text2';
export const EMAIL_FROM = 'email_from';
export const PROGRAM_CREATION_STEP4_TEXT3 = 'program_creation_step4_text3';
export const EMAIL_PREVIEW_TITLE = 'email_preview_title';
export const PROGRAM_CREATION_STEP4_TITLE = 'program_creation_step4_title';

//PROGRAM CREATION - SUMMARY
export const LAUNCH_DATE = 'launch_date';
export const PROGRAM_NUMBER = 'program_number';
export const ORDER_CONFIRMED_TEXT = 'order_confirmed_text';
export const ORDER_CONFIRMED_SUBTEXT = 'order_confirmed_subtext';
export const ORDER_CONFIRMED_FOLLOWING_PROGRAM_TEXT = 'order_confirmed_following_program_text';
export const CONFIRMATION_COST_RANGE = 'confirmation_cost_range';
export const MIN_PRICE = 'min_price';
export const MAX_PRICE = 'max_price';
export const PLEASE_CONFIRM_THE_ORDER = 'please_confirm_the_order';
export const CONFIRMATION = 'confirmation';

//COACH VIEW
export const SKILLS = 'skills';
export const PRESENTATION = 'presentation';
export const EXPERIENCE = 'experience';
export const LANGUAGES = 'languages';
export const LANGUAGE = 'language';
export const CONTACT = 'contact';
export const EDUCATION = 'education';
export const HOURS_OF_EXPERIENCE = 'hours_of_experience';
export const VIDEO = 'video';
export const COACH = 'coach';

//EDIT COACH VIEW
export const RADIO_TYPE_COACH = 'radio_type_coach';
export const RADIO_TYPE_MENTOR = 'radio_type_mentor';
export const ACTIVATE_PROFILE = 'activate_profile';
export const TITLE = 'title';
export const LINKEDIN_PROFILE = 'linkedin_profile';
export const DATE_OF_BIRTH = 'date_of_birth';
export const NATIONALITY = 'nationality';
export const ADDRESS = 'address';
export const SPECIAL_SKILLS = 'special_skills';
export const EXPERIENCE_ENUM_1 = 'experience_enum_1';
export const EXPERIENCE_ENUM_2 = 'experience_enum_2';
export const EXPERIENCE_ENUM_3 = 'experience_enum_3';
export const EXPERIENCE_ENUM_4 = 'experience_enum_4';
export const EDIT_THIS_EXPERT = 'edit_this_expert';
export const VIEW_THIS_EXPERT = 'view_this_expert';
export const VIEW_MY_PROFILE = 'view_my_profile';

//Terms and condition (page + button)
export const TNC_BUTTON = 'tnc_button';
export const ACCEPT_TNC_TEXT = 'accept_tnc_text';
export const ACCEPT_TNC_LINK = 'accept_tnc_link';
export const ACCEPT_COMPANY_TERMS_TEXT1 = 'accept_company_terms_text1';
export const ACCEPT_COMPANY_TERMS_TEXT2 = 'accept_company_terms_text2';

//Coachee Onboarding
export const LAUNCH = 'launch';
export const VALIDATE = 'validate';
export const CONTINUE = 'continue';
export const COACHEE_ONBOARDING_STEP1_TEXT = 'coachee_onboarding_step1_text';
export const COACHEE_ONBOARDING_STEP2_TEXT = 'coachee_onboarding_step2_text';
export const COACHEE_ONBOARDING_STEP2_SUBTEXT = 'coachee_onboarding_step2_subtext';
export const COACHEE_ONBOARDING_STEP3_TEXT1 = 'coachee_onboarding_step3_text1';
export const COACHEE_ONBOARDING_STEP3_TEXT2 = 'coachee_onboarding_step3_text2';
export const COACHEE_ONBOARDING_STEP3_SUBTEXT = 'coachee_onboarding_step3_subtext';

//Navbar
export const ACCOUNT = 'account';
export const PROFILE = 'profile';
export const EDIT_MY_PROFILE = 'edit_my_profile';
export const EDIT_PROFILE = 'edit_profile';
export const HOME = 'home';
export const ONBOARDING_KIT = 'onboarding_kit';
export const ROLE = 'role';
export const REPORTING = 'reporting';
export const MY_PROGRAMS = 'my_programs';

//Profile Page
export const GENERAL_INFORMATIONS = 'general_informations';
export const CHANGE_YOUR_PASSWORD = 'change_your_password';
export const CONFIRM_YOUR_PASSWORD = 'confirm_your_password';
export const PASSWORD = 'password';
export const CURRENT_PASSWORD = 'current_password';
export const NEW_PASSWORD = 'new_password';
export const SAVE_CHANGES = 'save_changes';
export const FIRSTNAME = 'firstname';
export const LASTNAME = 'lastname';
export const CITY = 'city';
export const PHONE_NUMBER = 'phone_number';

export const COACH_MAX_CAPACITY = 'coach_max_capacity';
export const SENIORITY_LEVEL = 'seniority_level';
export const GENDER_IDENTIFY = 'gender_identity';

export const PASSWORD_PLACEHOLDER = 'password_placeholder';
export const PASSWORD_REQUIRED = 'password_required';
export const PASSWORD_LENGTH_ERROR = 'password_length_error';
export const PASSWORD_NOT_MATCH_ERROR = 'password_not_match_error';
export const PASSWORD_MISSING_UPPERCASE_ERROR = 'password_missing_uppercase_error';
export const PASSWORD_MISSING_LOWERCASE_ERROR = 'password_missing_lowercase_error';
export const PASSWORD_MISSING_SPECIAL_CHARACTER_ERROR = 'password_missing_special_character_error';
export const PASSWORD_MISSING_NUMBER_ERROR = 'password_missing_number_error';
export const PASSWORD_PERSONAL_DATA_ERROR = 'password_personal_data_error';
export const PASSWORD_UNKNOWN_ERROR = 'password_unknown_error';

// New Session form
export const CREATE_NEW_SESSION_TEXT = 'create_new_session_text';
export const NAME = 'name';

// Session

export const ADD_TO_CALENDAR = 'add_to_calendar';
export const COMPLETE_SESSION = 'complete_session';
export const JOURNAL = 'journal';
export const PRIVATE_NOTES = 'private_notes';
export const COMMENT = 'comment';
export const COMMENT_QUESTION = 'comment_question';
export const LINK = 'link';
export const PLEASE_SELECT_DATE = 'please_select_date';
export const CONFIRMATION_DELETE_RESOURCE = 'confirmation_delete_resource';

// Settings page
export const LABEL = 'label';
export const CLIENT = 'client';
export const COACHING = 'coaching';
export const MENTORING = 'mentoring';

// Auth page
export const WELCOME = 'welcome';
export const LOGIN = 'login';
export const LOGIN_MICROSOFT = 'login_microsoft';
export const INVALID_CREDENTIALS_MESSAGE = 'invalid_credentials_message';
export const USERNAME = 'user_name';
export const FORGET_PASSWORD = 'forget_password';
export const EMAIL_NEW_PASSWORD = 'email_new_password';
export const LINK_EXPIRED = 'link_expired';
export const RE_ENTER_EMAIL = 're_enter_email';
export const RE_SEND_LINK = 're_send_link';
export const BACK_TO_LOG_IN = 'back_to_log_in';
export const LINK_SUCCESS_SENT = 'link-success_sent';
export const PASSWORD_SUCCESS_RESET = 'password_success_reset';
export const CHECK_FOR_NEW_LINK = 'check_for_new_link';
export const DEFINE_NEW_PASSWORD = 'define_new_password';
export const ENTER_NEW_PASSWORD = 'enter_new_password';
export const REMEMBER_ME = 'remember_me';
export const OPEN_PROGRAM = 'open_program';

// new values
export const ALL = 'all';
export const RESET = 'reset';

export const GENDER = 'gender';
export const INTERNAL_OR_EXTERNAL = 'internal_or_external';
export const MAX_CAPACITY = 'max_capacity';
export const LANGUAGES_IN_COMMON = 'languages_in_common';
export const SENIORITY_GAP = 'seniority_gap';
export const TARGETS_IN_COMMON = 'targets_in_common';

export const F = 'F';
export const H = 'H';
// Application form
export const FIELD_EMPLOYEEID = 'field_employeeID';
export const FIELD_LINKEDINURL = 'field_linkedinURL';
export const APPLICATION_FORM_TITLE_SHARE_YOUR_PORTRAIT_PHOTO = 'application_form_title_share_your_portrait_photo';
export const APPLICATION_FORM_FIELD_ADD_AN_IMAGE = 'application_form_field_add_an_image';
export const APPLICATION_FORM_TITLE_MATCHING_INFO = 'application_form_title_matching_info';
export const APPLICATION_FORM_FIELD_CURRENT_LOCATION = 'application_form_field_current_location';
export const APPLICATION_FORM_FIELD_AFFILIATION = 'application_form_field_affiliation';
export const APPLICATION_FORM_FIELD_SENIORITY_LEVEL = 'application_form_field_seniority_level';
export const APPLICATION_FORM_FIELD_GENDER_IDENTITY = 'application_form_field_gender_identity';
export const APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES = 'application_form_field_spoken_languages';
export const APPLICATION_FORM_FIELD_DEPARTMENT = 'application_form_field_department';
export const APPLICATION_FORM_TITLE_PARTICIPATION_IN_PROGRAM = 'application_form_title_participation_in_program';
export const APPLICATION_FORM_FIELD_SELECT_YOUR_ROLE = 'application_form_field_select_your_role';
export const APPLICATION_FORM_FIELD_TITLE = 'application_form_field_title';
export const APPLICATION_FORM_FIELD_CALENDAR_URL = 'application_form_field_calendar_url';
export const APPLICATION_FORM_FIELD_VIDEO_URL = 'application_form_field_video_url';
export const APPLICATION_FORM_FIELD_HOURS_OF_EXPERIENCE = 'application_form_field_hours_of_experience';
export const APPLICATION_FORM_TITLE_EMPLOYEE_OR_EXTERNAL_EXPERT = 'application_form_title_employee_or_external_expert';
export const APPLICATION_FORM_TITLE_CAPACITY_FOR_CLIENTS = 'application_form_title_capacity_for_clients';
export const APPLICATION_FORM_FIELD_SELECT_NUMBER = 'application_form_field_select_number';
export const APPLICATION_FORM_TITLE_ACHIEVED_GOALS = 'application_form_title_achieved_goals';
export const APPLICATION_FORM_FIELD_SELECT_EXPERTISES = 'application_form_field_select_expertises';
export const APPLICATION_FORM_TITLE_BIO = 'application_form_title_bio';
export const APPLICATION_FORM_TITLE_EXPECTATIONS_INITIATIVE = 'application_form_title_expectations_initiative';
export const APPLICATION_FORM_FIELD_SELECT_OBJECTIVES = 'application_form_field_select_objectives';
export const APPLICATION_FORM_TITLE_EXPECTATIONS_PROGRAM = 'application_form_title_expectations_program';
export const APPLICATION_FORM_FIELD_SELECT_OBJECTIVES_PROGRAM_GOALS =
  'application_form_field_select_objectives_program_goals';
export const APPLICATION_FORM_TITLE_PROGRAM_GOALS = 'application_form_title_program_goals';
export const APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE = 'application_form_field_open_ended_response';

export const SENIORITY1 = 'seniority1';
export const SENIORITY2 = 'seniority2';
export const SENIORITY3 = 'seniority3';
export const SENIORITY4 = 'seniority4';
export const SENIORITY5 = 'seniority5';
export const SENIORITY6 = 'seniority6';
export const SENIORITY7 = 'seniority7';
export const SENIORITY8 = 'seniority8';

export const DEPARTEMENT0 = 'departement0';
export const DEPARTEMENT1 = 'departement1';
export const DEPARTEMENT2 = 'departement2';
export const DEPARTEMENT3 = 'departement3';
export const DEPARTEMENT4 = 'departement4';
export const DEPARTEMENT5 = 'departement5';
export const DEPARTEMENT6 = 'departement6';
export const DEPARTEMENT7 = 'departement7';
export const DEPARTEMENT8 = 'departement8';
export const DEPARTEMENT9 = 'departement9';

export const TARGET_TEMPLATE1 = 'target_template1';
export const TARGET_TEMPLATE2 = 'target_template2';
export const TARGET_TEMPLATE3 = 'target_template3';
export const TARGET_TEMPLATE4 = 'target_template4';
export const TARGET_TEMPLATE5 = 'target_template5';
export const TARGET_TEMPLATE6 = 'target_template6';
export const TARGET_TEMPLATE7 = 'target_template7';
export const TARGET_TEMPLATE8 = 'target_template8';
export const TARGET_TEMPLATE9 = 'target_template9';
export const TARGET_TEMPLATE10 = 'target_template10';
export const TARGET_TEMPLATE11 = 'target_template11';
export const TARGET_TEMPLATE12 = 'target_template12';
export const TARGET_TEMPLATE13 = 'target_template13';
export const TARGET_TEMPLATE14 = 'target_template14';
export const TARGET_TEMPLATE15 = 'target_template15';

export const PROFILE_INFO = 'profile_info';
export const IMAGE_UPLOADED_SUCCESS = 'image_uploaded_success';

export const WELCOME_SCREEN_EVERGREEN_SUBTITLE = 'welcome_screen_evergreen_subtitle';
export const WELCOME_SCREEN_EVERGREEN_BODY = 'welcome_screen_evergreen_body';
export const STEP1_SELECT_PROGRAM = 'step1_select_program';
export const STEP1_SUBTITLE = 'step1_subtitle';
export const MINIMUM = 'minimum';
export const MORE_INFO = 'more_info';
export const STEP2_TELL_US_ABOUT_YOURSELF = 'step2_tell_us_about_yourself';
export const STEP2_SUBTITLE = 'step2_subtitle';
export const STEP2_SUBTITLE2 = 'step2_subtitle2';
export const TITLE_MATCHING_INFO = 'title_matching_info';
export const STEP2_SUCCESS = 'step2_success';
export const STEP3_CHOOSE_YOUR_EXPERT = 'step3_choose_your_expert';
export const STEP3_SUBTITLE = 'step3_subtitle';
export const STEP3_ORDER_SELECTION = 'step3_order_selection';
export const STEP3_ORDER_SELECTION_SUBTITLE = 'step3_order_selection_subtitle';
export const STEP4_YOURE_IN = 'step4_youre_in';
export const STEP4_BIG_TITLE = 'step4_big_title';
export const STEP4_BODY1 = 'step4_body1';
export const STEP4_BODY2 = 'step4_body2';
export const STEP4_BUTTON = 'step4_button';
export const BUTTON_ANSWER_REQUEST = 'button_answer_request';
export const BUTTON_LAUNCH_PROGRAM = 'button_launch_program';
export const COACHING_REQUEST_MODAL_TITLE = 'coaching_request_modal_title';
export const COACHING_REQUEST_MODAL_QUESTION = 'coaching_request_modal_question';
export const COACHING_REQUEST_MODAL_INSTRUCTIONS = 'coaching_request_modal_instructions';
export const ACCEPT = 'accept';
export const DECLINE = 'decline';
export const APPLY_TO_PROGRAM = 'apply_to_program';

export const APPFORM_TOOLTIP_IDEMPLOYEE = 'appform_tooltip-idEmployee';
export const APPFORM_TOOLTIP_LOCALIZATION = 'appform_tooltip-localization';
export const APPFORM_TOOLTIP_SECTOR = 'appform_tooltip-sector';
export const APPFORM_TOOLTIP_LANGUAGES = 'appform_tooltip-languages';
export const APPFORM_TOOLTIP_DEPARTMENT = 'appform_tooltip-department';
export const APPFORM_TOOLTIP_ROLE = 'appform_tooltip-role';
export const APPFORM_TOOLTIP_EXPECTATIONS = 'appform_tooltip-expectations';
export const APPFORM_TOOLTIP_TITLE = 'appform_tooltip-title';
export const APPFORM_TOOLTIP_MAXCAPCITY = 'appform_tooltip-maxCapacity';
export const APPFORM_TOOLTIP_BIO = 'appform_tooltip-bio';

export const APPLICATION_FORM_MANAGER_EMAIL_LABEL = 'application_form_manager_email_label';
export const PLACEHOLDER_MANAGER_EMAIL = 'placeholder_manager_email';

export const START = 'start';
export const FILTERS = 'filters';

export const PAGE_SIZE = 'page_size';

export const REQUEST = 'request';
export const REQUESTER = 'requester';
export const ANSWER_REQUEST = 'answer_request';

export const MICROSOFT_AUTH_UNAUTHORIZED_CLIENT_ERROR = 'microsoft_auth_unauthorized_client_error';
export const MICROSOFT_AUTH_ACCESS_DENIED_ERROR = 'microsoft_auth_access_denied_error';
export const MICROSOFT_AUTH_SERVER_ERROR_ERROR = 'microsoft_auth_server_error_error';
export const MICROSOFT_AUTH_TEMPORARILY_UNAVAILABLE_ERROR = 'microsoft_auth_temporarily_unavailable_error';
export const MICROSOFT_AUTH_LOGIN_REQUIRED_ERROR = 'microsoft_auth_login_required_error';
export const MICROSOFT_AUTH_INTERACTION_REQUIRED_ERROR = 'microsoft_auth_interaction_required_error';
export const MICROSOFT_AUTH_UNKNOWN_ERROR = 'microsoft_auth_unknown_error';
export const MICROSOFT_AUTH_NO_CODE_ERROR = 'microsoft_auth_no_code_error';
export const MICROSOFT_AUTH_SERVER_26001_ERROR = 'microsoft_auth_server_26001_error';
export const MICROSOFT_AUTH_SERVER_26002_ERROR = 'microsoft_auth_server_26002_error';
export const MICROSOFT_AUTH_SERVER_26003_ERROR = 'microsoft_auth_server_26003_error';
export const MICROSOFT_AUTH_SERVER_26004_ERROR = 'microsoft_auth_server_26004_error';
export const MICROSOFT_AUTH_BUTTON = 'microsoft_auth_button';

export const CANNOT_CHANGE_PASSWORD_SSO = 'cannot_change_password_sso';

export const PROFILE_SETTINGS = 'profile_settings';
export const PROFILE_SETTINGS_MY_ACCOUNT = 'profile_settings_my_account';
export const PROFILE_SETTINGS_MY_COMPANY = 'profile_settings_my_company';
export const PROFILE_SETTINGS_SECTIONS = 'profile_settings_sections';
export const PROFILE_SETTINGS_DESC = 'profile_settings_desc';
export const OBJECTIVES_EXPERTISES_TITLE = 'objectives_expertises_title';
export const LABELS = 'labels';
export const SECTORS = 'sectors';
export const DEPARTMENTS = 'departments';
export const SENIORITY_LEVELS = 'seniority_levels';
export const LABELS_TAB_INFORMATIONS = 'labels_tab_informations';
export const PROFILE_SETTINGS_LINKS_SURVEYS = 'profile_settings_links_surveys';
export const SETTINGS_LINKS_SURVEYS_DESCRIPTION = 'settings_links_surveys_description';
export const LABEL_INPUT_LINK_TERMS = 'label_input_link_terms';
export const LABEL_INPUT_LINK_FEEDBACK = 'label_input_link_feedback';
export const LABEL_INPUT_LINK_APPLICATION = 'label_input_link_application';
export const LABEL_INPUT_LINK_EVERGREEN = 'label_input_link_evergreen';
export const TOOLTIP_EVERGREEN = 'tooltip_evergreen';

export default {
  REQUEST,
  REQUESTER,
  ANSWER_REQUEST,
  RESET,
  ROLE_RH_MANAGER,
  ROLE_RH,
  ROLE_COACH,
  ROLE_COACHEE,
  //My experts
  ADD_TO_PROGRAM,
  COPY_EMAIL,
  SHOW_CALENDAR,
  SHOW_PHONE,
  SHOW_MORE,
  INVITE_COACH,
  MY_COACHES,
  COACHES,
  INTERNAL,
  EXTERNAL,
  PARTNERS,
  GO_BACK,
  ACTIVATED,
  DESACTIVATED,
  EMAIL_COPIED,
  //Navbar
  ACCOUNT,
  PROFILE,
  EDIT_PROFILE,
  EDIT_MY_PROFILE,
  HOME,
  ROLE,
  REPORTING,
  //Profile Page
  GENERAL_INFORMATIONS,
  FIRSTNAME,
  LASTNAME,
  CITY,
  PHONE_NUMBER,
  CHANGE_YOUR_PASSWORD,
  PASSWORD,
  CURRENT_PASSWORD,
  NEW_PASSWORD,
  CONFIRM_YOUR_PASSWORD,
  SAVE_CHANGES,
  COACH_MAX_CAPACITY,
  SENIORITY_LEVEL,
  GENDER_IDENTIFY,
  PASSWORD_PLACEHOLDER,
  PASSWORD_REQUIRED,
  PASSWORD_LENGTH_ERROR,
  PASSWORD_NOT_MATCH_ERROR,
  PASSWORD_MISSING_UPPERCASE_ERROR,
  PASSWORD_MISSING_LOWERCASE_ERROR,
  PASSWORD_MISSING_SPECIAL_CHARACTER_ERROR,
  PASSWORD_MISSING_NUMBER_ERROR,
  PASSWORD_PERSONAL_DATA_ERROR,
  PASSWORD_UNKNOWN_ERROR,
  //Home
  NEW_PROGRAM,
  PROGRAMS,
  TOPIC,
  SCOPE_COACH,
  SCOPE_COACHEE,
  SCOPES,
  ALL_SCOPES,
  STATUS,
  NEXT_SESSION,
  PROGRESS,
  NOT_SELECTED,
  PENDING,
  IN_PROGRESS,
  DONE,
  ONBOARDING_KIT,
  NO_PROGRAMS_TO_DISPLAY,
  //Program page
  OBJECTIVE,
  OBJECTIVES,
  DATE,
  STEP,
  COMMENTS,
  COACHEE,
  CANCEL,
  CREATE,
  NEW_SESSION,
  SUBTEXT_OBJECTIVES,
  COMPLETE_PROGRAM,
  SUCCESS_COMPLETE_PROGRAM,
  VIEW_DETAILS,
  EDIT,
  DELETE,
  DELETE_OBJECTIVE,
  DELETE_OBJECTIVE_TEXT,
  //Theme Page
  PROGRAM_DESCRIPTION,
  WHAT_YOU_WILL_LEARN,
  WHAT_IS_IT,
  REQUIREMENTS,
  //Coach view
  SKILLS,
  PRESENTATION,
  EXPERIENCE,
  LANGUAGES,
  CONTACT,
  EDUCATION,
  HOURS_OF_EXPERIENCE,
  VIDEO,
  COACH,
  //Edit Coach
  RADIO_TYPE_COACH,
  RADIO_TYPE_MENTOR,
  ACTIVATE_PROFILE,
  TITLE,
  LINKEDIN_PROFILE,
  DATE_OF_BIRTH,
  NATIONALITY,
  ADDRESS,
  SPECIAL_SKILLS,
  EXPERIENCE_ENUM_1,
  EXPERIENCE_ENUM_2,
  EXPERIENCE_ENUM_3,
  EXPERIENCE_ENUM_4,
  EDIT_THIS_EXPERT,
  VIEW_THIS_EXPERT,
  VIEW_MY_PROFILE,
  //Program creation
  MINIMUM_NUMBER_OF_SESSIONS,
  SETTINGS,
  COLLABORATORS,
  SUMMARY,
  SCOPE,
  DESCRIPTION_SETTINGS,
  DESCRIPTION_TOPIC,
  ADD_YOUR,
  FORMAT,
  TYPE,
  PERSONALIZED_MESSAGE,
  EMAIL,
  INVITE,
  INVITE_ANOTHER,
  INVITE_COACHEE,
  INVITE_ANOTHER_COACHEE,
  SESSIONS,
  SESSION_VALIDATION_DATE_FUTURE,
  LAUNCH_DATE,
  PROGRAM_NUMBER,
  ORDER_CONFIRMED_TEXT,
  ORDER_CONFIRMED_SUBTEXT,
  ORDER_CONFIRMED_FOLLOWING_PROGRAM_TEXT,
  MIN_PRICE,
  MAX_PRICE,
  PLEASE_CONFIRM_THE_ORDER,
  CONFIRMATION,
  LEADER_INSTRUCTIONS,
  ID_NUMBER_INSTRUCTIONS,
  EMAIL_SUBJECT,
  PROGRAM_CREATION_STEP4_TEXT1,
  EMAIL_TO,
  PROGRAM_CREATION_STEP4_TEXT_SWITCH,
  PROGRAM_CREATED_TEXT,
  PROGRAM_CREATED_SUCCESSFULLY,
  VALIDATE,
  BACK_TO_HOME,
  PROGRAM_CREATION_STEP4_TEXT2,
  EMAIL_FROM,
  PROGRAM_CREATION_STEP4_TEXT3,
  EMAIL_PREVIEW_TITLE,
  PROGRAM_CREATION_STEP4_TITLE,
  EMAIL_TEMPLATE_NOT_FOUND,
  //New session form
  CREATE_NEW_SESSION_TEXT,
  NAME,
  //TERMS & CONDITIONS
  TNC_BUTTON,
  ACCEPT_TNC_TEXT,
  ACCEPT_TNC_LINK,
  ACCEPT_COMPANY_TERMS_TEXT1,
  ACCEPT_COMPANY_TERMS_TEXT2,
  //COACHEE ONBOARDING
  LAUNCH,
  CONTINUE,
  COACHEE_ONBOARDING_STEP1_TEXT,
  COACHEE_ONBOARDING_STEP2_TEXT,
  COACHEE_ONBOARDING_STEP2_SUBTEXT,
  COACHEE_ONBOARDING_STEP3_TEXT1,
  COACHEE_ONBOARDING_STEP3_TEXT2,
  COACHEE_ONBOARDING_STEP3_SUBTEXT,
  //NEW FIELDS
  ONGOING_COACHING,
  COACHING_THIS_YEAR,
  ANNUAL_COST,
  COUNTRY,
  PREV,
  NEXT,
  ID_NUMBER,
  ADD_PHOTO,
  MESSAGE_SUCCESS_CHANGES,
  MESSAGE_SUCCESS_CHANGE_PROFILE,
  MESSAGE_SUCCESS_INVITE_COACH,
  MESSAGE_SUCCESS_CHANGE_PASSWORD,
  MESSAGE_SUCCESS_SAVE_PASSWORD,
  VALUE_MUST_BE_SET,
  VALUE_MUST_BE_LESS_THAN,
  CHARACTERS,
  EXPECT_AT_LEAST,
  COMMENT_EXPLANATION,
  UPLOAD_DONE,
  PROGRAM_NOT_COMPLETED,
  UPLOAD_PROFILE_PICTURE,
  PLACEHOLDER_CHIPS,
  COMPLETION_CONFIRMATION,
  //SESSION VIEW
  ADD_ACTION,
  ADD_NOTE,
  SESSION,
  NOTES,
  NOTE,
  DOCUMENTS,
  DOCUMENT,
  RESSOURCES,
  ACTIONS,
  ACTION,
  CONFIRM_DELETE_ACTION,
  MARK_AS_DONE,
  ADD_RESSOURCE,
  MESSAGE_SUCCESS_NOTE,
  SCHEDULED_AT,
  CONFIRM_DELETE_SESSION,
  COMPANY_RESOURCES,
  PROGRAM_RESOURCES,
  LINK,
  TO_SCHEDULE,
  JOURNAL,
  PRIVATE_NOTES,
  COMMENT,
  LABEL,
  CLIENT,
  COACHING,
  MENTORING,
  WELCOME,
  LOGIN,
  LOGIN_MICROSOFT,
  USERNAME,
  FORGET_PASSWORD,
  EMAIL_NEW_PASSWORD,
  LINK_EXPIRED,
  RE_ENTER_EMAIL,
  RE_SEND_LINK,
  BACK_TO_LOG_IN,
  LINK_SUCCESS_SENT,
  PASSWORD_SUCCESS_RESET,
  CHECK_FOR_NEW_LINK,
  DEFINE_NEW_PASSWORD,
  ENTER_NEW_PASSWORD,
  REMEMBER_ME,
  ALL,
  VIEW,
  ADD,
  OPEN_PROGRAM,
  GENDER,
  INTERNAL_OR_EXTERNAL,
  MAX_CAPACITY,
  LANGUAGES_IN_COMMON,
  SENIORITY_GAP,
  TARGETS_IN_COMMON,
  F,
  H,
  // Application form
  FIELD_EMPLOYEEID,
  FIELD_LINKEDINURL,
  APPLICATION_FORM_TITLE_SHARE_YOUR_PORTRAIT_PHOTO,
  APPLICATION_FORM_FIELD_ADD_AN_IMAGE,
  APPLICATION_FORM_TITLE_MATCHING_INFO,
  APPLICATION_FORM_FIELD_CURRENT_LOCATION,
  APPLICATION_FORM_FIELD_AFFILIATION,
  APPLICATION_FORM_FIELD_SENIORITY_LEVEL,
  APPLICATION_FORM_FIELD_GENDER_IDENTITY,
  APPLICATION_FORM_FIELD_SPOKEN_LANGUAGES,
  APPLICATION_FORM_FIELD_DEPARTMENT,
  APPLICATION_FORM_TITLE_PARTICIPATION_IN_PROGRAM,
  APPLICATION_FORM_FIELD_SELECT_YOUR_ROLE,
  APPLICATION_FORM_FIELD_TITLE,
  APPLICATION_FORM_FIELD_CALENDAR_URL,
  APPLICATION_FORM_FIELD_VIDEO_URL,
  APPLICATION_FORM_FIELD_HOURS_OF_EXPERIENCE,
  APPLICATION_FORM_TITLE_EMPLOYEE_OR_EXTERNAL_EXPERT,
  APPLICATION_FORM_TITLE_CAPACITY_FOR_CLIENTS,
  APPLICATION_FORM_FIELD_SELECT_NUMBER,
  APPLICATION_FORM_TITLE_ACHIEVED_GOALS,
  APPLICATION_FORM_FIELD_SELECT_EXPERTISES,
  APPLICATION_FORM_TITLE_BIO,
  APPLICATION_FORM_TITLE_EXPECTATIONS_INITIATIVE,
  APPLICATION_FORM_FIELD_SELECT_OBJECTIVES,
  APPLICATION_FORM_TITLE_EXPECTATIONS_PROGRAM,
  APPLICATION_FORM_FIELD_SELECT_OBJECTIVES_PROGRAM_GOALS,
  APPLICATION_FORM_TITLE_PROGRAM_GOALS,
  APPLICATION_FORM_FIELD_OPEN_ENDED_RESPONSE,
  SENIORITY1,
  SENIORITY2,
  SENIORITY3,
  SENIORITY4,
  SENIORITY5,
  SENIORITY6,
  SENIORITY7,
  SENIORITY8,
  DEPARTEMENT0,
  DEPARTEMENT1,
  DEPARTEMENT2,
  DEPARTEMENT3,
  DEPARTEMENT4,
  DEPARTEMENT5,
  DEPARTEMENT6,
  DEPARTEMENT7,
  DEPARTEMENT8,
  DEPARTEMENT9,
  TARGET_TEMPLATE1,
  TARGET_TEMPLATE2,
  TARGET_TEMPLATE3,
  TARGET_TEMPLATE4,
  TARGET_TEMPLATE5,
  TARGET_TEMPLATE6,
  TARGET_TEMPLATE7,
  TARGET_TEMPLATE8,
  TARGET_TEMPLATE9,
  TARGET_TEMPLATE10,
  TARGET_TEMPLATE11,
  TARGET_TEMPLATE12,
  TARGET_TEMPLATE13,
  TARGET_TEMPLATE14,
  TARGET_TEMPLATE15,
  PROFILE_INFO,
  IMAGE_UPLOADED_SUCCESS,
  WELCOME_SCREEN_EVERGREEN_SUBTITLE,
  WELCOME_SCREEN_EVERGREEN_BODY,
  STEP1_SELECT_PROGRAM,
  STEP1_SUBTITLE,
  MINIMUM,
  MORE_INFO,
  STEP2_TELL_US_ABOUT_YOURSELF,
  STEP2_SUBTITLE,
  STEP2_SUBTITLE2,
  TITLE_MATCHING_INFO,
  STEP2_SUCCESS,
  STEP3_CHOOSE_YOUR_EXPERT,
  STEP3_SUBTITLE,
  STEP3_ORDER_SELECTION,
  STEP3_ORDER_SELECTION_SUBTITLE,
  STEP4_YOURE_IN,
  STEP4_BIG_TITLE,
  STEP4_BODY1,
  STEP4_BODY2,
  STEP4_BUTTON,
  BUTTON_ANSWER_REQUEST,
  BUTTON_LAUNCH_PROGRAM,
  COACHING_REQUEST_MODAL_TITLE,
  COACHING_REQUEST_MODAL_QUESTION,
  COACHING_REQUEST_MODAL_INSTRUCTIONS,
  ACCEPT,
  DECLINE,
  APPLY_TO_PROGRAM,
  APPLICATION_FORM_MANAGER_EMAIL_LABEL,
  PLACEHOLDER_MANAGER_EMAIL,
  START,
  FILTERS,
  PAGE_SIZE,
  MICROSOFT_AUTH_UNAUTHORIZED_CLIENT_ERROR,
  MICROSOFT_AUTH_ACCESS_DENIED_ERROR,
  MICROSOFT_AUTH_SERVER_ERROR_ERROR,
  MICROSOFT_AUTH_TEMPORARILY_UNAVAILABLE_ERROR,
  MICROSOFT_AUTH_LOGIN_REQUIRED_ERROR,
  MICROSOFT_AUTH_INTERACTION_REQUIRED_ERROR,
  MICROSOFT_AUTH_UNKNOWN_ERROR,
  MICROSOFT_AUTH_NO_CODE_ERROR,
  MICROSOFT_AUTH_SERVER_26001_ERROR,
  MICROSOFT_AUTH_SERVER_26002_ERROR,
  MICROSOFT_AUTH_SERVER_26003_ERROR,
  MICROSOFT_AUTH_SERVER_26004_ERROR,
  MICROSOFT_AUTH_BUTTON,
  CANNOT_CHANGE_PASSWORD_SSO,
  PROFILE_SETTINGS,
  PROFILE_SETTINGS_MY_ACCOUNT,
  PROFILE_SETTINGS_MY_COMPANY,
  PROFILE_SETTINGS_SECTIONS,
  OBJECTIVES_EXPERTISES_TITLE,
  LABELS,
  SECTORS,
  DEPARTMENTS,
  SENIORITY_LEVELS,
  LABELS_TAB_INFORMATIONS,
  PROFILE_SETTINGS_LINKS_SURVEYS,
  SETTINGS_LINKS_SURVEYS_DESCRIPTION,
  LABEL_INPUT_LINK_TERMS,
  LABEL_INPUT_LINK_FEEDBACK,
  LABEL_INPUT_LINK_APPLICATION,
  LABEL_INPUT_LINK_EVERGREEN,
  TOOLTIP_EVERGREEN,
};
