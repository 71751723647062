const styles = {
  chip: {
    padding: '4px 8px',
    borderRadius: '16px',
    border: '1px solid',
    width: 'max-content',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    height: '14px',
  },
  purpleChip: {
    color: '#2B18A2',
    borderColor: '#2B18A299',
    background: '#F3F1FF',
  },
  greenChip: {
    color: '#0A7A3D',
    borderColor: '#0A7A3D299',
    background: '#E3FCEE',
  },
  blackChip: {
    color: '#32313B',
    borderColor: '#32313B299',
    background: '#F2F2F2',
  },
  redChip: {
    color: '#BE1622',
    borderColor: '#BE162299',
    background: '#FFF2F3',
  },
  primaryChip: {
    color: 'primary.main',
    borderColor: 'primary.main',
    background: 'white',
  },
};

export default styles;
