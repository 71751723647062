import React, { useState } from 'react';

import Box from 'ui/components/system/Box';
import EmailField from 'ui/components/form/EmailField';
import TextField from 'ui/components/form/TextField';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Switch, Case } from 'ui/components/system/SwitchCase';
import { useTranslation } from 'react-i18next';
import Select from 'ui/components/form/Select';
import Button from 'ui/components/Button/Button';
import {
  EMAIL,
  FIRSTNAME,
  ID_NUMBER_INSTRUCTIONS,
  INVITE_ANOTHER_COACHEE,
  INVITE_COACHEE,
  LASTNAME,
  LEADER_INSTRUCTIONS,
  SCOPE,
} from 'litterals';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Guests from './Guests';
import { t } from 'i18next';
import { useMeQuery } from 'hooks/queries';
import LoadingButton from 'ui/components/LoadingButton';

const getOptionsTab = () => {
  return [
    { id: 'tab-2', text: 'Registred', selected: true },
    { id: 'tab-3', text: 'New', selected: false },
  ];
};

const InviteForm = ({ onSubmit, control, onClickReset, disabled = false }) => {
  const styleInput = {
    width: '100%',
  };
  const { t } = useTranslation();
  const { data: user } = useMeQueryQuery();
  return (
    <Box component="form" onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="firstname"
            label={t(FIRSTNAME)}
            placeholder={t(FIRSTNAME)}
            required
            control={control}
            size="small"
            disabled={disabled}
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            name="lastname"
            label={t(LASTNAME)}
            placeholder={t(LASTNAME)}
            control={control}
            size="small"
            disabled={disabled}
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <EmailField
            name="email"
            label={t(EMAIL)}
            placeholder={t(EMAIL)}
            required
            control={control}
            size="small"
            disabled={disabled}
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <EmailField
            name="managerEmail"
            label="Leader email (optional)"
            placeholder={t(LEADER_INSTRUCTIONS)}
            control={control}
            size="small"
            disabled={disabled}
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="idNumber"
            label="Matricule (optional)"
            placeholder={t(ID_NUMBER_INSTRUCTIONS)}
            control={control}
            size="small"
            disabled={disabled}
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Switch value={disabled}>
            <Case value={false}>
              <Button variant="secondary" type="submit" disabled={disabled}>
                {t(INVITE_COACHEE)}
              </Button>
            </Case>
            <Case value={true}>
              <Button variant="secondary" onClick={onClickReset}>
                {t(INVITE_ANOTHER_COACHEE)}
              </Button>
            </Case>
          </Switch>
        </Grid>
      </Grid>
    </Box>
  );
};

export const InviteFormModal = ({ onSubmit, control, onCloseModal, simpleScopeOption, actions }) => {
  const styleInput = {
    width: '100%',
  };

  const { t } = useTranslation();
  const { data: user } = useMeQuery();
  return (
    <Box component="form" onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            name="firstname"
            label={t(FIRSTNAME)}
            placeholder={t(FIRSTNAME)}
            required
            control={control}
            size="small"
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            name="lastname"
            label={t(LASTNAME)}
            placeholder={t(LASTNAME)}
            control={control}
            size="small"
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <EmailField
            name="email"
            label={t(EMAIL)}
            placeholder={t(EMAIL)}
            required
            control={control}
            size="small"
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <Select
            name="userScope"
            label={t(SCOPE) + '*'}
            required
            control={control}
            options={simpleScopeOption}
            defaultValue=""
            variant={'filled'}
          />
        </Grid>
        <Grid item xs={12}>
          <EmailField
            name="managerEmail"
            label="Leader email (optional)"
            placeholder={t(LEADER_INSTRUCTIONS)}
            control={control}
            size="small"
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="idNumber"
            label="Matricule (optional)"
            placeholder={t(ID_NUMBER_INSTRUCTIONS)}
            control={control}
            size="small"
            sx={styleInput}
            variant="filled"
          />
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <Stack direction={'row'} justifyContent={'flex-end'}>
        <Button variant="secondary" type="submit" sx={{ marginRight: '10px' }} onClick={onCloseModal}>
          {t('cancel')}
        </Button>
        <LoadingButton type="submit" isloading={actions.isAddLoading}>
          {t('add')} {user?.company?.clientLabel}
        </LoadingButton>
      </Stack>
    </Box>
  );
};

const InviteCoachee = ({ state, actions, coachs, simpleScopeOption, onRowClicked, clickNext }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);
  const { data: user } = useMeQuery();

  return (
    <Stack sx={{ padding: '0px 3%' }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Button sx={{ padding: '5px 20px' }} variant="primary" onClick={() => setIsOpenForm(true)}>
          {t('add')} {user?.company?.clientLabel}
        </Button>
      </Stack>
      <Guests coachs={coachs ? coachs : []} onRowClicked={onRowClicked} />
      <Dialog open={isOpenForm} onClose={() => {}}>
        <IconButton
          aria-label="close"
          onClick={() => setIsOpenForm(false)}
          sx={{
            alignSelf: 'end',
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogTitle sx={{ p: 0, pb: '16px', pl: '24px' }}>
          {t('add')} {user?.company?.clientLabel}
        </DialogTitle>
        <DialogContent>
          <InviteFormModal
            actions={actions}
            control={state.inviteForm.control}
            onSubmit={async (e) => {
              await actions.submitInviteForm(e);
              setIsOpenForm(false);
              clickNext();
            }}
            onCloseModal={() => setIsOpenForm(false)}
            disabled={state.nav.inviteFormDisabled}
            onClickReset={actions.submitInviteForm}
            simpleScopeOption={simpleScopeOption}
          />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default InviteCoachee;
