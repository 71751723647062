import React from 'react';

import Box from 'ui/components/system/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { SESSIONS } from 'litterals';
import { useTranslation } from 'react-i18next';

export const Header = ({ subtitle }) => (
  <Box variant="flexColumn" sx={{ alignItems: 'center', justifyContent: 'center' }}>
    <Box variant="flex" sx={{ justifyContent: 'flex-start', width: '100%', pr: 'none', alignItems: 'end' }}></Box>
    <Typography sx={{ color: 'text.disabled', mb: '8px' }} variant="subtitle2">
      {subtitle}
    </Typography>
  </Box>
);

export const DataField = ({ label, value, nbSessions, children }) => {
  const { t } = useTranslation();
  return (
    <ListItem sx={{ pt: 0, pb: 0 }}>
      <ListItemAvatar>
        <Avatar>{children}</Avatar>
      </ListItemAvatar>

      <Box>
        <ListItemText
          primary={label}
          secondary={value || '...'}
          secondaryTypographyProps={{ color: value ? 'primary' : '' }}
        />
        {nbSessions ? (
          <ListItemText primary={nbSessions + ' ' + t(SESSIONS)} primaryTypographyProps={{ marginTop: '-5px' }} />
        ) : null}
      </Box>
    </ListItem>
  );
};

export const DataListField = ({ label, icon, children }) => {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={label} />
      </ListItem>
      <List sx={{ maxWidth: '200px', paddingInlineStart: '12px', pt: 0 }}>{children}</List>
    </>
  );
};
