import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import clipboardCopy from 'clipboard-copy';
import { useAlert } from 'common/alertContext';

const InvitationLinkModal = ({ open, applicationLink, onClose }) => {
  const { t } = useTranslation();
  const { setStateAlert } = useAlert();
  return (
    <Dialog open={open} onClose={() => onClose()}>
      <IconButton
        aria-label="close"
        onClick={() => onClose()}
        sx={{
          alignSelf: 'end',
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogTitle sx={{ p: 0, pb: '16px', pl: '24px' }}>{t('share_registration_link')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ p: 0, pb: '16px' }}>{t('registration_invitation_text')}</DialogContentText>
        <Stack direction={'row'}>
          <TextField
            value={window.location.origin + '/program-application?applicationLink=' + applicationLink}
            fullWidth
            id="outlined-basic"
            variant="outlined"
          />
          <Button
            onClick={() => {
              clipboardCopy(window.location.origin + '/program-application?applicationLink=' + applicationLink)
                .then(() => {
                  console.log('Text copied successfully: RAMI');
                })
                .catch((err) => {
                  console.error('Copy failed:', err);
                });
              setStateAlert({ open: true, message: t('invitation_link_copied') });
            }}
            variant="outlined">
            {t('copy')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default InvitationLinkModal;
