import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import Login from 'ui/pages/login';
import Users from 'ui/pages/users';

import Layout from 'ui/layout/Layout';
import Home from 'ui/pages/home';
import CreateSubscription from 'ui/pages/subscription/create';
import Profile from 'ui/pages/profile';
import Coach from 'ui/pages/coach';
import EditCoachPage from 'ui/pages/coach/EditCoachPage';
import Coachee from 'ui/pages/coachee';
import EditCoacheePage from 'ui/pages/coachee/EditCoacheePage';
import ThemePage from 'ui/pages/theme';
import ProgramOnboardingPage from 'ui/pages/program-onboarding';
import ProgramPage from 'ui/pages/program';
import CreatePasswordPage from 'ui/pages/password/CreatePasswordPage';
import { useMeQuery } from 'hooks/queries';
import AcceptCompanyTerms from 'ui/pages/login/AcceptCompanyTerms';
import SettingsPage from 'ui/pages/settings/SettingsPage';
import DashboardPage from 'ui/pages/dashboard/DashboardPage';
import ResetPasswordPage from 'ui/pages/password/ResetPasswordPage';
import { roleList } from 'utils/constUtils';
import ApplicationForm from 'ui/pages/application/ApplicationForm';
import HelpPage from 'ui/pages/help';
import Main from 'ui/pages/evergreen-link/Main';
import SelfRegistrationIndex from 'ui/pages/self-registration/SelfRegistrationIndex';
import EditCompany from 'ui/pages/home/admin/companies/EditCompany';
import MicrosoftAuth from 'ui/pages/sso/MicrosoftAuth';
import SamlRedirect from 'ui/pages/sso/SamlRedirect';
import SamlCallback from 'ui/pages/sso/SamlCallback';
import MicrosoftRedirect from 'ui/pages/sso/MicrosoftRedirect';
import NoMobileAccessPage from 'ui/pages/NoMobileAccessPage';
import EditorExercisePage from 'ui/pages/program/components/Sessions/Activities/EditorExercisePage';
import EditThemePage from 'ui/pages/theme/EditThemePage/EditThemePage';
import useIsMobile from 'hooks/usecase/useIsMobile';
import { Alert, Snackbar } from '@mui/material';
import { useAlert } from 'common/alertContext';

const NotFound = () => <h1>404 Not Found</h1>;

const urlRedirectionNotAllowed = ['/settings'];

const RouteMobileWrapper = ({ children }) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <NoMobileAccessPage />;
  }

  return children;
};

const ProtectedRoute = ({ redirectPath, isAllowed, children }) => {
  const { data: user } = useMeQuery();

  if (!isAllowed(user)) return <Navigate to={redirectPath} replace />;

  if (
    user.acceptCompanyTerms !== false &&
    user?.redirectUrl &&
    user.redirectUrl !== window.location.pathname &&
    !urlRedirectionNotAllowed.includes(window.location.pathname)
  ) {
    return <Navigate to={user.redirectUrl} replace />;
  }

  return children ? children : <Outlet />;
};

const AuthRoute = ({ children }) => {
  const token = localStorage.getItem('REACT_TOKEN_AUTH');
  const { data: user } = useMeQuery({ enabled: !!token });
  const { stateAlert, setStateAlert } = useAlert();

  const handleOnClose = () => {
    setStateAlert((previousState) => ({ ...previousState, open: false }));
  };

  if (token && !!user) {
    return <Navigate to={'/'} replace />;
  }

  return (
    <>
      {children ? children : <Outlet />}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={stateAlert.open}
        autoHideDuration={stateAlert.duration || 3000}
        onClose={handleOnClose}>
        <Alert severity={stateAlert.type} variant="filled" sx={{ width: '100%' }} onClose={handleOnClose}>
          {stateAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const Router = () => {
  return (
    <Routes>
      <Route path="/program-application" element={<ApplicationForm />} />
      <Route path="/program-application/:selfRegistration/:themeId" element={<ApplicationForm />} />
      <Route path="/program-application/evergreen/:evergreenLink/:themeId" element={<ApplicationForm />} />
      <Route
        path="/evergreen-link"
        element={
          <RouteMobileWrapper>
            <Main />
          </RouteMobileWrapper>
        }
      />
      <Route
        path="/self-registration-link/:selfRegistration/:applicationLink/:themeId"
        element={<SelfRegistrationIndex />}
      />
      <Route
        element={
          <RouteMobileWrapper>
            <AuthRoute />
          </RouteMobileWrapper>
        }>
        <Route path="/create_password/:tokenId" element={<CreatePasswordPage />} />
        <Route path="/create_password/:ssoType/:company/:tokenId" element={<CreatePasswordPage />} />
        <Route path="/reset_password/:tokenId" element={<ResetPasswordPage />} />
        <Route path="/reset_password/:ssoType/:company/:tokenId" element={<ResetPasswordPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/microsoft_auth" element={<MicrosoftAuth />} />
        <Route path="/microsoft_auth/:type" element={<MicrosoftAuth />} />
        <Route path="/microsoft_redirect/:type" element={<MicrosoftRedirect />} />
        <Route path="/saml_redirect/:type" element={<SamlRedirect />} />
        <Route path="/saml_callback/:type" element={<SamlCallback />} />
      </Route>
      <Route
        element={
          <RouteMobileWrapper>
            <ProtectedRoute redirectPath={'/login'} isAllowed={(user) => !!user} />
          </RouteMobileWrapper>
        }>
        <Route element={<ProtectedRoute redirectPath={'/'} isAllowed={(user) => !user?.acceptCompanyTerms} />}>
          <Route path="/acceptCompanyTerms" element={<AcceptCompanyTerms />} />
        </Route>
        <Route
          element={
            <ProtectedRoute redirectPath={'/acceptCompanyTerms'} isAllowed={(user) => user?.acceptCompanyTerms} />
          }>
          <Route element={<Layout />}>
            {/** Backoffice routes */}
            <Route
              element={
                <ProtectedRoute redirectPath="/home" isAllowed={(user) => !!user && user.role === roleList.ADMIN} />
              }>
              <Route path="companies/:companyId" element={<EditCompany />} />
            </Route>
            <Route
              element={
                <ProtectedRoute redirectPath="/" isAllowed={(user) => !!user && user.role === roleList.ADMIN} />
              }>
              <Route path="companies" element={<Home />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  redirectPath="/companies"
                  isAllowed={(user) => !!user && user.role !== roleList.ADMIN}
                />
              }>
              <Route path="/" element={<Home />} />
            </Route>
            <Route element={<ProtectedRoute isAllowed={(user) => !!user && user.role === roleList.ADMIN} />}>
              <Route path="/activities/:activityId/exercises/:exerciseId/editor" element={<EditorExercisePage />} />
              <Route path="/activities/:activityId/exercises/editor" element={<EditorExercisePage />} />
            </Route>
            {/** Global routes */}
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/companies" element={<Home />} />
            <Route path="/help" element={<HelpPage />} />
            <Route path="/settings" element={<Profile />} />
            <Route path="/program/:programId" element={<ProgramPage />} />
            <Route path="/coach/:coachId" element={<Coach />} />
            <Route path="/coachee/:coacheeId" element={<Coachee />} />
            <Route path="/theme/:themeId" element={<ThemePage />} />
            <Route path="/customizaton/theme/edit/:themeId" element={<EditThemePage />} />
            <Route
              element={
                <ProtectedRoute
                  redirectPath="/home"
                  isAllowed={(user) => (!!user && user.role === roleList.COACHEE) || user.role === roleList.COACH}
                />
              }>
              <Route path="program/onboarding/:programId" element={<ProgramOnboardingPage />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  redirectPath="/home"
                  isAllowed={(user) => !!user && [roleList.COACH, roleList.RH_MANAGER, roleList.RH].includes(user.role)}
                />
              }>
              <Route path="coach/edit/:coachId" element={<EditCoachPage />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  redirectPath="/home"
                  isAllowed={(user) =>
                    !!user && [roleList.COACHEE, roleList.RH_MANAGER, roleList.RH].includes(user.role)
                  }
                />
              }>
              <Route path="coachee/edit/:coacheeId" element={<EditCoacheePage />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  redirectPath="/home"
                  isAllowed={(user) => !!user && [roleList.RH, roleList.RH_MANAGER].includes(user.role)}
                />
              }>
              <Route path="/users" element={<Users />} />
              <Route path="/create-program" element={<CreateSubscription />} />
              <Route path="/reporting" element={<DashboardPage />} />
            </Route>
            <Route
              element={
                <ProtectedRoute
                  redirectPath="/home"
                  isAllowed={(user) => !!user && roleList.RH_MANAGER === user.role}
                />
              }>
              <Route path="/customizaton" element={<SettingsPage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
