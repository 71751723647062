import React from 'react';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { STEP4_BIG_TITLE, STEP4_BODY1, STEP4_BODY2, STEP4_BUTTON } from 'litterals';
import { Box } from '@mui/system';
import Layout from './Layout';

const Congratulations = ({ company }) => {
  const { t } = useTranslation();
  /* ********************************** CONSTANTS ********************************* */
  // constants
  const LOGO = `${process.env.PUBLIC_URL}/uploads/images/Pathline-logo-subtitle.svg`;

  return (
    <Layout logo={company && company.logo ? company.logo : LOGO}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} component="form">
        <Typography sx={{ fontSize: '34px', fontWeight: 500, textAlign: 'center' }} color={'#153060'}>
          {t(STEP4_BIG_TITLE)}
        </Typography>
        <Box sx={{ height: '8px' }} />
        <Typography
          sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 400, lineHeight: '24px', color: '#000000CC' }}>
          {t(STEP4_BODY1).replace(
            '{{expertLabel}}',
            company && company.expertLabel ? company.expertLabel.toLowerCase() : ''
          )}
        </Typography>
        <Box sx={{ height: '25px' }} />
        <Typography
          sx={{ textAlign: 'center', fontSize: '20px', fontWeight: 400, lineHeight: '24px', color: '#000000CC' }}>
          {t(STEP4_BODY2).replace(
            '{{sessionLabel}}',
            company && company.sessionLabel ? company.sessionLabel.toLowerCase() : ''
          )}
        </Typography>
        <Box sx={{ height: '32px' }} />
        <Button
          variant="contained"
          onClick={() => {
            window.open(process.env.REACT_APP_PATHLINE_RESSOURCES_LINK, '_blank', 'noopener,noreferrer');
          }}
          sx={{
            width: '100%',
            backgroundColor: 'main.primary',
            height: {
              xs: '34px',
              sm: '34px',
              md: '34px',
              lg: '64px',
              xl: '64px',
              fontSize: '20px',
              fontWeight: 700,
            },
          }}>
          {t(STEP4_BUTTON)}
        </Button>
      </Box>
    </Layout>
  );
};

export default Congratulations;
