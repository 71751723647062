import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TnCButton from 'ui/components/shared/TnCButton';
import { useTranslation } from 'react-i18next';
import { ACCEPT_COMPANY_TERMS_TEXT1, ACCEPT_COMPANY_TERMS_TEXT2, CONFIRMATION } from 'litterals';
import useLogin from 'hooks/usecase/useLogin';
import AuthLayout from './AuthLayout';
import { Button } from '@mui/material';

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  gap: '20px',
};

const AcceptCompanyTerms = () => {
  const [acceptTerms, setAcceptTerms] = useState(false);

  const { state, actions } = useLogin();
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <Box sx={styleBox}>
        <Typography variant="subtitle1" color="text.secondary" align="left">
          {t(ACCEPT_COMPANY_TERMS_TEXT1)}
          <br /> {t(ACCEPT_COMPANY_TERMS_TEXT2)}&nbsp;
        </Typography>
        <div style={{ marginInline: '12px' }}>
          <TnCButton acceptTerms={acceptTerms} setAcceptTerms={setAcceptTerms} link={state.nav.company.linkTerms} />
        </div>
        <Button
          color="ternary"
          variant="contained"
          disabled={!acceptTerms}
          onClick={actions.acceptTerms}
          sx={{ height: '64px', fontWeight: '700', fontSize: '14px', lineHeight: '23px' }}>
          {t(CONFIRMATION)}
        </Button>
      </Box>
    </AuthLayout>
  );
};

export default AcceptCompanyTerms;
