import { get, post } from './request';

class Subscriptions {
  static getMe() {
    return get('/users/me/subscriptions').then((res) => res.data);
  }

  static getCoachees(subscriptionId) {
    return get(`/subscriptions/${subscriptionId}/coachees`).then((res) => res.data);
  }

  static getById(subscriptionId) {
    return get(`/subscriptions/${subscriptionId}`).then((res) => res.data);
  }

  static create(data) {
    return post('/subscriptions', data).then((res) => res.data);
  }

  static linkCoachees({ subId, coachees }) {
    return post(`/subscriptions/${subId}/coachees`, { coachees }).then((res) => res.data);
  }

  static chooseCoach({ subId, coachId }) {
    return post(`/subscriptions/${subId}/coaches/chose`, { coachId }).then((res) => res.data);
  }
}

export default Subscriptions;
