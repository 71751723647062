import React from 'react';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import PasswordField from 'ui/components/form/PasswordField';
import {
  CONFIRM_YOUR_PASSWORD,
  ENTER_NEW_PASSWORD,
  LOGIN,
  PASSWORD_REQUIRED,
  PASSWORD_PLACEHOLDER,
  PASSWORD_NOT_MATCH_ERROR,
} from 'litterals';

const styleInput = {
  margin: '12px',
};

const styleBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
};

const PasswordForm = ({ onSubmit, control, watch, t }) => {
  let pwd = watch('password');

  return (
    <Box sx={styleBox} component="form">
      <Typography
        component="h3"
        sx={{
          color: '#153060',
          fontSize: '16px',
          lineHeight: '24px',
          letter: '0.15px',
          paddingInline: '12px',
          marginTop: '24px',
        }}>
        {t(ENTER_NEW_PASSWORD)}
      </Typography>
      <PasswordField
        enableFormattingErrors
        required
        name="password"
        label=""
        placeholder={t(PASSWORD_PLACEHOLDER)}
        control={control}
        style={styleInput}
      />
      <Typography
        component="h3"
        sx={{
          color: '#153060',
          fontSize: '16px',
          lineHeight: '24px',
          letter: '0.15px',
          paddingInline: '12px',
          marginTop: '12px',
        }}>
        {t(CONFIRM_YOUR_PASSWORD)}
      </Typography>
      <PasswordField
        required={true}
        name="password_repeat"
        label=""
        placeholder={t(PASSWORD_PLACEHOLDER)}
        control={control}
        style={styleInput}
        rules={{
          required: t(PASSWORD_REQUIRED),
          validate: {
            checkNotMatch: (value) => value === pwd || t(PASSWORD_NOT_MATCH_ERROR),
          },
        }}
      />
      <Button
        type="submit"
        variant="contained"
        onClick={onSubmit}
        sx={{
          mt: '12px',
          marginInline: '12px',
          borderRadius: '4px',
          fontSize: '20px',
          paddingBlock: '6px',
          backgroundColor: 'primary.main',
        }}>
        {t(LOGIN)}
      </Button>
    </Box>
  );
};

export default PasswordForm;
