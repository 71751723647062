import { useEffect, useState } from 'react';
import { useActivitiesList, useExercisesList } from 'hooks/queries';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import api from 'api';

import dayjs from 'dayjs';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

export const useActivities = (programId) => {
  const { setStateAlert } = useAlert();
  const [activityIdSelected, setActivityIdSelected] = useState('');
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const queryParamsExercises = { page: 1, size: 100, activityId: activityIdSelected, programId };

  const { data: activitiesData, isLoading: isLoadingActivities } = useActivitiesList({ page: 1, size: 100 });
  const {
    data: exercisesData,
    isLoading: isLoadingExercises,
    refetch: refetchExercises,
  } = useExercisesList(queryParamsExercises, !!activityIdSelected);

  const activities = activitiesData?.rows ? activitiesData?.rows : [];
  const exercises = exercisesData?.rows ? exercisesData?.rows : [];

  useEffect(() => {
    activityIdSelected && refetchExercises();
  }, [activityIdSelected]);

  const addExerciseValidated = useMutation(api.ProgramExerciseValidated.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-exercises', queryParamsExercises]);
      setStateAlert({ open: true, message: t('program_activities.validated_exercise') });
    },
    onError: (error) => {
      queryClient.invalidateQueries(['get-exercises', queryParamsExercises]);
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
    },
  });

  const deleteExerciseValidated = useMutation(api.ProgramExerciseValidated.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-exercises', queryParamsExercises]);
      setStateAlert({ open: true, message: t('program_activities.delete_exercise_validation') });
    },
    onError: (error) => {
      queryClient.invalidateQueries(['get-exercises', queryParamsExercises]);
      setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' });
    },
  });

  const onValidatedChange = (exerciseId) => {
    const exercise = exercises.find(({ id }) => id === exerciseId);

    if (exercise?.programExercisesValidated?.length) {
      deleteExerciseValidated.mutate(exercise.programExercisesValidated[0].id);
    } else {
      addExerciseValidated.mutate({ exerciseId, programId });
    }
  };

  return {
    state: {
      activityIdSelected,
      activities,
      exercises,
      isLoading: isLoadingActivities || (!!activityIdSelected && isLoadingExercises),
    },
    actions: { setActivityIdSelected, onValidatedChange },
  };
};
