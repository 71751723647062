import { post, get } from './request';

class Application {
  static create(data) {
    return post('/guests', data).then((res) => res.data);
  }
  static getScopesList(applicationLink) {
    return get(`/guests/${applicationLink}/scopes`).then((res) => res.data);
  }

  static getCompany(applicationLink) {
    return get(`/guests/${applicationLink}/company`).then((res) => res.data);
  }
}

export default Application;
