const userModalNextPaginationManagement = ({
  userIndex,
  users,
  totalPages,
  queryParams,
  setUserIdSelected,
  setQueryParams,
}) => {
  if (userIndex + 1 <= users.length - 1) {
    setUserIdSelected(users[userIndex + 1].id);
  } else {
    if (queryParams.page < totalPages) {
      setQueryParams({ ...queryParams, page: queryParams.page + 1 });
    } else {
      if (totalPages === 1) {
        setUserIdSelected(users[0].id);
      } else if (queryParams.page === totalPages) {
        setQueryParams({ ...queryParams, page: 1 });
      }
    }
  }
};

const userModalPreviousPaginationManagement = ({
  userIndex,
  users,
  totalPages,
  queryParams,
  setUserIdSelected,
  setQueryParams,
}) => {
  if (userIndex - 1 >= 0) {
    setUserIdSelected(users[userIndex - 1].id);
  } else {
    if (queryParams.page === 1) {
      if (totalPages === 1) {
        setUserIdSelected(users[users.length - 1].id);
      } else {
        setQueryParams({ ...queryParams, page: totalPages });
      }
    } else {
      setQueryParams({ ...queryParams, page: queryParams.page - 1 });
    }
  }
};

export { userModalNextPaginationManagement, userModalPreviousPaginationManagement };
