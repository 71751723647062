const styles = {
  container: {
    padding: '30px 40px 0 40px',
    maxHeight: '90vh',
  },

  title: {
    fontSize: '24px',
    fontWeight: 600,
  },

  subtitle: {
    marginTop: '12px',
    marginBottom: '12px',
    fontSize: '16px',
  },

  categoryTitle: {
    fontSize: '16px',
    fontWeight: 500,
  },

  categorySubtitle: {
    fontSize: '14px',
  },

  coverContainer: {
    marginTop: '16px',
    marginBottom: '16px',
  },

  thumbnailContainer: {
    marginTop: '16px',
    display: 'flex',
    gap: '24px',
    position: 'relative',
  },

  preview: {
    transform: 'scale(0.6) translateX(33%) translateY(-33%)',
    position: 'absolute',
    top: 0,
    right: 0,
  },

  uploaderThumbnail: {
    display: 'flex',
    flexGrow: 1,
    marginRight: '246px',
    height: '210px',
  },

  uploaderCover: {
    height: '100px',
  },
};

export default styles;
