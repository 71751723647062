import {
  PASSWORD_LENGTH_ERROR,
  PASSWORD_MISSING_UPPERCASE_ERROR,
  PASSWORD_MISSING_LOWERCASE_ERROR,
  PASSWORD_MISSING_SPECIAL_CHARACTER_ERROR,
  PASSWORD_MISSING_NUMBER_ERROR,
  PASSWORD_PERSONAL_DATA_ERROR,
  PASSWORD_UNKNOWN_ERROR,
} from 'litterals';

const backErrorMatching = {
  3005: PASSWORD_LENGTH_ERROR,
  3006: PASSWORD_MISSING_UPPERCASE_ERROR,
  3007: PASSWORD_MISSING_SPECIAL_CHARACTER_ERROR,
  3008: PASSWORD_MISSING_NUMBER_ERROR,
  3009: PASSWORD_PERSONAL_DATA_ERROR,
  3010: PASSWORD_MISSING_LOWERCASE_ERROR,
};

const onErrorPassword = (error, inputName, setError, t) => {
  if (!error?.response?.data?.success) {
    const { code } = error.response.data;
    const errorKey = backErrorMatching[code] ? backErrorMatching[code] : PASSWORD_UNKNOWN_ERROR;

    setError(inputName, { type: 'custom', message: t(errorKey) });
  }
};

export { onErrorPassword };
