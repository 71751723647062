import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styles from './EditThemeModal.styles';
import { useTranslation } from 'react-i18next';
import SelectProgram from 'ui/pages/evergreen-link/SelectProgram';
import ViewModal from 'ui/components/shared/ViewModal';
import ThemeView from 'ui/components/shared/theme/ThemeView';
import GoBackButton from 'ui/components/shared/GoBackButton';

const EditThemeModal = ({ open, company, themeId, onClose, onChangeTheme }) => {
  const { t } = useTranslation();
  const [themeIdToShow, setThemeIdToShow] = useState(null);
  const [themeIdSelected, setThemeIdSelected] = useState(themeId);

  const onCloseModal = () => {
    setThemeIdToShow(null);
    setThemeIdSelected(themeId);
    onClose();
  };

  const stylesContainer = !themeIdToShow ? styles.container : { ...styles.container, padding: '0 0 20px 0' };

  return (
    <div>
      <ViewModal open={open} onClose={onCloseModal} customStyleBox={stylesContainer}>
        {!themeIdToShow && (
          <Box>
            <Box>
              <Typography variant="h1" sx={styles.title}>
                {t('edit_theme_modal_title')}
              </Typography>
              <Typography variant="h3" sx={styles.subTitle}>
                {t('edit_theme_modal_subtitle')}
              </Typography>
            </Box>
            <Box sx={styles.cardsTheme}>
              <SelectProgram
                activeHorizontalScroll
                showTabs
                idCompany={company.id}
                company={company}
                showObjectives={false}
                showSelectButton={false}
                showThemeViewInModal={false}
                themeIdSelected={themeIdSelected}
                onThemeDetail={(themeId) => setThemeIdToShow(themeId)}
                onSelectProgram={(theme) => setThemeIdSelected(theme.id)}
              />
            </Box>
            <Box sx={styles.containerButtons}>
              <Button onClick={onCloseModal} variant="outlined">
                {t('cancel')}
              </Button>
              <Button
                disabled={!themeIdSelected}
                onClick={() => {
                  onChangeTheme(themeIdSelected);
                  onCloseModal();
                }}
                sx={styles.validButton}
                variant="contained">
                {t('edit_theme_modal_valid')}
              </Button>
            </Box>
          </Box>
        )}
        {themeIdToShow && (
          <Box sx={{ position: 'relative', overflowY: 'scroll' }}>
            <GoBackButton
              onClick={() => {
                setThemeIdToShow(null);
              }}
              sx={styles.returnButton}
            />
            <ThemeView fullWidthHeader themeId={themeIdToShow} />
          </Box>
        )}
      </ViewModal>
    </div>
  );
};

export default EditThemeModal;
