import React from 'react';

import MUICard from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';

const Card = ({
  onClick,
  img,
  title,
  content,
  buttons,
  sx = {},
  sxTitle = {},
  sxCardContent = {},
}) => {
  return (
    <MUICard
      sx={{
        width: '220px',
        height: '275px',
        borderRadius: '6px',
        ...sx,
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardMedia sx={{ width: '100%', height: '120px' }} component="img" src={img} />
        <CardContent sx={{ padding: '10px', ...sxCardContent }}>
          <Tooltip title={title}>
            <Typography
              gutterBottom
              textTransform="capitalize"
              align="center"
              sx={{ fontWeight: 'bold', paddingTop: '0', ...sxTitle }}
              noWrap={true}
            >
              {title}
            </Typography>
          </Tooltip>
          {content}
        </CardContent>
      </CardActionArea>
      {!!buttons && (
        <CardActions
          disableSpacing
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '0',
          }}
        >
          {buttons}
        </CardActions>
      )}
    </MUICard>
  );
};

export default Card;
