const styles = {
  pictureContainer: {
    alignItems: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
    marginBottom: '32px',
  },

  picture: {
    height: { lg: '230px', xl: '230px' },
    borderRadius: '12px',
    objectFit: 'cover',
  },

  pictureHover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    backgroundColor: '#00000056',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  button: {
    backdropFilter: 'blur(5px)',
    background: 'none',
    border: '1px solid white',

    '&:hover': {
      background: 'white',
      color: 'black',
    },
  },

  buttonContainer: {
    display: 'flex',
    textAlign: 'center',
  },
};

export default styles;
