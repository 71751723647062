import React from 'react';

import AvatarGroupMui from '@mui/material/AvatarGroup';
import Avatar from '../Avatar/Avatar';

const AvatarGroup = ({ avatarConfig, max = 10, width = 48 }) => {
  const addMarginLeft = (index) => {
    if (index) return { marginLeft: '-14px !important' };
    return {};
  };
  return (
    <AvatarGroupMui max={max}>
      {avatarConfig.map(({ id, firstname, lastname, picture }, index) => (
        <Avatar key={id} firstname={firstname} lastname={lastname} picture={picture} sx={addMarginLeft(index)} />
      ))}
    </AvatarGroupMui>
  );
};

export default AvatarGroup;
