const styles = {
  title: {
    fontWeight: 500,
    fontSize: '20px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '16px',
    color: '#B2AEAE',
  },
};
export default styles;
