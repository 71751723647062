import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

const TypeFilter = (props) => {
  const { item, applyValue } = props;

  const handleFilterChange = (newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        height: 42,
        pt: '2px',
      }}>
      <Select
        sx={{ width: '100%', height: '20px' }}
        value={item.value}
        label="Age"
        onChange={(event) => handleFilterChange(event.target.value)}>
        <MenuItem value={true}>{'INTERNAL'}</MenuItem>
        <MenuItem value={false}>{'EXTERNAL'}</MenuItem>
      </Select>
    </Box>
  );
};

const TypeFilterOperators = [
  {
    label: 'Above',
    value: 'above',
    getApplyFilterFn: (filterItem) => {},
    InputComponent: TypeFilter,
  },
];

export default TypeFilterOperators;
