import {
  STEP1_SELECT_PROGRAM,
  STEP2_TELL_US_ABOUT_YOURSELF,
  STEP3_CHOOSE_YOUR_EXPERT,
  STEP4_YOURE_IN,
} from 'litterals';

const initialState = {
  steps: [STEP1_SELECT_PROGRAM, STEP2_TELL_US_ABOUT_YOURSELF, STEP3_CHOOSE_YOUR_EXPERT, STEP4_YOURE_IN],
  activeStep: 0,
  company: {},
  selectedProgram: {},
  coachee: {},
  experts: [],
  selectedExperts: [],
  orderedSelectedExperts: [],
  selectedFilters: [],
};

export const createEvergreenSlice = (set) => ({
  ...initialState,
  setSelectedProgram: (_program) => set({ selectedProgram: _program }),
  setActiveStep: (_step) => set({ activeStep: _step }),
  setCompany: (_company) => set({ company: _company }),
  setCoachee: (_coachee) => set({ coachee: _coachee }),
  setExperts: (_experts) => set({ experts: _experts }),

  toggleSelected: (objectId) => {
    set((state) => ({
      experts: state.experts.map((obj) => (obj.id === objectId ? { ...obj, isSelected: !obj.isSelected } : obj)),
    }));
  },
  setSelectedExperts: (selectExpert) => {
    set((state) => ({
      selectedExperts: [...state.selectedExperts].filter((obj) => obj.isSelected),
    }));
  },
  setOrderedSelectedExperts: (_orderedSelectedExperts) => {
    set((state) => ({
      orderedSelectedExperts: _orderedSelectedExperts,
    }));
  },
  addOrderedExpert: (expert) =>
    set((state) => ({
      orderedSelectedExperts: [...state.orderedSelectedExperts, expert],
    })),

  removeOrderedExpert: (objectId) =>
    set((state) => ({
      orderedSelectedExperts: state.orderedSelectedExperts.filter((item) => item.id !== objectId),
    })),

  addExpert: (expert) =>
    set((state) => ({
      selectedExperts: [...state.selectedExperts, expert],
    })),

  removeExpert: (objectId) =>
    set((state) => ({
      selectedExperts: state.selectedExperts.filter((item) => item.id !== objectId),
    })),
});
