import React from 'react';
import { useTranslation } from 'react-i18next';
import Chip from '@mui/material/Chip';

export const StatusChip = ({ status, expertLabel, style = {} }) => {
  const { t, i18n } = useTranslation();

  const i18nStatusKey = `program_status_${status.replaceAll(' ', '_').toLowerCase()}`;

  const statusLabel = i18n.exists(i18nStatusKey, { expertLabel }) ? t(i18nStatusKey, { expertLabel }) : status;

  const statusColor = {
    draft: 'ternary',
    pending: '#AC772D',
    ['in progress']: '#007DC7',
    Started: 'success',
    done: '#29612F',
    deactivated: '#E45C5D',
  };

  const bgColor = {
    draft: 'ternary',
    pending: '#FFF2DC',
    ['in progress']: '#DBEFF7',
    Started: 'success',
    done: '#DEEEDF',
    deactivated: '#FFE4E1',
  };

  return (
    <Chip
      label={`${statusLabel.trim()}`}
      style={{ backgroundColor: bgColor[status], color: statusColor[status], minWidth: '70px', ...style }}
    />
  );
};
