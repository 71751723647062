/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

// UI lib dependencies;
import Stack from '@mui/material/Stack';
// Style
import CoachCardMini from 'ui/components/shared/coaches/CoachCardMini';
import CoachViewModalWithRequest from 'ui/components/shared/coaches/CoachViewModalWithRequest';

import { Tooltip, Typography } from '@mui/material';
import i18next from 'i18next';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const colorStatus = {
  coaching_accepted: '#45893F',
  coaching_refused: '#D32F2FB3',
  coaching_request_expires: '#808080',
  waiting_coach_reply: '#B18225',
  coaching_archived_before_response: '#808080',
};

function CellCoach({ coach, evergreenData = false }) {
  const { t, i18n } = useTranslation();
  /* ********************************** HOOKS ********************************* */

  const evergreenStatus = (evergreenData) => {
    if (!evergreenData) return;

    const type = evergreenData.type.replaceAll('-', '_');
    const i18nStatusKey = `coach_evergreen_status_${type}`;
    if (!i18n.exists(i18nStatusKey)) return;

    const tooltipTitle = dayjs(evergreenData.createdAt)
      .locale(i18next ? i18next.language : 'en')
      .format('DD/MM/YY HH:mm');

    return (
      <Tooltip title={tooltipTitle}>
        <Typography
          sx={{
            color: colorStatus[type],
            fontSize: '13px',
            marginLeft: '35px',
            marginTop: '-6px',
            width: 'max-content',
          }}>
          {t(i18nStatusKey)}
        </Typography>
      </Tooltip>
    );
  };

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack direction={'column'} sx={{ width: '100%' }}>
      <Stack alignItems={'center'} direction={'row'} data-tag="allowRowEvents" sx={{ width: '100%' }}>
        <CoachCell coach={coach} />
      </Stack>
      {evergreenStatus(evergreenData)}
    </Stack>
  );
}

export const CoachCell = ({ coach }) => {
  const [modalOpened, setModalOpened] = useState(false);
  const onClick = (event) => {
    event.stopPropagation();
    coach?.id ? setModalOpened(true) : setModalOpened(false);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setModalOpened(false);
  };
  return (
    <>
      <CoachCardMini
        firstname={coach && coach.user ? coach.user.firstname : ''}
        lastname={coach && coach.user ? coach.user.lastname : ''}
        {...coach}
        onClick={onClick}
        sx={{ width: 'max-content' }}
        isDataGrid={true}
      />
      {coach && modalOpened && (
        <CoachViewModalWithRequest coachId={coach.id} open={modalOpened} onClose={handleClose} />
      )}
    </>
  );
};

export default CellCoach;
