import React from 'react';
import Paper from '@mui/material/Paper';
import GoBackButton from 'ui/components/shared/GoBackButton';
import { useParams } from 'react-router-dom';
import { useGuests } from 'hooks/usecase/useGuests';
import { useMeQuery } from 'hooks/queries';
import EditUser from '../user/EditUser';

export default function EditCoacheePage() {
  const { coacheeId } = useParams();
  const { data: user } = useMeQuery();
  const { actions, control, isLoading } = useGuests({ id: coacheeId });

  if (isLoading) return <p>Loading...</p>;

  return (
    <Paper
      sx={{
        width: '90%',
        alignSelf: 'center',
        mt: '24px',
        height: '80%',
        overflowY: 'auto',
        pb: '24px',
      }}>
      <GoBackButton sx={{ mt: '12px', ml: '12px', position: 'fixed' }} />

      <EditUser
        userRole={user?.role}
        company={user?.company}
        actions={actions}
        typeUser="mentee"
        control={control}
        userId={coacheeId}
        showActiveProfile={false}
        showBackToProfile={false}
      />
    </Paper>
  );
}
