import React from 'react';
import { Fab, Paper, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import styles from './HelpPage.styles';
import { useTranslation } from 'react-i18next';
import Box from 'ui/components/system/Box';
import GoBackButton from 'ui/components/shared/GoBackButton';

const HelpPage = () => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ padding: '30px 60px' }}>
      {/** Back button */}
      <Stack sx={{ marginBottom: '30px' }}>
        <GoBackButton />
      </Stack>
      <Stack>
        <Paper
          elevation={1}
          sx={{
            p: '24px',
            pb: '24px',
            position: 'relative',
            overflowY: 'scroll',
            height: '83vh',
          }}>
          <Box sx={styles.paragraph}>
            <Typography
              component="h1"
              variant="h4"
              fontWeight="bold"
              color="ternary.dark"
              sx={{ marginBottom: '50px' }}>
              {t('help_page_title')}
            </Typography>
            <Typography component="p" sx={styles.description} variant="h7">
              {t('help_message')}
            </Typography>
            <Box>
              <Fab variant="extended" color="secondary" href="mailto:contact@pathline.co">
                {t('contact_support')}
              </Fab>
            </Box>
          </Box>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default HelpPage;
