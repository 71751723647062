/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

import { truncateString } from 'common/utils';

// Style
import styles from './styles';
import { INTERNAL, EXTERNAL } from 'litterals';
/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const LocationHome = `${process.env.PUBLIC_URL}/uploads/icons/internal.svg`;
const HotelClass = `${process.env.PUBLIC_URL}/uploads/icons/external.svg`;

function CellInternalCoach({ type }) {
  /* ********************************** HOOKS ********************************* */
  const { t } = useTranslation();

  /* ******************************** RENDERING ******************************* */
  return (
    <Stack
      sx={{ height: '24px', width: '24px' }}
      justifyContent={'center'}
      alignItems={'center'}
      direction={'row'}
      data-tag="allowRowEvents">
      <Tooltip title={t(type.toLowerCase() === INTERNAL.toLowerCase() ? INTERNAL : EXTERNAL)}>
        {type.toLowerCase() === INTERNAL.toLowerCase() ? (
          <Box component="img" src={LocationHome} />
        ) : (
          <Box component="img" src={HotelClass} />
        )}
      </Tooltip>
    </Stack>
  );
}

export default CellInternalCoach;
