const styles = {
  container: {
    alignSelf: 'center',
    overflowY: 'auto',
    p: '24px',
    marginX: '24px',
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1200px',
    width: '100%',
    boxSizing: 'border-box',
    margin: '0 auto',
  },

  headerActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  content: {
    p: '32px',
    borderRadius: '8px',
    backgroundColor: 'rgb(253, 253, 253)',
    border: '1px solid',
    borderColor: 'primary.border',
    boxShadow: 'none',
    height: 'max-content',
    position: 'relative',
  },

  title: {
    fontSize: '24px',
    fontWeight: 600,
  },

  pictureContainer: {
    alignItems: 'left',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    position: 'relative',
    marginBottom: '32px',
  },

  picture: {
    height: { lg: '230px', xl: '230px' },
    borderRadius: '12px',
    objectFit: 'cover',
  },

  pictureHover: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: '12px',
    backgroundColor: '#00000063',
  },

  subtitle: {
    color: 'primary.grey',
    fontSize: '16px',
    maxWidth: '728px',
  },

  titleInput: {
    fontSize: '32px',
    fontWeight: 500,
    color: '#475467',
  },

  subTitleInput: {
    marginTop: '16px',
    width: '100%',
    '.MuiFormLabel-root': { zIndex: 0 },
  },

  editorContainer: {
    padding: '10px 24px 0 24px',
    border: '1px solid',
    borderColor: 'primary.border',
    borderRadius: '4px',
  },

  updatedAt: {
    color: 'primary.grey',
    position: 'absolute',
    top: '6px',
    right: '12px',
    fontSize: '12px',
  },
};

export default styles;
