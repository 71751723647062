import React from 'react';
import get from 'lodash/get';
import { useController } from 'react-hook-form';
import MUITextField from '@mui/material/TextField';

const BaseTextField = ({
  control,
  name,
  placeholder,
  defaultValue,
  rules = {},
  disabled = false,
  label = '',
  style = {},
  required = false,
  variant = 'outlined',
  multiline = false,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    formState: { errors },
  } = useController({
    name,
    control,
    defaultValue,
    rules,
  });
  const errorMessage = get(errors, `${name}.message`);

  return (
    <MUITextField
      onChange={onChange} // send value to hook form
      onBlur={onBlur} // notify when input is touched/blur
      value={value || ''} // input value
      name={name} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear
      error={!!errorMessage}
      helperText={errorMessage}
      disabled={!!rules?.disabled || disabled}
      placeholder={placeholder}
      variant={variant}
      label={label}
      sx={style}
      required={required}
      color="ternary" // TODO: set var
      multiline={multiline}
      {...rest}
    />
  );
};

export default BaseTextField;
