import React from 'react';

import TextField from '@mui/material/TextField';

import { useController } from 'react-hook-form';

const TextArea = ({ control, name, label, rows, ...props }) => {
  const {
    field: { onChange, value },
  } = useController({ control, name, defaultValue: '' });

  return (
    <TextField
      id="outlined-basic"
      label={label}
      variant="outlined"
      control={control}
      name={name}
      value={value}
      multiline
      rows={rows}
      maxRows={rows}
      onChange={onChange}
      {...props}
    />
  );
};

export default TextArea;
