import { useListRemindersSettings } from 'hooks/queries';
import { useMutation } from '@tanstack/react-query';
import { useAlert } from 'common/alertContext';
import { useTranslation } from 'react-i18next';
import api from 'api';

const useRemindersSettings = () => {
  const { t } = useTranslation();
  const { data, isLoading, refetch, isRefetching } = useListRemindersSettings();
  const { setStateAlert } = useAlert();

  const settings = data || [];

  const categories = [...new Set((settings || []).map((reminder) => reminder.category))];

  const getRemindersWithCategories = (category) => {
    return settings.filter((reminder) => reminder.category === category);
  };

  const updateRemindersMutation = useMutation(api.Reminders.updateRemindersSettings, {
    onSuccess: (data, params) => {
      // We refresh the data because there are selectors connected to each other, so we want everything to update correctly.
      /*if (params?.find((param) => param.autoFillingSecondNotificationWithMultiple)) {
        refetch();
      }*/

      refetch();
    },
  });

  const updateManyRemindersMutation = useMutation(api.Reminders.updateRemindersSettings, {
    onSuccess: () => {
      refetch();
      setStateAlert({ open: true, message: t('reminder_settings_updated') });
    },
  });

  const updateRemindersSettings = (settings) => {
    updateRemindersMutation.mutate(settings);
  };

  const computeConfigIndex = (index, activated) => {
    const result = [];
    const activatableParameters = settings.filter((setting) => !setting.required);
    for (const reminderConfig of activatableParameters) {
      result.push({ ...reminderConfig, activated, index });
    }

    return result;
  };

  const onSwitchFirstColumnReminder = (activated) => {
    updateManyRemindersMutation.mutate(computeConfigIndex(1, activated));
  };

  const onSwitchEnableAll = (activated) => {
    const results = [...computeConfigIndex(1, activated), ...computeConfigIndex(2, activated)];

    updateManyRemindersMutation.mutate(results);
  };

  const onSwitchSecondColumnReminder = (activated) => {
    updateManyRemindersMutation.mutate(computeConfigIndex(2, activated));
  };

  const switchFirstColumnReminder = !settings.find((config) => {
    if (config.required) return false;
    if (!config?.remindersCompany?.length) return true;

    return config.remindersCompany.find((reminderCompany) => reminderCompany.index === 1 && !reminderCompany.activated);
  });

  const switchAllSecondReminder = !settings.find((config) => {
    if (config.required) return false;
    if (config.possibleNumberOfNotifications < 2) return false;
    if (!config?.remindersCompany?.length) return true;

    return config.remindersCompany.find((reminderCompany) => reminderCompany.index === 2 && !reminderCompany.activated);
  });

  const switchEnableAll = switchFirstColumnReminder && switchAllSecondReminder;

  return {
    isLoading,
    isRefetching,
    state: {
      settings,
      categories,
      switchEnableAll,
      switchFirstColumnReminder,
      switchAllSecondReminder,
    },
    actions: {
      updateRemindersSettings,
      onSwitchEnableAll,
      onSwitchFirstColumnReminder,
      onSwitchSecondColumnReminder,
    },
    getters: {
      getRemindersWithCategories,
    },
  };
};

export default useRemindersSettings;
