import React, { Suspense, useState } from 'react';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import Box from 'ui/components/system/Box';
import { useListCompaniesQuery } from 'hooks/queries';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import AdminTable from './AdminTable';
import { sortByAttribute } from 'common/utils';
import useCompany from 'hooks/usecase/useCompany';
import { CREATE } from 'litterals';
import AddIcon from '@mui/icons-material/Add';
import { CreateEditFormCompany, CreateEditFormEmailTemplate, CreateEditFormTheme } from './EditFormModal';
import { useEditTheme, useCreateTheme } from 'hooks/usecase/useEditTheme';
import { Switch } from 'ui/components/system/SwitchCase';
import { Case } from 'ui/components/system/SwitchCase';
import useEmailTemplates from 'hooks/usecase/useEmailTemplates';
import CreateForm from './EmailTemplates/CreateForm';
import StandardActivities from './standardActivities/Activities';

// Modules
const Companies = React.lazy(() => import('./companies'));
const StandardEmailTemplates = React.lazy(() => import('./standardEmailTemplates'));
const StandardThemes = React.lazy(() => import('./standardThemes/Themes'));
const StandardTargetTemplateItems = React.lazy(() => import('./standardTargetTemplateItems/TargetTemplateItems'));

// Components
import TabNavigation from 'ui/pages/program/components/TabNavigation';

const getOptionsTab = () => {
  return [
    { id: 'tab-2', text: 'companies', selected: true },
    { id: 'tab-3', text: 'formats', selected: false },
  ];
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

const TableHeader = ({ name }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'space-between',
          pl: '12px',
          pr: '12px',
          marginBottom: '24px',
        }}>
        <Typography component="h1" variant="h4" fontWeight="bold" sx={{ pl: '12px' }} color="ternary.dark">
          {name}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ pr: '24px' }}></Stack>
      </Box>
      <Switch value={name}>
        <Case value={'Companies'}>
          <CreateCompanyButton />
        </Case>
        {/* <Case value={'Themes'}>
          <CreateThemeButton />
        </Case> */}
        <Case value={'Email Templates'}>
          <CreateEmailTemplateButton />
        </Case>
      </Switch>
    </>
  );
};

const CreateCompanyButton = () => {
  const { state, actions, formState } = useCompany();
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
      <Fab variant="extended" color="primary" size="medium" sx={{ mt: '6px', mr: '3px' }} onClick={actions.clickAdd}>
        <AddIcon sx={{ mr: '2px' }} />
        <Typography>{CREATE}</Typography>
      </Fab>
      <CreateEditFormCompany
        open={state.nav.showDialog}
        onClose={actions.close}
        state={state}
        actions={actions}
        submitLabel={CREATE}
        dialogTitle={'Create Company'}
      />
    </Box>
  );
};

const CreateThemeButton = () => {
  const { state, actions, formState } = useCreateTheme();
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
      <Fab variant="extended" color="primary" size="medium" sx={{ mt: '6px', mr: '3px' }} onClick={actions.clickAdd}>
        <AddIcon sx={{ mr: '2px' }} />
        <Typography>{CREATE}</Typography>
      </Fab>
      <CreateEditFormTheme
        open={state.nav.showDialog}
        onClose={actions.close}
        state={state}
        actions={actions}
        submitLabel={CREATE}
        dialogTitle={'Create Theme'}
      />
    </Box>
  );
};

const CreateEmailTemplateButton = () => {
  const { state, actions, formState } = useEmailTemplates();
  const { data: dataCompanies } = useListCompaniesQuery();
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const handleClickOpen = () => {
    setShowCreateModal(true);
    actions.clickAdd();
  };
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
      <Fab variant="extended" color="primary" size="medium" sx={{ mt: '6px', mr: '3px' }} onClick={handleClickOpen}>
        <AddIcon sx={{ mr: '2px' }} />
        <Typography>{CREATE}</Typography>
      </Fab>
      {showCreateModal && (
        <CreateForm
          open={state.nav.showDialog}
          onClose={actions.close}
          state={state}
          actions={actions}
          submitLabel={CREATE}
          dialogTitle={'Create Email Template'}
          companies={dataCompanies}
        />
      )}
    </Box>
  );
};

const HomeAdmin = ({ user }) => {
  const [selectedTab, setSelectedTab] = useState('tab-1');

  const renderComponent = () => {
    switch (selectedTab) {
      case 'tab-1':
        return <Companies user={user} />;
      case 'tab-2':
        return <StandardThemes user={user} />;
      case 'tab-3':
        return <StandardEmailTemplates user={user} />;
      case 'tab-4':
        return <StandardTargetTemplateItems user={user} />;
      case 'tab-5':
        return <StandardActivities user={user} showExerciseEditor />;
      default:
        return null;
    }
  };

  return (
    <Stack sx={{ paddingTop: '24px', paddingBottom: '24px', paddingLeft: '54px', paddingRight: '54px' }}>
      <Box sx={{ backgroundColor: 'white', marginRight: '55px', paddingLeft: '30px' }}>
        <Box sx={{ padding: '9px 0px' }}>
          <TabNavigation
            options={[
              { id: 'tab-1', text: 'Companies', selected: selectedTab === 'tab-1', disabled: false },
              { id: 'tab-2', text: 'Standard Theme', selected: selectedTab === 'tab-2', disabled: false },
              { id: 'tab-3', text: 'Standard Email Templates', selected: selectedTab === 'tab-3', disabled: false },
              { id: 'tab-4', text: 'Standard Target Templates', selected: selectedTab === 'tab-4', disabled: false },
              { id: 'tab-5', text: 'Standard Activities', selected: selectedTab === 'tab-5', disabled: false },
            ]}
            onChange={(tab) => {
              setSelectedTab(tab);
            }}
          />{' '}
        </Box>
      </Box>
      <Stack sx={{ paddingRight: '54px' }} direction={'row'} justifyContent={'center'}>
        <Suspense fallback={<div>Loading...</div>}>{renderComponent()}</Suspense>
      </Stack>
    </Stack>
  );
};

export default HomeAdmin;
