const styles = {
  container: {
    padding: '20px 0px 10px 0px',
  },
  tab: {
    boxShadow: 'none',
    color: 'primary.grey',
    fontFamily: 'Karla',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    textAlign: 'center',
    textTransform: 'uppercase',
    minHeight: '37px',
    minWidth: '80px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  subTab: {
    boxShadow: 'none',
    color: 'primary.grey',
    fontFamily: 'Karla',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '17.54px',
    textAlign: 'center',
    textTransform: 'uppercase',
    height: '37px',
    width: '80px',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  unselectedTab: {
    backgroundColor: 'white',
    whiteSpace: 'nowrap',
  },
  selectedTab: {
    color: 'primary.main',
    borderBottom: '2px solid',
    borderColor: 'primary.main',
    borderRadius: '0px',
  },
  iconReturn: {
    height: '12px',
    width: '12px',
    paddingBottom: '1px',
  },
};
export default styles;
