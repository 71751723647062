/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { parseJsonTranslate } from 'utils/parse';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Icons

// Styles
import styles from './CoachingRequest.styles';
import { EMAIL, REQUEST, REQUESTER, THEME } from 'litterals';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const CoachingRequest = ({
  isOpen,
  setOpen,
  sessionLabel = '{_sessionLabel}',
  expertLabel = '{_expertLabel}',
  onAccept,
  onDecline,
  coach,
  program,
  t,
}) => {
  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
  };

  const LinkedInIcon = `${process.env.PUBLIC_URL}/uploads/icons/linkedin.svg`;

  const sessionLabelLowerCase = sessionLabel ? sessionLabel.toLowerCase() : '';
  const expertLabelLowerCase = expertLabel ? expertLabel.toLowerCase() : '';

  const themeName = parseJsonTranslate(program && program.theme && program.theme.name);

  return (
    <Dialog
      disableEnforceFocus
      PaperProps={{ sx: styles.containerSessionTemplateDialog }}
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle sx={styles.containerTitle}>
        <Typography style={styles.title}>
          {t('coaching_request_modal_title', { sessionLabel: sessionLabelLowerCase })}
        </Typography>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Box sx={styles.boxList}>
          <Box sx={{ display: 'flex' }}>
            <Typography sx={styles.textAttribute}>{t(THEME)}</Typography>
            <Box sx={{ width: '10px' }} />
            <Typography sx={styles.textValue}>{themeName}</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <Typography sx={styles.textAttribute}>{t(REQUESTER)}</Typography>
            <Box sx={{ width: '10px' }} />
            <Typography sx={styles.textValue}>
              {`${program && program.guest && program.guest.firstname ? program.guest.firstname : ''} ${
                program && program.guest && program.guest.lastname ? program.guest.lastname : ''
              } `}
            </Typography>
            <Box sx={{ width: '3px' }} />
            {program && program.guest && program.guest.linkedinUrl && (
              <IconButton
                size="small"
                onClick={() => {
                  const linkLinkedin = program && program.guest && program.guest.linkedinUrl;
                  window.open(linkLinkedin, '_blank', 'noopener,noreferrer');
                }}>
                <Box component="img" src={LinkedInIcon} />
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: 'flex', marginTop: '5px' }}>
            <Typography sx={styles.textAttribute}>{t(EMAIL)}</Typography>
            <Box sx={{ width: '10px' }} />
            <Typography sx={styles.textValue}>
              {program && program.guest && program.guest.email ? program.guest.email : ''}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', marginTop: '5px' }}>
            <Typography sx={styles.textAttribute}>{t('application_form_field_affiliation')}</Typography>
            <Box sx={{ width: '10px' }} />
            <Typography sx={styles.textValue}>{`${
              program && program.scope && program.scope.name ? program.scope.name : ''
            } `}</Typography>
          </Box>
          <Box sx={{ height: '30px' }} />
          {program && program.guest && !!program.guest.expectations ? (
            <>
              <Typography sx={styles.textAttribute}>{t(REQUEST)}</Typography>
              <Typography>{program && program.guest && program.guest.expectations}</Typography>
            </>
          ) : null}
        </Box>
        <Box sx={{ height: '20px' }} />
        <Typography sx={styles.question}>
          {t('coaching_request_modal_question', { sessionLabel: sessionLabelLowerCase })}
        </Typography>
        <Box sx={{ height: '10px' }} />
        <Box sx={{ padding: '0px 70px' }}>
          <Typography sx={styles.instructions}>
            {t('coaching_request_modal_instructions', { expertLabel: expertLabelLowerCase })}
          </Typography>
        </Box>
        {/** Content of dialog */}
      </DialogContent>
      <DialogActions sx={styles.btnBack}>
        <Button
          variant="contained"
          sx={styles.btnSave}
          onClick={() => {
            onAccept(program && program.guest && program.guest.id ? program.guest.id : 'undefined', program.id, {
              accept: true,
            });
          }}
          size="large">
          {t('ACCEPT')}
        </Button>
        <Button
          sx={{ color: 'primary.main', borderColor: 'primary.main' }}
          variant="outlined"
          onClick={() => {
            onDecline(program && program.guest && program.guest.id ? program.guest.id : 'undefined', program.id, {
              accept: false,
            });
          }}
          size="large">
          {t('DECLINE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CoachingRequest;
