import theme from 'theme';

const styles = {
  containerNbrSessions: {
    paddingBottom: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  nbrSesstionText: {
    color: '#1A1A1A',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 400,
  },
  boxList: {
    border: '1px solid #0000003B',
    padding: '11px 30px',
    height: '280px',
    borderRadius: '2px',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
  addButton: {
    width: '250px',
    height: '40px',
    borderRadius: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'primary.dark',
    color: 'white',
    fontWeight: 500,
    fontSize: '15px',
    boxShadow: '0px 3px 5px -1px #00000033',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
  },
  containerButton: {
    paddingBottom: '9px',
  },
  containerSessionTemplateDialog: {
    backgroundColor: 'white',
    borderRadius: '16px',
    minWidth: '644px',
    height: '585px',
  },
  containerButtons: {},
  btnBack: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '19px 184px',
  },
  btnSave: {
    backgroundColor: 'primary.dark',
  },
  containerInputsSessionDialog: {
    padding: '20px 0px',
  },
  titleContent: {
    color: theme.palette.primary.dark,
    fontSize: '40px',
    fontWeight: 800,
    lineHeight: '46.76px',
  },
  themeContent: {
    fontSize: '16px',
    fontWeight: 600,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
  title: {
    paddingTop: '20px',
    color: '#000000DE',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '30px',
    alignText: 'center',
  },
  containerTitle: {
    height: '54px',
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    padding: ' 0px 51px',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
  textAttribute: {
    fontSize: '14px',
    color: '#5b5b5b',
    width: '80px',
    fontWeight: 500,
  },
  textValue: {
    fontSize: '14px',
    fontWeight: 700,
  },
  question: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '23px',
    textAlign: 'center',
  },
  instructions: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '18.7px',
    textAlign: 'center',
    color: 'primary.main',
  },
};
export default styles;
