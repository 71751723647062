/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */
// React
import React, { useState, useEffect } from 'react';

// MUI
import { useController } from 'react-hook-form';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Draft from 'draft-js';
import { Typography, Stack } from '@mui/material';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const EditorField = (props) => {
  const { control, name, title } = props;
  const [editorState, setEditorState] = useState();
  const [disableRerender, setDisableRerender] = useState(false); // setting default value
  const {
    fieldState: { error },
    field: { onChange, value, ref },
  } = useController({ control, name, defaultValue: null, ref: props.ref, rules: props.rules });

  const onEditorStateChange = (editorContent) => {
    setDisableRerender(true);
    setEditorState(editorContent);
    const html = draftToHtml(convertToRaw(editorContent.getCurrentContent()));
    onChange(html);
  };

  useEffect(() => {
    if (!disableRerender) {
      if (value == null) {
        setEditorState(Draft.EditorState.createEmpty());
      } else {
        const html = value;
        const contentBlock = htmlToDraft(`<div>` + html + `</div>`);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          setEditorState(editorState);
        }
      }
    }
  }, [value]);
  /* ********************************** HOOKS ********************************* */

  /* ******************************** RENDERING ******************************* */

  return (
    <Stack>
      <Typography component="p">{title}</Typography>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="demo-wrapper"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        wrapperStyle={{ border: '1px solid rgba(0, 0, 0, 0.30)', borderRadius: '4px', mb: '15px' }}
        editorStyle={{ height: '250px', borderRadius: '4px', padding: '6px 12px 6px 12px' }}
      />
    </Stack>
  );
};

export default EditorField;
