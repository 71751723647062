import { useEffect, useState } from 'react';
import { useListFormSettingsPublic } from 'hooks/queries';
import { useListEvergreenDepartmentsQuery } from 'hooks/queries';
import { uselistSeniorityEvergreenQuery } from 'hooks/queries';
import { useListTimeZones } from 'hooks/queries';

const useFormPublic = (_Link, queryParams = { isMentor: false }) => {
  const { data: formSettingsList, isLoading } = useListFormSettingsPublic({ _Link, queryParams });
  const departments = useListEvergreenDepartmentsQuery(_Link);
  const seniorityLevels = uselistSeniorityEvergreenQuery(_Link);
  const { data: timeZones } = useListTimeZones();

  return {
    isLoading,
    formSettingsList,
    departments,
    seniorityLevels,
    timeZones,
  };
};

export default useFormPublic;
