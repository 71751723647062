import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

// Style
import { useTranslation } from 'react-i18next';

import ApplicationForm from './ApplicationForm';
import { STEP2_SUBTITLE, STEP2_SUBTITLE2 } from 'litterals';

const About = ({ onSubmit, company, control, actions, state }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ border: '0px solid purple', padding: '0px 164px' }}>
      <Box sx={{ padding: '32px 48px 32px 48px', backgroundColor: 'white' }}>
        <Typography sx={{ fontSize: '30px', lineHeight: '37px', fontWeight: 700 }}>
          {t(STEP2_SUBTITLE).replace('{{expertLabel}}', company && company.expertLabel ? company.expertLabel : '')}
        </Typography>
        <Typography sx={{ fontSize: '20px', lineHeight: '37px', fontWeight: 400, color: '#00000099' }}>
          {t(STEP2_SUBTITLE2)}
        </Typography>
        <Box height={'5px'} />
      </Box>
      <Box height={'15px'} />
      <ApplicationForm
        actions={actions}
        setStateAlert={actions.setStateAlert}
        control={control}
        company={company}
        onSubmit={onSubmit}
        state={state}
      />
    </Box>
  );
};

export default About;
