import React, { useEffect, useState } from 'react';
import { useMeQuery } from 'hooks/queries';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { useListFormatThemeQuery } from 'hooks/queries';
import { useNavigate } from 'react-router-dom';
import CardProgram from 'ui/pages/evergreen-link/components/Cards/CardProgram/CardProgram';
import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';

import styles from './ProgramTheme.styles';

const ProgramTheme = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [themeIdToShow, setThemeIdToShow] = useState(null);
  const { data: user } = useMeQuery();
  const { data: listThemeData, isLoading } = useListFormatThemeQuery(user.company.id);

  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <Typography sx={styles.title}>{t('program_theme_management.title')}</Typography>

      <Typography sx={styles.description}>{t('program_theme_management.desc')}</Typography>

      <Box sx={styles.content}>
        {listThemeData.map((theme) => (
          <CardProgram
            key={theme.id}
            active={theme.active}
            adminButtons
            picture={theme.marketingPicture}
            title={theme.name}
            subtitle={theme.subtitle}
            nbSessions={theme.nbSessions}
            onSelect={() => navigate(`/customizaton/theme/edit/${theme.id}`)}
            onEdit={() => navigate(`/customizaton/theme/edit/${theme.id}`)}
            onShowMoreInfo={() => {
              setThemeIdToShow(theme.id);
            }}
          />
        ))}
      </Box>

      {!!themeIdToShow && (
        <ThemeViewModal
          theme={listThemeData?.find((item) => item.id === themeIdToShow)}
          themeId={themeIdToShow}
          open={!!themeIdToShow}
          onClose={() => {
            setThemeIdToShow(null);
          }}
        />
      )}
    </>
  );
};

export default ProgramTheme;
