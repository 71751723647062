const styles = {
  availabilitiesTitle: {
    fontSize: '24px',
    marginBottom: '40px',
    fontWeight: 600,
  },

  availabilityAddButton: {
    fontSize: '14px',
  },

  currentAvailabilityHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  currentAvailabilityTitle: {
    fontSize: '18px',
    fontWeight: 500,
  },

  availabilityDetailContainer: {
    borderRadius: '8px',
    background: '#6C63FF05',
    padding: '24px',
    border: '1px solid',
    borderColor: 'primary.border',
  },

  detailTitle: {
    fontSize: '16px',
    fontWeight: 500,
  },

  availabilityDetailHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },

  availabilities: {
    marginTop: '30px',
    overflowY: 'scroll',
    height: '440px',
  },

  undefinedAvailability: {
    display: 'flex',
    justifyContent: 'center',
    height: '400px',
    alignItems: 'center',
  },

  bottomContainer: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginTop: '32px',
  },

  recommendedHours: {
    fontWeight: 400,
    color: '#00000099',
    fontSize: '12px',
  },

  calendarButtons: {
    marginTop: '24px',
    gap: '16px',
    display: 'flex',
  },
};

export default styles;
