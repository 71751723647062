import React, { useState } from 'react';
import { Box } from '@mui/material';
import Button from 'ui/components/Button/Button';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';

import styles from './BackgroundPicture.styles';

import { useTranslation } from 'react-i18next';

export default function BackgroundPicture({ src, onClick }) {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState(false);

  return (
    <Box sx={styles.pictureContainer} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <Box sx={styles.picture} component="img" src={src} />

      {(isHover || !src) && (
        <Box sx={styles.pictureHover}>
          <Button sx={styles.button} onClick={onClick}>
            <Box sx={styles.buttonContainer}>
              {t('background_picture_theme.button')}
              <PhotoOutlinedIcon sx={{ marginLeft: '6px' }} />
            </Box>
          </Button>
        </Box>
      )}
    </Box>
  );
}
