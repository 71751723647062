const styles = {
  container: {
    backgroundColor: 'background.paper',
    borderRadius: '4px',
    padding: '32px',
    flexDirection: 'column',
    display: 'flex',
    minHeight: '40%',
    minWidth: '50%',
    maxHeight: '700px',
    maxWidth: '90vw',
    width: 'auto',
    height: 'none',
    overflowY: 'none',
    boxShadow: 24,
  },

  containerButtons: {
    paddingTop: '20px',
    textAlign: 'right',
  },

  validButton: {
    marginLeft: '16px',
  },

  title: {
    fontSize: '28px',
    fontWeight: 600,
    paddingBottom: '12px',
  },

  subTitle: {
    color: '#545454',
    fontSize: '16px',
    fontWeight: 300,
    paddingBottom: '12px',
  },

  returnButton: {
    margin: '12px 16px',
  },

  iconReturn: {
    color: '#757575',
    width: '12px',
    marginRight: '8px',
  },
};
export default styles;
