import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useAlert } from 'common/alertContext';
import api from 'api';

const useCreateCompanyResource = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(api.CompanyRessources.create, {
    onSuccess: () => {
      setIsLoadingEdit(false);
      queryClient.invalidateQueries(['get-list-company-ressources']);
      setShowDialog(false);
      reset();
    },
    onError: (error) => {
      setStateAlert({ open: true, message: error.response.data.error, type: 'error' });
    },
  });
  const submit = (payload) => {
    mutation.mutate(payload);
  };
  return {
    actions: { submit, isAddLoading: mutation.isLoading },
  };
};

export const useCompanyUploadDocument = (companyId) => {
  const { setStateAlert } = useAlert();

  const { formState, handleSubmit, control, reset, resetField } = useForm({
    mode: 'all',
    defaultValues: { file: null },
  });
  const [showDialog, setShowDialog] = useState(false);
  const [showResourceDialog, setShowResourceDialog] = useState(false);
  const [resource, setResource] = useState(null);
  const { actions: programActions } = useCreateCompanyResource();

  const mutation = useMutation(api.Users.upload, {
    onSuccess: (data) => {
      const parts = data.url.Key.split('/');
      const fileName = parts[parts.length - 1];

      const payload = {
        filename: resource.name ? resource.name : fileName,
        filepath: resource.path ? resource.path : data.url.Location,
        key: data.url.Key,
        companyId: companyId,
        language: resource.language ? resource.language : 'english',
        type: resource.type ? resource.type : 'Document',
        picture: resource.picture ? resource.picture : '',
      };
      programActions.submit(payload);
      reset({ file: null });
      setShowResourceDialog(false);
    },
    onError: (error) => {
      setShowResourceDialog(false);
    },
  });

  const submit = handleSubmit((data) => {
    setResource(data);
    console.log('resource: ', resource);
    console.log('Data: ', data);
    // Check if data has file then upload file else only create document
    if (data.file) {
      mutation.mutate(data.file);
    } else {
      const { file, ...payload } = data;
      if (payload.filepath.length > 0) {
        const newPayload = { ...payload, ...{ companyId } };
        programActions.submit(newPayload);
        setShowResourceDialog(false);
      } else {
        setStateAlert({
          open: true,
          message: 'Resource must have a file or a link',
          type: 'error',
        });
      }
    }
  });

  const close = () => {
    setShowDialog(false);
    setShowResourceDialog(false);
    reset();
  };

  const clickAdd = () => setShowDialog(true);
  const handleShowDialog = () => {
    reset();
    setShowResourceDialog(true);
  };

  return {
    state: { form: { control }, nav: { showDialog, showResourceDialog } },
    actions: { close, submit, resetField, clickAdd, handleShowDialog, isAddLoading: programActions.isAddLoading },
    formState,
  };
};

export default useCreateCompanyResource;
