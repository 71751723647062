import React, { useState, useRef, useEffect } from 'react';

// UI Lib dependencies
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from 'ui/components/Button/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';

// UI Local Lib dependencies
import DataGrid from 'ui/components/DataGrid';
import Input from 'ui/components/Input';
import DeleteModal from '../DeleteModal';
import LoadingButton from 'ui/components/LoadingButton';

// Hooks

import useScopes from 'hooks/usecase/useScopes';
import { useTranslation } from 'react-i18next';

// Configs
import { tableSchemaBase as columns } from './Scopes.config';

// Validation
import validation from './validators/company.validator';

// Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Global State
import useStore from 'store';
import { useMeQuery } from 'hooks/queries';
import { useAlert } from 'common/alertContext';

const Scopes = ({ disableInformationText = false }) => {
  const { t } = useTranslation();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hasUserAttached, setHasUserAttached] = useState({
    hasCoachees: false,
    hasCoaches: false,
  });

  const { stateAlert, setStateAlert } = useAlert();
  // Hook
  const { data: user } = useMeQuery();

  let companyId = user.companyId;
  // States
  const [queryParams, setQueryParams] = useState({ page: 1, size: 25, companyId: companyId });

  const {
    scopes,
    control,
    isLoading,
    isEditLoading,
    isAddLoading,
    onRemoveScope,
    onAddScope,
    isOpenAddModal,
    setIsOpenAddModal,
    reset,
    isOpenEditModal,
    setIsOpenEditModal,
    setEditingScope,
    onEditScope,
  } = useScopes(companyId);

  const {
    completScopes,
    scopes: scopesState,
    setScopes,
    size,
    setSize,
    setCurrentPage,
    totalPages,
    setCompleteScopes,
    setTotalPages,
    setAllScopes,
    allScopes,
  } = useStore();

  // Refs
  const refName = useRef();

  // Helper
  const updateList = (data) =>
    data.reduce((acc, currentItem) => {
      // if (currentItem.companyId === companyId) {
      const modifiedItem = {
        ...currentItem,
        createdAt: dayjs(currentItem.createdAt).format('DD-MM-YYYY'),
      };
      acc.push(modifiedItem);
      //  }

      return acc;
    }, []);

  function getPaginatedItems(items, pageSize, pageNumber) {
    let filteredItems = items;
    const startIndex = (pageNumber - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedItems = filteredItems.slice(startIndex, endIndex);
    return paginatedItems;
  }

  function calculateTotalPages(items, itemsPerPage) {
    // Vérifier si les paramètres sont valides
    if (!Array.isArray(items) || itemsPerPage < 1) {
      return 0;
    }

    // Calculer le nombre total de pages
    const totalPages = Math.ceil(items.length / itemsPerPage);

    return totalPages;
  }

  const handleOnFilter = (filters, currentPage) => {
    let queryParamsFilter = {};
    if (filters.items) {
      filters.items.map((filter) => {
        switch (filter.field) {
          case 'scopeName':
            queryParamsFilter = { ...queryParamsFilter, userScope: filter.value };
            break;
          case 'user':
            queryParamsFilter = { ...queryParamsFilter, name: filter.value };
            break;
          case 'registrationDate':
            queryParamsFilter = {
              ...queryParamsFilter,
              createdAt: filter.value && dayjs(filter.value).format('YYYY-MM-DD'),
            };
            break;
          default:
            queryParamsFilter = { ...queryParamsFilter, [filter.field]: filter.value };
            break;
        }
      });
    }
    // zustand : Filter using state management
    if (!queryParamsFilter.name) {
      setCompleteScopes(allScopes, size);
      setScopes(allScopes.slice(0, size));
      setTotalPages(calculateTotalPages(allScopes, size));
    } else {
      const filteredItems = allScopes.filter(
        (item) =>
          item.name
            .toLowerCase()
            .includes(
              queryParamsFilter.name && queryParamsFilter.name !== undefined ? queryParamsFilter.name.toLowerCase() : ''
            ) && item.companyId === companyId
      );
      const startIndex = (currentPage - 1) * size;
      const endIndex = startIndex + size;
      const paginatedItems = filteredItems.slice(startIndex, endIndex);
      setTotalPages(calculateTotalPages(filteredItems, size));
      setScopes(paginatedItems);
      setCompleteScopes(filteredItems, size);
    }
  };

  function sortByAlphabetical(orderDirection) {
    return function (a, b) {
      if (orderDirection === 'ASC') {
        return a.name.localeCompare(b.name);
      }
      return b.name.localeCompare(a.name);
    };
  }

  const handleOnSort = (sort, currentPage, currentSize) => {
    setScopes(allScopes.sort(sortByAlphabetical(sort.orderBy ? sort.orderDirection : 'ASC')).slice(0, currentSize));
    setQueryParams({ ...queryParams, page: currentPage, size: currentSize, ...sort });
  };

  const handleOpenModal = () => {
    setIsOpenAddModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenAddModal(false);
    reset();
  };

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
    reset({ name: '' });
  };

  // zustand : Set state management items
  useEffect(() => {
    if (scopes) {
      setScopes(updateList(scopes).slice(0, 10));
      setCompleteScopes(updateList(scopes), size);
      setAllScopes(updateList(scopes), size);
    }
  }, [scopes]);

  // zustand : Reset stat management page size
  useEffect(() => {
    return () => {
      setSize(10);
      setCurrentPage(1);
    };
  }, []);

  return (
    <Stack sx={{ minHeight: '630px', width: '100%', padding: '25px 0px', backgroundColor: 'white' }}>
      {!disableInformationText && (
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#0288d12b', // Blue background color
            padding: '16px',
            boxShadow: 'none', // Remove box-shadow
            width: 'fit-content', // Adjust width for content
          }}>
          <InfoOutlinedIcon sx={{ color: '#0288D1', marginRight: '8px' }} />
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: 'var(--Light-Info-Shades-160p, #0288D1)',
                fontFamily: 'Karla',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '150%', // 24px
                letterSpacing: '0.15px',
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}>
              {t('settings_sectors_tab_info')}
            </Typography>
          </Box>
        </Paper>
      )}
      <Stack sx={{ paddingBottom: '9px', padding: '25px 0px' }}>
        <Button endIcon={<AddIcon />} onClick={handleOpenModal} variant="primary">
          {t('add')}
        </Button>
      </Stack>
      {/** DataGrid */}
      <DataGrid
        columns={columns}
        rows={scopes ? scopesState : []}
        onDelete={(item) => {
          setHasUserAttached({
            hasCoachees: item.hasCoachees,
            hasCoaches: item.hasCoaches,
          });
          setSelectedItem(item);
          setIsOpenDeleteModal(true);
        }}
        onShow={() => {}}
        onEdit={(scope) => {
          setEditingScope(scope);
        }}
        onRowClicked={(scope, event) => {
          if (!event.defaultMuiPrevented) {
            setEditingScope(scope.row);
          }
        }}
        onFilter={handleOnFilter}
        onSort={handleOnSort}
        totalItems={scopes ? updateList(completScopes).length : 10}
        totalPages={totalPages ? totalPages : 1}
        onPaginationChange={(page, nbrPages) => {
          setSize(nbrPages);
          setScopes(getPaginatedItems(completScopes, nbrPages, page));
          setTotalPages(calculateTotalPages(completScopes, nbrPages));
        }}
        loading={isLoading}
      />
      {/* Add modal */}
      <Dialog open={isOpenAddModal} onClose={handleCloseModal}>
        <DialogTitle>
          <Typography
            style={{
              color: '#000000DE',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
            }}>
            {t('create_scope')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '25px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
            },
          }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ padding: '8px 24px', height: '70px', minWidth: '300px' }}>
            <Input
              ref={refName}
              control={control}
              variant="outlined"
              label={t('name')}
              name="name"
              rules={validation.name}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '19px 77px',
            borderTop: '1px solid #dfe0e0',
          }}>
          <Button disabled={isAddLoading} variant="secondary" onClick={handleCloseModal}>
            {t('go_back')}
          </Button>
          <LoadingButton
            isloading={isAddLoading}
            onClick={() => {
              onAddScope();
            }}
            title={t('save_changes')}
          />
        </DialogActions>
      </Dialog>

      {/* Edit modal */}
      <Dialog open={isOpenEditModal} onClose={handleCloseEditModal}>
        <DialogTitle>
          <Typography
            style={{
              color: '#000000DE',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: '32px',
            }}>
            {t('edit_scope')}
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            overflowX: 'hidden',
            '&::-webkit-scrollbar': {
              '-webkit-appearance': 'none',
              width: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '25px',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
            },
          }}>
          <IconButton
            aria-label="close"
            onClick={() => setIsOpenEditModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <Box sx={{ padding: '8px 24px', height: '70px', minWidth: '300px' }}>
            <Input
              ref={refName}
              control={control}
              variant="outlined"
              label={t('name')}
              name="name"
              rules={validation.name}
              fullWidth
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '19px 77px',
            borderTop: '1px solid #dfe0e0',
          }}>
          <Button disabled={isEditLoading} variant="secondary" onClick={handleCloseEditModal}>
            {t('go_back')}
          </Button>
          <LoadingButton
            isloading={isEditLoading}
            onClick={() => {
              onEditScope();
            }}
            title={t('save_changes')}
          />
        </DialogActions>
      </Dialog>
      <DeleteModal
        title={t('delete_sector')}
        description={t('delete_sector_description')}
        open={isOpenDeleteModal}
        onDelete={() => {
          if (hasUserAttached.hasCoachees || hasUserAttached.hasCoaches) {
            setStateAlert({
              open: true,
              message: t('delete_sector_error'),
              type: 'error',
            });
            return stateAlert;
          }
          onRemoveScope(selectedItem.id);
        }}
        handelModal={() => {
          setIsOpenDeleteModal(false);
        }}
      />
    </Stack>
  );
};

export default Scopes;
