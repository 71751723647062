import React, { useRef, useState, useEffect } from 'react';
import GoBackButton from 'ui/components/shared/GoBackButton';
import Button from 'ui/components/Button/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ThemeViewModal from 'ui/components/shared/theme/ThemeViewModal';
import { Box, Paper, Typography } from '@mui/material';
import Switch from 'ui/components/form/Switch';
import TabLangues from 'ui/components/TabLangues/TabLangues';
import TitleInput from 'ui/components/TitleInput';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';

import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useEditTheme from 'hooks/usecase/useEditTheme';
import { getContentTranslate } from 'utils/parse';

import YooptaEditor from 'ui/components/YooptaEditor/YooptaEditor';
import BackgroundPicture from './components/BackgroundPicture/BackgroundPicture';
import EditThemePicturesModal from './components/EditThemePicturesModal/EditThemePicturesModal';
import { formatDateWithSecondes } from 'utils/dateUtils';
import { useMeQuery } from 'hooks/queries';
import { roleList } from 'utils/constUtils';

import styles from './EditThemePage.styles';

export default function EditThemePage() {
  const { themeId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { state: themeState, actions: themeActions } = useEditTheme(themeId);
  const [isOpenModalTheme, setIsOpenModalTheme] = useState(false);
  const [languageSelected, setLanguageSelect] = useState('');
  const [languagesConfigured, setLanguagesConfigured] = useState([]);
  const [subtitle, setSubtitle] = useState('');
  const [isShowModalPicture, setIsShowModalPicture] = useState(false);
  const [description, setDescription] = useState('');
  const languageSelectedRef = useRef(languageSelected);
  const descriptionRef = useRef(description);
  const themeIdRef = useRef(themeId);
  const [isLoadingUpdateFiles, setIsLoadingUpdateFiles] = useState(false);
  const [isDescriptionChanged, setIsDescriptionChanged] = useState(false);
  const isDescriptionChangedRef = useRef(isDescriptionChanged);
  const { data: user } = useMeQuery();

  const { control, setValue } = useForm({ mode: 'all', defaultValues: { name: '' } });

  const theme = themeState.query.data;

  useEffect(() => {
    if (themeIdRef && theme?.id && themeIdRef.current !== theme.id) {
      window.location.reload();
    }

    if (theme && typeof theme.description === 'object' && theme.description !== null) {
      const languages = Object.keys(theme.description);
      setLanguagesConfigured(languages);

      if (languages.length && !languageSelected) setLanguage(languages[0]);
    } else {
      setLanguagesConfigured([]);
      setLanguage('', false);
    }
  }, [theme]);

  const activeLabel = themeActions.watch('active') ? t('theme_activated') : t('theme_disabled');

  const onAddLangueClick = (lng) => {
    setLanguagesConfigured([...languagesConfigured, lng]);
    themeActions.addLanguage(lng);
    setLanguage(lng, false);
  };

  const onLangueClick = (lng) => {
    setLanguage(lng);
  };

  const onDeleteLangue = () => {
    setLanguagesConfigured([...languagesConfigured.filter((line) => line !== languageSelected)]);

    themeActions.deleteLanguage(languageSelected);

    if (languagesConfigured.length) {
      setLanguage(languagesConfigured[0], false);
    } else {
      setLanguage(null, false);
    }
  };

  const setLanguage = (language, sendDescription = true) => {
    if (languageSelected && sendDescription) {
      themeActions.setValueLanguage(languageSelected, 'description', description);
    }

    setLanguageSelect(language);

    const currentTitle = language ? getContentTranslate(theme?.name, language) : '';
    const currentSubtitle = language ? getContentTranslate(theme?.subtitle, language) : '';
    const currentDescription = language ? getContentTranslate(theme?.description, language) : '';

    setValue('name', currentTitle, { shouldDirty: true, shouldValidate: true });
    setSubtitle(currentSubtitle);
    setDescription(currentDescription);
  };

  const onSaveTitle = (title) => {
    themeActions.setValueLanguage(languageSelected, 'name', title);
  };

  const onSaveSubtitle = (event) => {
    themeActions.setValueLanguage(languageSelected, 'subtitle', event.target.value);
  };

  const onChangeDescription = (value) => {
    setDescription(value);
    setIsDescriptionChanged(true);
  };

  const onChangeSubtitle = (event) => {
    setSubtitle(event.target.value);
  };

  useEffect(() => {
    languageSelectedRef.current = languageSelected;
  }, [languageSelected]);

  useEffect(() => {
    isDescriptionChangedRef.current = isDescriptionChanged;
  }, [isDescriptionChanged]);

  useEffect(() => {
    descriptionRef.current = description;
  }, [description]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isDescriptionChangedRef.current) {
        themeActions.setValueLanguage(languageSelectedRef.current, 'description', descriptionRef.current);
        setIsDescriptionChanged(false);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const onChangePictures = async (thumbnailFile, coverFile) => {
    setIsLoadingUpdateFiles(true);
    await themeActions.updatePictures(thumbnailFile, coverFile);
    setIsLoadingUpdateFiles(false);
    setIsShowModalPicture(false);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.headerActions}>
        <GoBackButton
          text="theme_editor.back"
          onClick={() => {
            navigate('/customizaton#themes');
          }}
        />

        <Box>
          <Switch
            sx={{ color: 'primary.grey' }}
            control={themeState.form.control}
            variant="outlined"
            label={activeLabel}
            labelPlacement="end"
            name="active"
            onChange={() => themeActions.submitForm()}
          />

          <Button
            size="xlg"
            greyWithHover
            onClick={() => {
              setIsOpenModalTheme(true);
            }}
            variant="secondary">
            <VisibilityOutlinedIcon sx={{ marginRight: '6px', fontSize: '20px' }} />
            {t('theme_editor.theme_overview')}
          </Button>
        </Box>
      </Box>

      <Box>
        <Typography sx={styles.title} component="h1">
          {t('theme_editor.title')}
        </Typography>

        <Typography sx={styles.subtitle} component="p">
          {t('theme_editor.subtitle')}
        </Typography>
      </Box>

      <Paper sx={styles.content}>
        {theme?.updatedAt && (
          <Box sx={styles.updatedAt}>{t('last_save', { date: formatDateWithSecondes(theme.updatedAt) })}</Box>
        )}

        <BackgroundPicture src={theme?.backgroundPicture} onClick={() => setIsShowModalPicture(true)} />

        <Box sx={{ marginBottom: '32px' }}>
          <TabLangues
            languageSelected={languageSelected}
            languagesEnabled={languagesConfigured}
            onAddLangueClick={onAddLangueClick}
            onDeleteLangue={onDeleteLangue}
            onLangueClick={onLangueClick}
          />
        </Box>

        {languageSelected && (
          <>
            <Box sx={{ marginBottom: '24px' }}>
              <TitleInput
                name="name"
                disabledTooltip
                control={control}
                sxInputProps={styles.titleInput}
                exportTitle={onSaveTitle}
              />
            </Box>

            <Box sx={{ marginBottom: '32px' }}>
              <TextField
                sx={styles.subTitleInput}
                label={t('edit_theme.subtitle_label')}
                value={subtitle}
                rows={1}
                maxRows={1}
                multiline
                control={control}
                name="subtitle"
                variant="outlined"
                onChange={onChangeSubtitle}
                onBlur={onSaveSubtitle}
              />
            </Box>

            <Box sx={styles.editorContainer}>
              {languageSelected && (
                <YooptaEditor
                  key={`${languageSelected}_${theme.id}`}
                  disablePlugins={user.role === roleList.ADMIN ? [] : ['accordion']}
                  folderUpload={`companies/${theme?.company?.id}/themes/${theme?.id}/uploads/`}
                  style={{ width: '100%' }}
                  value={description}
                  onChange={onChangeDescription}
                />
              )}
            </Box>
          </>
        )}
      </Paper>

      {
        <ThemeViewModal
          theme={theme}
          themeId={themeId}
          open={isOpenModalTheme}
          onClose={() => {
            setIsOpenModalTheme(false);
          }}
        />
      }

      {isShowModalPicture && (
        <EditThemePicturesModal
          isLoading={isLoadingUpdateFiles}
          theme={theme}
          open={isShowModalPicture}
          onChangeFiles={onChangePictures}
          onClose={() => {
            setIsShowModalPicture(false);
          }}
        />
      )}
    </Box>
  );
}
