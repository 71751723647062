import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { useAlert } from 'common/alertContext';
import { useListSessionTemplatesQuery } from 'hooks/queries';
import { extractAttributesWithNullValues } from 'utils/functions';

const useSessionTemplates = (companyId, themeId) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [editingsessionTemplate, setEditingsessionTemplate] = useState(null);

  const { handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    mode: 'all',
    defaultValues: {
      session_order: null,
      session_title: '',
      session_subtitle: '',
      session_agenda: '',
    },
  });
  const queryClient = useQueryClient();
  const { setStateAlert } = useAlert();

  const { data: sessionTemplateList, isLoading } = useListSessionTemplatesQuery({});

  const mutationDelete = useMutation(api.SesstionTemplates.delete, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-session-templates']);
      setStateAlert({ open: true, message: 'session template successfully deleted' });
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onRemovesessionTemplate = (id) => {
    mutationDelete.mutate(id);
  };

  const mutationAdd = useMutation(api.SesstionTemplates.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-session-templates']);
      setStateAlert({ open: true, message: 'session template successfully created' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onAddsessionTemplate = handleSubmit(async (data) => {
    mutationAdd.mutate({ ...data, companyId: companyId, themeId: themeId });
  });

  const mutationDuplicate = useMutation(api.SesstionTemplates.create, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-session-templates']);
      setStateAlert({ open: true, message: 'session template successfully duplicated' });
      setIsOpenAddModal(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  const onDuplicatesessionTemplate = async (data) => {
    mutationDuplicate.mutate({
      ...data,
      session_title: data.session_title + '-copy',
      companyId: companyId,
      themeId: themeId,
    });
  };

  const mutationEdit = useMutation(api.SesstionTemplates.edit, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['get-list-session-templates']);
      setStateAlert({ open: true, message: 'session template successfully edited' });
      setEditingsessionTemplate(false);
      reset();
    },
    onError: (error) => setStateAlert({ open: true, message: `${error.response.data.error}`, type: 'error' }),
  });

  useEffect(() => {
    if (editingsessionTemplate) {
      setValue('session_title', editingsessionTemplate.session_title);
      setValue('session_subtitle', editingsessionTemplate.session_subtitle);
      setValue('session_order', editingsessionTemplate.session_order);
      setValue('session_agenda', editingsessionTemplate.session_agenda);
      setIsOpenEditModal(true);
    }
  }, [editingsessionTemplate]);

  const onEditsessionTemplate = handleSubmit(async (data) => {
    mutationEdit.mutate({
      id: editingsessionTemplate.id,
      payload: { ...data, companyId: companyId, themeId: themeId },
    });
  });

  const submitEditMarketingPicture = async () => {
    const data = getValues();
    if (!!data.marketingPicture && typeof data.marketingPicture !== 'string') {
      const { url } = await api.Users.upload(data.marketingPicture);
      data.marketingPicture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
      setTimeout(() => {
        setValue('marketingPicture', '' + process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
      }, 100);
    } else {
      delete data.marketingPicture;
    }
  };

  const submitEditBackgroundPicture = async () => {
    const data = getValues();
    if (!!data.backgroundPicture && typeof data.backgroundPicture !== 'string') {
      const { url } = await api.Users.upload(data.backgroundPicture);
      data.backgroundPicture = process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key;
      setTimeout(() => {
        setValue('backgroundPicture', '' + process.env.REACT_APP_LINK_BUCKET_SCALEWAY + url.Key);
      }, 100);
    } else {
      delete data.backgroundPicture;
    }
  };

  const sessionTemplates =
    sessionTemplateList &&
    sessionTemplateList
      .filter((sessionTemplate) => sessionTemplate.themeId == themeId)
      .sort((a, b) => a.session_order - b.session_order);

  const onReorderSessionTemplates = async (newSessionTemplates) => {
    const newSessionTemplatesClone = [...newSessionTemplates];
    newSessionTemplatesClone.map((item) => {
      const payload = extractAttributesWithNullValues(item);
      mutationEdit.mutate({
        id: payload.id,
        payload: { ...payload },
      });
    });
    Promise.all(newSessionTemplatesClone).then((values) => {});
  };

  return {
    isLoading: isLoading,
    sessionTemplates: sessionTemplates,
    control,
    watch,
    reset,
    getValues,
    onRemovesessionTemplate,
    onAddsessionTemplate,
    isOpenAddModal,
    setIsOpenAddModal,
    setEditingsessionTemplate,
    setIsOpenEditModal,
    isOpenEditModal,
    onEditsessionTemplate,
    editingsessionTemplate,
    submitEditMarketingPicture,
    submitEditBackgroundPicture,
    onDuplicatesessionTemplate,
    onReorderSessionTemplates,
  };
};

export default useSessionTemplates;
